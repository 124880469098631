import React, { useState, useEffect } from 'react';
import { sha256 } from 'js-sha256';

import storage from '../../api/localstorage';
import AppConstants from '../../coreComponent/AppConstants';
import Loader from '../../coreComponent/Loader/index';
import Input from '../../coreComponent/Input/index';
import Button from '../../coreComponent/Button/index';
import Header from '../Header';
import './WhatsappAadhaarVerification.scss';
import utils from '../../utils/index';
import queryString from 'query-string';

export default function WhatsappAadhaarVerification(props) {
  const [loading, setLoading] = useState(true);
  const [proceedLoading, setProceedLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [clientCode] = useState('SPRI2175');
  const [requestId] = useState(new Date().valueOf());
  const [stan] = useState(new Date().valueOf() + 2);
  const [apiKey] = useState(AppConstants.apiKey);
  const [salt] = useState(AppConstants.salt);
  const [otpRequired] = useState('N');
  const [hash, setHash] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    let hashString = clientCode + '|' + requestId + '|' + apiKey + '|' + salt;
    setHash(sha256(hashString));
    getDetails();
  }, []);

  const getDetails = () => {
    setRedirectUrl(
      window.location.href.split('/')[0] +
        '/' +
        window.location.href.split('/')[1] +
        '/' +
        window.location.href.split('/')[2] +
        '/' +
        window.location.href.split('/')[3] +
        '/result'
    );
    let tokenString = window.location.href.split('/')[4];
    let newToken = tokenString.replace(/\+/g, '.');
    storage.setWhatsappAadhaarToken(newToken);
    props
      .getDetailsAPI()
      .then((response) => {
        setLoading(false);
        setPhoneNumber(response.phoneNumber.split('+91')[1]);
      })
      .catch((error) => {
        props.history.push('/aadhaar-verification-portal/result', 'expired');
      });
  };

  const handleProceed = () => {
    if (phoneNumber.length !== 10 || !phoneNumber.match('^[6-9][0-9]*')) {
      setPhoneError('Please enter a valid Phone Number');
    } else {
      setPhoneError('');
      setProceedLoading(true);
      props.setWhatsappNewNumber(phoneNumber);
      storePhoneNumber();
    }
  };

  const storePhoneNumber = () => {
    let requestBody = {
      phoneNumber: props.whatsappAadhaarDetails.phoneNumber,
      verificationNumber: phoneNumber
    };

    props
      .storePhoneNumberAPI(requestBody)
      .then(() => {
        callKhoslaInitAPI();
      })
      .catch((error) => {
        setProceedLoading(false);
      });
    callKhoslaInitAPI();
  };

  const getPersonId = () => {
    const token = props.match.params.token.split('+');
    let personId = utils.decode_base64(token[1]);
    personId = utils.convertFromBinary(personId);

    return personId.personId;
  };
  const callKhoslaInitAPI = () => {
    let requestBody = {
      personId: getPersonId(),
      client_code: clientCode,
      request_id: requestId,
      api_key: apiKey,
      redirect_url: redirectUrl,
      stan: stan,
      otp_required: otpRequired,
      mobile: phoneNumber,
      salt: salt,
      hash: hash
    };

    props
      .khoslaInitAPI(queryString.stringify(requestBody))
      .then((response) => {
        window.open(response.data.url, '_self');
      })
      .catch(() => {
        setProceedLoading(false);
      });
  };

  const handleCancel = () => {
    setCancelLoading(true);
    let requestBody = {
      message:
        'You have cancelled your Aadhaar Verification. Please send *SpringScan* again to start the verification process.',
      phoneNumber: props.whatsappAadhaarDetails.phoneNumber
    };

    props
      .cancelProcessAPI(requestBody)
      .then(() => {
        window.open(AppConstants.whatsAppNumberAPI, '_self');
      })
      .catch((error) => {
        setCancelLoading(false);
      });
  };

  return (
    <div className={'whatsapp-aadhaar-verification-ctr'}>
      <Header />
      <div className={'main-ctr'}>
        <div className={'heading'}>{'SpringScan Verification Portal'}</div>
        {loading ? (
          <div className={'loader'}>
            <Loader size={'60px'} color={'#999999'} width={'0.4rem'} />
          </div>
        ) : (
          <div>
            <div className={'sub-heading'}>
              {
                "Welcome to SpringScan Aadhaar Verification Portal. Please click 'Proceed' to initiate the verification process."
              }
            </div>
            <Input
              label={'Phone Number'}
              type={'text'}
              maxLength={10}
              value={phoneNumber}
              onChange={(value) => {
                setPhoneError('');
                setPhoneNumber(value);
              }}
              placeholder={'Phone Number'}
              error={phoneError}
            />
            <div className={'button-ctr'}>
              <div className={'button'}>
                <Button
                  text={'Proceed'}
                  onClick={handleProceed}
                  height={'50px'}
                  isDisabled={cancelLoading || proceedLoading}
                  isLoading={proceedLoading}
                />
              </div>
              <div className={'button'}>
                <Button
                  text={'Cancel'}
                  onClick={handleCancel}
                  height={'50px'}
                  isDisabled={cancelLoading || proceedLoading}
                  isLoading={cancelLoading}
                  isWhiteBtn={true}
                />
              </div>
            </div>
            <div className={'sub-title'}>
              {
                '* The Phone Number you are providing must be linked with the Aadhaar Card which you are verifying.'
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
