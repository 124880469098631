import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import UploadDocument from '../../../coreComponent/UploadDocument/index';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';

import Checkbox from '../../../coreComponent/Checkbox/index';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function AadhaarMasking(props) {
  const [document1, setDocument1] = useState('');
  const [document2, setDocument2] = useState('');
  const [isConsent, setIsConsent] = useState(false);
  const [docError, setDocError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [docLoading, setDocLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, seReqData] = useState('');
  const [responseData, setResponseData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (props.uiActive === 'Masking') {
      if (props.aadhaarMaskingResponse && !isEmpty(props.aadhaarMaskingResponse)) {
        setDocLoading(true);
        loadData();
        setResponseData(JSON.stringify(props.aadhaarMaskingResponse));
        setIsConsent(true);
        setIsEditable(false);
        setIsSubmitted(true);
      }
    }
  }, []);

  useEffect(() => {
    setIsDisabled(!isConsent);
  }, [document1]);

  const loadData = () => {
    let data = props.uiPerson;
    if (data && data.documents && data.documents.ind_aadhaar) {
      let doc1 = '';
      let doc2 = '';
      let requestData = {
        docType: 'mask_aadhaar',
        aadhaar_url: [],
        consent: isConsent
      };
      if (data.documents.ind_aadhaar.document1) {
        doc1 = data.documents.ind_aadhaar.document1;
        setDocument1(doc1);
        requestData.aadhaar_url.push(doc1);
      }
      if (data.documents.ind_aadhaar.document2) {
        doc2 = data.documents.ind_aadhaar.document2;
        setDocument2(doc2);
        requestData.aadhaar_url.push(doc2);
      }
      if (doc1) {
        setRequestHeaders('Content type: application/json');
        seReqData(JSON.stringify(requestData));
      }
    }
    setDocLoading(false);
  };

  const getDocumentUrl = (url, index) => {
    if (index === 1) {
      setDocument1(url);
      setDocError('');
    } else {
      setDocument2(url);
    }
  };

  const addDocument = () => {
    let requestBody = {
      docType: 'ind_aadhaar',
      document1: document1,
      document2: document2
    };
    let requestData = {
      aadhaar_url: [document1],
      consent: isConsent
    };
    if (document2) {
      requestData.aadhaar_url.push(document2);
    }

    props
      .addDocument(requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        setRequestHeaders('Content type: application/json');
        seReqData(JSON.stringify(requestData));
        aadhaarMasking();
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
          setDocError(error.data.message || 'Something went wrong.');
        }
        setIsLoading(false);
        setIsEditable(true);
        setIsDisabled(true);
      });
  };

  const aadhaarMasking = () => {
    let requestBody = {
      docType: 'mask_aadhaar',
      personId: props.personId,
      manual_input: {
        aadhaar_url: document2 ? [document1, document2] : [document1]
      }
    };

    props
      .aadhaarMasking(requestBody)
      .then((response) => {
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(response));
        setSuccessMsg('Aadhaar Masking successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (document1) {
        setDocError('');
        setIsLoading(true);
        setIsEditable(false);
        addDocument();
      } else {
        setDocError('Front image is missing');
      }
    }
  };

  const reset = () => {
    props.invalidateAadhaarMaskingResponse();
    props.invalidateAadhaarOcrResponse();
    if (props.identityDocType === 'ind_aadhaar') {
      props.invalidateIdentityResponse();
    }
    setDocument1('');
    setDocument2('');
    setIsConsent(false);
    setDocError('');
    setIsEditable(true);
    setDocLoading(false);
    setIsLoading(false);
    setIsDisabled(true);
    setIsSubmitted(false);
    setRequestHeaders('');
    seReqData('');
    setResponseData('');
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Aadhaar Masking'}</div>
      <div className={'sub-heading'}>
        {'Masks an Aadhaar image to hide first 12 digits of Aadhaar ID number'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {docLoading ? (
            <div className={'upload-double-ctr'}>
              <div className={'upload-small-ctr'}>
                <div className={'loader'}>
                  <Loader color={'#002C9D'} size={'24px'} />
                </div>
              </div>
              <div className={'upload-small-ctr'}>
                <div className={'loader'}>
                  <Loader color={'#002C9D'} size={'24px'} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <UploadDocument
                isDouble={true}
                image1={document1}
                image2={document2}
                isEditable={isEditable}
                personId={props.personId}
                getUrl={(url, index) => getDocumentUrl(url, index)}
                handleCancel={(index) => {
                  setDocError('');
                  props.invalidateAadhaarOcrResponse();
                  if (index === 1) {
                    setDocument1('');
                    setIsDisabled(true);
                    if (props.identityDocType === 'ind_aadhaar') {
                      props.invalidateIdentityResponse();
                    }
                  } else {
                    setDocument2('');
                  }
                }}
              />
              {docError ? <div className={'upload-error'}>{docError}</div> : null}
            </div>
          )}
          <DemoNotes showNotes={'document'} />
          <div className={'consent-checkbox-ctr'}>
            <Checkbox
              label={'I hereby confirm to proceed.'}
              checked={isConsent}
              disabled={!isEditable}
              onClick={() => {
                setIsDisabled(isConsent);
                setIsConsent(!isConsent);
                let requestData = {
                  aadhaar_url: [document1],
                  consent: !isConsent
                };
                if (document2) {
                  requestData.aadhaar_url.push(document2);
                }
                seReqData(JSON.stringify(requestData));
              }}
            />
          </div>
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={responseData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
