import React from 'react';

import policy from '../../Assets/documents/PrivacyPolicy.pdf';

export default function PrivacyPolicy(props) {
  return (
    <div data-testid='privacypolicy'>
      <embed type='application/pdf' src={policy} style={{ width: '100vw', height: '100vh' }} />
    </div>
  );
}
