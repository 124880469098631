import React, { useState, useEffect } from 'react';
import LoaderSpinner from 'react-loader-spinner';
import storage from '../../api/localstorage';
import Input from '../../coreComponent/Input/index';
import Button from '../../coreComponent/Button/index';
import { validateEmail, validatesPhoneNumber, getIntlPhoneNumber } from '../../utils/validations';
import SideNavbar from '../SideNavbar';
import * as msal from '@azure/msal-browser';
import { GoogleLogin } from '@react-oauth/google';
import AppHelper from '../../coreComponent/AppHelper';
import AppConstants from '../../coreComponent/AppConstants';
import { LOGIN_ERROR_MESSAGES } from '../../utils/constants';
import msEnvConstants from '../../config/microsoft-auth.json';
import './Login.scss';
import Countdown from 'react-countdown';
import { Alert } from 'react-bootstrap';
import moment from 'moment';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

export default function Login(props) {
  const { GoogleOrMsLogin, loginAPI, setSignupEmail, history } = props;
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [loginAttemptsCount, setLoginAttemptsCount] = useState(0);
  const [lastFailedAttempt, setLastFailedAttempt] = useState(null);
  const message = new URLSearchParams(document.location.search).get('message') || null;

  useEffect(() => {
    if (message) {
      toast.success(message);
      history.push({ search: '' });
    }
  }, [message]);

  useEffect(() => {
    window && window.scrollTo(0, 0);

    const loginAttemptCount = storage.getLoginAttemptsCount() || 0;
    const lastFailedLoginStamp = storage.getFailedAttemptStamp() || null;
    const userEmail = secureLocalStorage.getItem('user');

    setLoginAttemptsCount(loginAttemptCount);
    setLastFailedAttempt(lastFailedLoginStamp);
    setLoginId(userEmail || '');

    const devToken = storage.getDevToken() ?? '';
    const prodToken = storage.getProdToken() ?? '';

    if (devToken && prodToken) {
      history.push('/dashboard');
    }
  }, []);

  const verifyLoginId = (value) => {
    const userEmail = secureLocalStorage.getItem('user');
    if (!userEmail) {
      secureLocalStorage.setItem('user', value);
    } else if (userEmail && userEmail !== value) {
      handleClearFailedAttempts();
      secureLocalStorage.setItem('user', value);
    }

    if (!validatesPhoneNumber(value) && !validateEmail(value)) {
      setError('Incorrect Email or Phone number');
    } else {
      setError('');
    }
  };

  const login = async () => {
    setSubmitting(true);
    const isPhoneNumber = validatesPhoneNumber(loginId);
    const phoneNumber = isPhoneNumber ? getIntlPhoneNumber(loginId)?.number ?? loginId : null;

    const body = {
      ...(isPhoneNumber ? { phone_number: phoneNumber } : { email: loginId }),
      password
    };
    try {
      await loginAPI(body);
      history.push('/dashboard');
    } catch (err) {
      console.error('Login API Error: ', err);
      handleLoginError(err);
    }
  };

  const handleLoginError = (err) => {
    setSubmitting(false);
    switch (err?.status) {
      case 403:
        handleLoginFailureAttempts(err?.data);
        setPasswordError(LOGIN_ERROR_MESSAGES.GENERIC_ERR);
        break;
      case 404:
        if (err?.data?.message.includes('Password')) {
          setPasswordError(LOGIN_ERROR_MESSAGES.PASSWORD_NOT_FOUND);
        } else {
          setPasswordError(LOGIN_ERROR_MESSAGES.GENERIC_ERR);
        }
        break;
      case 405:
        if (!validatesPhoneNumber(loginId)) {
          setSignupEmail(loginId);
        }
        history.push('/send-verification-link');
        break;
      case 429:
        handleLoginFailureAttempts(err?.data);
        break;
      default:
        setError(LOGIN_ERROR_MESSAGES.DEFAULT_ERROR);
    }
  };

  const handleLoginFailureAttempts = (data) => {
    if (data?.numberOfFailedAttempts >= 3) {
      const waitTime = moment(data?.lastAttempt).add(15, 'minutes').local();
      setLastFailedAttempt(new Date(waitTime));
      storage.saveLoginAttemptsCount(data?.numberOfFailedAttempts || 0);
      storage.saveFailedAttemptStamp(waitTime);
    }
    setLoginAttemptsCount(data?.numberOfFailedAttempts || 0);
  };

  const forgotPassword = () => {
    props.history.push('/forgot-password');
  };

  const signup = () => {
    props.history.push('/sign-up');
  };

  const privacyPolicy = () => {
    props.history.push('/privacy-policy');
  };

  const termsAndCondition = () => {
    props.history.push('/terms-and-condition');
  };

  const msClientId = AppHelper?.isLocallyServed()
    ? msEnvConstants?.local?.clientId
    : msEnvConstants?.web?.clientId;

  const msalConfig = {
    auth: {
      clientId: msClientId,
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: `${AppConstants.baseURL}`,
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
      }
    }
  };

  const loginRequest = {
    scopes: ['User.Read'],
    forceRefresh: true
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  const handleMicrosoftLogin = async () => {
    try {
      const msLoginObj = await msalInstance.loginPopup(loginRequest);
      handleSuccess(null, msLoginObj);
    } catch (e) {
      console.error('MS login Err: ', e);
    }
  };

  const handleSuccess = async (googleData, microsoftData = null) => {
    const path = microsoftData ? 'microsoft-login' : 'google-login';
    const body = { token: microsoftData ? microsoftData?.accessToken : googleData?.credential };

    try {
      setLoading(true);
      await GoogleOrMsLogin(path, body);
      setLoading(false);
      history.push('/dashboard');
    } catch (err) {
      setLoading(false);
      console.error('Google Or MS Login API Error: ', err);
    }
  };

  const handleFailure = (e) => {
    console.error(e.error);
  };

  const countdownTimerContent = ({ minutes, seconds, completed }) => {
    if (completed) {
      handleClearFailedAttempts();
    }
    return (
      <div className='time'>
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </div>
    );
  };

  const handleClearFailedAttempts = () => {
    storage.clearLoginAttemptsCount();
    storage.clearLoginStamp();
    setLastFailedAttempt(null);
    setLoginAttemptsCount(0);
  };

  const handleKeyPress = (e) => {
    if (
      e.key === 'Enter' &&
      loginId &&
      password &&
      !error &&
      !passwordError &&
      loginAttemptsCount < 3
    ) {
      login();
    }
  };

  return (
    <div className={'login-main-ctr'}>
      {isLoading && (
        <div className='loading'>
          <LoaderSpinner type='ThreeDots' color='#002c9d' height='100' width='100' />
        </div>
      )}
      <SideNavbar />
      <div className={'login-ctr'}>
        <div className={'login-form-ctr'}>
          <div className={'heading'}>{'Login'}</div>
          <div className='gm-auth-buttons'>
            <div className={'google-container'}>
              <GoogleLogin
                onSuccess={handleSuccess}
                onError={(e) => {
                  handleFailure(e);
                }}
                cancel_on_tap_outside={false}
                useOneTap={true}
                theme='filled_blue'
                size='large'
                width='268px'
                shape='square'
              />
            </div>
            <button className={'microsoftLoginContainer'} onClick={handleMicrosoftLogin}>
              <div className={'microsoftLoginIcon'}></div>
              <div className={'microsoftLoginText'}>Sign in with Microsoft</div>
            </button>
          </div>
          <div className={'separator'}>
            <span>OR</span>
          </div>

          <Input
            label={'Email or Phone number*'}
            disabled={submitting}
            type={'text'}
            value={loginId}
            onBlur={(value) => {
              verifyLoginId(value);
            }}
            onChange={(value) => {
              setLoginId(value);
              setError('');
            }}
            placeholder={'Enter Email ID or Phone Number'}
            error={error}
            errorWithIcon={true}
            onKeyPress={handleKeyPress}
          />
          <Input
            label={'Password*'}
            disabled={submitting}
            type={'password'}
            id={'password'}
            value={password}
            onChange={(value) => {
              setPassword(value);
              setPasswordError('');
            }}
            placeholder={'Enter Password'}
            error={passwordError}
            errorWithIcon={true}
            onKeyPress={handleKeyPress}
          />
          <div className={'forgot-btn'} onClick={forgotPassword}>
            {'Forgot Password?'}
          </div>
          {loginAttemptsCount > 0 && (
            <div className='invalid-3-attempts-message'>
              <Alert variant={'danger'}>
                {loginAttemptsCount > 0 && lastFailedAttempt <= 2 && (
                  <>
                    <h6>Incorrect password. You have {3 - loginAttemptsCount} attempts left.</h6>
                    <p>
                      Warning : After 3 consecutive unsuccessful login attempts, your account will
                      be locked for 15 minutes
                    </p>
                  </>
                )}
                {loginAttemptsCount >= 3 && (
                  <p>
                    Your account has been locked, You have reached max number of failed attempts.
                    Please try again after 15 mins or <a onClick={forgotPassword}>Reset password</a>
                    .
                  </p>
                )}
              </Alert>
            </div>
          )}
          <div className={'login-btn'}>
            <Button
              text={'Login'}
              onClick={login}
              width={'120px'}
              height={'50px'}
              isDisabled={
                !loginId || !password || error || passwordError || loginAttemptsCount >= 3
              }
              isLoading={submitting}
              isWhiteBtn={!loginId || !password || error || passwordError || submitting}
            />
            {loginAttemptsCount >= 3 && (
              <div className='timerContainer'>
                Please retry after{' '}
                <Countdown date={lastFailedAttempt} renderer={countdownTimerContent} /> minutes.
              </div>
            )}
          </div>
          <div className={'signup-ctr'}>
            <div className={'text'}> {'Don’t have an account?'}</div>
            <div className={'link'} onClick={signup}>
              {'Sign up'}
            </div>
          </div>
          <div className={'login-footer'}>
            <div className={'link'} onClick={privacyPolicy}>
              {'Privacy Policy'}
            </div>
            <div className={'text'}>{'and'}</div>
            <div className={'link'} onClick={termsAndCondition}>
              {'Terms and Condition'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
