import React, { useState, useEffect } from 'react';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import Image from '../../../../coreComponent/Image';
import Icon from '../../../../coreComponent/Icon';
import Tooltip from '../../../../coreComponent/Tooltip/index';
import Table from '../../../../coreComponent/Table/index';
import DatePicker from '../../../../coreComponent/DatePicker/index';
import SearchInput from '../../../../coreComponent/SearchInput/index';
import MultiSelect from '../../../../coreComponent/MultiSelect/index';
import { getDocType, getPersonStatus, getPersonName } from '../../../../utils/utilities';
import './PeopleAdded.scss';

export default function PeopleAdded(props) {
  const [tableRows, setTableRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState(true); // true->asec, false->desc
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });
  const [statusFilter, setStatusFilter] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [name, setName] = useState('');
  const statusOptions = ['OCR Done', 'Pending', 'Govt Verified', 'Failed', 'Face Matched'];

  useEffect(() => {
    if (!props.displayFilters) {
      loadDataWithoutFilters();
    } else {
      loadData();
    }
  }, [props.liveMode, props.displayFilters]);

  useEffect(() => {
    if (props.people && !isEmpty(props.people)) {
      let rows = getRowData(props.people.data);
      setTableRows(rows);
      setTotalCount(props.people.total_count);
    }
  }, [props.people]);

  const headCells = [
    {
      id: 'id',
      label: ''
    },
    {
      id: 'personName',
      label: 'NAME',
      width: '200px',
      highlight: true,
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('name');
      }
    },
    {
      id: 'dateAdded',
      label: 'DATE ADDED',
      width: '150px',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('dateAdded');
      }
    },
    {
      id: 'documents',
      label: 'DOCUMENTS',
      width: '600px'
    }
  ];

  const loadData = () => {
    setPage(1);
    setRowsPerPage(10);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    if (statusFilter && statusFilter.length > 0) {
      data.status_filter = statusFilter;
    }
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  const loadDataWithoutFilters = () => {
    setSortBy('');
    setSortOrder(true);
    setDateFilter({ start: '', end: '' });
    setStatusFilter([]);
    setSelectedStatus([]);
    setName('');
    setPage(1);
    setRowsPerPage(10);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  //this is for each row the heading creation  with whatever id for headcells
  const createData = (id, personName, dateAdded, documents) => {
    return { id, personName, dateAdded, documents };
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value, key) {
      let rowData = createData(
        value.person_id,
        getPersonName(value.name),
        moment(value.date_added).utcOffset('+05:30').format('DD-MM-YYYY'),
        getDocuments(value.documents)
      );
      data.push(rowData);
    });
    return data;
  };

  const getDocuments = (documents) => {
    let docArray = [];
    for (let doc in documents) {
      if (
        getDocType(doc) !== 'UPI ID' &&
        getDocType(doc) !== 'Bank Account' &&
        documents[doc] &&
        !isEmpty(documents[doc])
      ) {
        let document = { name: getDocType(doc), icon: getStatusIcon(documents[doc].status) };
        docArray.push(document);
      }
    }
    return (
      <div className={'documents-ctr'}>
        {docArray && docArray.length > 0
          ? docArray.map((value, index) => (
              <div key={index} className={'document'}>
                {value.icon}
                <div>{value.name}</div>
              </div>
            ))
          : null}
      </div>
    );
  };

  const getStatusIcon = (personStatus) => {
    let status = getPersonStatus(personStatus);
    let icon = null;
    let width = '0px';
    let left = '-40px';
    switch (status) {
      case 'OCR Done':
        width = '80px';
        left = '-36px';
        icon = <Icon icon={'SuccessStatus'} color={'#65D782'} width={'16'} height={'16'} />;
        break;
      case 'Pending':
        width = '66px';
        left = '-29px';
        icon = <Icon icon={'PendingStatus'} color={'#FF891C'} width={'16'} height={'16'} />;
        break;
      case 'Failed':
        width = '52px';
        left = '-22px';
        icon = <Icon icon={'FailedStatus'} color={'#FF6C64'} width={'16'} height={'16'} />;
        break;
      case 'Face Matched':
        width = '104px';
        left = '-48px';
        icon = <Image name={'faceMatchedIcon.svg'} />;
        break;
      case 'Govt Verified':
        width = '94px';
        left = '-43px';
        icon = <Icon icon={'GovtVerifiedStatus'} color={'#506EF8'} width={'16'} height={'16'} />;
        break;
    }
    return (
      <div className={'icon-ctr'}>
        <div className={'tooltip-icon'}>{icon}</div>
        <Tooltip text={status} left={left} top={'8px'} width={width} position={'bottom'} />
      </div>
    );
  };

  const applySorting = (key) => {
    setLoading(true);
    setPage(1);
    let data = {
      range: rowsPerPage,
      page: 1
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    if (statusFilter && statusFilter.length > 0) {
      data.status_filter = statusFilter;
    }
    switch (key) {
      case 'name': {
        setSortBy('name.first');
        setSortOrder(!sortOrder);
        data.sort_by = 'name.first';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'dateAdded': {
        setSortBy('createdAt');
        setSortOrder(!sortOrder);
        data.sort_by = 'createdAt';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
    }
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setLoading(true);
    let data = {
      range: rowsPerPage,
      page: newPage
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    if (statusFilter && statusFilter.length > 0) {
      data.status_filter = statusFilter;
    }
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
    setLoading(true);
    let data = {
      range: parseInt(value, 10),
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    if (statusFilter && statusFilter.length > 0) {
      data.status_filter = statusFilter;
    }
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applyDateFilter = (date) => {
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (date && date.start && date.end) {
      setDateFilter(date);
      data.start_date = date.start;
      data.end_date = date.end;
    } else {
      setDateFilter({ start: '', end: '' });
    }
    if (name) {
      data.name_filter = name;
    }
    if (statusFilter && statusFilter.length > 0) {
      data.status_filter = statusFilter;
    }
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applySearchFilter = (value) => {
    props.history.push('/usage/summary/people-added');
    setName(value);
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1,
      name_filter: value
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (statusFilter && statusFilter.length > 0) {
      data.status_filter = statusFilter;
    }
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applyStatusFilter = (value) => {
    setSelectedStatus(value);
    let statuses = [];
    value.map((status) => {
      statuses.push(getPersonStatus(status));
    });
    setStatusFilter(statuses);
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (value && value.length > 0) {
      data.status_filter = statuses;
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    props.getPeopleAPI(data).then(() => {
      setLoading(false);
    });
  };

  return (
    <div className={'people-added-ctr'}>
      <div data-testid='searchInput' className={'search-ctr'}>
        <SearchInput
          value={name}
          handleSearch={(value) => applySearchFilter(value)}
          handleClear={() => applySearchFilter('')}
        />
      </div>
      {props.displayFilters ? (
        <div data-testid='tableElement' className={'heading-ctr'}>
          <div data-testid='summaryHeading' className={'heading'}>
            {'Summary'}
          </div>
          <div data-testid='datePickerElement' className={'filter-ctr'}>
            <div data-testid='multiSelectElement' className={'select-filter'}>
              <MultiSelect
                placeholder='All Statuses'
                value={selectedStatus}
                options={statusOptions}
                onChange={(value) => {
                  applyStatusFilter(value);
                }}
              />
            </div>
            <DatePicker
              emptyFilter={true}
              startDate={dateFilter.start}
              endDate={dateFilter.end}
              onClear={applyDateFilter}
              onSubmit={applyDateFilter}
            />
          </div>
        </div>
      ) : null}
      <Table
        headCells={headCells}
        rowData={tableRows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(pageNo) => handleChangePage(pageNo)}
        handleChangeRowsPerPage={(rows) => handleChangeRowsPerPage(rows)}
        loading={loading}
        clickable={true}
        onRowClick={(personId) => {
          props.history.push('/usage/summary/person', { personId: personId });
        }}
      />
    </div>
  );
}
PeopleAdded.defaultProps = {
  displayFilters: true
};
