import { connect } from 'react-redux';
import { forgotPassword as forgotPasswordAPI, logout } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPasswordAPI: (data) => dispatch(forgotPasswordAPI(data)),
    logout: () => dispatch(logout('direct', null, false, false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
