import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { getErrorMessage, saveLoginResponse } from '../utils/utilities';

import storage from '../api/localstorage';
import {
  login as loginAPI,
  signup as signupAPI,
  sendVerificationLink as sendVerificationLinkAPI,
  accountSetup as accountSetupAPI,
  forgotPassword as forgotPasswordAPI,
  setPassword as setPasswordAPI,
  getInvoiceDetails as getInvoiceDetailsAPI,
  GoogleOrMsLogin as GoogleOrMsLoginAPI,
  logout as logoutAPI
} from '../api/authentication';
import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';

export const LOGIN_DATA = 'LOGIN_DATA';
export const SIGNUP_EMAIL = 'SIGNUP_EMAIL';
export const LOGOUT = 'LOGOUT';

export const setLoginData = (data) => {
  return {
    type: LOGIN_DATA,
    data
  };
};

export const setSignupEmail = (data) => {
  return {
    type: SIGNUP_EMAIL,
    data
  };
};

export const setLogout = (data) => {
  return {
    type: LOGOUT,
    data
  };
};

export const login = (data) => (dispatch) => {
  return loginAPI(data)
    .then((res) => {
      const data = res?.data;
      if (data) {
        saveLoginResponse(data);
        dispatch(setLoginData(data));
      }
      return data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const signup = (data) => (dispatch) => {
  return signupAPI(data)
    .then((response) => {
      dispatch(setSignupEmail(data.email));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const sendVerificationLink = (data) => (dispatch) => {
  return sendVerificationLinkAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const accountSetup = (data, config) => (dispatch) => {
  return accountSetupAPI(data, config)
    .then((response) => {
      storage.setDevToken(response.data.dashboard_auth_token_dev);
      storage.setProdToken(
        response.data.dashboard_auth_token_prod
          ? response.data.dashboard_auth_token_prod
          : response.data.dashboard_auth_token_dev
      );
      dispatch(setLoginData(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const forgotPassword = (data) => (dispatch) => {
  return forgotPasswordAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const setPassword = (data) => (dispatch) => {
  return setPasswordAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const getInvoiceDetails = (data) => (dispatch) => {
  return getInvoiceDetailsAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const logout =
  (type = 'direct', history, forceLogout = false, showToast = true) =>
  (dispatch) => {
    const jwtProdToken = storage.getProdToken();
    const jwtDevToken = storage.getDevToken();

    if (forceLogout || (!jwtDevToken && !jwtProdToken)) {
      clearStorage(dispatch, type, history, showToast);
      return;
    }

    if (jwtProdToken || jwtDevToken) {
      const logoutPromisesArray = [];

      const devBaseUrl = AppConstants.testAPIURL;
      const currentBaseUrl = AppConstants.developmentAPIURL;

      if (jwtProdToken) {
        const prodLogoutPromise = logoutAPI(currentBaseUrl, jwtProdToken, AppHelper.getToken());
        logoutPromisesArray.push(prodLogoutPromise);
      }
      if (jwtDevToken) {
        const devLogoutPromise = logoutAPI(devBaseUrl, jwtDevToken, AppHelper.getDevToken());
        logoutPromisesArray.push(devLogoutPromise);
      }

      return Promise.all(logoutPromisesArray)
        .then(() => {
          clearStorage(dispatch, type, history, showToast);
        })
        .catch((err) => {
          console.error('err: ', err);
          clearStorage(dispatch, type, history, showToast);
        });
    }
  };

const clearStorage = (dispatch, type, history, showToast) => {
  const userEmail = secureLocalStorage.getItem('user');
  if (showToast) toast.success('Logged Out Successfully', { toastId: 'logoutSuccess' });
  storage.clearStorage();
  secureLocalStorage.setItem('user', userEmail);
  const loginPath =
    type !== 'direct'
      ? '/login?message=Password successfully changed. Please log in again.'
      : '/login';
  if (history) {
    history.push(loginPath);
  }
  dispatch(setLogout({}));
};

// Google or MS login
export const GoogleOrMsLoginAction = (path, data) => (dispatch) => {
  return GoogleOrMsLoginAPI(path, data)
    .then((res) => {
      const data = res?.data;
      if (data) {
        saveLoginResponse(data);
        dispatch(setLoginData(data));
      }
      return data;
    })
    .catch((err) => {
      toast.error(getErrorMessage(err));
      throw err?.response;
    });
};
