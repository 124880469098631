import { connect } from 'react-redux';
import {
  govtVerification,
  saveDigiLockerToken,
  sendWebhook
} from '../../actions/digiLockerFlowActions';

const mapStateToProps = (state) => {
  return {
    saveDigiToken: state?.digi?.saveDigiToken,
    govtVerify: state?.digi?.govtVerify
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveToken: (data, token, isDirectFlow) =>
      dispatch(saveDigiLockerToken(data, token, isDirectFlow)),
    verifyGovtToken: (body, token, isDirectFlow) =>
      dispatch(govtVerification(body, token, isDirectFlow)),
    sendWebhook: (body, token, isDirectFlow) => dispatch(sendWebhook(body, token, isDirectFlow))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
