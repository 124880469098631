import React, { useState, useEffect } from 'react';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isEmpty } from 'lodash';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function Udyam(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [udyamNumber, setUdyamNumber] = useState('');
  const [enterpriseName, setEnterpriseName] = useState('');
  const [enterpriseNameError, setEnterpriseNameError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [idError, setIdError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['id_number']);

  useEffect(() => {
    if (props.uiActive === 'Udyam') {
      if (
        props.uiPerson.documents.ind_udyam &&
        props.uiPerson.documents.ind_udyam.result.hasOwnProperty('id_number') &&
        !isEmpty(props.udyamResponse)
      ) {
        setUdyamNumber(props.uiPerson.documents.ind_udyam.result.id_number);
        setEnterpriseName(props.uiPerson.documents.ind_udyam.result.enterprise_name);
        setIsDisabled(false);
        setReqData(buildRequestBody());
        setRequestHeaders('Content type: application/json');
      }
      if (
        props.udyamResponse &&
        !isEmpty(props.udyamResponse) &&
        props.udyamResponse.output.source.hasOwnProperty('id_number')
      ) {
        loadData();
      }
    }
  }, []);

  const loadData = () => {
    setResData(JSON.stringify(props.udyamResponse));
    setIsEditable(false);
    setIsSubmitted(true);
    setIsDisabled(false);
    setRequestHeaders('Content type: application/json');
    setUdyamNumber(props.udyamResponse.output.source.id_number);
    setReqData(buildRequestBody());
  };

  const buildRequestBody = () => {
    let requestBody = {
      doc_type: 'ind_udyam',
      personId: props.personId,
      successParameters: successParameters,
      manual_input: {
        id_number: udyamNumber
      }
    };
    if (enterpriseName) {
      requestBody['manual_input']['enterprise_name'] = enterpriseName;
    }
    return requestBody;
  };

  const reset = () => {
    props.invalidateUdyamResponse();
    setUdyamNumber('');
    setIdError('');
    setEnterpriseName('');
    setEnterpriseNameError('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setResData('');
    setErrorCode('');
    setReqData('');
    setSuccessMsg('');
    setErrorMsg('');
    setSuccessParameters(['id_number']);
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('enterprise_name') && !enterpriseName) {
      error = true;
      setEnterpriseNameError('Enterprise Name is Required');
    }
    return error ? true : false;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (
        !isSuccessParameterReqError() &&
        validateUdyamNumber(udyamNumber) &&
        !enterpriseNameError
      ) {
        setIsLoading(true);
        setIsEditable(false);
        udyamCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const udyamCheck = () => {
    let requestBody = {
      docType: 'ind_udyam',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        id_number: udyamNumber
      }
    };
    if (enterpriseName) {
      requestBody['manual_input']['enterprise_name'] = enterpriseName;
    }
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('ind_udyam', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setErrorCode('');
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Udyam check successful.');
        setErrorMsg('');
        setErrorCode('');
        setEnterpriseNameError('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };
  const validateUdyamNumber = (udyamNumber) => {
    if (!udyamNumber) {
      setIdError('ID Number is Required');
      setIsDisabled(true);
      return false;
    } else if (udyamNumber.length < 12) {
      setIdError('ID number must contain 19 characters');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(udyamNumber)) {
      setIdError('Invalid ID number');
      setIsDisabled(true);
      return false;
    } else if (!udyamNumber.match('[A-Z]{5}-[A-Z]{2}-[0-9]{2}-[0-9]{7}')) {
      setIdError('Invalid ID number format');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      setUdyamNumber(udyamNumber);
      setIsDisabled(false);
      return true;
    }
  };

  const validateEnterpriseName = (enterpriseName) => {
    if (isSpecialCharacter(enterpriseName)) {
      setEnterpriseNameError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else if (!enterpriseName.match('^[0-9a-zA-Z]')) {
      setEnterpriseNameError('Enterprise name should be a Alphanumeric String');
      setIsDisabled(true);
      return false;
    } else {
      setEnterpriseNameError('');
      return true;
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Udyam'}</div>
      <div className={'sub-heading'}>
        {
          'Realtime verification of user registration number as per as Ministry of Micro, Small, Medium Enterprise'
        }
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'udyamNumber'}
                  label={'ID Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'enterpriseName'}
                  label={'Enterprise Name'}
                  disabled={false}
                  checked={successParameters.includes('enterprise_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['enterprise_name']));
                    setIsDisabled(false);
                    setEnterpriseNameError('');
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'ID Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={19}
              value={udyamNumber}
              onBlur={(value) => {
                validateUdyamNumber(value);
              }}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIdError('');
                  setIsDisabled(true);
                }
                setUdyamNumber(value.toUpperCase());
              }}
              placeholder={'Ex: UDYAM-UP-00-1234567'}
              error={idError}
              showClear={true}
              onClear={() => {
                setUdyamNumber('');
                setIdError('');
                setIsDisabled(true);
              }}
            />
            <Input
              label={'Enterprise Name'}
              disabled={!isEditable}
              type={'text'}
              maxLength={100}
              value={enterpriseName}
              onBlur={(value) => {
                if (value) validateEnterpriseName(value);
              }}
              onChange={(value) => {
                setIsDisabled(false);
                setEnterpriseNameError('');
                setEnterpriseName(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setEnterpriseName('');
                setEnterpriseNameError('');
                setIsDisabled(false);
              }}
              placeholder={''}
              error={enterpriseNameError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>

          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'ind_udyam'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
