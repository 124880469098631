import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import UploadDocument from '../../../coreComponent/UploadDocument/index';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import Checkbox from '../../../coreComponent/Checkbox';
import { addDocumentOnlyForOCR as addDocumentOnlyForOCRAPI } from '../../../api/demoUI';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import moment from 'moment';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function VoterID(props) {
  const [isOCR, setIsOCR] = useState(true);
  const [voterId, setVoterId] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [yob, setYob] = useState('');
  const [idError, setIdError] = useState('');
  const [dobError, setDobError] = useState('');
  const [yobError, setYobError] = useState('');
  const [nameError, setNameError] = useState('');
  const [document1, setDocument1] = useState('');
  const [document2, setDocument2] = useState('');
  const [docError, setDocError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [docLoading, setDocLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [onlyOcrErrorCode, setOnlyOcrErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [apiActivityIsVerification, setApiActivityIsVerification] = useState(false);
  const [successParameters, setSuccessParameters] = useState(['id_number']);
  const [requestID, setRequestID] = useState('');
  const [onlyOCRDocument1, setOnlyOCRDocument1] = useState('');
  const [onlyOCRDocument2, setOnlyOCRDocument2] = useState('');
  const [ocrReqData, setOcrReqData] = useState('');
  const [ocrResData, setOcrResData] = useState('');
  const [onlyOcrDocError, setOnlyOcrDocError] = useState('');
  const [onlyOcrErrorMsg, setOnlyOcrErrorMsg] = useState('');
  const [successMsgForOcrOnly, setSuccessMsgFroOcrOnly] = useState('');
  const [isSubmittedForOcrOnly, setIsSubmittedForOcrOnly] = useState(false);
  const [isOnlyOcrDisabled, setOnlyOcrIsDisabled] = useState(true);

  useEffect(() => {
    if (props.uiActive === 'Voter') {
      loadData();
      if (props.voterOnlyOcrResponse && !isEmpty(props.voterOnlyOcrResponse)) {
        const responseData = props.voterOnlyOcrResponse;
        setOcrResData(JSON.stringify(responseData));
        setIsSubmittedForOcrOnly(true);
      }
      if (props.voterOcrResponse && !isEmpty(props.voterOcrResponse)) {
        let responseData = props.voterOcrResponse;
        setResData(JSON.stringify(responseData));
        setIsEditable(false);
        setRequestID(responseData.configuration.api_config.request_id);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    if (data && data.documents && data.documents.ind_voter_id) {
      if (data.documents.ind_voter_id.document1 && !isEmpty(props.voterOnlyOcrResponse)) {
        setOnlyOCRDocument1(data.documents.ind_voter_id.document1);
        setOnlyOcrIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setOcrReqData('no data');
      }
      if (data.documents.ind_voter_id.document2 && !isEmpty(props.voterOnlyOcrResponse)) {
        setOnlyOCRDocument2(data.documents.ind_voter_id.document2);
      }
      if (data.documents.ind_voter_id.document1 && !isEmpty(props.voterOcrResponse)) {
        setDocument1(data.documents.ind_voter_id.document1);
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
      }
      if (data.documents.ind_voter_id.document2 && !isEmpty(props.voterOcrResponse)) {
        setDocument2(data.documents.ind_voter_id.document2);
      }
    }
    setDocLoading(false);
  };

  const getDocumentUrl = (url, index) => {
    setDocError('');
    if (index === 1) {
      apiActivityIsVerification ? setDocument1(url) : setOnlyOCRDocument1(url);
      setIsDisabled(false);
      setOnlyOcrIsDisabled(false);
    } else {
      apiActivityIsVerification ? setDocument2(url) : setOnlyOCRDocument2(url);
    }
    setRequestHeaders('Content type: application/json');
    setReqData('no data');
  };

  const addDocument = () => {
    let requestBody = {
      docType: 'ind_voter_id',
      personId: props.personId
    };
    if (document1 || document2 || requestID) {
      if (document1 || document2) {
        requestBody['document_front'] = document1;
        requestBody['document_back'] = document2;
        requestBody['success_parameters'] = ['id_number'];
        if (!document1) {
          setIsLoading(false);
          setIsDisabled(true);
          setDocError('Front image is missing');
          return false;
        }
        addDocumentOnlyForOCRAPI(requestBody)
          .then((res) => {
            const responseData = res.data;
            const requestId = responseData.configuration.api_config.request_id;
            if (requestId) {
              requestBody['request_id'] = requestId;
              requestBody['success_parameters'] = successParameters;
              setRequestID(requestId);
              govtVerificationWithOCR(requestBody);
            } else {
              props.reloadCredits();
              setDocError(error.data.api_status_description || 'Something went wrong.');
            }
          })
          .catch((error) => {
            if (error.status === 402) {
              props.showAlert('insufficient');
            } else {
              props.reloadCredits();
              setDocError(error.api_status_description || 'Something went wrong.');
            }
            setIsLoading(false);
            setIsEditable(true);
            setIsDisabled(true);
          });
      } else {
        requestBody['success_parameters'] = successParameters;
        requestBody['request_id'] = requestID;
        govtVerificationWithOCR(requestBody);
      }
    } else {
      setDocError('Either document image or request ID is missing');
      setIsLoading(false);
      setIsDisabled(true);
      setIsEditable(true);
    }
  };

  const addDocumentOnlyForOCR = () => {
    let requestBody = {
      docType: 'ind_voter_id',
      success_parameters: ['id_number'] /* In ocr only id_number success parameter is req.*/,
      personId: props.personId,
      document_front: onlyOCRDocument1,
      document_back: onlyOCRDocument2
    };
    setOcrReqData(JSON.stringify(requestBody));
    props
      .addDocumentOnlyForOCR('ind_voter_id', requestBody)
      .then((res) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmittedForOcrOnly(true);
        const responseData = res;
        setOcrResData(JSON.stringify(responseData));
        setSuccessMsgFroOcrOnly('Voter Id OCR is done successful');
        setOnlyOcrErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
          // setOnlyOcrDocError(error.data.api_status_description || 'Something went wrong.');
        }
        setOcrResData(JSON.stringify(error));
        setIsSubmittedForOcrOnly(true);
        setSuccessMsgFroOcrOnly('');
        setIsLoading(false);
        setOnlyOcrIsDisabled(true);
        setOnlyOcrErrorCode(error.status);
        const errMessage = error?.data?.api_status_message
          ? error?.data?.api_status_message
          : error?.data?.api_status_description;
        setOnlyOcrErrorMsg(errMessage || 'Something went wrong');
      });
  };

  const getDOB = (date) => {
    if (!moment(date, 'YYYY-MM-DD').isValid()) {
      setDobError('Invalid date');
      setIsDisabled(true);
      return false;
    }
    if (!date.match('[0-9]{4}-[0-1][0-9]-[0-3][0-9]')) {
      setDobError('Invalid D.O.B');
      setIsDisabled(true);
      return false;
    } else {
      setDobError('');
      return true;
    }
  };

  const getYOB = (date) => {
    if (!date.match('[0-9]{4}')) {
      setYobError('Invalid Y.O.B');
      setIsDisabled(true);
      return false;
    } else {
      setYobError('');
      return true;
    }
  };

  const getVoterId = (id) => {
    if (id.length < 10) {
      setIdError('Voter ID must be of length 10');
      setIsDisabled(true);
      return false;
    } else if (!id.match('[A-Z]{3}[0-9]{7}')) {
      setIdError('Invalid Voter ID');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  const govtVerificationWithOCR = (requestBody) => {
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithOCR(requestBody, 'ind_voter_id')
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Voter ID Document Check successful.');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  const govtVerificationWithoutOCR = () => {
    let requestBody = {
      docType: 'ind_voter_id',
      success_parameters: successParameters,
      personId: props.personId,
      manual_input: {
        id_number: voterId
      }
    };
    if (dob) {
      requestBody['manual_input']['dob'] = dob;
    }
    if (name) {
      requestBody['manual_input']['name'] = name;
    }
    props
      .govtVerificationWithoutOCR('ind_voter_id', requestBody)
      .then((response) => {
        props.reloadCredits();
        props.updateUIPerson(true);
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Voter ID Document Check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('name') && !name) {
      error = true;
      setNameError('Name is Required');
    }
    if (successParameters.includes('dob') && !dob) {
      error = true;
      setDobError('Date of Birth is Required');
    }
    return error;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!apiActivityIsVerification) {
        setOnlyOcrErrorCode('');
        setOnlyOcrErrorMsg('');
        setIsLoading(true);
        addDocumentOnlyForOCR();
      } else {
        if (isOCR && apiActivityIsVerification) {
          setIsLoading(true);
          setIsEditable(false);
          addDocument();
        } else {
          if (
            !isSuccessParameterReqError() &&
            getVoterId(voterId) &&
            !nameError &&
            !yobError &&
            !dobError
          ) {
            let requestData = {
              docType: 'ind_voter_id',
              id_number: voterId
            };
            if (name) {
              requestData.name = name;
            }
            setIsLoading(true);
            setIsEditable(false);
            setRequestHeaders('Content type: application/json');
            setReqData(JSON.stringify(requestData));
            govtVerificationWithoutOCR();
          } else {
            setIsDisabled(true);
          }
        }
      }
    }
  };

  const reset = () => {
    if (!apiActivityIsVerification) {
      props.invalidateVoterOnlyOcrResponse();
      if (props.identityDocType === 'ind_voter_id') {
        props.invalidateIdentityResponse();
      }
      setOcrReqData('');
      setOcrResData('');
      setOnlyOCRDocument1('');
      setOnlyOCRDocument2('');
      setOnlyOcrDocError('');
      setSuccessMsgFroOcrOnly('');
      setOnlyOcrErrorMsg('');
      setErrorMsg('');
      setOnlyOcrErrorCode('');
      setIsSubmittedForOcrOnly(false);
      setOnlyOcrIsDisabled(true);
    } else {
      if (isOCR && apiActivityIsVerification) {
        props.invalidateVoterOcrResponse();
        if (props.identityDocType === 'ind_voter_id') {
          props.invalidateIdentityResponse();
        }
        setDocument1('');
        setDocument2('');
        setRequestID('');
        setDocError('');
      } else {
        props.invalidateVoterResponse();
        setVoterId('');
        setName('');
        setIdError('');
      }
      setIsEditable(true);
      setDocLoading(false);
      setIsLoading(false);
      setDob('');
      setYob('');
      setIsDisabled(true);
      setIsSubmitted(false);
      setRequestHeaders('');
      setReqData('');
      setResData('');
      setSuccessMsg('');
      setErrorCode('');
      setErrorMsg('');
    }
    setSuccessParameters(['id_number']);
  };

  const setInputType = (ocr) => {
    setSuccessParameters(['id_number']);
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    if (ocr) {
      setIsOCR(true);
      if (props.voterOcrResponse && !isEmpty(props.voterOcrResponse)) {
        let responseData = props.voterOcrResponse;
        setResData(JSON.stringify(responseData));
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setResData('');
        setIsEditable(true);
        setIsDisabled(!document1);
        setIsSubmitted(false);
      }
    } else {
      setIsOCR(false);
      if (props.voterResponse && !isEmpty(props.voterResponse)) {
        const data = props.voterResponse;
        const id = data.output.source.id_number;
        const idName = data.output.source.name;
        const d_o_b = data.output.source.dob;
        let requestData = {
          docType: 'ind_voter_id',
          id_number: id
        };
        if (idName) {
          requestData.name = idName;
        }
        if (d_o_b) {
          requestData.dob = idName;
        }

        let responseData = props.voterResponse;
        if (responseData.information) {
          let information = JSON.parse(JSON.stringify(responseData.information));
          responseData.information = information;
        }
        setResData(JSON.stringify(responseData));
        setRequestHeaders('Content type: application/json');
        setReqData(JSON.stringify(requestData));
        setVoterId(id);
        setName(idName);
        setDob(d_o_b);
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setReqData('');
        setIsEditable(true);
        setIsDisabled(voterId ? false : true);
        setIsSubmitted(false);
      }
    }
  };

  const setApiActivity = (isVerification) => {
    if (isVerification) {
      setApiActivityIsVerification(true);
    } else {
      setApiActivityIsVerification(false);
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Voter ID'}</div>
      <div className={'sub-heading'}>
        {
          'Realtime check across multiple public and government databases for verification of user identity against Voter Card details'
        }
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Api Activity Radio Button */}
          <div className={'radio-ctr'}>
            <div className={'radio-heading'}>{'Choose activity:'}</div>
            <div className={'radio-input'}>
              <input
                className={'input'}
                type={'radio'}
                value={true}
                checked={apiActivityIsVerification}
                onChange={() => setApiActivity(true)}
              />
              <div className={'label'} onClick={() => setApiActivity(true)}>
                {'Verification'}
              </div>
              <input
                className={'input'}
                type={'radio'}
                value={false}
                checked={!apiActivityIsVerification}
                onChange={() => setApiActivity(false)}
              />
              <div className={'label'} onClick={() => setApiActivity(false)}>
                {'OCR'}
              </div>
            </div>
          </div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'idNumber'}
                  label={'ID Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              {apiActivityIsVerification && (
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'name'}
                    label={'Name'}
                    disabled={false}
                    checked={successParameters.includes('name')}
                    onClick={() => {
                      setSuccessParameters(_.xor(successParameters, ['name']));
                      setNameError('');
                      setIsDisabled(false);
                      if (name) validateNameInput(name);
                    }}
                  />
                </div>
              )}
            </div>
            {apiActivityIsVerification && (
              <div className={'check-input'}>
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'dob'}
                    label={'Date of birth'}
                    disabled={false}
                    checked={successParameters.includes('dob')}
                    onClick={() => {
                      setSuccessParameters(_.xor(successParameters, ['dob']));
                      setDobError('');
                      setIsDisabled(false);
                      if (dob) getDOB(dob);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {apiActivityIsVerification && (
            <div className={'radio-ctr'}>
              <div className={'radio-heading'}>{'Choose verification type:'}</div>
              <div className={'radio-input'}>
                <input
                  className={'input'}
                  type={'radio'}
                  value={true}
                  checked={isOCR}
                  onChange={() => setInputType(true)}
                />
                <div className={'label'} onClick={() => setInputType(true)}>
                  {'OCR'}
                </div>
                <input
                  className={'input'}
                  type={'radio'}
                  value={false}
                  checked={!isOCR}
                  onChange={() => setInputType(false)}
                />
                <div className={'label'} onClick={() => setInputType(false)}>
                  {'Manual Input'}
                </div>
              </div>
            </div>
          )}
          {/*  If Chosen api activity is OCR */}
          {!apiActivityIsVerification && (
            <div>
              <div className={'image-label upper-label'}>{'ID Image :'}</div>
              {docLoading ? (
                <div className={'upload-double-ctr'}>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <UploadDocument
                    isDouble={true}
                    image1={onlyOCRDocument1}
                    image2={onlyOCRDocument2}
                    isEditable={!isSubmittedForOcrOnly}
                    personId={props.personId}
                    getUrl={(url, index) => getDocumentUrl(url, index)}
                    handleCancel={(index) => {
                      setOnlyOcrDocError('');
                      if (index === 1) {
                        setOnlyOCRDocument1('');
                        setOnlyOcrIsDisabled(true);
                        if (props.identityDocType === 'ind_voter_id') {
                          props.invalidateIdentityResponse();
                        }
                      } else {
                        setOnlyOCRDocument2('');
                      }
                    }}
                  />
                  {onlyOcrDocError ? <div className={'upload-error'}>{onlyOcrDocError}</div> : null}
                </div>
              )}
              <DemoNotes showNotes={'document'} isReports={true} />
            </div>
          )}
          {apiActivityIsVerification &&
            (isOCR ? (
              <div>
                <div className={'image-label upper-label'}>{'ID Image :'}</div>
                {docLoading ? (
                  <div className={'upload-double-ctr'}>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <UploadDocument
                      isDouble={true}
                      image1={document1}
                      image2={document2}
                      isEditable={isEditable}
                      personId={props.personId}
                      getUrl={(url, index) => getDocumentUrl(url, index)}
                      handleCancel={(index) => {
                        setDocError('');
                        if (index === 1) {
                          setDocument1('');
                          setIsDisabled(true);
                          if (props.identityDocType === 'ind_voter_id') {
                            props.invalidateIdentityResponse();
                          }
                        } else {
                          setDocument2('');
                        }
                      }}
                    />
                    {docError ? <div className={'upload-error'}>{docError}</div> : null}
                  </div>
                )}
                <div className={'or-label'}>{'OR'}</div>
                <div>
                  <Input
                    label={'Request ID'}
                    disabled={isSubmitted ? true : false}
                    type={'text'}
                    maxLength={100}
                    value={requestID ?? ''}
                    onChange={(value) => {
                      document1
                        ? setIsDisabled(false)
                        : value
                        ? setIsDisabled(false)
                        : setIsDisabled(true);
                      setRequestID(value);
                    }}
                    placeholder={'OCR request ID'}
                    id={'req_id'}
                    showClear={isSubmitted ? false : true}
                    onClear={() => {
                      setRequestID('');
                      document1 ? setIsDisabled(false) : setIsDisabled(true);
                    }}
                  />
                </div>
                <DemoNotes showNotes={'document'} isReports={true} />
              </div>
            ) : (
              <div>
                <div className={'input-ctr'}>
                  <Input
                    label={'Voter ID'}
                    disabled={!isEditable}
                    type={'text'}
                    isRequiredField={true}
                    maxLength={10}
                    value={voterId}
                    onBlur={(value) => {
                      getVoterId(value);
                    }}
                    onChange={(value) => {
                      if (value) {
                        setIsDisabled(false);
                      } else {
                        setIsDisabled(true);
                      }
                      setIdError('');
                      setVoterId(value.toUpperCase());
                    }}
                    placeholder={'Ex: ABC2430988'}
                    error={idError}
                    showClear={true}
                    onClear={() => {
                      setVoterId('');
                      setIdError('');
                      setIsDisabled(false);
                    }}
                  />
                  <Input
                    label={'Name on card'}
                    disabled={!isEditable}
                    type={'text'}
                    value={name}
                    onBlur={(value) => {
                      if (value) {
                        validateNameInput(value);
                      }
                    }}
                    onChange={(value) => {
                      setIsDisabled(false);
                      setNameError('');
                      setName(value.toUpperCase().trim());
                    }}
                    placeholder={'Name'}
                    showClear={true}
                    onClear={() => {
                      setName('');
                      setNameError('');
                      setIsDisabled(false);
                    }}
                    error={nameError}
                  />
                  <div style={{ display: 'flex' }}>
                    <Input
                      label={'Date of birth'}
                      disabled={!isEditable}
                      type={'text'}
                      maxLength={10}
                      value={dob}
                      onBlur={(value) => {
                        if (value) {
                          getDOB(value);
                        }
                      }}
                      onChange={(value) => {
                        setDobError('');
                        setIsDisabled(false);
                        setDob(value);
                      }}
                      placeholder={'YYYY-MM-DD'}
                      showClear={true}
                      onClear={() => {
                        setDob('');
                        setIsDisabled(false);
                        setDobError('');
                      }}
                      error={dobError}
                    />
                    <div style={{ width: '30px' }}></div>
                    <Input
                      label={'Year of birth'}
                      disabled={!isEditable}
                      type={'text'}
                      maxLength={10}
                      value={yob}
                      onBlur={(value) => {
                        if (value) {
                          getYOB(value);
                        }
                      }}
                      onChange={(value) => {
                        setYob(value);
                        setYobError('');
                        setIsDisabled(false);
                      }}
                      placeholder={'YYYY'}
                      showClear={true}
                      onClear={() => {
                        setIsDisabled(false);
                        setYobError('');
                        setYob('');
                      }}
                      error={yobError}
                    />
                  </div>
                </div>
                <DemoNotes showNotes={'detail'} />
              </div>
            ))}
          <div className={'submit-btn'}>
            {apiActivityIsVerification ? (
              isSubmitted ? (
                <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
              ) : (
                <Button
                  text={'Submit'}
                  onClick={handleSubmit}
                  width={'132px'}
                  height={'47px'}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isWhiteBtn={isDisabled || isLoading}
                />
              )
            ) : isSubmittedForOcrOnly ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isOnlyOcrDisabled}
                isLoading={isLoading}
                isWhiteBtn={isOnlyOcrDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={!apiActivityIsVerification ? 'v4/ocr/' : 'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard
              data={apiActivityIsVerification ? reqData : ocrReqData}
              headers={requestHeaders}
            />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={apiActivityIsVerification ? resData : ocrResData}
              successMsg={apiActivityIsVerification ? successMsg : successMsgForOcrOnly}
              errorCode={apiActivityIsVerification ? errorCode : onlyOcrErrorCode}
              errorMsg={apiActivityIsVerification ? errorMsg : onlyOcrErrorMsg}
              reportDoc={'ind_voter_id'}
              showReports={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
