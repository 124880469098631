import React from 'react';

import Icon from '../../../../coreComponent/Icon';
import './RechargeSuccessful.scss';

export default function RechargeSuccessful(props) {
  const showBalance = () => {
    props.history.push('/billing/recharge-account', {});
  };

  const viewInvoice = () => {
    window.open(props.invoiceUrl, '_blank');
  };

  return (
    <div className={'recharge-successful-ctr'}>
      <Icon icon={'Success'} color={'#65D782'} width={'40'} height={'42'} />
      <div className={'title'}>{'Recharge Successful!'}</div>
      <div className={'content'}>
        {'We’ve recieved your payment of '}
        <b>
          {'Rs '}
          {props.amount}
        </b>
        {' for '}
        <b>
          {props.rechargeCredits}
          {' credits'}
        </b>
        {' on '}
        <b>{props.date}</b>
        {' Transaction ID '}
        <b>{props.transactionId}</b>
      </div>
      <div className={'links-ctr'}>
        <div className={'link'} onClick={showBalance}>
          {'Show Balance'}
        </div>
        <div className={'link'} onClick={viewInvoice}>
          {'View Invoice'}
        </div>
      </div>
    </div>
  );
}
RechargeSuccessful.defaultProps = {
  amount: '',
  rechargeCredits: '',
  date: '',
  transactionId: '',
  invoiceUrl: ''
};
