import React, { useState, useEffect } from 'react';
import LoaderSpinner from 'react-loader-spinner';

import storage from '../../../api/localstorage';
import Loader from '../../../coreComponent/Loader/index';
import Header from '../../Header';
import queryString from 'query-string';

export default function Redirection(props) {
  const searchUrl = queryString.parse(window.location.search);
  const [message, setMessage] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [isMissing, setIsMissing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uuid] = useState(searchUrl?.uuid);

  //not required, but still can be used to track khosla response
  // const [status, setStatus] = useState(searchUrl.status);
  // const [requestId, setRequestId] = useState(searchUrl['?requestId']);
  // const [hashFromKhosla, setHashFromKhosla] = useState(searchUrl.hash);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      props.location.state === 'expired' ||
      !storage.getAadhaarToken() ||
      storage.getAadhaarToken() === 'null' ||
      storage.getAadhaarToken() === 'undefined'
    ) {
      setIsLoading(false);
      setIsExpired(true);
      setMessage('This Page has been expired. Please reinitiate the process.');
    } else if (
      props.location.state === 'missing' ||
      !storage.getAadhaarPersonId() ||
      storage.getAadhaarPersonId() === 'null' ||
      storage.getAadhaarPersonId() === 'undefined'
    ) {
      setIsLoading(false);
      setIsMissing(true);
      setMessage('Some Data is missing. Please reinitiate the process.');
    } else {
      callKhoslaFetchAPI();
    }
  }, []);

  const callKhoslaFetchAPI = () => {
    let requestBody = {
      personId: storage.getAadhaarPersonId(),
      uuid: uuid
    };

    props
      .khoslaFetchAPI(requestBody)
      .then((response) => {
        setIsLoading(false);
        setIsSuccess(response.status === 'SUCCESS');
        setMessage(response.message);
        setLoading(true);
        setRedirection(response.url);
      })
      .catch((error) => {
        if (error && error.status === 430 && error.data && error.data.error) {
          setIsLoading(false);
          setIsSuccess(false);
          setMessage(error.data.error.message);
          setLoading(true);
          setRedirection(error.data.error.url);
        } else if (error && error.status === 404) {
          setIsLoading(false);
          setIsMissing(true);
          setMessage('Some Data is missing. Please reinitiate the process.');
        } else {
          setIsLoading(false);
          setIsExpired(true);
          setMessage('This Page has been expired. Please reinitiate the process.');
        }
      });
  };

  const setRedirection = (url) => {
    setTimeout(() => {
      window.open(url, '_self');
    }, 2500);
  };

  return (
    <div className={'aadhaar-verification-ctr'}>
      <Header />
      <div className={'main-ctr'}>
        <div className={'heading'}>{'SpringScan Verification Portal'}</div>
        {isLoading ? (
          <div className={'loader'}>
            <Loader size={'60px'} color={'#999999'} width={'0.4rem'} />
          </div>
        ) : (
          <div>
            <div className={isSuccess ? 'successMessage' : 'failureMessage'}>
              {isExpired
                ? 'Page Expired !!'
                : isMissing
                ? 'Data Missing !!'
                : isSuccess
                ? 'Congratulations!!'
                : 'Failure!!'}
            </div>
            <div className={'sub-heading'}>{message}</div>
            {loading ? (
              <div className={'spinner-loader'}>
                <LoaderSpinner type='ThreeDots' color='#002c9d' height='100' width='100' />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
