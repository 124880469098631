import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Input from '../../../../coreComponent/Input/index';
import Button from '../../../../coreComponent/Button/index';
import './GenerateKey.scss';

export default function GenerateKey(props) {
  const [keyName, setKeyName] = useState('');
  const [password, setPassword] = useState('');
  const [keyNameError, setKeyNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const generateKey = () => {
    setSubmitting(true);
    let requestData = {
      key_name: keyName,
      password: password
    };
    props
      .generateTokenKeyAPI(requestData)
      .then((response) => {
        setSubmitting(false);
        props.onSubmit(response);
        onHide();
      })
      .catch((error) => {
        console.log('error: ', error);
        setSubmitting(false);
        if (error.status === 403) {
          setPasswordError('Incorrect Password. Try again.');
        } else if (error.status === 400) {
          const errMsg = error?.data[0]?.msg;
          setKeyNameError(errMsg);
        } else {
          setKeyNameError('Something went wrong.');
        }
      });
  };

  const onHide = () => {
    setKeyName('');
    setPassword('');
    setKeyNameError('');
    setPasswordError('');
    setSubmitting(false);
    props.onHide();
  };

  const validateTokenName = (keyName) => {
    var pattern = /^[A-Za-z\s]+$/;
    if (pattern.test(keyName)) {
      return '';
    } else {
      return 'Invalid Key Name. Only Alphabets are allowed';
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'generate-key-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div className={'heading'} data-testid={'modal-title'}>
            {'Generate New Key'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          label={'Key Name*'}
          disabled={submitting}
          type={'text'}
          value={keyName}
          onChange={(value) => {
            setKeyName(value.trim());
            setKeyNameError(validateTokenName(value.trim()));
          }}
          placeholder={'Enter Key Name'}
          error={keyNameError}
          errorWithIcon={true}
        />
        <Input
          label={'Password*'}
          disabled={submitting}
          type={'password'}
          id={'password'}
          value={password}
          onChange={(value) => {
            setPassword(value);
            setPasswordError('');
          }}
          placeholder={'Enter Password'}
          error={passwordError}
          errorWithIcon={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
            isDisabled={submitting}
          />
        </div>
        <Button
          text={'Confirm'}
          onClick={generateKey}
          width={'120px'}
          height={'50px'}
          isDisabled={!keyName || !password || keyNameError || passwordError}
          isLoading={submitting}
        />
      </Modal.Footer>
    </Modal>
  );
}
GenerateKey.defaultProps = {
  show: false,
  onHide: null,
  onSubmit: null
};
