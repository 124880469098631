import React from 'react';

import Icon from '../../../../coreComponent/Icon';
import './Packages.scss';

export default function Packages(props) {
  return (
    <div className={'packages-ctr'} data-testid='packages'>
      <div className={'heading'}>{'Packages Available'}</div>
      <div className={'sub-heading'}>
        {'Recharge with packages available to make API call in Prod environment'}
      </div>
      <div className={'packages-card-ctr'}>
        <div className={'package-card'}>
          <div className={'package-card-body'}>
            <div className={'heading'}>{'2000'}</div>
            <div className={'sub-heading'}>{'CREDITS'}</div>
          </div>
          <div
            data-testid='2000-couponButton'
            className={'package-card-footer'}
            onClick={() => {
              props.onClick(2000, 2000, 0);
            }}
          >
            <div className={'text'}>{'Rs 2000'}</div>
            <div className={'icon'}>
              <Icon icon={'ArrowRight'} color={'#002C9D'} width={'20'} height={'13'} />
            </div>
            <div className={'icon-hover'}>
              <Icon icon={'ArrowRight'} color={'#FFFFFF'} width={'20'} height={'13'} />
            </div>
          </div>
        </div>
        <div className={'package-card'}>
          <div className={'package-card-offer'}>{'SAVE 40%'}</div>
          <div className={'package-card-body'}>
            <div className={'heading'}>{'5000'}</div>
            <div className={'sub-heading'}>{'CREDITS'}</div>
          </div>
          <div
            data-testid='3500-couponButton'
            className={'package-card-footer'}
            onClick={() => {
              props.onClick(5000, 3500, 1000);
            }}
          >
            <div className={'text'}>
              {'Rs 3500'}
              <div className={'text-fadded'}>{'Rs 4500'}</div>
            </div>
            <div className={'icon'}>
              <Icon icon={'ArrowRight'} color={'#002C9D'} width={'20'} height={'13'} />
            </div>
            <div className={'icon-hover'}>
              <Icon icon={'ArrowRight'} color={'#FFFFFF'} width={'20'} height={'13'} />
            </div>
          </div>
        </div>
        <div className={'package-card'}>
          <div className={'package-card-offer'}>{'SAVE 60%'}</div>
          <div className={'package-card-body'}>
            <div className={'heading'}>{'10000'}</div>
            <div className={'sub-heading'}>{'CREDITS'}</div>
          </div>
          <div
            data-testid='5000-couponButton'
            className={'package-card-footer'}
            onClick={() => {
              props.onClick(10000, 5000, 1000);
            }}
          >
            <div className={'text'}>
              {'Rs 5000'}
              <div className={'text-fadded'}>{'Rs 6000'}</div>
            </div>
            <div className={'icon'}>
              <Icon icon={'ArrowRight'} color={'#002C9D'} width={'20'} height={'13'} />
            </div>
            <div className={'icon-hover'}>
              <Icon icon={'ArrowRight'} color={'#FFFFFF'} width={'20'} height={'13'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Packages.defaultProps = {
  onClick: null
};
