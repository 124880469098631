import React, { useState, useEffect } from 'react';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import Image from '../../../coreComponent/Image';
import Table from '../../../coreComponent/Table/index';
import Button from '../../../coreComponent/Button/index';
import DatePicker from '../../../coreComponent/DatePicker/index';
import SearchInput from '../../../coreComponent/SearchInput/index';
import AddAdminModal from './AddAdmin/index';
import EditAdminModal from './EditAdmin/index';
import DeleteAdminModal from './DeleteAdmin/index';
import './Admin.scss';

export default function Admin(props) {
  const [tableRows, setTableRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState(true); // true->asec, false->desc
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });
  const [name, setName] = useState('');
  const [admin, setAdmin] = useState('');
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [deleteAdminModal, setDeleteAdminModal] = useState(false);
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (props.admins && !isEmpty(props.admins)) {
      let rows = getRowData(props.admins.data);
      setTableRows(rows);
      setTotalCount(props.admins.total_count);
    }
  }, [props.admins]);

  const headCells = [
    {
      id: 'adminName',
      label: 'NAME',
      width: '160px',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('name');
      }
    },
    {
      id: 'emailId',
      label: 'EMAIL ID',
      width: '250px'
    },
    {
      id: 'phone',
      label: 'PHONE',
      width: '160px'
    },
    {
      id: 'dateAdded',
      label: 'DATE ADDED',
      width: '160px',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('dateAdded');
      }
    },
    {
      id: 'actions',
      label: '',
      width: '100px',
      onHover: true
    }
  ];

  const loadData = () => {
    setPage(1);
    setRowsPerPage(10);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    props.getAdminsAPI(data).then(() => {
      setLoading(false);
    });
  };

  //this is for each row the heading creation  with whatever id for headcells
  const createData = (adminName, emailId, phone, dateAdded, actions) => {
    return { adminName, emailId, phone, dateAdded, actions };
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value, key) {
      let rowData = createData(
        value.name ? value.name.first + ' ' + value.name.last : '',
        value.email_id,
        value.phone_number ? '+' + value.phone_number : '',
        moment(value.date_added).utcOffset('+05:30').format('DD-MM-YYYY'),
        getActions(value)
      );
      data.push(rowData);
    });
    return data;
  };

  const getActions = (adminData) => {
    return (
      <div className={'actions-ctr'}>
        <div
          className={'edit-action'}
          onClick={() => {
            setAdmin(adminData);
            setEditAdminModal(true);
          }}
        >
          <Image name={'edit.svg'} />
        </div>
        {adminData.primaryAdmin || props.loginData._id === adminData.admin_id ? null : (
          <div
            onClick={() => {
              setAdmin(adminData);
              setDeleteAdminModal(true);
            }}
          >
            <Image name={'delete.svg'} />
          </div>
        )}
      </div>
    );
  };

  const applySorting = (key) => {
    setLoading(true);
    setPage(1);
    let data = {
      range: rowsPerPage,
      page: 1
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    switch (key) {
      case 'name': {
        setSortBy('name');
        setSortOrder(!sortOrder);
        data.sort_by = 'name';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'dateAdded': {
        setSortBy('createdAt');
        setSortOrder(!sortOrder);
        data.sort_by = 'createdAt';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
    }
    props.getAdminsAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setLoading(true);
    let data = {
      range: rowsPerPage,
      page: newPage
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    props.getAdminsAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
    setLoading(true);
    let data = {
      range: parseInt(value, 10),
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (name) {
      data.name_filter = name;
    }
    props.getAdminsAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applyDateFilter = (date) => {
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (date && date.start && date.end) {
      setDateFilter(date);
      data.start_date = date.start;
      data.end_date = date.end;
    } else {
      setDateFilter({ start: '', end: '' });
    }
    if (name) {
      data.name_filter = name;
    }
    props.getAdminsAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applySearchFilter = (value) => {
    setName(value);
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1,
      name_filter: value
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    props.getAdminsAPI(data).then(() => {
      setLoading(false);
    });
  };

  return (
    <div data-testid='addAdmin' className={'admin-ctr'}>
      <AddAdminModal
        show={addAdminModal}
        onHide={() => setAddAdminModal(false)}
        onSubmit={loadData}
      />
      <EditAdminModal
        show={editAdminModal}
        onHide={() => setEditAdminModal(false)}
        onSubmit={loadData}
        adminDetails={admin}
      />
      <DeleteAdminModal
        show={deleteAdminModal}
        onHide={() => setDeleteAdminModal(false)}
        onSubmit={loadData}
        adminDetails={admin}
      />
      <div className={'search-ctr'}>
        <SearchInput
          value={name}
          handleSearch={(value) => applySearchFilter(value)}
          handleClear={() => applySearchFilter('')}
        />
      </div>
      <div className={'heading-ctr'}>
        <div data-testid='adminHeading' className={'heading'}>
          {'Admin'}
        </div>
        <div className={'filter-ctr'}>
          <div className={'date-filter'}>
            <DatePicker
              emptyFilter={true}
              startDate={dateFilter.start}
              endDate={dateFilter.end}
              onClear={applyDateFilter}
              onSubmit={applyDateFilter}
            />
          </div>
          <Button
            text={'Add Admin'}
            onClick={() => {
              setAddAdminModal(true);
            }}
            width={'150px'}
            height={'50px'}
          />
        </div>
      </div>
      <Table
        headCells={headCells}
        rowData={tableRows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(pageNo) => handleChangePage(pageNo)}
        handleChangeRowsPerPage={(rows) => handleChangeRowsPerPage(rows)}
        loading={loading}
        noDataText={'No admin found. Click on "Add Admin" to add people'}
      />
    </div>
  );
}
