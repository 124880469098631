import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { copyToClipboard } from '../../../utils/utilities';
import AppConstants from '../../../coreComponent/AppConstants';
import Icon from '../../../coreComponent/Icon';
import Image from '../../../coreComponent/Image';
import Tooltip from '../../../coreComponent/Tooltip/index';
import Loader from '../../../coreComponent/Loader/index';
import GenerateKeyModal from '../TokenKey/GenerateKey/index';
import ConfirmPasswordModal from '../TokenKey/ConfirmPassword/index';
import ViewKeyModal from './ViewKey/index';
import './GettingStarted.scss';

export default function GettingStarted(props) {
  const [keyDropdown, setKeyDropdown] = useState(true);
  const [demoUIDropdown, setDemoUIDropdown] = useState(false);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [rechargeDropdown, setRechargeDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeKeys, setActivekeys] = useState([]);
  const [generated, setGenerated] = useState(false);
  const [expiringTime, setExpiringTime] = useState('');
  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [confirmPasswordModal, setConfirmPasswordModal] = useState(false);
  const [viewKeyModal, setViewKeyModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [testKey, setTestKey] = useState({});
  const [liveKey, setLiveKey] = useState({});
  const [totalKeys, setTotalKeys] = useState(0);

  useEffect(() => {
    getActiveKeys();
  }, [props.liveMode]);

  useEffect(() => {
    if (totalKeys !== 0 && props.testTokenKey && props.testTokenKey.key_name) {
      let testTokenKey = props.testTokenKey;
      testTokenKey['token'] = '';
      setTestKey(testTokenKey);
    }
    setExpiringTime('');
  }, [activeKeys, props.testTokenKey]);

  useEffect(() => {
    if (totalKeys !== 0 && props.liveTokenKey && props.liveTokenKey.key_name) {
      let liveTokenKey = props.liveTokenKey;
      liveTokenKey['token'] = '';
      setLiveKey(liveTokenKey);
    }
    setExpiringTime('');
  }, [activeKeys, props.liveTokenKey]);

  const getActiveKeys = () => {
    setLoading(true);
    let data = {
      page: 1,
      range: 20,
      status_filter: ['active', 'expiring_soon']
    };
    props.getTokenKeysAPI(data).then((response) => {
      if (response) {
        setLoading(false);
        setActivekeys(response.data);
        setTotalKeys(response.total_count);
        if (response.data.length === 1) {
          if (props.liveMode) {
            props.setLiveTokenKey(response.data[0]);
          } else {
            props.setTestTokenKey(response.data[0]);
          }
        }
      }
    });
  };

  const generateKey = (key) => {
    if (props.liveMode) {
      setLiveKey(key);
      props.setLiveTokenKey(key);
    } else {
      setTestKey(key);
      props.setTestTokenKey(key);
    }
    setGenerated(true);
    getActiveKeys();
    props.reloadState();
  };

  const confirmPassword = (key) => {
    setIsCopied(false);
    if (props.liveMode) {
      setLiveKey(key);
    } else {
      setTestKey(key);
    }
    if (key && key.status === 'expiring_soon') {
      setExpiringTime(key.disabled_at);
    } else {
      setExpiringTime('');
    }
  };

  const viewKey = (key) => {
    if (props.liveMode) {
      props.setLiveTokenKey(key);
    } else {
      props.setTestTokenKey(key);
    }
    setConfirmPasswordModal(true);
  };

  const demoUI = () => {
    window.open(AppConstants.baseURL + 'demo-ui', '_blank');
  };

  const launchUI = () => {
    window.open(AppConstants.baseURL + 'launch-ui', '_blank');
  };

  const notification = () => {
    props.history.push('/account/settings');
  };

  const rechargeAccount = () => {
    props.history.push('/billing/recharge-account');
  };

  return (
    <div className={'getting-started-ctr'}>
      <GenerateKeyModal
        show={generateKeyModal}
        onHide={() => setGenerateKeyModal(false)}
        onSubmit={(key) => generateKey(key)}
      />
      <ConfirmPasswordModal
        show={confirmPasswordModal}
        onHide={() => setConfirmPasswordModal(false)}
        onSubmit={(key) => confirmPassword(key)}
        liveMode={props.liveMode}
      />
      <ViewKeyModal
        value={props.liveMode ? liveKey : testKey}
        options={activeKeys}
        show={viewKeyModal}
        onHide={() => setViewKeyModal(false)}
        onSubmit={(key) => {
          viewKey(key);
        }}
      />
      <div data-testid='gettingStartedHeading' className={'heading'}>
        {'Getting Started'}
      </div>
      <div
        data-testid='welcomeText'
        className={'welcome-ctr'}
      >{`Welcome, ${props.username} – follow these steps to get started`}</div>
      {!props.liveMode ? (
        <div>
          <div className={'dropdown-ctr'}>
            <div
              className={'title'}
              onClick={() => {
                setKeyDropdown(!keyDropdown);
              }}
            >
              {generated ? (
                <Icon icon={'CircleTickLarge'} color={'#65D782'} width={'14'} height={'14'} />
              ) : keyDropdown ? (
                <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
              ) : (
                <Icon icon={'UpArrow'} color={'#333333'} width={'10'} height={'11'} />
              )}
              {generated ? (
                <div className={'text'}>
                  {'Congratulations! Your test token key is successfully generated.'}
                </div>
              ) : totalKeys !== 0 ? (
                <div data-testid='viewTestKey' className={'text'}>
                  {'View your active test token key'}
                </div>
              ) : (
                <div className={'text'}>{'Get your test token key'}</div>
              )}
            </div>
            {keyDropdown ? (
              <div>
                {loading ? (
                  <div className={'content-loader'}>
                    <Loader color={'#666666'} size={'12px'} />
                    <div className={'text'}>{'Loading...'}</div>
                  </div>
                ) : (
                  <div>
                    {testKey && !isEmpty(testKey) ? (
                      <div className={'content'}>
                        <div
                          data-testid='viewText'
                          className={'text'}
                        >{`Key name : ${testKey.key_name}`}</div>
                        {testKey.token && (
                          <div>
                            <div className={'key-ctr'}>
                              {expiringTime && (
                                <div>
                                  <div className='tooltip-icon'>
                                    <div className={'info-icon'}>
                                      <Image name={'timerIcon.svg'} />
                                    </div>
                                  </div>
                                  <Tooltip
                                    top={'-58px'}
                                    left={'-128px'}
                                    width={'260px'}
                                    text={`This key will expire on ${moment(expiringTime)
                                      .utcOffset('+05:30')
                                      .add(1, 'days')
                                      .format('DD-MM-YYYY HH:mm')}`}
                                  />
                                </div>
                              )}
                              <div className={'text'}>{`Token key : ${testKey.token}`}</div>
                              <div>
                                <div className='tooltip-icon'>
                                  <div
                                    className={'copy-icon'}
                                    onClick={() => {
                                      copyToClipboard(testKey.token);
                                      setIsCopied(true);
                                      setTimeout(() => {
                                        setIsCopied(false);
                                      }, 3000);
                                    }}
                                  >
                                    <Icon
                                      icon={'Copy'}
                                      color={'#002C9D'}
                                      width={'12'}
                                      height={'15'}
                                    />
                                  </div>
                                </div>
                                <Tooltip
                                  top={'-58px'}
                                  left={isCopied ? '-26px' : '-20px'}
                                  width={isCopied ? '60px' : '48px'}
                                  text={isCopied ? 'Copied' : 'Copy'}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {totalKeys === 1 && testKey.token ? null : (
                          <div
                            className={'button'}
                            onClick={() =>
                              totalKeys > 1 ? setViewKeyModal(true) : setConfirmPasswordModal(true)
                            }
                          >
                            <div data-testid='viewKeyText' className={'text'}>
                              {'View Key'}
                            </div>
                            {totalKeys > 1 && (
                              <div>
                                <div className='tooltip-icon'>
                                  <Icon
                                    icon={'Info'}
                                    color={'#999999'}
                                    width={'12'}
                                    height={'12'}
                                  />
                                </div>
                                <Tooltip
                                  top={'-58px'}
                                  left={'-58px'}
                                  width={'120px'}
                                  text={`${totalKeys} Active Keys`}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={'content'}>
                        {totalKeys > 0 ? (
                          <div
                            className={'button'}
                            onClick={() =>
                              totalKeys > 1 ? setViewKeyModal(true) : setConfirmPasswordModal(true)
                            }
                          >
                            <div className={'text'}>{'View Key'}</div>
                            {totalKeys > 1 && (
                              <div>
                                <div className='tooltip-icon'>
                                  <Icon
                                    icon={'Info'}
                                    color={'#999999'}
                                    width={'12'}
                                    height={'12'}
                                  />
                                </div>
                                <Tooltip
                                  top={'-58px'}
                                  left={'-58px'}
                                  width={'120px'}
                                  text={`${totalKeys} Active Keys`}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className={'text'}>
                              {'Generate token key here to start using SpringScan in test mode'}
                            </div>
                            <div className={'button'} onClick={() => setGenerateKeyModal(true)}>
                              <div className={'text'}>{'Generate Key'}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div
            className={`dropdown-ctr ${props.disabled || props.demoUIDisabled ? 'disabled' : ''}`}
          >
            <div className={`${props.disabled || props.demoUIDisabled ? 'tooltip-icon' : ''}`}>
              <div
                className={'title'}
                onClick={() => {
                  !(props.disabled || props.demoUIDisabled) && setDemoUIDropdown(!demoUIDropdown);
                }}
              >
                {props.disabled || props.demoUIDisabled ? (
                  <Icon icon={'Lock'} color={'#999999'} width={'10'} height={'12'} />
                ) : demoUIDropdown ? (
                  <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
                ) : (
                  <Icon icon={'UpArrow'} color={'#333333'} width={'10'} height={'11'} />
                )}
                <div className={'text'}>{'Make your first API call through SpringScan'}</div>
              </div>
            </div>
            <Tooltip
              text={'Please generate test token key to view here'}
              left={'354px'}
              top={'-32px'}
              width={'300px'}
              position={'right'}
            />
            {!props.disabled && !props.demoUIDisabled && demoUIDropdown && (
              <div className={'content'}>
                <div className={'text'}>
                  {
                    'Start making API call using SpringScan API document or Demo UI. Use generated test token to make call.'
                  }
                </div>
                <div className={'button'} onClick={demoUI}>
                  <div className={'text'}>{'Demo  UI'}</div>
                </div>
              </div>
            )}
          </div>
          <div className={`dropdown-ctr ${props.disabled ? 'disabled' : ''}`}>
            <div className={`${props.disabled ? 'tooltip-icon' : ''}`}>
              <div
                className={'title'}
                onClick={() => {
                  !props.disabled && setNotificationDropdown(!notificationDropdown);
                }}
              >
                {props.disabled ? (
                  <Icon icon={'Lock'} color={'#999999'} width={'10'} height={'12'} />
                ) : notificationDropdown ? (
                  <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
                ) : (
                  <Icon icon={'UpArrow'} color={'#333333'} width={'10'} height={'11'} />
                )}
                <div className={'text'}>{'Set customized notification alert'}</div>
              </div>
            </div>
            <Tooltip
              text={'Please generate test token key to view here'}
              left={'276px'}
              top={'-32px'}
              width={'300px'}
              position={'right'}
            />
            {!props.disabled && notificationDropdown && (
              <div className={'content'}>
                <div className={'text'}>
                  {'Set customized notification alert to get alerted in case of credit run out'}
                </div>
                <div className={'button'} onClick={notification}>
                  <div className={'text'}>{'Customize'}</div>
                </div>
              </div>
            )}
          </div>
          <div className={`dropdown-ctr ${props.disabled ? 'disabled' : ''}`}>
            <div className={`${props.disabled ? 'tooltip-icon' : ''}`}>
              <div
                className={'title'}
                onClick={() => {
                  !props.disabled && setRechargeDropdown(!rechargeDropdown);
                }}
              >
                {props.disabled ? (
                  <Icon icon={'Lock'} color={'#999999'} width={'10'} height={'12'} />
                ) : rechargeDropdown ? (
                  <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
                ) : (
                  <Icon icon={'UpArrow'} color={'#333333'} width={'10'} height={'11'} />
                )}
                <div className={'text'}>{'Activate your SpringScan account'}</div>
              </div>
            </div>
            <Tooltip
              text={'Please generate test token key to view here'}
              left={'286px'}
              top={'-32px'}
              width={'300px'}
              position={'right'}
            />
            {!props.disabled && rechargeDropdown && (
              <div className={'content'}>
                <div className={'text'}>
                  {'Recharge your Springscan account to make calls in Prod environment'}
                </div>
                <div className={'button'} onClick={rechargeAccount}>
                  <div className={'text'}>{'Recharge Account'}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className={'dropdown-ctr'}>
            <div
              className={'title'}
              onClick={() => {
                setKeyDropdown(!keyDropdown);
              }}
            >
              {generated ? (
                <Icon icon={'CircleTickLarge'} color={'#65D782'} width={'14'} height={'14'} />
              ) : keyDropdown ? (
                <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
              ) : (
                <Icon icon={'UpArrow'} color={'#333333'} width={'10'} height={'11'} />
              )}
              {generated ? (
                <div className={'text'}>
                  {'Congratulations! Your live token key is successfully generated.'}
                </div>
              ) : totalKeys !== 0 ? (
                <div className={'text'}>{'View your active live token key'}</div>
              ) : (
                <div className={'text'}>{'Get your live token key'}</div>
              )}
            </div>
            {keyDropdown ? (
              <div>
                {loading ? (
                  <div className={'content-loader'}>
                    <Loader color={'#666666'} size={'12px'} />
                    <div className={'text'}>{'Loading...'}</div>
                  </div>
                ) : (
                  <div>
                    {liveKey && !isEmpty(liveKey) ? (
                      <div className={'content'}>
                        <div className={'text'}>{`Key name : ${liveKey.key_name}`}</div>
                        {liveKey.token && (
                          <div>
                            <div className={'key-ctr'}>
                              {expiringTime && (
                                <div>
                                  <div className='tooltip-icon'>
                                    <div className={'info-icon'}>
                                      <Image name={'timerIcon.svg'} />
                                    </div>
                                  </div>
                                  <Tooltip
                                    top={'-58px'}
                                    left={'-128px'}
                                    width={'260px'}
                                    text={`This key will expire on ${moment(expiringTime)
                                      .utcOffset('+05:30')
                                      .add(1, 'days')
                                      .format('DD-MM-YYYY HH:mm')}`}
                                  />
                                </div>
                              )}
                              <div className={'text'}>{`Token key : ${liveKey.token}`}</div>
                              <div>
                                <div className='tooltip-icon'>
                                  <div
                                    className={'copy-icon'}
                                    onClick={() => {
                                      copyToClipboard(liveKey.token);
                                      setIsCopied(true);
                                      setTimeout(() => {
                                        setIsCopied(false);
                                      }, 3000);
                                    }}
                                  >
                                    <Icon
                                      icon={'Copy'}
                                      color={'#002C9D'}
                                      width={'12'}
                                      height={'15'}
                                    />
                                  </div>
                                </div>
                                <Tooltip
                                  top={'-58px'}
                                  left={isCopied ? '-26px' : '-20px'}
                                  width={isCopied ? '60px' : '48px'}
                                  text={isCopied ? 'Copied' : 'Copy'}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {totalKeys === 1 && liveKey.token ? null : (
                          <div
                            className={'button'}
                            onClick={() =>
                              totalKeys > 1 ? setViewKeyModal(true) : setConfirmPasswordModal(true)
                            }
                          >
                            <div className={'text'}>{'View Key'}</div>
                            {totalKeys > 1 && (
                              <div>
                                <div className='tooltip-icon'>
                                  <Icon
                                    icon={'Info'}
                                    color={'#999999'}
                                    width={'12'}
                                    height={'12'}
                                  />
                                </div>
                                <Tooltip
                                  top={'-58px'}
                                  left={'-58px'}
                                  width={'120px'}
                                  text={`${totalKeys} Active Keys`}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={'content'}>
                        {totalKeys > 0 ? (
                          <div
                            className={'button'}
                            onClick={() =>
                              totalKeys > 1 ? setViewKeyModal(true) : setConfirmPasswordModal(true)
                            }
                          >
                            <div className={'text'}>{'View Key'}</div>
                            {totalKeys > 1 && (
                              <div>
                                <div className='tooltip-icon'>
                                  <Icon
                                    icon={'Info'}
                                    color={'#999999'}
                                    width={'12'}
                                    height={'12'}
                                  />
                                </div>
                                <Tooltip
                                  top={'-58px'}
                                  left={'-58px'}
                                  width={'120px'}
                                  text={`${totalKeys} Active Keys`}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className={'text'}>
                              {'Recharge your Springscan account to make calls in Prod environment'}
                            </div>
                            <div className={'button'} onClick={() => setGenerateKeyModal(true)}>
                              <div className={'text'}>{'Generate API Key'}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className={`dropdown-ctr ${props.launchUIDisabled ? 'disabled' : ''}`}>
            <div className={`${props.launchUIDisabled ? 'tooltip-icon' : ''}`}>
              <div
                className={'title'}
                onClick={() => {
                  setDemoUIDropdown(!demoUIDropdown) && !props.launchUIDisabled;
                }}
              >
                {props.launchUIDisabled ? (
                  <Icon icon={'Lock'} color={'#999999'} width={'10'} height={'12'} />
                ) : demoUIDropdown ? (
                  <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
                ) : (
                  <Icon icon={'UpArrow'} color={'#333333'} width={'10'} height={'11'} />
                )}
                <div className={'text'}>{'Make your first API call through SpringScan'}</div>
              </div>
            </div>
            <Tooltip
              text={'Please generate live token key to view here'}
              left={'354px'}
              top={'-32px'}
              width={'300px'}
              position={'right'}
            />
            {!props.launchUIDisabled && demoUIDropdown && (
              <div className={'content'}>
                <div className={'text'}>
                  {
                    'Start making API call using SpringScan API document or Launch UI. Use generated live token to make call.'
                  }
                </div>
                <div className={'button'} onClick={launchUI}>
                  <div className={'text'}>{'Launch  UI'}</div>
                </div>
              </div>
            )}
          </div>
          <div className={'dropdown-ctr'}>
            <div
              className={'title'}
              onClick={() => {
                setNotificationDropdown(!notificationDropdown);
              }}
            >
              {notificationDropdown ? (
                <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
              ) : (
                <Icon icon={'UpArrow'} color={'#333333'} width={'10'} height={'11'} />
              )}
              <div className={'text'}>{'Set customized notification alert'}</div>
            </div>
            {notificationDropdown && (
              <div className={'content'}>
                <div className={'text'}>
                  {'Set customized notification alert to get alerted in case of credit run out'}
                </div>
                <div className={'button'} onClick={notification}>
                  <div className={'text'}>{'Customize'}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
GettingStarted.defaultProps = {
  liveMode: true,
  username: '',
  disabled: false,
  reloadState: null,
  demoUIDisabled: false,
  launchUIDisabled: false
};
