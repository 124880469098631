import React, { useState, useEffect, useRef } from 'react';
import DateRangePicker from 'react-daterange-picker';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import 'react-daterange-picker/dist/css/react-calendar.css';

import Image from '../Image';
import Button from '../Button/index';
import './DatePicker.scss';

export default function DatePicker(props) {
  const moment = extendMoment(originalMoment);
  const today = moment();
  const startingDate = moment().subtract(1, 'month');
  const [value, setValue] = useState(props.emptyFilter ? null : moment.range(startingDate, today));
  const [show, setShow] = useState(false);
  const datePickerNode = useRef();
  useEffect(() => {
    if (props.startDate && props.endDate) {
      let startDate = moment(props.startDate);
      let endDate = moment(props.endDate);
      setValue(moment.range(startDate, endDate));
    }
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    // add when mounted (componentDidMount)
    document.addEventListener('click', handleClick);
    // return function to be called when unmounted (componentWillMount)
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (datePickerNode && !datePickerNode.current.contains(e.target)) {
      setShow(false);
    }
  };

  const select = (val) => {
    setValue(val);
  };

  const clear = () => {
    setValue(null);
    setShow(false);
    props.onClear();
  };

  const apply = () => {
    let date = {
      start: moment(value.start.valueOf()).format('YYYY-MM-DDTHH:mm:ss.SSS'),
      end: moment(value.end.valueOf())
        .add(23, 'hours')
        .add(59, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss.SSS')
    };
    setShow(false);
    props.onSubmit(date);
  };

  return (
    <div className={'date-picker-filter'} ref={datePickerNode}>
      <div
        data-testid='calenderImage'
        className={`date-picker-ctr ${show ? 'filter-open' : ''}`}
        onClick={() => {
          setShow(!show);
        }}
      >
        <div
          data-testid='datePickerPlaceholder'
          className={value !== null ? 'value' : 'placeholder-value'}
        >
          {value !== null
            ? value.start.format('DD-MM-YY') === value.end.format('DD-MM-YY')
              ? `${value.start.format('DD-MM-YY')}`
              : `${value.start.format('DD-MM-YY')} to ${value.end.format('DD-MM-YY')}`
            : 'Filter by'}
        </div>
        <Image name={'calendar.svg'} />
      </div>
      {show ? (
        <div className={'date-picker-calendar'}>
          <DateRangePicker
            key={value}
            value={value}
            onSelect={select}
            singleDateRange={true}
            minimumDate={props.minDate}
            maximumDate={new Date()}
          />
          <div className={'details'}>
            <div className={'field'}>
              <div className={'text'}>{'Start'}</div>
              <div className={'date'}>{value !== null ? value.start.format('DD/MM/YY') : ''}</div>
            </div>
            <div className={'field'}>
              <div className={'text'}>{'To'}</div>
              <div className={'date'}>{value !== null ? value.end.format('DD/MM/YY') : ''}</div>
            </div>
            <div className={'buttons'}>
              <div className={'clr-btn'}>
                <Button
                  text={'Clear'}
                  onClick={clear}
                  width={'84px'}
                  height={'32px'}
                  isWhiteBtn={true}
                />
              </div>
              <div className={'apply-btn'}>
                <Button text={'Apply'} onClick={apply} width={'84px'} height={'32px'} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
DatePicker.defaultProps = {
  startDate: '',
  endDate: '',
  onClear: null,
  onSubmit: null,
  emptyFilter: false,
  minDate: null
};
