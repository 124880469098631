import React, { useState, useEffect } from 'react';
import { isEmpty, isArray } from 'lodash';

import AppHelper from '../../../coreComponent/AppHelper';
import Image from '../../../coreComponent/Image';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import SwitchButton from '../../../coreComponent/SwitchButton/index';
import Checkbox from '../../../coreComponent/Checkbox/index';
import DiscardSettingsModal from './DiscardSettings/index';
import './Settings.scss';

export default function Settings(props) {
  const [saved, setSaved] = useState(false);
  const [settingsEnabled, setSettingsEnabled] = useState(false);
  const [webhookSettingsEnabled, setWebhookSettingsEnabled] = useState(false);
  const [threshold, setThreshold] = useState([]);
  const [error, setError] = useState('');
  const [isMail, setIsMail] = useState(true);
  const [isSms, setIsSms] = useState(false);
  const [allAdmins, setAllAdmins] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittingWebhook, setSubmittingWebhook] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [discardSettingsModal, setDiscardSettingsModal] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');

  useEffect(() => {
    props.getSettingsAPI();
  }, []);
  useEffect(() => {
    if (props.settings && !isEmpty(props.settings)) {
      let thresholds = [];
      for (let key in props.settings.thresholds) {
        thresholds.push(key);
      }
      setSaved(props.settings.send_notification);
      setSettingsEnabled(props.settings.send_notification);
      setWebhookSettingsEnabled(props.settings.send_notification_to_webhook);
      setWebhookUrl(props.settings.webhook_url || '');
      setThreshold(isArray(props.settings.thresholds) ? props.settings.thresholds : thresholds);
      setIsMail(props.settings.channel.includes('mail'));
      setIsSms(props.settings.channel.includes('sms'));
      setAllAdmins(props.settings.all_admins);
    } else {
      setSaved(false);
      setSettingsEnabled(false);
      setThreshold([]);
      setIsMail(true);
      setIsSms(false);
      setAllAdmins(false);
    }
  }, [props.settings]);

  const toggleWebhookSettings = () => {
    let thresholds = [];
    for (let key in props.settings.thresholds) {
      thresholds.push(key);
    }
    if (webhookSettingsEnabled) {
      let requestData = {
        ...props.settings,
        thresholds: isArray(props.settings.thresholds) ? props.settings.thresholds : thresholds,
        send_notification_to_webhook: !webhookSettingsEnabled,
        webhook_url: ''
      };
      props.updateSettingsAPI(requestData);
    }
    setWebhookSettingsEnabled(!webhookSettingsEnabled);
  };

  const toogleSettings = () => {
    if (settingsEnabled && saved) {
      setDiscardSettingsModal(true);
    } else {
      setSettingsEnabled(!settingsEnabled);
      setDisabled(false);
      props.discardChanges(true);
    }
  };

  const saveSettings = () => {
    setSubmitting(true);
    let requestData = {
      send_notification: settingsEnabled,
      thresholds: threshold,
      channel: [],
      all_admins: allAdmins,
      send_notification_to_webhook: props.settings.send_notification_to_webhook,
      webhook_url: props.settings.webhook_url
    };
    if (isMail) {
      requestData.channel.push('mail');
    }
    if (isSms) {
      requestData.channel.push('sms');
    }
    props
      .updateSettingsAPI(requestData)
      .then(() => {
        setSubmitting(false);
        setSaved(true);
        setDisabled(true);
        props.discardChanges(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const saveWebhookUrl = () => {
    let thresholds = [];
    for (let key in props.settings.thresholds) {
      thresholds.push(key);
    }
    setSubmittingWebhook(true);
    let requestData = {
      ...props.settings,
      thresholds: isArray(props.settings.thresholds) ? props.settings.thresholds : thresholds,
      send_notification_to_webhook: webhookSettingsEnabled,
      webhook_url: webhookUrl
    };
    props
      .updateSettingsAPI(requestData)
      .then(() => {
        setSubmittingWebhook(false);
      })
      .catch(() => {
        setSubmittingWebhook(false);
        setSubmitting(false);
      });
  };

  const discardSettings = () => {
    setSubmitting(true);
    let requestData = {
      send_notification: false,
      thresholds: [],
      channel: [],
      all_admins: false,
      send_notification_to_webhook: webhookSettingsEnabled,
      webhook_url: webhookUrl
    };
    props
      .updateSettingsAPI(requestData)
      .then(() => {
        setSubmitting(false);
        setSettingsEnabled(!settingsEnabled);
        setSaved(false);
        setDisabled(false);
        props.discardChanges(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const addThreshold = () => {
    let array = [...threshold];
    if (array.length === 0) {
      array.push(50);
    } else if (array.length === 1) {
      array.push(90);
    } else if (array.length === 2) {
      array.push(100);
    }
    setThreshold(array);
    setDisabled(false);
    props.discardChanges(true);
  };

  return (
    <div className={'settings-main-ctr'}>
      <DiscardSettingsModal
        show={discardSettingsModal}
        onHide={() => setDiscardSettingsModal(false)}
        onSubmit={discardSettings}
      />
      <div data-testid='settingsHeading' className={'heading'}>
        {'Settings'}
      </div>
      <div className={'settings-ctr'}>
        <div className={'toogle-settings-ctr'}>
          <SwitchButton
            label={'Set notification alert for credit usage'}
            checked={settingsEnabled}
            onChange={toogleSettings}
            disabled={submitting}
            whiteSwitch={false}
          />
        </div>
      </div>
      {settingsEnabled ? (
        <div className={'settings-ctr'}>
          <div className={'settings-row'}>
            <div data-testid='settingsLabel' className={'label'}>
              {'Data percent consumption'}
            </div>
            <div>
              {threshold.map((thresholdValue, index) => {
                return (
                  <div key={index} className={'threshold-input-ctr'}>
                    <Input
                      type={'percentage'}
                      value={thresholdValue}
                      disabled={submitting}
                      onChange={(value) => {
                        props.discardChanges(true);
                        if (parseFloat(value) > 0 && parseFloat(value) <= 100) {
                          setDisabled(false);
                          let array = [...threshold];
                          array[index] = parseFloat(value);
                          setThreshold(array);
                          setError('');
                        } else {
                          setError('Threshold should be greater than 0 and less than 100');
                        }
                      }}
                    />
                    <div
                      className={'close-icon'}
                      onClick={() => {
                        if (!submitting) {
                          setDisabled(false);
                          props.discardChanges(true);
                          let array = [...threshold];
                          array.splice(index, 1);
                          setThreshold(array);
                          setError('');
                        }
                      }}
                    >
                      <Image name={'closeIcon.svg'} />
                    </div>
                  </div>
                );
              })}
              {error ? <div className={'error'}>{error}</div> : null}
              {threshold.length > 2 ? null : (
                <div
                  data-testid='addThreshold'
                  className={'link'}
                  onClick={submitting ? null : addThreshold}
                >
                  {'Add Threshold'}
                </div>
              )}
            </div>
          </div>
          <div className={'settings-row'}>
            <div data-testid='labelText1' className={'label'}>
              {'Channel'}
            </div>
            <div>
              <div className={'channel-select-ctr'}>
                <Checkbox
                  id={'mailCheckbox'}
                  label={'Mail'}
                  large={true}
                  checked={isMail}
                  disabled={submitting}
                  onClick={() => {
                    setIsMail(!isMail);
                    setDisabled(false);
                    props.discardChanges(true);
                  }}
                />
                {AppHelper.isLocallyServed() || AppHelper.isDevServed() ? null : (
                  <Checkbox
                    id={'smsCheckbox'}
                    label={'SMS'}
                    large={true}
                    checked={isSms}
                    disabled={submitting}
                    onClick={() => {
                      setIsSms(!isSms);
                      setDisabled(false);
                      props.discardChanges(true);
                    }}
                  />
                )}
              </div>
              <div data-testid='supportText1' className={'support-text'}>
                {'Select channel to get notification alert for credit usage'}
              </div>
            </div>
          </div>
          <div className={'settings-row'}>
            <div data-testid='labelText2' className={'label'}>
              {'Send to all Admins'}
            </div>
            <div>
              <div className={'radio-value'}>
                <input
                  className={'input'}
                  type={'radio'}
                  value={true}
                  checked={allAdmins}
                  onChange={() => {
                    setAllAdmins(true);
                    setDisabled(false);
                    props.discardChanges(true);
                  }}
                />
                <div
                  data-testid='labelText3'
                  className={'label'}
                  onClick={() => {
                    setAllAdmins(true);
                    setDisabled(false);
                    props.discardChanges(true);
                  }}
                >
                  {'Yes'}
                </div>
                <input
                  className={'input'}
                  type={'radio'}
                  value={false}
                  checked={!allAdmins}
                  onChange={() => {
                    setAllAdmins(false);
                    setDisabled(false);
                    props.discardChanges(true);
                  }}
                />
                <div
                  data-testid='labelText4'
                  className={'label'}
                  onClick={() => {
                    setAllAdmins(false);
                    setDisabled(false);
                    props.discardChanges(true);
                  }}
                >
                  {'No'}
                </div>
              </div>
              {allAdmins ? null : (
                <div data-testid='supportText2' className={'support-text'}>
                  {'Sends notification to primary admin'}
                </div>
              )}
            </div>
          </div>
          <Button
            text={'Save Changes'}
            onClick={saveSettings}
            width={'140px'}
            height={'50px'}
            isDisabled={
              disabled || submitting || threshold.length === 0 || error || !(isMail || isSms)
            }
            isLoading={submitting}
          />
        </div>
      ) : null}
      <div className={'settings-ctr webhook-setting'}>
        <div className={'toogle-settings-ctr'}>
          <SwitchButton
            label={'Configure webhook to enable Digilocker'}
            checked={webhookSettingsEnabled}
            onChange={toggleWebhookSettings}
            disabled={submitting}
            whiteSwitch={false}
          />
        </div>
      </div>
      {webhookSettingsEnabled ? (
        <div className={'settings-ctr'}>
          <div className={'settings-row webhook-settings-row'}>
            <div data-testid='settingsLabel' className={'label'}>
              {'Add Webhook URL'}
            </div>
            <div>
              <div className={' threshold-input-ctr threshold-input-webhook'}>
                <Input
                  type={'text'}
                  value={webhookUrl}
                  onChange={(value) => {
                    setWebhookUrl(value);
                  }}
                />
              </div>
            </div>
          </div>
          <Button
            text={'Save Changes'}
            onClick={saveWebhookUrl}
            width={'140px'}
            height={'50px'}
            isDisabled={!webhookUrl}
            isLoading={submittingWebhook}
          />
          {/* <div className={'error-webhook'}>
            {
              'Looks like the added URL has authentication. Please add a URL without authentication.'
            }
          </div> */}
          <div className={'instruction'}>
            <div className={'instruction-top'}>
              {
                'This webhook will be used to push the response when the user completes the Digilocker signup/signin flow. This will act as the indicator to call the subsequent verification API'
              }
            </div>
            <div className={'instruction-heading'}>{'The Digilocker flow:'}</div>
            <div className={'instruction-items'}>
              {'1. Generate Digilocker URL and share to user.'}
            </div>
            <div className={'instruction-items'}>
              {'2. User completes flow and you get a success response hit in the above webhook.'}
            </div>
            <div className={'instruction-items'}>{'3. You call the verification API.'}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
