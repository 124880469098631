import { connect } from 'react-redux';
import {
  govtVerificationWithOCR,
  govtVerificationWithoutOCR,
  invalidateIdentityResponse,
  invalidatePassportOcrResponse,
  invalidatePassportResponse,
  invalidatePassportOnlyOcrResponse,
  updateUIPerson,
  addDocumentOnlyForOCR,
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    passportOcrResponse: AppHelper.isLaunchUI()
      ? state.passportOcrResponseLaunch
      : state.passportOcrResponse,
    passportOnlyOcrResponse: AppHelper.isLaunchUI()
      ? state.passportOnlyOcrResponseLaunch
      : state.passportOnlyOcrResponse,
    passportResponse: AppHelper.isLaunchUI()
      ? state.passportResponseLaunch
      : state.passportResponse,
    identityDocType: AppHelper.isLaunchUI() ? state.identityDocTypeLaunch : state.identityDocType,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // addDocument: (data) => dispatch(addDocument(data)),
    govtVerificationWithOCR: (requestBody,docType) => dispatch(govtVerificationWithOCR(requestBody,docType)),
    govtVerificationWithoutOCR: (docType, data) =>
      dispatch(govtVerificationWithoutOCR(docType, data)),
    invalidateIdentityResponse: () => dispatch(invalidateIdentityResponse()),
    invalidatePassportOcrResponse: () => dispatch(invalidatePassportOcrResponse()),
    invalidatePassportOnlyOcrResponse: () => dispatch(invalidatePassportOnlyOcrResponse()),
    invalidatePassportResponse: () => dispatch(invalidatePassportResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data)),
    addDocumentOnlyForOCR:(docType,data)=>dispatch(addDocumentOnlyForOCR(docType,data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
