import AppHelper from './AppHelper';
let localConfig = require('../config/local.json');
let devConfig = require('../config/dev.json');
let acceptanceConfig = require('../config/acceptance.json');
let prodConfig = require('../config/prod.json');

console.log('🔥 Environment is ' + process.env.NODE_ENV);

var appVar =
  process.env.NODE_ENV === 'production'
    ? prodConfig
    : process.env.NODE_ENV === 'acceptance'
    ? acceptanceConfig
    : AppHelper && AppHelper.isLocallyServed()
    ? localConfig
    : devConfig;

const AppConstants = {
  ...appVar,
  LIVE_MODE: 'live',
  TEST_MODE: 'test'
};
export default AppConstants;
