import React from 'react';

import Loader from '../Loader/Loader';
import './Button.scss';

export default function Button(props) {
  const customStyle = {
    width: props.width,
    height: props.height
  };

  return (
    <button
      style={customStyle}
      className={`btn-ctr ${props.isUnderline ? 'underline-text' : ''} ${
        props.isWhiteBtn ? 'white-btn' : ''
      }`}
      disabled={props.isDisabled || props.isLoading}
      onClick={props.isDisabled || props.isLoading ? null : props.onClick}
    >
      {props.isLoading ? (
        <Loader color={props.isWhiteBtn ? '#002C9D' : '#999999'} />
      ) : (
        <div className={'text'}>{props.text}</div>
      )}
    </button>
  );
}
Button.defaultProps = {
  text: '',
  isDisabled: false,
  isLoading: false,
  onClick: null,
  width: '100%',
  height: '48px',
  isUnderline: false,
  isWhiteBtn: false
};
