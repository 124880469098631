import React from 'react';

import './Tooltip.scss';

export default function Tooltip(props) {
  const customStyle = {
    left: props.left,
    top: props.top,
    width: props.width,
    padding: props.position === 'right' || props.position === 'left' ? '16px' : '8px'
  };
  const outerArrowStyle = {
    transform: `translate(calc(${props.width} - 44px), -12px)`
  };
  const arrowStyle = {
    transform: `translate(calc(${props.width} - 42px), -10px)`
  };
  const simpleArrowStyle = {
    transform: `translate(${
      props.position === 'right'
        ? '-23px'
        : props.position === 'left'
        ? `calc(${props.width} - 16px)`
        : `calc(${props.width}/2 - 12px)`
    }, ${
      props.position === 'right' || props.position === 'left'
        ? '-16px'
        : props.position === 'bottom'
        ? '-32px'
        : '7px'
    })`
  };
  return (
    <div className={'tooltip-main-ctr'}>
      {props.text ? (
        <div className={'simple-tooltip-ctr'} style={customStyle} data-testid='simple-tooltip'>
          <div className={'text'}>{props.text}</div>
          {props.position === 'right' ? (
            <div className={'right-arrow-ctr'} style={simpleArrowStyle}></div>
          ) : props.position === 'left' ? (
            <div className={'left-arrow-ctr'} style={simpleArrowStyle}></div>
          ) : props.position === 'bottom' ? (
            <div className={'up-arrow-ctr'} style={simpleArrowStyle}></div>
          ) : (
            <div className={'down-arrow-ctr'} style={simpleArrowStyle}></div>
          )}
        </div>
      ) : (
        <div className={'tooltip-ctr'} data-testid='tooltip' style={customStyle}>
          <div className={'up-arrow-outer-ctr'} style={outerArrowStyle}></div>
          <div className={'up-arrow-ctr'} style={arrowStyle}></div>
          {props.tooltipData.map((value, index) => {
            return (
              <div key={index} className={'text'} onClick={value.onClick}>
                {value.text}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
Tooltip.defaultProps = {
  top: '50px',
  left: '-172px',
  width: '189px',
  tooltipData: [{ text: '', onClick: null }],
  text: '',
  position: 'top'
};
