import React, { useState, useEffect } from 'react';

import Input from '../../coreComponent/Input/index';
import Button from '../../coreComponent/Button/index';
import Icon from '../../coreComponent/Icon';
import { validateEmail } from '../../utils/validations';
import SideNavbar from '../SideNavbar';
import './ForgotPassword.scss';
import secureLocalStorage from 'react-secure-storage';

export default function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);
  const [secondSubmit, setSecondSubmit] = useState(false);

  useEffect(() => {
    window && window.scrollTo(0, 0);
    const userEmail = secureLocalStorage.getItem('user') || '';
    setEmail(userEmail);
  }, []);

  const forgotPassword = () => {
    setSubmitting(true);
    let requestData = {
      email: email
    };
    props
      .forgotPasswordAPI(requestData)
      .then(() => {
        setSubmitting(false);
        setSecondSubmit(firstSubmit);
        setFirstSubmit(true);
      })
      .catch((err) => {
        setSubmitting(false);
        if (err.status === 404) {
          setError('Email id does not exist with us. Try again');
        } else {
          setError('Something went wrong.');
        }
      });
  };

  const login = () => {
    props.history.push('/login');
  };

  return (
    <div className={'forgot-password-main-ctr'}>
      <SideNavbar />
      <div className={'forgot-password-ctr'}>
        <div className={'content-ctr'}>
          {secondSubmit ? (
            <div>
              <div className={'login-ctr'} onClick={login}>
                <Icon icon={'ArrowLeft'} color={'#002C9D'} width={'20'} height={'20'} />
                <div className={'text'}>{'Back to Login'}</div>
              </div>
              <div className={'heading'}>{'Check your email'}</div>
              <div className={'content'}>
                {'We have resent an email to '}
                <b>{email}</b>
                {' with link to reset your password. Please check the instructions'}
              </div>
            </div>
          ) : firstSubmit ? (
            <div>
              <div className={'login-ctr'} onClick={login}>
                <Icon icon={'ArrowLeft'} color={'#002C9D'} width={'20'} height={'20'} />
                <div className={'text'}>{'Back to Login'}</div>
              </div>
              <div className={'heading'}>{'Check your email'}</div>
              <div className={'content'}>
                {
                  "Thanks, please check your email for instructions to reset your password. Didn't get the email? Check your spam folder or "
                }
                <div className={'link'} onClick={forgotPassword}>
                  {'Resend'}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={'login-ctr'} onClick={login}>
                <Icon icon={'ArrowLeft'} color={'#002C9D'} width={'20'} height={'20'} />
                <div className={'text'}>{'Back to Login'}</div>
              </div>
              <div className={'heading'}>{'Forgot Password'}</div>
              <div className={'sub-heading'}>
                {
                  "Enter the email address associated with your account and we'll send you a link to reset your password"
                }
              </div>
              <Input
                label={'Enter Email Address*'}
                disabled={submitting}
                type={'text'}
                value={email}
                onBlur={(value) => {
                  if (!validateEmail(value)) {
                    setError('Please enter a valid email id');
                  } else {
                    setError('');
                  }
                }}
                onChange={(value) => {
                  setEmail(value);
                  setError('');
                }}
                placeholder={'Enter Email Address'}
                error={error}
                errorWithIcon={true}
              />
              <div className={'submit-btn'}>
                <Button
                  text={'Submit'}
                  onClick={forgotPassword}
                  width={'120px'}
                  height={'50px'}
                  isDisabled={!email || error}
                  isLoading={submitting}
                  isWhiteBtn={!email || error || submitting}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
