import { connect } from 'react-redux';
import {
  getTokenKeys as getTokenKeysAPI,
  setTestTokenKey,
  setLiveTokenKey
} from '../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    testTokenKey: state.testTokenKey,
    liveTokenKey: state.liveTokenKey
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTokenKeysAPI: (data) => dispatch(getTokenKeysAPI(data)),
    setTestTokenKey: (data) => dispatch(setTestTokenKey(data)),
    setLiveTokenKey: (data) => dispatch(setLiveTokenKey(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
