import React from 'react';

import Button from '../../../../coreComponent/Button/index';
import './CreditsBanner.scss';

export default function CreditsBanner(props) {
  const rechargeAccount = () => {
    props.history.push('/billing/recharge-account');
  };

  return (
    <div className={'credits-banner-report-ctr'} data-testid='credit-banner-report'>
      <div>
        <div className={'title'}>{'CREDITS AVAILABLE'}</div>
        <div className={'value'}>{props.credits}</div>
      </div>
      <div>
        <Button
          text={'Recharge Account'}
          onClick={rechargeAccount}
          width={'180px'}
          height={'50px'}
          isWhiteBtn={true}
        />
      </div>
    </div>
  );
}
CreditsBanner.defaultProps = {
  credits: ''
};
