import React, { useState, useEffect } from 'react';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isEmpty } from 'lodash';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function Coa(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [name, setName] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [idError, setIdError] = useState('');
  const [nameError, setNameError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['coa_number']);

  useEffect(() => {
    if (props.uiActive === 'Coa') {
      loadData();
      if (props.coaResponse && !isEmpty(props.coaResponse)) {
        setResData(JSON.stringify(props.coaResponse));
        setIsEditable(false);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    let requestBody = {
      doc_type: 'ind_coa',
      personId: props.personId
    };
    if (
      data &&
      data.documents &&
      data.documents.ind_coa &&
      data.documents.ind_coa.result &&
      data.documents.ind_coa.result.registration_number &&
      !isEmpty(props.coaResponse)
    ) {
      requestBody.registration_number = data.documents.ind_coa.result.registration_number;
      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      setRegistrationNumber(data.documents.ind_coa.result.registration_number);
      setReqData(requestBody);
    }
  };

  const reset = () => {
    props.invalidateCoaResponse();
    setRegistrationNumber('');
    setIdError('');
    setName('');
    setNameError('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setSuccessMsg('');
    setResData('');
    setErrorCode('');
    setErrorMsg('');
    setReqData('');
    setSuccessParameters(['coa_number']);
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('architect_name') && !name) {
      error = true;
      setNameError('Architect Name is Required');
    }
    return error;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!isSuccessParameterReqError() && validateRegNo(registrationNumber) && !nameError) {
        setIsLoading(true);
        setIsEditable(false);
        coaCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const coaCheck = () => {
    let requestBody = {
      docType: 'ind_coa',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        coa_number: registrationNumber.trim()
      }
    };
    if (name) {
      requestBody['manual_input']['architect_name'] = name;
    }
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('ind_coa', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setErrorCode('');
        let responseData = response;
        if (responseData.information) {
          let information = JSON.parse(responseData.information);
          responseData.information = information;
        }
        setResData(JSON.stringify(responseData));
        setSuccessMsg('CoA check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setSuccessMsg('');
        setErrorMsg(getErrorResponseMessage(error));
      });
  };
  const validateRegNo = (regNo) => {
    if (regNo.length < 13) {
      setIdError("COA number must contain 13 characters (including '/')");
      setIsDisabled(true);
      return false;
    } else if (!regNo.match('[A-Z]{2}/[0-9]{4}/[0-9]{5}')) {
      setIdError('Invalid COA number');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      setRegistrationNumber(regNo);
      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };
  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'CoA'}</div>
      <div className={'sub-heading'}>
        {'Realtime verification of users registration number on Council of Architecture database.'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'coaNumber'}
                  label={'COA Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'architectName'}
                  label={`Architect's Name`}
                  disabled={false}
                  checked={successParameters.includes('architect_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['architect_name']));
                    setIsDisabled(false);
                    setNameError('');
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'COA Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={13}
              value={registrationNumber}
              onBlur={(value) => {
                validateRegNo(value);
              }}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIsDisabled(true);
                  setIdError(" ID must contain 13 characters (including '/')");
                }
                setRegistrationNumber(value);
              }}
              placeholder={'Ex: CA/1995/34526'}
              showClear={true}
              onClear={() => {
                setRegistrationNumber('');
                setIdError('');
                setIsDisabled(true);
              }}
              error={idError}
            />
            <Input
              label={`Architect's Name`}
              disabled={!isEditable}
              type={'text'}
              value={name}
              onBlur={(value) => {
                if (value) validateNameInput(value);
              }}
              onChange={(value) => {
                setName(value.toUpperCase());
                setIsDisabled(false);
                setNameError('');
              }}
              placeholder={''}
              showClear={true}
              onClear={() => {
                setName('');
                setNameError('');
                setIsDisabled(false);
              }}
              error={nameError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>

          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'ind_coa'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
