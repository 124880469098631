import { connect } from 'react-redux';
import { getPurchaseHistory as getPurchaseHistoryAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    purchaseHistory: state.purchaseHistory
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseHistoryAPI: (data) => dispatch(getPurchaseHistoryAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
