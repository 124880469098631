import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Image from '../../../../coreComponent/Image';
import Icon from '../../../../coreComponent/Icon';
import Loader from '../../../../coreComponent/Loader/index';
import {
  getDocType,
  getPersonName,
  getNameIcon,
  getPersonStatus
} from '../../../../utils/utilities';
import PersonInfoModal from './PersonInfo/index';
import './Person.scss';

export default function Person(props) {
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState(null);
  const [selfieUrl, setSelfieUrl] = useState('');
  const [name, setName] = useState('');
  const [documents, setDocuments] = useState([]);
  const [documentDetails, setDocumentDetails] = useState({
    name: '',
    status: '',
    image: '',
    details: {}
  });
  const [personInfoModal, setPersonInfoModal] = useState(false);
  useEffect(() => {
    getPerson();
  }, [props.personId]);

  const getPerson = () => {
    setLoading(true);
    props.getPersonAPI(props.personId).then((response) => {
      if (response && response.person) {
        setPerson(response.person);
        setSelfieUrl(response.person.selfie ? response.person.selfie.url : '');
        setName(getPersonName(response.person.name));
        getDocuments(response.person.documents);
        setLoading(false);
      }
    });
  };

  const getDocuments = (docs) => {
    let docArray = [];
    for (let doc in docs) {
      if (
        getDocType(doc) !== 'UPI ID' &&
        getDocType(doc) !== 'Bank Account' &&
        docs[doc] &&
        !isEmpty(docs[doc])
      ) {
        let document;
        if (getDocType(doc) === 'GST Certificate') {
          document = {
            name: getDocType(doc),
            status: getStatus(docs[doc].status),
            image: docs[doc].document1,
            details: {
              'Legal Name': docs[doc].result ? docs[doc].result.legal_name : '',
              GSTIN: docs[doc].result ? docs[doc].result.gstin : '',
              'PAN NUMBER': docs[doc].result ? docs[doc].result.pan_number : ''
            }
          };
        } else {
          document = {
            name: getDocType(doc),
            status: getStatus(docs[doc].status),
            image: docs[doc].document1,
            details: {
              'Name on Card': docs[doc].result ? docs[doc].result.name_on_card : '',
              'ID Number': docs[doc].result ? docs[doc].result.id_number : '',
              'Date of Birth': docs[doc].result ? docs[doc].result.date_of_birth : '',
              'Father`s Name': docs[doc].result ? docs[doc].result.fathers_name : ''
            }
          };
        }
        docArray.push(document);
      }
    }
    setDocuments(docArray);
  };

  const getStatus = (personStatus) => {
    let status = getPersonStatus(personStatus);
    let icon = null;
    let content = '';
    switch (status) {
      case 'OCR Done':
        content = 'Your document has been successfully OCRed.';
        icon = <Icon icon={'SuccessStatus'} color={'#65D782'} width={'16'} height={'16'} />;
        break;
      case 'Pending':
        content = 'Your document is being reviewed.';
        icon = <Icon icon={'PendingStatus'} color={'#FF891C'} width={'16'} height={'16'} />;
        break;
      case 'Failed':
        content = 'Your document has not successfully processed.';
        icon = <Icon icon={'FailedStatus'} color={'#FF6C64'} width={'16'} height={'16'} />;
        break;
      case 'Face Matched':
        content = 'Your document has been successfully matched.';
        icon = <Image name={'faceMatchedIcon.svg'} />;
        break;
      case 'Govt Verified':
        content = 'Your document has been successfully verified.';
        icon = <Icon icon={'GovtVerifiedStatus'} color={'#506EF8'} width={'16'} height={'16'} />;
        break;
    }
    return (
      <div className={'doc-status-ctr'}>
        <div className={'status'}>
          <div>{icon}</div>
          <div className={'text'}>{status}</div>
        </div>
        <div className={'content'}>{content}</div>
      </div>
    );
  };

  const viewDocument = (document) => {
    setDocumentDetails(document);
    setPersonInfoModal(true);
  };

  const hideDocument = () => {
    setPersonInfoModal(false);
  };

  return (
    <div className={'person-ctr'}>
      <PersonInfoModal show={personInfoModal} onHide={hideDocument} document={documentDetails} />
      {person && !loading ? (
        <div>
          <div className={'person-info'}>
            <div data-testid='personImage' className={'person-image'}>
              {selfieUrl ? (
                <img className={'image'} src={selfieUrl} />
              ) : (
                <div className={'text'}>{getNameIcon(name)}</div>
              )}
            </div>
            <div data-testid='personName' className={'person-name'}>
              {name}
            </div>
          </div>
          <div className={'documents-main-ctr'}>
            <div data-testid='documentHeading' className={'heading'}>
              {'DOCUMENTS'}
            </div>
            {documents && documents.length > 0 ? (
              <div className={'documents-ctr'}>
                {documents.map((document, index) => (
                  <div key={index} className={'document'}>
                    <div className={'doc-image'}>
                      <img data-testid='documentImage' className={'image'} src={document.image} />
                    </div>
                    <div data-testid='documentName' className={'doc-name'}>
                      {document.name}
                    </div>
                    <div data-testid='documentStatus'>{document.status}</div>
                    <div
                      data-testid='viewDetails'
                      className={'doc-footer'}
                      onClick={() => {
                        viewDocument(document);
                      }}
                    >
                      {'View Details'}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className={'person-loading-ctr'}>
          <Loader size={'120px'} color={'#666666'} width={'0.5rem'} />
        </div>
      )}
    </div>
  );
}
Person.defaultProps = {
  personId: ''
};
