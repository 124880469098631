import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../../../../coreComponent/Button/index';
import './DisableTokenKey.scss';

export default function DisableTokenKey(props) {
  const [submitting, setSubmitting] = useState(false);
  const disableKey = () => {
    setSubmitting(true);
    let requestData = {
      token_id: props.tokenId,
      password: props.password,
      immediate: props.immediate
    };
    props
      .disableTokenKeyAPI(requestData)
      .then(() => {
        setSubmitting(false);
        props.onSubmit('success');
        onHide();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.status === 403) {
          props.onSubmit('passwordError');
        } else {
          props.onSubmit('error');
        }
        onHide();
      });
  };

  const onHide = () => {
    setSubmitting(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'disable-token-key-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='headingDisable' className={'heading'}>
            {'Disable Token Key'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div data-testid='bodyText1' className={'text'}>
          {props.immediate
            ? 'This key will get disabled immediately after this action, henceforth making any API call with this token ineffective.'
            : 'This key will get disabled after 24 hours from this action.'}
        </div>
        <div data-testid='bodyText2' className={'text'}>
          {'Please confirm if you want to proceed.'}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
            isDisabled={submitting}
          />
        </div>
        <Button
          text={'Confirm'}
          onClick={disableKey}
          width={'120px'}
          height={'50px'}
          isLoading={submitting}
        />
      </Modal.Footer>
    </Modal>
  );
}
DisableTokenKey.defaultProps = {
  tokenId: '',
  immediate: true,
  password: '',
  show: false,
  onHide: null,
  onSubmit: null
};
