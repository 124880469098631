import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../PrivateRoute';
import Login from '../Login';
import PrivacyPolicy from '../PrivacyPolicy';
import TnC from '../TnC';
import AadhaarVerification from '../AadhaarVerification';
import AadhaarRedirection from '../AadhaarVerification/Redirection';
import AadhaarClientDemo from '../AadhaarVerification/ClientDemo';
import WhatsappAadhaarVerification from '../WhatsappAadhaarVerification';
import WhatsappAadhaarResult from '../WhatsappAadhaarVerification/Result';
import SignUp from '../SignUp';
import VerificationEmailSent from '../VerificationEmailSent';
import EmailSent from '../EmailSent';
import AccountSetup from '../AccountSetup';
import SendVerificationLink from '../SendVerificationLink';
import ForgotPassword from '../ForgotPassword';
import SetPassword from '../SetPassword';
import DemoUI from '../DemoUI';
import Dashboard from '../Dashboard';
import RechargeInvoice from '../RechargeInvoice';
import My404Page from '../My404Page';
import DigiRed from '../DigiRedirectionPage';
import ClientRedirectionPage from '../ClientRedirectionPage';

export default () => {
  return (
    <Switch>
      <Redirect exact from='/' to='/login' />
      <Redirect exact from='/dashboard' to='/getting-started' />
      <Redirect exact from='/account' to='/account/overview' />
      <Redirect exact from='/my-team' to='/my-team/admin' />
      <Redirect exact from='/usage' to='/usage/summary' />
      <Redirect exact from='/billing' to='/billing/recharge-account' />
      <Route exact path={'/login'} component={Login} />
      <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
      <Route exact path={'/terms-and-condition'} component={TnC} />
      <Route exact path={'/aadhaar-verification'} component={AadhaarVerification} />
      <Route exact path={'/aadhaar-verification/redirection'} component={AadhaarRedirection} />
      <Route exact path={'/aadhaar-verification/client-demo'} component={AadhaarClientDemo} />
      <Route exact path={'/aadhaar-verification-portal/result'} component={WhatsappAadhaarResult} />
      <Route
        exact
        path={'/aadhaar-verification-portal/:token'}
        component={WhatsappAadhaarVerification}
      />
      <Route exact path={'/sign-up'} component={SignUp} />
      <Route exact path={'/verification-email-sent'} component={VerificationEmailSent} />
      <Route exact path={'/email-sent'} component={EmailSent} />
      <Route exact path={'/account-setup'} component={AccountSetup} />
      <Route exact path={'/send-verification-link'} component={SendVerificationLink} />
      <Route exact path={'/forgot-password'} component={ForgotPassword} />
      <Route exact path={'/reset-password'} component={SetPassword} />
      <Route exact path={'/set-password'} component={SetPassword} />
      <Route exact path={'/redirection'} component={DigiRed} />
      <Route exact path={'/digilocker/initiate'} component={ClientRedirectionPage} />
      <PrivateRoute exact path={'/demo-ui'} component={DemoUI} />
      <PrivateRoute exact path={'/launch-ui'} component={DemoUI} />
      <PrivateRoute exact path={'/profile'} component={Dashboard} />
      <PrivateRoute exact path={'/getting-started'} component={Dashboard} />
      <PrivateRoute exact path={'/account/overview'} component={Dashboard} />
      <PrivateRoute exact path={'/account/token-key'} component={Dashboard} />
      <PrivateRoute exact path={'/account/settings'} component={Dashboard} />
      <PrivateRoute exact path={'/my-team/admin'} component={Dashboard} />
      <PrivateRoute exact path={'/usage/summary'} component={Dashboard} />
      <PrivateRoute exact path={'/usage/summary/people-added'} component={Dashboard} />
      <PrivateRoute exact path={'/usage/summary/person'} component={Dashboard} />
      <PrivateRoute exact path={'/usage/api-log'} component={Dashboard} />
      <PrivateRoute exact path={'/billing/recharge-account'} component={Dashboard} />
      <PrivateRoute exact path={'/billing/credit-usage-report'} component={Dashboard} />
      <Route exact path={'/recharge-invoice/:orderId'} component={RechargeInvoice} />
      <Route exact path={'*'} component={My404Page} />
    </Switch>
  );
};
