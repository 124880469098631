import { connect } from 'react-redux';
import {
  addSelfie,
  addDocumentOnlyForOCR,
  compareSelfieAndDocument,
  invalidateFaceResponse,
  invalidateIdentityResponse,
  invalidateIdentityDocType,
  invalidateAadhaarOcrResponse,
  invalidateDLOcrResponse,
  invalidateVoterOcrResponse,
  invalidatePANOcrResponse,
  invalidatePassportResponse,
  updateUIPerson
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    identityResponse: AppHelper.isLaunchUI()
      ? state.identityResponseLaunch
      : state.identityResponse,
    identityDocType: AppHelper.isLaunchUI() ? state.identityDocTypeLaunch : state.identityDocType,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSelfie: (data) => dispatch(addSelfie(data)),
    addDocumentOnlyForOCR: (docType,data) => dispatch(addDocumentOnlyForOCR(docType,data)),
    compareSelfieAndDocument: (docType, data) => dispatch(compareSelfieAndDocument(docType, data)),
    invalidateFaceResponse: () => dispatch(invalidateFaceResponse()),
    invalidateIdentityResponse: () => dispatch(invalidateIdentityResponse()),
    invalidateIdentityDocType: () => dispatch(invalidateIdentityDocType()),
    invalidateAadhaarOcrResponse: () => dispatch(invalidateAadhaarOcrResponse()),
    invalidateDLOcrResponse: () => dispatch(invalidateDLOcrResponse()),
    invalidateVoterOcrResponse: () => dispatch(invalidateVoterOcrResponse()),
    invalidatePANOcrResponse: () => dispatch(invalidatePANOcrResponse()),
    invalidatePassportResponse: () => dispatch(invalidatePassportResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
