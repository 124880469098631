import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';
import Keys from './keysMapping';
import storage from '../api/localstorage';

export const getErrorMessage = (error) => {
  if ((error.response && error.response.data) || error.data) {
    const errorObject =
      (error.data && error.data?.errorMessage) ||
      error.response.data?.message ||
      error.response.data?.api_status_description ||
      error.response.data?.api_status_message ||
      error.response.data.errorMsg ||
      error.response.data.msg ||
      (error.response.data.error && error.response.data?.error?.message) ||
      (Array.isArray(error.response.data?.errors) &&
        error.response.data.errors?.length &&
        error.response.data?.errors[0].msg) ||
      error.response.data?.error ||
      error.response.data ||
      'Something went wrong.';

    switch (typeof errorObject) {
      case 'object':
        return (
          errorObject.msg ||
          errorObject.message ||
          JSON.stringify(errorObject.msg) ||
          'Something went wrong.'
        );
      case 'string':
        return errorObject || 'Something went wrong.';
      default:
        return 'Something went wrong.';
    }
  }
  return 'Something went wrong.';
};

export const copyToClipboard = (value) => {
  var dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = value;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export const getDocType = (docType) => {
  switch (docType) {
    case Keys.docTypeKeys.AADHAAR_CARD:
      return 'Aadhaar Card';
    case Keys.docTypeKeys.DRIVING_LICENSE:
      return 'Driving License';
    case Keys.docTypeKeys.VOTER_ID:
      return 'Voter ID';
    case Keys.docTypeKeys.PAN_CARD:
      return 'PAN Card';
    case Keys.docTypeKeys.GST_CERTIFICATE:
      return 'GST Certificate';
    case Keys.docTypeKeys.PASSPORT:
      return 'Passport';
    case Keys.docTypeKeys.VEHICLE_RC:
      return 'Vehicle RC';
    case Keys.docTypeKeys.BANK_ACCOUNT:
      return 'Bank Account';
    case Keys.docTypeKeys.UPI_ID:
      return 'UPI ID';
    default:
      return docType;
  }
};

export const getTransactionType = (txType) => {
  switch (txType) {
    case Keys.txTypeKeys.AADHAAR_VERIFICATION:
      return 'Aadhaar Card Verification';
    case Keys.txTypeKeys.AADHAAR_OCR:
      return 'Aadhaar Card OCR';
    case Keys.txTypeKeys.DL_VERIFICATION:
      return 'Driving License Verification';
    case Keys.txTypeKeys.DL_OCR:
      return 'Driving License OCR';
    case Keys.txTypeKeys.VOTER_VERIFICATION:
      return 'Voter ID Verification';
    case Keys.txTypeKeys.VOTER_OCR:
      return 'Voter ID OCR';
    case Keys.txTypeKeys.PAN_VERIFICATION:
      return 'PAN Card Verification';
    case Keys.txTypeKeys.PAN_OCR:
      return 'PAN Card OCR';
    case Keys.txTypeKeys.GST_VERIFICATION:
      return 'GST Certificate Verification';
    case Keys.txTypeKeys.GST_OCR:
      return 'GST Certificate OCR';
    case Keys.txTypeKeys.PASSPORT_VERIFICATION:
      return 'Passport Verification';
    case Keys.txTypeKeys.PASSPORT_OCR:
      return 'Passport OCR';
    case Keys.txTypeKeys.RC_VERIFICATION:
      return 'RC Verification';
    case Keys.txTypeKeys.RC_OCR:
      return 'RC OCR';
    case Keys.txTypeKeys.CHEQUE_OCR:
      return 'Cheque OCR';
    case Keys.txTypeKeys.BANK_ACCOUNT:
      return 'Bank Account';
    case Keys.txTypeKeys.UPI_ID:
      return 'UPI ID';
    case Keys.txTypeKeys.COURT_CHECK:
      return 'Court Check';
    case Keys.txTypeKeys.AADHAAR_MASK:
      return 'Aadhaar Masking';
    default:
      return txType;
  }
};

export const getPersonStatus = (status) => {
  switch (status) {
    case Keys.personStatusKeys.SUCCESS:
      return 'OCR Done';
    case Keys.personStatusKeys.PENDING:
      return 'Pending';
    case Keys.personStatusKeys.FAILED:
      return 'Failed';
    case Keys.personStatusKeys.FACE_MATCHED:
      return 'Face Matched';
    case Keys.personStatusKeys.GOVT_VERIFIED:
      return 'Govt Verified';
    case 'OCR Done':
      return Keys.personStatusKeys.SUCCESS;
    case 'Pending':
      return Keys.personStatusKeys.PENDING;
    case 'Failed':
      return Keys.personStatusKeys.FAILED;
    case 'Face Matched':
      return Keys.personStatusKeys.FACE_MATCHED;
    case 'Govt Verified':
      return Keys.personStatusKeys.GOVT_VERIFIED;
    default:
      return status;
  }
};

export const getPersonName = (name) => {
  let personName = name
    ? name.first
      ? name.first +
        (name.middle && name.middle !== name.first
          ? name.last && name.middle !== name.last
            ? ' ' + name.middle + ' ' + name.last
            : ' ' + name.middle
          : name.last
          ? ' ' + name.last
          : '')
      : 'User Name'
    : 'User Name';
  return personName;
};

export const getNameIcon = (name) => {
  let nameArray = name.split(' ');
  let nameIcon = '';
  if (nameArray.length > 1) {
    nameIcon = nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase();
  } else {
    nameIcon = nameArray[0][0].toUpperCase() + nameArray[0][1].toUpperCase();
  }
  return nameIcon;
};

export const getDigiLockerUrl = (personId) => {
  let clientID = '';
  const baseUrl = AppHelper.isLaunchUI()
    ? AppConstants.baseURL
    : 'https://devdashboard.springscan.springverify.com/';

  const data = {
    personId: personId,
    token: AppHelper.isLaunchUI() ? storage.getLaunchToken() : storage.getDemoToken(),
    isLaunchUI: AppHelper.isLaunchUI()
  };
  var encoded = window.btoa(JSON.stringify(data));

  // For demo-ui we are calling dev-apis so client-id and baseurl should be of dev

  switch (process.env.NODE_ENV) {
    case 'production':
      clientID = AppHelper.isLaunchUI() ? '325AE702' : '2608135E';
      break;
    case 'acceptance':
      clientID = AppHelper.isLaunchUI() ? '00C7B28A' : '2608135E';
      break;
    default:
      clientID = '2608135E';
      break;
  }

  return `https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=${clientID}&redirect_uri=${baseUrl}redirection&state=${encoded}&dl_flow=signup&verified_mobile=9623452038`;
};

export const getValidJWTToken = (token) => {
  return token.split('.').slice(1).join('.');
};

export const getDigiLockerUrlForClientRedirection = (
  personId,
  token = '',
  decodeToken,
  requestId,
  directFlow = false
) => {
  let clientID = '';
  let baseUrl = AppConstants.baseURL;

  const data = {
    personId: personId,
    token: token,
    isLaunchUI: true,
    decodeToken: decodeToken,
    requestId,
    directFlow
  };
  var encoded = window.btoa(JSON.stringify(data));

  switch (process.env.NODE_ENV) {
    case 'production':
      clientID = directFlow ? '325AE702' : AppHelper.isLaunchUI() ? '325AE702' : '2608135E';
      break;
    case 'acceptance':
      clientID = directFlow ? '00C7B28A' : AppHelper.isLaunchUI() ? '00C7B28A' : '2608135E';
      break;
    default:
      clientID = '2608135E';
      break;
  }

  const redirectURL = `https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=${clientID}&redirect_uri=${baseUrl}redirection&state=${encoded}&dl_flow=signup&verified_mobile=9623452038`;
  if (directFlow) {
    window.open(redirectURL, '_blank');
  } else {
    window.location.href = redirectURL;
  }
};

export const getErrorResponseMessage = (error) => {
  return (
    (error?.data?.error && error?.data?.error?.message) ||
    error?.data?.message ||
    error?.data?.api_status_description ||
    error?.data?.api_status_message
  );
};

export const saveLoginResponse = (data) => {
  try {
    const isProductionOrAcceptance =
      process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'acceptance';
    storage.setMode(data.prod_enabled ? 'live' : 'test');
    storage.setDevToken(data.dashboard_auth_token_dev);
    storage.setProdToken(data.dashboard_auth_token_prod || data.dashboard_auth_token_dev);
    storage.setDemoTokenId(data.demo_token_id);
    storage.setDemoToken(data.demo_token);
    storage.setDemoPersonId(data.demo_person_id);
    storage.setLaunchTokenId(
      data.launch_token_id || (isProductionOrAcceptance ? null : data.demo_token_id)
    );
    storage.setLaunchToken(
      data.launch_token || (isProductionOrAcceptance ? null : data.demo_token)
    );
    storage.setLaunchPersonId(
      data.launch_person_id || (isProductionOrAcceptance ? null : data.demo_person_id)
    );
  } catch (err) {
    console.error('On Save Login Res Err: ', err);
  }
};
