import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Icon from '../../../coreComponent/Icon';
import Input from '../../../coreComponent/Input/index';
import SelectInput from '../../../coreComponent/SelectInput';
import Button from '../../../coreComponent/Button/index';
import {
  validateName,
  validateNumber,
  validatesPhoneNumber,
  getIntlPhoneNumber
} from '../../../utils/validations';
import countryCodes from '../../../utils/countryCodes.json';
import ChangePasswordModal from './ChangePassword/index';
import './Profile.scss';

export default function Profile(props) {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  useEffect(() => {
    if (props.userProfile && !isEmpty(props.userProfile)) {
      loadProfile(props.userProfile);
    }
  }, [props.userProfile]);

  const loadProfile = (profile) => {
    setName(profile.name ? profile.name.first + ' ' + profile.name.last : '');
    setEmail(profile.email);
    let phoneNumber = profile.phoneNo ? getIntlPhoneNumber('+' + profile.phoneNo) : '';
    setCountryCode({
      label: phoneNumber ? `${phoneNumber.country} (+${phoneNumber.countryCallingCode})` : '',
      value: phoneNumber ? `+${phoneNumber.countryCallingCode}` : ''
    });
    setMobileNumber(phoneNumber ? phoneNumber.nationalNumber : '');
  };

  const editProfile = () => {
    setSubmitting(true);
    let requestBody = {
      admin_id: props.userProfile.uuid,
      name: name,
      phone_number: countryCode.value + mobileNumber
    };
    props
      .editAdminAPI(requestBody)
      .then(() => {
        setSubmitting(false);
        setEdit(false);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.status === 400) {
          setPhoneError('Phone Number already exists. Please try another.');
        } else {
          setPhoneError('Something went wrong.');
        }
      });
  };

  return (
    <div className={'profile-ctr'}>
      <ChangePasswordModal
        show={changePasswordModal}
        onHide={() => setChangePasswordModal(false)}
      />
      <div className={'heading'}>{'Personal Detail'}</div>
      <div className={'company-details-ctr'}>
        {edit ? null : (
          <div className={'actions-ctr'}>
            <div
              className={'action'}
              onClick={() => {
                setChangePasswordModal(true);
              }}
            >
              <Icon icon={'ChangePassword'} color={'#002C9D'} width={'12'} height={'12'} />
              <div className={'text'}>{'Change Password'}</div>
            </div>
            <div
              className={'action'}
              onClick={() => {
                setEdit(true);
              }}
            >
              <Icon icon={'Edit'} color={'#002C9D'} width={'12'} height={'12'} />
              <div className={'text'}>{'Edit'}</div>
            </div>
          </div>
        )}
        <div className={'data-row'}>
          <div className={'label'}>{'Name'}</div>
          <div className={'separator'}>{':'}</div>
          {edit ? (
            <div className={'input-value'}>
              <Input
                disabled={submitting}
                type={'text'}
                value={name}
                onChange={(value) => {
                  setName(value);
                  if (validateName(value)) {
                    setNameError('');
                  } else {
                    setNameError('Please enter a valid name');
                  }
                }}
                placeholder={'Enter Name'}
                error={nameError}
              />
            </div>
          ) : (
            <div>{name}</div>
          )}
        </div>
        <div className={'data-row'}>
          <div className={'label'}>{'Email'}</div>
          <div className={'separator'}>{':'}</div>
          <div>{email}</div>
        </div>
        <div className={'data-row'}>
          <div className={'label'}>{'Phone'}</div>
          <div className={'separator'}>{':'}</div>
          {edit ? (
            <div className={'phone-input-value'}>
              <div className={'select-value'}>
                <SelectInput
                  defaultValue={countryCode}
                  isDisabled={submitting}
                  isSearchable={true}
                  options={countryCodes}
                  onSubmit={(value) => {
                    setCountryCode(value);
                    let phoneNumber = value.value + mobileNumber;
                    if (
                      (mobileNumber && phoneNumber && validatesPhoneNumber(phoneNumber)) ||
                      !mobileNumber
                    ) {
                      setPhoneError('');
                    } else {
                      setPhoneError('Please enter a valid number');
                    }
                  }}
                />
              </div>
              <div className={'input-value'}>
                <Input
                  disabled={submitting}
                  type={'text'}
                  value={mobileNumber}
                  onBlur={(value) => {
                    setMobileNumber(value);
                    let phoneNumber = countryCode.value + value;
                    if (phoneNumber && validatesPhoneNumber(phoneNumber)) {
                      setPhoneError('');
                    } else {
                      setPhoneError('Please enter a valid number');
                    }
                  }}
                  onChange={(value) => {
                    setMobileNumber(value);
                    if (validateNumber(value)) {
                      setPhoneError('');
                    } else {
                      setPhoneError('Please enter a valid number');
                    }
                  }}
                  placeholder={'Enter Phone Number'}
                  error={phoneError}
                />
              </div>
            </div>
          ) : (
            <div>{countryCode.value + ' ' + mobileNumber}</div>
          )}
        </div>
        {edit ? (
          <div className={'button-ctr'}>
            <div className={'cancel-btn'}>
              <Button
                text={'Cancel'}
                onClick={() => {
                  loadProfile(props.userProfile);
                  setEdit(false);
                }}
                width={'120px'}
                height={'50px'}
                isWhiteBtn={true}
              />
            </div>
            <Button
              text={'Save'}
              onClick={editProfile}
              width={'120px'}
              height={'50px'}
              isDisabled={submitting || nameError || phoneError}
              isLoading={submitting}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
