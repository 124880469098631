import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { copyToClipboard } from '../../../../utils/utilities';
import Icon from '../../../../coreComponent/Icon';
import Tooltip from '../../../../coreComponent/Tooltip/index';
import Button from '../../../../coreComponent/Button/index';
import './ViewKey.scss';

export default function ViewKey(props) {
  const [tokenKey, setTokenKey] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setTokenKey(props.tokenKey);
  }, [props.tokenKey]);

  const onHide = () => {
    setIsCopied(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'view-token-key-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='viewHeading' className={'heading'}>
            {'View Key'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div data-testid='tooltip' className={'key-ctr'}>
          <div data-testid='tokenKey' className={'key'}>
            {tokenKey}
          </div>
          <div className='tooltip-icon'>
            <div
              className={'copy-icon'}
              onClick={() => {
                copyToClipboard(tokenKey);
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 3000);
              }}
            >
              <Icon icon={'Copy'} color={'#002C9D'} width={'12'} height={'15'} />
            </div>
          </div>
          <Tooltip
            top={'-28px'}
            left={isCopied ? '-60px' : '-54px'}
            width={isCopied ? '60px' : '48px'}
            text={isCopied ? 'Copied' : 'Copy'}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text={'Cancel'}
          onClick={onHide}
          width={'120px'}
          height={'50px'}
          isWhiteBtn={true}
        />
      </Modal.Footer>
    </Modal>
  );
}
ViewKey.defaultProps = {
  tokenKey: '',
  show: false,
  onHide: null
};
