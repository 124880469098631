import React, { useState } from 'react';

import { copyToClipboard } from '../../../utils/utilities';
import Tooltip from '../../../coreComponent/Tooltip/Tooltip';
import AppConstants from '../../../coreComponent/AppConstants';
import AppHelper from '../../../coreComponent/AppHelper';
import './DemoUrl.scss';

export default function DemoUrl(props) {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className={'url-ctr'}>
      <div className={'method'}>{props.method}</div>
      <div className='tooltip-icon'>
        <div
          className={'url'}
          onClick={() => {
            copyToClipboard(
              (AppHelper.isLaunchUI() ? AppConstants.developmentAPIURL : AppConstants.testAPIURL) +
                props.url
            );
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 3000);
          }}
        >
          {(AppHelper.isLaunchUI() ? AppConstants.developmentAPIURL : AppConstants.testAPIURL) +
            props.url}
        </div>
      </div>
      <Tooltip
        top={'-40px'}
        left={'-200px'}
        width={isCopied ? '60px' : '108px'}
        text={isCopied ? 'Copied' : `Copy ${props.method} url`}
      />
    </div>
  );
}
DemoUrl.defaultProps = {
  method: 'POST', //need method here
  url: '' //need url
};
