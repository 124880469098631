import React from 'react';

import './DemoNotes.scss';

export default function DemoNotes(props) {
  return (
    <div>
      {props.showNotes === 'face' ? (
        <div>
          <div className={'notice'}>
            {
              'You will be charged credits for running a check. Make sure you upload correct documents'
            }
          </div>
          <div className={'notes-ctr'}>
            <div className={'notes-heading'}>{'Important Notes:'}</div>
            <div className={'notes'}>{'Face should not be too far or too close to camera'}</div>
            <div className={'notes'}>{'Make sure your image is not blurred'}</div>
            <div className={'notes'}>{'Doesn’t support zipped file or folder'}</div>
            <div className={'notes'}>{'Image uploaded will be stored in S3 bucket'}</div>
          </div>
        </div>
      ) : props.showNotes === 'upi' ? (
        <div>
          <div className={'notice'}>
            {
              'You will be charged credits for running a check. Make sure input details are correct '
            }
          </div>
          <div className={'notes-ctr'}>
            <div className={'notes-heading'}>{'Important Notes:'}</div>
            <div className={'notes'}>
              {'This check can only be run through pre-defined test data. Success case VPA Id is '}
              <b>{'success@upi'}</b>
              {'  and Failed case VPA id is '}
              <b>{'failure@upi'}</b>
            </div>
            <div className={'notes'}>
              {'Data should be entered in suggested format, for manual input'}
            </div>
          </div>
        </div>
      ) : props.showNotes === 'detail' ? (
        <div>
          <div className={'notice'}>
            {
              'You will be charged credits for running a check. Make sure input details are correct '
            }
          </div>
          <div className={'notes-ctr'}>
            <div className={'notes-heading'}>{'Important Notes:'}</div>
            <div className={'notes'}>
              {'Data should be entered in suggested format, for manual input'}
            </div>
            {props.isAllInputs ? (
              <div className={'notes'}>{'For better result, run check with all input details'}</div>
            ) : null}
          </div>
        </div>
      ) : props.showNotes === 'multi-details' ? (
        <div>
          <div className={'notice'}>
            {
              'You will be charged credits independently for each ID. Make sure input details are correct'
            }
          </div>
          <div className={'notes-ctr'}>
            <div className={'notes-heading'}>{'Important Notes:'}</div>
            <div className={'notes'}>
              {'Data should be entered in suggested format, for manual input'}
            </div>
            <div className={'notes'}>{'Minimum IDs allowed here is 1'}</div>
            <div className={'notes'}>{'Maximum IDs allowed here is 5'}</div>
          </div>
        </div>
      ) : (
        <div>
          <div className={'notice'}>
            {
              'You will be charged credits for running a check. Make sure you upload correct documents'
            }
          </div>
          <div className={'notes-ctr'}>
            <div className={'notes-heading'}>{'Important Notes:'}</div>
            <div className={'notes'}>{'Make sure your image is not blurred'}</div>
            <div className={'notes'}>{'Doesn’t support zipped file or folder'}</div>
            <div className={'notes'}>
              {'Uploaded document here will be used for further calls unless removed'}
            </div>
            <div className={'notes'}>{'Image uploaded will be stored in S3 bucket'}</div>
          </div>
        </div>
      )}
    </div>
  );
}
DemoNotes.defaultProps = {
  showNotes: 'document', //face || document || detail || upi || multi-details
  isReports: false,
  isAllInputs: false
};
