import React from 'react';

import tnc from '../../Assets/documents/TnC.pdf';

export default function TnC(props) {
  return (
    <div>
      <embed
        title='terms and conditions'
        type='application/pdf'
        src={tnc}
        style={{ width: '100vw', height: '100vh' }}
      />
    </div>
  );
}
