import { connect } from 'react-redux';
import {
  govtVerificationWithOCR,
  govtVerificationWithoutOCR,
  invalidateIdentityResponse,
  invalidateVoterOcrResponse,
  invalidateVoterResponse,
  invalidateVoterOnlyOcrResponse,
  updateUIPerson,
  addDocumentOnlyForOCR,
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    voterOcrResponse: AppHelper.isLaunchUI()
      ? state.voterOcrResponseLaunch
      : state.voterOcrResponse,
    voterOnlyOcrResponse: AppHelper.isLaunchUI()
      ? state.voterOnlyOcrResponseLaunch
      : state.voterOnlyOcrResponse,
    voterResponse: AppHelper.isLaunchUI() ? state.voterResponseLaunch : state.voterResponse,
    identityDocType: AppHelper.isLaunchUI() ? state.identityDocTypeLaunch : state.identityDocType,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    govtVerificationWithOCR: (requestBody,docType) => dispatch(govtVerificationWithOCR(requestBody,docType)),
    govtVerificationWithoutOCR: (docType, data) =>
      dispatch(govtVerificationWithoutOCR(docType, data)),
    invalidateIdentityResponse: () => dispatch(invalidateIdentityResponse()),
    invalidateVoterOcrResponse: () => dispatch(invalidateVoterOcrResponse()),
    invalidateVoterOnlyOcrResponse: () => dispatch(invalidateVoterOnlyOcrResponse()),
    invalidateVoterResponse: () => dispatch(invalidateVoterResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data)),
    addDocumentOnlyForOCR:(docType,data)=>dispatch(addDocumentOnlyForOCR(docType,data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
