import React, { useState } from 'react';
import moment from 'moment';

import AppHelper from '../../../../coreComponent/AppHelper';
import Button from '../../../../coreComponent/Button/index';
import Alert from '../../../../coreComponent/Alert/index';
import AppConstants from '../../../../coreComponent/AppConstants';
import './ReviewOrder.scss';

export default function ReviewOrder(props) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const pay = () => {
    setAlert(false);
    createOrder();
  };

  const showRazorPay = (internalOrderId, razorpayOrderId) => {
    let options = {
      key: AppHelper.getRazorpayKey(),
      amount: props.amount * 100,
      currency: 'INR',
      name: 'SpringScan',
      description: props.loginData.company_name,
      image: require('../../../../logo.png'),
      order_id: razorpayOrderId,
      handler: function (response) {
        updateOrder(response, internalOrderId, razorpayOrderId);
      },
      prefill: {
        name: props.userProfile.name
          ? props.userProfile.name.first + ' ' + props.userProfile.name.last
          : '',
        email: props.userProfile.email,
        contact: props.userProfile.phoneNo
      },
      notes: {
        address: 'SpringScan'
      },
      theme: {
        color: '#002C9D'
      }
    };
    let rzp = new Razorpay(options);
    rzp.open();
  };

  const createOrder = () => {
    setLoading(true);
    let internalOrderId = `${Math.random().toFixed(16).substring(2)}`;
    let data = {
      amount: props.amount * 100,
      credits: props.credits,
      internal_order_id: internalOrderId
    };
    props
      .createOrderAPI(data)
      .then((response) => {
        setLoading(false);
        showRazorPay(response.internal_order_id, response.razorpay_order_id);
      })
      .catch(() => {
        setAlert(true);
      });
  };

  const updateOrder = (razorpayData, internalOrderId, razorpayOrderId) => {
    setLoading(true);
    let data = {
      internal_order_id: internalOrderId,
      razorpay_order_id: razorpayOrderId,
      razorpay_payment_id: razorpayData.razorpay_payment_id,
      razorpay_signature: razorpayData.razorpay_signature,
      status: 'success'
    };
    props
      .updateOrderAPI(data)
      .then(() => {
        generateInvoice(internalOrderId);
      })
      .catch(() => {
        setAlert(true);
      });
  };

  const generateInvoice = (internalOrderId) => {
    let date = moment().format('DD MMMM YYYY');
    let data = {
      internal_order_id: internalOrderId,
      invoice_url: AppConstants.developmentURL + 'recharge-invoice/' + internalOrderId
    };
    props
      .generateInvoiceAPI(data)
      .then((response) => {
        setLoading(false);
        props.getDashboardStateAPI();
        props.onSuccess(props.credits, props.amount, date, internalOrderId, response.url);
      })
      .catch((error) => {
        setAlert(true);
      });
  };

  return (
    <div className={'review-order-ctr'} data-testid='review-order'>
      <div className={'heading'}>{'Review Order'}</div>
      <div className={'sub-heading'}>{'Please review your order and confirm payment'}</div>
      <div className={'order-summary-ctr'}>
        <div className={'title'}>{'Summary'}</div>
        <div className={'summary-row'}>
          <div className={'label'}>
            {props.credits}
            {' Credits'}
          </div>
          <div className={'amount'}>
            {'Rs '}
            {props.amount + props.discount}
          </div>
        </div>
        {props.discount !== 0 ? (
          <div className={'summary-row'}>
            <div className={'label'}>{'Discount'}</div>
            <div className={'discount'}>
              {'- Rs '}
              {props.discount}
            </div>
          </div>
        ) : null}
        <div className={'total-row'}>
          <div className={'label'}>{'TOTAL'}</div>
          <div className={'amount'}>
            {'Rs '}
            {props.amount}
          </div>
        </div>
      </div>
      <Button text={'Pay Now'} onClick={pay} width={'120px'} height={'50px'} isLoading={loading} />
      {alert ? (
        <Alert
          message={'Sorry! We couldn’t initiate your payment. Please try again.'}
          onClose={() => {
            setAlert(false);
          }}
        />
      ) : null}
    </div>
  );
}
ReviewOrder.defaultProps = {
  credits: 0,
  amount: 0,
  discount: 0,
  onSuccess: null
};
