import React, { useState, useEffect } from 'react';
import Image from '../../coreComponent/Image';
import Header from '../Header';
import './DigiRedirection.scss';
import ThreeDots from '../../coreComponent/ThreeDotLoader/ThreeDotLoader';
import storage from '../../api/localstorage';
import { useDispatch } from 'react-redux';
import { saveDigiErr } from '../../actions/digiLockerFlowActions';
import {
  ACCESS_DENIED,
  ACCESS_DENIED_MESSAGE,
  COMMON_ERROR_MESSAGE,
  REDIRECTION_FAILED,
  USER_CANCELLED,
  USER_CANCELLED_MESSAGE
} from '../../utils/constants';

export default function DigiRed(props) {
  const { saveToken, verifyGovtToken, saveDigiToken, sendWebhook } = props;
  const { loading = true } = saveDigiToken;
  const dispatch = useDispatch();

  const token = new URLSearchParams(document.location.search).get('code') || null;
  const state = new URLSearchParams(document.location.search).get('state');
  const redirectionErr = new URLSearchParams(document.location.search).get('error') || null;

  const [timeLeft, setTimeLeft] = useState(10);

  const handleTimer = () => {
    storage.setDigiTokenValidation(true);
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(intervalId);
          window.close();
          return prevTime;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (!token && !state) {
      props.history.push('/dashboard');
    }
    const decodeString = window.atob(state);
    let decodedObject = {};
    try {
      decodedObject = JSON.parse(decodeString);
      if (redirectionErr) {
        dispatch(
          saveDigiErr({
            errMessage:
              redirectionErr === 'access_denied' ? ACCESS_DENIED_MESSAGE : USER_CANCELLED_MESSAGE
          })
        );
        const eventType = redirectionErr === 'access_denied' ? ACCESS_DENIED : USER_CANCELLED;
        if (decodedObject?.directFlow) {
          sendWebhook(
            { eventType, requestId: decodedObject?.requestId },
            decodedObject?.token,
            decodedObject?.directFlow
          );
        }
        handleTimer();
        return;
      }
      if (!token) {
        dispatch(saveDigiErr({ errMessage: COMMON_ERROR_MESSAGE }));
        if (decodedObject?.directFlow) {
          sendWebhook(
            {
              eventType: REDIRECTION_FAILED,
              requestId: decodedObject?.requestId
            },
            decodedObject?.token,
            decodedObject?.directFlow
          );
        }
        handleTimer();
        return;
      }
    } catch (e) {
      console.error('State Err: ', e);
    }
    const reqBody = {
      personId: decodedObject?.personId,
      code: token
    };
    const saveTokenData = async () => {
      try {
        await saveToken(reqBody, decodedObject?.token, decodedObject?.directFlow);
        handleTimer();
        if (decodedObject?.decodeToken) {
          const requestBody = {
            docType: decodedObject?.decodeToken.docType,
            success_parameters: decodedObject?.decodeToken.success_parameters,
            personId: decodedObject?.personId,
            sourceIsDigilocker: true,
            request_id: decodedObject?.requestId
          };
          verifyGovtToken(requestBody, decodedObject?.token, decodedObject?.directFlow);
        }
      } catch (e) {
        if (decodedObject?.directFlow) {
          sendWebhook(
            {
              eventType: REDIRECTION_FAILED,
              requestId: decodedObject?.requestId
            },
            decodedObject?.token,
            decodedObject?.directFlow
          );
        }
        handleTimer();
      }
    };
    saveTokenData();
  }, []);

  return (
    <div className={'my-digilocker-redirect'}>
      <Header />
      {!loading ? (
        <div className={'page-ctr'}>
          {saveDigiToken?.error ? (
            <Image name={'failureResponseNew.png'} />
          ) : (
            <Image name={'successNew.png'} />
          )}
          <div className={'content-ctr'}>
            <div className={'heading'}>{'Thank you for your patience.'}</div>
            <div className={'sub-heading'}>
              {saveDigiToken?.error ? (
                <div>
                  <div className={'sub-heading'}>
                    {saveDigiToken?.errMessage || COMMON_ERROR_MESSAGE}
                  </div>
                </div>
              ) : (
                <div> {'You have successfully connected to the DigiLocker.'}</div>
              )}
            </div>
            <div className={'text'}>
              {`This page will automatically close in ${timeLeft} seconds.`}
            </div>
          </div>
        </div>
      ) : (
        <div className={'page-ctr'}>
          <ThreeDots />
        </div>
      )}
    </div>
  );
}
