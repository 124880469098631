import { connect } from 'react-redux';
import {
  addDocument,
  aadhaarMasking,
  invalidateIdentityResponse,
  invalidateAadhaarMaskingResponse,
  invalidateAadhaarOcrResponse,
  updateUIPerson
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    aadhaarMaskingResponse: AppHelper.isLaunchUI()
      ? state.aadhaarMaskingResponseLaunch
      : state.aadhaarMaskingResponse,
    identityDocType: AppHelper.isLaunchUI() ? state.identityDocTypeLaunch : state.identityDocType,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDocument: (data) => dispatch(addDocument(data)),
    aadhaarMasking: (data) => dispatch(aadhaarMasking(data)),
    invalidateIdentityResponse: () => dispatch(invalidateIdentityResponse()),
    invalidateAadhaarMaskingResponse: () => dispatch(invalidateAadhaarMaskingResponse()),
    invalidateAadhaarOcrResponse: () => dispatch(invalidateAadhaarOcrResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
