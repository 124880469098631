import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import UploadDocument from '../../../coreComponent/UploadDocument/index';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function FaceLivenessCheck(props) {
  const [documentUrl, setDocumentUrl] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [docLoading, setDocLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [responseData, setResponseData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (props.uiActive === 'Face') {
      if (props.faceResponse && !isEmpty(props.faceResponse)) {
        loadData();
        setResponseData(JSON.stringify(props.faceResponse));
        setIsEditable(false);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    setDocLoading(true);
    let data = props.uiPerson;
    if (data && data.selfie && data.selfie.url) {
      let requestData = {
        person_id: props.personId,
        selfie_url: data.selfie.url
      };
      setDocumentUrl(data.selfie.url);
      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      setReqData(JSON.stringify(requestData));
    }
    setDocLoading(false);
  };

  const getDocumentUrl = (url) => {
    setDocumentUrl(url);
    addSelfie(url);
  };

  const addSelfie = (selfieUrl) => {
    setDocLoading(true);
    let requestData = {
      personId: props.personId,
      selfie_image: selfieUrl
    };
    let requestBody = {
      selfieurl: selfieUrl
    };

    props
      .addSelfie(requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        setDocLoading(false);
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setReqData(JSON.stringify(requestData));
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        setDocLoading(false);
      });
  };

  const faceCheckQuality = () => {
    let requestBody = {
      personId: props.personId,
      selfie_image: documentUrl
    };

    props
      .faceCheckQuality(requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(response));
        setSuccessMsg('Selfie Quality Detection successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      setIsLoading(true);
      setIsEditable(false);
      faceCheckQuality();
    }
  };

  const reset = () => {
    setDocumentUrl('');
    setIsEditable(true);
    setDocLoading(false);
    setIsLoading(false);
    setIsDisabled(true);
    setIsSubmitted(false);
    setRequestHeaders('');
    setReqData('');
    setResponseData('');
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    props.invalidateFaceResponse();
    props.invalidateIdentityResponse();
    props.invalidateAadhaarOcrResponse();
    props.invalidateDLOcrResponse();
    props.invalidateVoterOcrResponse();
    props.invalidatePANOcrResponse();
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Selfie Quality Detection'}</div>
      <div className={'sub-heading'}>
        {'Returns quality of selfie image as boolean value- Yes/No in result'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {docLoading ? (
            <div className={'upload-ctr'}>
              <div className={'loader'}>
                <Loader color={'#002C9D'} size={'38px'} />
              </div>
            </div>
          ) : (
            <UploadDocument
              image1={documentUrl}
              isFace={true}
              isEditable={isEditable}
              personId={props.personId}
              getUrl={(url, index) => getDocumentUrl(url)}
              handleCancel={() => {
                setIsDisabled(true);
                setDocumentUrl('');
                props.invalidateFaceResponse();
                props.invalidateIdentityResponse();
                props.invalidateAadhaarOcrResponse();
                props.invalidateDLOcrResponse();
                props.invalidateVoterOcrResponse();
                props.invalidatePANOcrResponse();
              }}
            />
          )}
          <DemoNotes showNotes={'face'} />
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/checkFaceQuality'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={responseData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
