import React, { useState, useEffect } from 'react';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

import AppHelper from '../../../coreComponent/AppHelper';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import Header from '../../Header';
import queryString from 'query-string';

export default function ClientDemo(props) {
  const searchUrl = queryString.parse(window.location.search);
  const [heading, setHeading] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [messageCode] = useState(searchUrl?.messageCode);
  const [status, setStatus] = useState(searchUrl?.status);
  const [data, setData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMessage();
  }, []);

  const getMessage = () => {
    switch (messageCode) {
      case 'cancel': {
        setIsLoading(false);
        setHeading('Cancelled !!');
        setMessage('You’ve cancelled the process. Please reintiate the process.');
        break;
      }
      case 'phoneMismatch': {
        setIsLoading(false);
        setHeading('Phone Mismatch !!');
        setMessage(
          'Your Aadhaar Verification is unsuccessful as you have not entered the ‘Registered Phone Number’ with your Aadhaar.'
        );
        break;
      }
      case 'idMismatch': {
        setIsLoading(false);
        setHeading('ID Mismatch !!');
        setMessage(
          'Your Aadhaar Verification is unsuccessful as your Aadhaar Id is ‘NOT Matched’ with the ID we fetch from OCR.'
        );
        break;
      }
      case 'operationFail': {
        setIsLoading(false);
        setHeading('Failure !!');
        setMessage(
          'Your Aadhaar Verification is unsuccessful as you’ve ‘NOT Completed’ the process successfully.'
        );
        break;
      }
      case 'success': {
        setIsLoading(false);
        setHeading('Congratulations !!');
        setMessage('You have successfully completed the verification process.');
        break;
      }
      default: {
        setIsLoading(false);
        setStatus('FAIL');
        setHeading('Page Expired !!');
        setMessage('This Page has been expired. Please reinitiate the process.');
        break;
      }
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    props
      .fetchUserAPI()
      .then((response) => {
        setLoading(false);
        setData(JSON.stringify(response));
      })
      .catch((error) => {
        setLoading(false);
        setData('Error');
      });
  };

  return (
    <div className={'aadhaar-verification-ctr'}>
      <Header />
      <div className={'main-ctr'}>
        <div className={'heading'}>{'Client Demo Page'}</div>
        {isLoading ? (
          <div className={'loader'}>
            <Loader size={'60px'} color={'#999999'} width={'0.4rem'} />
          </div>
        ) : (
          <div>
            <div className={status === 'SUCCESS' ? 'successMessage' : 'failureMessage'}>
              {heading}
            </div>
            <div className={'sub-heading'}>{message}</div>
          </div>
        )}
        {(AppHelper.isLocallyServed() || AppHelper.isDevServed()) && !data && (
          <div className={'large-button-ctr'}>
            <Button
              text={'Fetch Response'}
              onClick={handleSubmit}
              height={'50px'}
              isDisabled={loading}
              isLoading={loading}
            />
          </div>
        )}
      </div>
      {data && (
        <div className={'main-ctr'}>
          <div className={'heading'}>{'SpringScan Response for Aadhaar Verification'}</div>
          {data === 'Error' ? (
            <div className={'sub-heading'}>{'Failed to Fetch Response !!'}</div>
          ) : (
            <div>
              <JSONPretty space={4} data={data}></JSONPretty>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
