import React from 'react';
import CreditsBanner from './CreditsBanner/index';
import RecentTransactions from './RecentTransactions/index';
import './CreditUsageReport.scss';

export default function CreditUsageReport(props) {
  return (
    <div className={'credit-usage-report-ctr'} data-testid='credit-usage-report'>
      <div className={'heading'}>{'Credit Usage Report'}</div>
      <CreditsBanner credits={props.credits} {...props} />
      <RecentTransactions {...props} />
    </div>
  );
}
CreditUsageReport.defaultProps = {
  liveMode: true,
  credits: ''
};
