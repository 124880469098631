import { connect } from 'react-redux';
import {
  storePhoneNumber as storePhoneNumberAPI,
  cancelProcess as cancelProcessAPI,
  khoslaInit as khoslaInitAPI
} from '../../actions/aadhaarVerification';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    storePhoneNumberAPI: (data) => dispatch(storePhoneNumberAPI(data)),
    cancelProcessAPI: (data) => dispatch(cancelProcessAPI(data)),
    khoslaInitAPI: (data) => dispatch(khoslaInitAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
