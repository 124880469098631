import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

import Icon from '../../../../coreComponent/Icon';
import SelectInput from '../../../../coreComponent/SelectInput/index';
import Button from '../../../../coreComponent/Button/index';
import './ViewKey.scss';

export default function ViewKey(props) {
  const [selectedValue, setSelectedValue] = useState('');
  const [expiringTime, setExpiringTime] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.show) {
      let activeKeys = [];
      props.options.map((value, index) => {
        let option = {
          label: value.key_name,
          value: value.token_id
        };
        activeKeys.push(option);
      });
      setOptions(activeKeys);
    }
  }, [props.show, props.options]);

  useEffect(() => {
    if (props.show && props.value && !isEmpty(props.value)) {
      let option = {
        label: props.value.key_name,
        value: props.value.token_id
      };
      if (props.value.status === 'expiring_soon') {
        setExpiringTime(props.value.disabled_at);
      } else {
        setExpiringTime('');
      }
      setSelectedValue(option);
    }
  }, [props.show]);

  const selectKey = (key) => {
    setSelectedValue(key);
    let tokenKey = props.options.filter((value, index) => {
      key = index;
      return value.token_id === key.value && value.key_name === key.label;
    });
    if (tokenKey[0] && tokenKey[0].status === 'expiring_soon') {
      setExpiringTime(tokenKey[0].disabled_at);
    } else {
      setExpiringTime('');
    }
  };

  const viewKey = () => {
    let tokenKey = props.options.filter((value, index) => {
      return value.token_id === selectedValue.value && value.key_name === selectedValue.label;
    });
    props.onSubmit(tokenKey[0]);
    onHide();
  };

  const onHide = () => {
    setSelectedValue('');
    setExpiringTime('');
    setOptions([]);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'view-key-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='viewAPIKey' className={'heading'}>
            {'View API  Key'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'select-ctr'}>
          <div data-testid='selectKey' className={'label'}>
            {'Select Key Name*'}
          </div>
          <SelectInput
            defaultValue={selectedValue}
            placeholder={'Select key name'}
            isSearchable={true}
            options={options}
            onSubmit={(value) => selectKey(value)}
          />
          {expiringTime && (
            <div className={'warning-ctr'}>
              <Icon icon={'Info'} color={'#FF891C'} width={'14'} height={'14'} />
              <div className={'text'}>{`This key will expire on ${moment(expiringTime)
                .utcOffset('+05:30')
                .add(1, 'days')
                .format('DD-MM-YYYY HH:mm')}`}</div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
          />
        </div>
        <Button
          text={'Next'}
          onClick={viewKey}
          width={'120px'}
          height={'50px'}
          isDisabled={!selectedValue || isEmpty(selectedValue)}
        />
      </Modal.Footer>
    </Modal>
  );
}
ViewKey.defaultProps = {
  value: {},
  options: [],
  show: false,
  onHide: null,
  onSubmit: null
};
