import { toast } from 'react-toastify';
import { getErrorMessage } from '../utils/utilities';
import AppHelper from '../coreComponent/AppHelper';

import {
  authenticateRedirectionAPI,
  saveDigiLockerTokenAPI,
  sendWebhookAPI,
  govtVerificationWithoutOCRRedirectionAPI,
  getTokenValidityAPI
} from '../api/digiLockerFlowAPIs';
import { COMMON_ERROR_MESSAGE } from '../utils/constants';

// DigiLocker Authenticate User
export const GET_JWT_AUTH = 'GET_JWT_AUTH';
export const SAVE_JWT_AUTH = 'SAVE_JWT_AUTH';
export const SAVE_JWT_ERR = 'SAVE_JWT_ERR';

export const requestJwtAuth = (data) => {
  return {
    type: GET_JWT_AUTH,
    data
  };
};
export const saveJwtAuth = (data) => {
  return {
    type: SAVE_JWT_AUTH,
    data
  };
};
export const saveJwtErr = (data) => {
  return {
    type: SAVE_JWT_ERR,
    data
  };
};

export const getJwtAuth =
  (id, token, isDirectFlow = true) =>
  (dispatch) => {
    dispatch(requestJwtAuth);
    return authenticateRedirectionAPI(id, token, isDirectFlow)
      .then((response) => {
        dispatch(saveJwtAuth(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(saveJwtErr({ errorMsg: error?.response?.data?.api_status_description || '' }));
        console.error(error);
        toast.error(getErrorMessage(error));
        throw error;
      });
  };

// Save Digi Token

export const REQ_SAVE_TOKEN = 'REQ_SAVE_TOKEN';
export const SAVE_DIGI_TOKEN = 'SAVE_JWT_AUTH';
export const SAVE_DIGI_ERR = 'SAVE_JWT_ERR';

export const requestSaveDigiToken = (data) => {
  return {
    type: REQ_SAVE_TOKEN,
    data
  };
};
export const saveDigiToken = (data) => {
  return {
    type: SAVE_DIGI_TOKEN,
    data
  };
};
export const saveDigiErr = (data) => {
  return {
    type: SAVE_DIGI_ERR,
    data
  };
};

export const saveDigiLockerToken =
  (id, token, isDirectFlow = true) =>
  (dispatch) => {
    dispatch(requestSaveDigiToken);
    return saveDigiLockerTokenAPI(id, token, isDirectFlow)
      .then((response) => {
        dispatch(saveDigiToken(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(
          saveDigiErr({
            errMessage: error?.response?.data?.api_status_description || COMMON_ERROR_MESSAGE
          })
        );
        throw error;
      });
  };

// govt Verification Without OCR Redirection

export const REQ_GOVT_VERIFY = 'REQ_SAVE_TOKEN';
export const SAVE_GOVT_VERIFY_ERR = 'SAVE_JWT_ERR';
export const SAVE_GOVT_VERIFY = 'SAVE_GOVT_VERIFY';

export const requestGovtVerify = (data) => {
  return {
    type: REQ_GOVT_VERIFY,
    data
  };
};
export const saveGovtVerify = (data) => {
  return {
    type: SAVE_GOVT_VERIFY,
    data
  };
};
export const saveGovtVerifyErr = (data) => {
  return {
    type: SAVE_GOVT_VERIFY_ERR,
    data
  };
};

export const govtVerification =
  (body, token, isDirectFlow = true) =>
  (dispatch) => {
    dispatch(requestGovtVerify);
    return govtVerificationWithoutOCRRedirectionAPI(body, token, isDirectFlow)
      .then((response) => {
        dispatch(saveGovtVerify(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(
          saveGovtVerifyErr({ errorMsg: error?.response?.data?.api_status_description || '' })
        );
        console.error(error);
        toast.error(getErrorMessage(error));
        throw error;
      });
  };

// Send webhook action

export const SENDING_WEBHOOK = 'SENDING_WEBHOOK';
export const SAVE_SEND_WEBHOOK_STATE = 'SAVE_SEND_WEBHOOK_STATE';

export const sendingWebhook = (data) => {
  return {
    type: SENDING_WEBHOOK,
    data
  };
};

export const saveSendWebhookState = (data) => {
  return {
    type: SAVE_SEND_WEBHOOK_STATE,
    data
  };
};

export const sendWebhook =
  (body, token, isDirectFlow = true) =>
  (dispatch) => {
    dispatch(sendingWebhook);
    return sendWebhookAPI(body, token, isDirectFlow)
      .then((response) => {
        dispatch(sendingWebhook(response.data));
        return response.data;
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
        throw error;
      });
  };

// Get Token Verification

export const REQ_TOKEN_VALIDITY = 'REQ_TOKEN_VALIDITY';
export const SAVE_TOKEN_VALIDITY_ERR = 'SAVE_TOKEN_VALIDITY_ERR';
export const SAVE_TOKEN_VALIDITY = 'SAVE_TOKEN_VALIDITY';

export const requestTokenValidity = (data) => {
  return {
    type: REQ_TOKEN_VALIDITY,
    data
  };
};
export const saveTokenValidity = (data) => {
  return {
    type: SAVE_TOKEN_VALIDITY,
    data
  };
};
export const saveTokenValidityErr = (data) => {
  return {
    type: SAVE_TOKEN_VALIDITY_ERR,
    data
  };
};

export const getTokenValidity =
  (personId, token, isDirectFlow = true) =>
  (dispatch) => {
    dispatch(requestTokenValidity);
    return getTokenValidityAPI(personId, token, isDirectFlow)
      .then((response) => {
        dispatch(saveTokenValidity(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(
          saveTokenValidityErr({ errorMsg: error?.response?.data?.api_status_description || '' })
        );
        toast.error(getErrorMessage(error));
        throw error;
      });
  };
