import React, { useState, useEffect } from 'react';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isEmpty } from 'lodash';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function KRA(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [idNumber, setIdNumber] = useState('');
  const [kraName, setKraName] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [idError, setIdError] = useState('');
  const [kraNameError, setKraNameError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['id_number']);

  useEffect(() => {
    if (props.uiActive === 'KRA') {
      if (!isEmpty(props.kraResponse)) {
        let responseData = props.kraResponse;
        setResData(JSON.stringify(responseData));
        setIsEditable(false);
        setIsSubmitted(true);
      }

      loadData();
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    let requestBody = {};
    if (
      data &&
      data.documents &&
      data.documents.ind_pan_kyc &&
      data.documents.ind_pan_kyc.result &&
      data.documents.ind_pan_kyc.result.id_number &&
      !isEmpty(props.kraResponse)
    ) {
      requestBody.docType = 'ind_pan_kyc';
      requestBody.personId = props.personId;
      requestBody.id_number = data.documents.ind_pan_kyc.result.id_number;
      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      setIdNumber(data.documents.ind_pan_kyc.result.id_number);
      setReqData(requestBody);
    }
  };

  const reset = () => {
    props.invalidateKRAResponse();
    setIdNumber('');
    setIdError('');
    setKraName('');
    setKraNameError('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setResData('');
    setReqData('');
    setSuccessMsg('');
    setErrorMsg('');
    setErrorCode('');
    setSuccessParameters(['id_number']);
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!isSuccessParameterReqError() && validateIdNo(idNumber) && !kraNameError) {
        setIsLoading(true);
        setIsEditable(false);
        kraCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const kraCheck = () => {
    let requestBody = {
      docType: 'ind_pan_kyc',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        id_number: idNumber
      }
    };
    if (kraName) {
      requestBody['manual_input']['kra_name'] = kraName;
    }
    setRequestHeaders('Content type: application/json');
    setReqData(JSON.stringify(requestBody));
    props
      .kraCheck(requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('KYC check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const validateIdNo = (id) => {
    if (isSpecialCharacter(id)) {
      setIdError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else if (!id) {
      setIdError('ID Number is Required');
      setIsDisabled(true);
      return false;
    } else if (id.length !== 10) {
      setIdError('PAN ID must be of length 10');
      setIsDisabled(true);
      return false;
    } else if (!id.match('[A-Z]{5}[0-9]{4}[A-Z]{1}')) {
      setIdError('Invalid PAN ID');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      setIsDisabled(false);
      return true;
    }
  };
  const validateNameInput = (nameInput) => {
    if (isSpecialCharacter(nameInput)) {
      setKraNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else {
      setKraNameError('');
    }
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('kra_name') && !kraName) {
      error = true;
      setKraNameError('Kra Name is Required');
    }
    return !error ? false : true;
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'PAN KRA'}</div>
      <div className={'sub-heading'}>
        {'Check if the PAN number provided is registered with a KYC Registration Agency'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'idNumber'}
                  label={'ID Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'kraName'}
                  label={'KRA Name'}
                  disabled={false}
                  checked={successParameters.includes('kra_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['kra_name']));
                    setIsDisabled(false);
                    setKraNameError('');
                    if (kraName) validateNameInput(kraName);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'ID Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={10}
              value={idNumber}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIsDisabled(true);
                  setIdError('');
                }
                setIdNumber(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setIdNumber('');
                setIdError('');
                setIsDisabled(true);
              }}
              placeholder={'Ex. DLQP56391'}
              error={idError}
            />
            <Input
              label={'Enterprise Name'}
              disabled={!isEditable}
              type={'text'}
              maxLength={100}
              value={kraName}
              onBlur={(value) => {
                if (value) validateNameInput(value);
              }}
              onChange={(value) => {
                setIsDisabled(false);
                setKraNameError('');
                setKraName(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setKraName('');
                setKraNameError('');
              }}
              placeholder={'Enterprise Name'}
              error={kraNameError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={`v4/databaseCheck`} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'ind_pan_kyc'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
