import { connect } from 'react-redux';
import { disableTokenKey as disableTokenKeyAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    disableTokenKeyAPI: (data) => dispatch(disableTokenKeyAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
