import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import Icon from '../Icon';
import Loader from '../Loader/Loader';
import './UploadDocument.scss';

export default function UploadDocument(props) {
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const accept = 'image/jpeg,image/png,image/jpg';

  const onDrop1 = React.useCallback((files) => {
    if (files.length === 0) {
      setError1(true);
    } else {
      setError1(false);
      files.map((file) => {
        setLoader1(true);
        uploadOnS3(file, null);
      });
    }
  }, []);

  const onDrop2 = React.useCallback((files) => {
    if (files.length === 0) {
      setError2(true);
    } else {
      setError2(false);
      files.map((file) => {
        setLoader2(true);
        uploadOnS3(null, file);
      });
    }
  }, []);

  const uploadOnS3 = (document1, document2) => {
    let formData = new FormData();
    document1 && formData.append('document1', document1);
    document2 && formData.append('document2', document2);
    formData.append('person_id', props.personId);
    props
      .uploadOnS3(formData)
      .then((response) => {
        if (response.document1) {
          setLoader1(false);
          props.getUrl(response.document1.Location, 1);
        } else if (response.document2) {
          setLoader2(false);
          props.getUrl(response.document2.Location, 2);
        }
      })
      .catch((error) => {
        document1 && setLoader1(false);
        document2 && setLoader2(false);
        document1 && setError1(true);
        document2 && setError2(true);
      });
  };

  return (
    <div>
      {props.isDouble ? (
        <div className={'upload-double-ctr'}>
          {props.image1 ? (
            <div
              className={`upload-small-ctr prefilled-small ${
                props.isEditable ? '' : 'non-editable'
              }`}
            >
              <div className={'uploaded-image-ctr'}>
                <img
                  data-testid='image1'
                  className={'uploaded-image'}
                  alt={'document'}
                  src={props.image1}
                />
                {props.isEditable ? (
                  <div data-testid='closeIcon' className={'close-icon'}>
                    <Icon
                      icon={'ImageClose'}
                      color={'#002C9D'}
                      width={'11'}
                      height={'11'}
                      onClick={() => {
                        props.handleCancel(1);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div data-testid='frontSideText' className={'support-text'}>
                {'Frontside image'}
              </div>
            </div>
          ) : (
            <div>
              {loader1 ? (
                <div className={'upload-small-ctr'}>
                  <div className={'loader'}>
                    <Loader color={'#002C9D'} size={'24px'} />
                  </div>
                </div>
              ) : (
                <Dropzone onDrop={onDrop1} accept={accept}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className={`upload-small-ctr ${error1 ? 'filled' : ''}`}
                      {...getRootProps()}
                    >
                      <div className={'upload-icon'}>
                        <input {...getInputProps()} />
                        <Icon
                          icon={'UploadDocument'}
                          color={'#002C9D'}
                          width={'24'}
                          height={'16'}
                        />
                        <div data-testid='uploadText' className={'text'}>
                          {'Upload'}
                        </div>
                      </div>
                      <div data-testid='frontSideText2' className={'support-text'}>
                        {'Frontside image'}
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
          )}
          {props.image2 ? (
            <div
              className={`upload-small-ctr prefilled-small ${
                props.isEditable ? '' : 'non-editable'
              }`}
            >
              <div className={'uploaded-image-ctr'}>
                <img
                  data-testid='image2'
                  className={'uploaded-image'}
                  alt={'document'}
                  src={props.image2}
                />
                {props.isEditable ? (
                  <div data-testid='closeIcon2' className={'close-icon'}>
                    <Icon
                      icon={'ImageClose'}
                      color={'#002C9D'}
                      width={'11'}
                      height={'11'}
                      onClick={() => {
                        props.handleCancel(2);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div data-testid='backsideText' className={'support-text'}>
                {'Backside image'}
              </div>
            </div>
          ) : (
            <div>
              {loader2 ? (
                <div className={'upload-small-ctr'}>
                  <div className={'loader'}>
                    <Loader color={'#002C9D'} size={'24px'} />
                  </div>
                </div>
              ) : !props.isEditable ? (
                <div className={'upload-small-ctr disabled'}>
                  <div className={'upload-icon'}>
                    <Icon icon={'UploadDocument'} color={'#002C9D'} width={'24'} height={'16'} />
                    <div data-testid='uploadText1' className={'text'}>
                      {'Upload'}
                    </div>
                  </div>
                  <div data-test='backsideText2' className={'support-text'}>
                    {'Backside image'}
                  </div>
                </div>
              ) : (
                <Dropzone onDrop={onDrop2} accept={accept}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className={`upload-small-ctr ${error2 ? 'filled' : ''}`}
                      {...getRootProps()}
                    >
                      <div className={'upload-icon'}>
                        <input {...getInputProps()} />
                        <Icon
                          icon={'UploadDocument'}
                          color={'#002C9D'}
                          width={'24'}
                          height={'16'}
                        />
                        <div data-testid='uploadText2' className={'text'}>
                          {'Upload'}
                        </div>
                      </div>
                      <div data-testid='backsideText3' className={'support-text'}>
                        {'Backside image'}
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
          )}
          {error1 || error2 ? (
            <div className={'upload-error'}>
              {'Upload failed - Incorrect image format (use .png, .jpeg, .jpg only)'}
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          {props.image1 ? (
            <div className={`upload-ctr prefilled ${props.isEditable ? '' : 'non-editable'}`}>
              <img className={'uploaded-image'} alt={'document'} src={props.image1} />
              {props.isEditable ? (
                <div className={'close-icon'}>
                  <Icon
                    icon={'ImageClose'}
                    color={'#002C9D'}
                    width={'11'}
                    height={'11'}
                    onClick={() => {
                      props.handleCancel();
                    }}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              {loader1 ? (
                <div className={'upload-ctr'}>
                  <div className={'loader'}>
                    <Loader color={'#002C9D'} size={'38px'} />
                  </div>
                </div>
              ) : !props.isEditable ? (
                <div className={'upload-ctr disabled'}>
                  <div className={'upload-icon'}>
                    <Icon icon={'UploadDocument'} color={'#002C9D'} width={'24'} height={'16'} />
                    {props.isFace ? (
                      <div className={'text'}>{'Upload Face Image'}</div>
                    ) : (
                      <div className={'text'}>{'Upload'}</div>
                    )}
                  </div>
                </div>
              ) : (
                <Dropzone onDrop={onDrop1} accept={accept}>
                  {({ getRootProps, getInputProps }) => (
                    <div className={`upload-ctr ${error1 ? 'filled' : ''}`} {...getRootProps()}>
                      <div className={'upload-icon'}>
                        <input {...getInputProps()} />
                        <Icon
                          icon={'UploadDocument'}
                          color={'#002C9D'}
                          width={'24'}
                          height={'16'}
                        />
                        {props.isFace ? (
                          <div className={'text'}>{'Upload Face Image'}</div>
                        ) : (
                          <div className={'text'}>{'Upload'}</div>
                        )}
                      </div>
                      {error1 ? null : (
                        <div>
                          <div className={'support-text'}>{'Supported formats .png, .jpeg'}</div>
                          <div className={'support-text'}>
                            {'Drag and drop files or click on upload'}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Dropzone>
              )}
              {error1 ? (
                <div className={'upload-error'}>
                  {'Upload failed - Incorrect image format (use .png, .jpeg, .jpg only)'}
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
UploadDocument.defaultProps = {
  image1: '',
  image2: '',
  isFace: false,
  isEditable: true,
  isDouble: false,
  personId: '',
  getUrl: null,
  handleCancel: null
};
