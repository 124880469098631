const DEV_TOKEN = 'devToken';
const PROD_TOKEN = 'prodToken';
const MODE = 'mode';
const DEMO_TOKEN_ID = 'demoTokenId';
const DEMO_TOKEN = 'demoToken';
const DEMO_PERSON_ID = 'demoPersonId';
const LAUNCH_TOKEN_ID = 'launchTokenId';
const LAUNCH_TOKEN = 'launchToken';
const LAUNCH_PERSON_ID = 'launchPersonId';
const AADHAAR_TOKEN = 'aadhaarToken';
const AADHAAR_PERSON_ID = 'aadhaarPersonId';
const WHATSAPP_AADHAAR_TOKEN = 'whatsappAadhaarToken';
const IS_VALID_TOKEN = 'isDigiTokenValid';
const LOGIN_ATTEMPTS_COUNT = 'loginAttemptsCount';
const LAST_FAILED_LOGIN_STAMP = 'lastLoginAttemptStamp';
const DIGI_REQ_ID = 'dig_req_id';

export default {
  saveLoginAttemptsCount: (count) => localStorage.setItem(LOGIN_ATTEMPTS_COUNT, count),
  saveFailedAttemptStamp: (stamp) => localStorage.setItem(LAST_FAILED_LOGIN_STAMP, stamp),
  getLoginAttemptsCount: () => localStorage.getItem(LOGIN_ATTEMPTS_COUNT),
  getFailedAttemptStamp: () => localStorage.getItem(LAST_FAILED_LOGIN_STAMP),
  clearLoginAttemptsCount: () => localStorage.removeItem(LOGIN_ATTEMPTS_COUNT),
  clearLoginStamp: () => localStorage.removeItem(LAST_FAILED_LOGIN_STAMP),

  setDevToken: (devToken) => localStorage.setItem(DEV_TOKEN, devToken),
  getDevToken: () => localStorage.getItem(DEV_TOKEN),

  setProdToken: (prodToken) => localStorage.setItem(PROD_TOKEN, prodToken),
  getProdToken: () => localStorage.getItem(PROD_TOKEN),

  setMode: (mode) => localStorage.setItem(MODE, mode),
  getMode: () => localStorage.getItem(MODE),

  setDemoTokenId: (demoTokenId) => localStorage.setItem(DEMO_TOKEN_ID, demoTokenId),
  getDemoTokenId: () => localStorage.getItem(DEMO_TOKEN_ID),
  setDemoToken: (demoToken) => localStorage.setItem(DEMO_TOKEN, demoToken),
  getDemoToken: () => localStorage.getItem(DEMO_TOKEN),
  setDemoPersonId: (demoPersonId) => localStorage.setItem(DEMO_PERSON_ID, demoPersonId),
  getDemoPersonId: () => localStorage.getItem(DEMO_PERSON_ID),

  setLaunchTokenId: (launchTokenId) => localStorage.setItem(LAUNCH_TOKEN_ID, launchTokenId),
  getLaunchTokenId: () => localStorage.getItem(LAUNCH_TOKEN_ID),
  setLaunchToken: (launchToken) => localStorage.setItem(LAUNCH_TOKEN, launchToken),
  getLaunchToken: () => localStorage.getItem(LAUNCH_TOKEN),
  setLaunchPersonId: (launchPersonId) => localStorage.setItem(LAUNCH_PERSON_ID, launchPersonId),
  getLaunchPersonId: () => localStorage.getItem(LAUNCH_PERSON_ID),

  setAadhaarToken: (aadhaarToken) => localStorage.setItem(AADHAAR_TOKEN, aadhaarToken),
  getAadhaarToken: () => localStorage.getItem(AADHAAR_TOKEN),
  setAadhaarPersonId: (aadhaarPersonId) => localStorage.setItem(AADHAAR_PERSON_ID, aadhaarPersonId),
  getAadhaarPersonId: () => localStorage.getItem(AADHAAR_PERSON_ID),

  setWhatsappAadhaarToken: (whatsappAadhaarToken) =>
    localStorage.setItem(WHATSAPP_AADHAAR_TOKEN, whatsappAadhaarToken),
  getWhatsappAadhaarToken: () => localStorage.getItem(WHATSAPP_AADHAAR_TOKEN),

  setDigiTokenValidation: (isValid) => localStorage.setItem(IS_VALID_TOKEN, isValid),
  getDigiTokenValidation: () => localStorage.getItem(IS_VALID_TOKEN),
  removeDigiTokenValidation: () => localStorage.removeItem(IS_VALID_TOKEN),

  setDigiReqId: (id) => localStorage.setItem(DIGI_REQ_ID, id),
  getDigiReqId: () => localStorage.getItem(DIGI_REQ_ID),

  clearStorage: () => localStorage.clear()
};
