import http from './http';
import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';

let baseUrl = AppHelper.isLaunchUI() ? AppConstants.developmentAPIURL : AppConstants.testAPIURL;
console.log('baseUrl: ', baseUrl);

const getBaseUrl = (isDirectFlow) => {
  return isDirectFlow ? AppConstants.developmentAPIURL : baseUrl;
};

export const authenticateRedirectionAPI = (id, token, isDirectFlow) => {
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  let config = {
    headers: headers
  };

  return http.get(
    `${getBaseUrl(isDirectFlow)}v4/digilocker/authenticate-redirection/${id}`,
    config
  );
};

export const saveDigiLockerTokenAPI = (data, token, isDirectFlow) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      tokenKey: token
    }
  };
  return http.post(`${getBaseUrl(isDirectFlow)}v4/digilocker/saveToken`, data, config);
};

export const govtVerificationWithoutOCRRedirectionAPI = (data, token, isDirectFlow) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      tokenKey: token
    }
  };
  return http.post(`${getBaseUrl(isDirectFlow)}v4/databaseCheck`, data, config);
};

export const sendWebhookAPI = (data, token, isDirectFlow) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      tokenKey: token
    }
  };
  return http.post(`${getBaseUrl(isDirectFlow)}webhook/send`, data, config);
};

export const getTokenValidityAPI = (personId, token, isDirectFlow) => {
  console.log('token: ', token);
  console.log('personId: ', personId);
  let config = {
    headers: {
      'Content-Type': 'application/json',
      tokenKey: token
    }
  };
  return http.get(`${getBaseUrl(isDirectFlow)}v4/digilocker/getTokenValidity/${personId}`, config);
};
