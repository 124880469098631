import React, { useEffect } from 'react';

import Icon from '../../coreComponent/Icon';
import SideNavbar from '../SideNavbar';
import './EmailSent.scss';

export default function EmailSent(props) {
  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  const login = () => {
    props.logout();
    props.history.push('/login');
  };

  return (
    <div className={'email-sent-main-ctr'}>
      <SideNavbar />
      <div className={'email-sent-ctr'}>
        <div className={'content-ctr'}>
          <div className={'login-ctr'} onClick={login}>
            <Icon icon={'ArrowLeft'} color={'#002C9D'} width={'20'} height={'20'} />
            <div className={'text'}>{'Return to Login'}</div>
          </div>
          <div className={'heading'}>{'Check your email'}</div>
          <div className={'sub-heading'}>
            {"We've resent an email to "}
            <b>{props.signupEmail}</b>
            {' with link to verify yourself and activate your account'}
          </div>
        </div>
      </div>
    </div>
  );
}
