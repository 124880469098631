import React, { useState, useEffect } from 'react';

import Button from '../../coreComponent/Button/index';
import SideNavbar from '../SideNavbar';
import './SendVerificationLink.scss';

export default function SendVerificationLink(props) {
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    window && window.scrollTo(0, 0);
    if (!props.signupEmail) {
      props.history.push('/login');
    }
  }, []);

  const sendLink = () => {
    setSubmitting(true);
    let requestBody = {
      email: props.signupEmail
    };
    props
      .sendVerificationLinkAPI(requestBody)
      .then((response) => {
        props.history.push('/email-sent');
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const signup = () => {
    props.logout();
    props.history.push('/sign-up');
  };

  const privacyPolicy = () => {
    props.history.push('/privacy-policy');
  };

  const termsAndCondition = () => {
    props.history.push('/terms-and-condition');
  };

  return (
    <div className={'send-verification-link-main-ctr'}>
      <SideNavbar />
      <div className={'send-link-ctr'}>
        <div className={'content-ctr'}>
          <div className={'heading'}>{'Login'}</div>
          <div className={'sub-heading'}>{"Your email isn't verified yet."}</div>
          <div className={'sub-heading'}>
            {
              'The email associated with your SpringScan account needs to be confirmed. We can send '
            }
            <b>{props.signupEmail}</b>
            {' a quick verification link'}
          </div>
          <div className={'send-link-btn'}>
            <Button
              text={'Send verification  Link'}
              onClick={sendLink}
              width={'220px'}
              height={'50px'}
              isLoading={submitting}
              isWhiteBtn={submitting}
            />
          </div>
          <div className={'signup-ctr'}>
            <div className={'text'}> {'Don’t have an account?'}</div>
            <div className={'link'} onClick={signup}>
              {'Sign up'}
            </div>
          </div>
          <div className={'login-footer'}>
            <div className={'link'} onClick={privacyPolicy}>
              {'Privacy Policy'}
            </div>
            <div className={'text'}>{'and'}</div>
            <div className={'link'} onClick={termsAndCondition}>
              {'Terms and Condition'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
