import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function GSTCertification(props) {
  const [gstIn, setGstIn] = useState('');
  const [name, setName] = useState('');
  const [idError, setIdError] = useState('');
  const [nameError, setNameError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['gstin']);

  useEffect(() => {
    if (props.uiActive === 'GST') {
      if (!isEmpty(props.gstResponse)) {
        let responseData = props.gstResponse;
        setResData(JSON.stringify(responseData));
        setIsEditable(false);
        setIsSubmitted(true);
      }
      loadData();
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    const requestBody = {};
    if (
      data &&
      data.documents &&
      data.documents.ind_gst_certificate &&
      data.documents.ind_gst_certificate.result &&
      data.documents.ind_gst_certificate.result.gstin &&
      !isEmpty(props.gstResponse)
    ) {
      requestBody.docType = 'ind_gst_certificate';
      requestBody.personId = props.personId;
      requestBody.id_number = data.documents.ind_gst_certificate.result.gstin;
      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      setGstIn(data.documents.ind_gst_certificate.result.gstin);
      setName(data.documents.ind_gst_certificate.result.legal_name);
      setReqData(requestBody);
    }
  };

  const getGstIn = (gstin) => {
    if (gstin.length < 15) {
      setIdError('GST number must be of length 15');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(gstin)) {
      setIdError('Special Characters not allowed');
      setIsDisabled(true);
      return false;
    } else if (!gstin.match('\\d{2}[A-Z]{5}\\d{4}[A-Z]{1}[A-Z\\d]{1}[Z]{1}[A-Z\\d]{1}')) {
      setIdError('Invalid GST Number Format');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('legal_name') && !name) {
      error = true;
      setNameError('Name is Required');
    }
    return error;
  };

  const gstCheck = () => {
    let requestBody = {
      docType: 'ind_gst_certificate',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        gstin: gstIn
      }
    };
    if (name) {
      requestBody['manual_input']['legal_name'] = name;
    }
    setRequestHeaders('Content type: application/json');
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('ind_gst_certificate', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('GST Certification Document Check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!isSuccessParameterReqError() && getGstIn(gstIn) && !nameError) {
        setIsLoading(true);
        setIsEditable(false);
        gstCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const reset = () => {
    props.invalidateGSTResponse();
    setGstIn('');
    setName('');
    setIdError('');
    setIsEditable(true);
    setIsLoading(false);
    setIsDisabled(true);
    setIsSubmitted(false);
    setRequestHeaders('');
    setReqData('');
    setResData('');
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    setSuccessParameters(['gstin']);
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'GST'}</div>
      <div className={'sub-heading'}>
        {
          'Realtime check across multiple public and government databases for verification of user identity against GST Certificate details'
        }
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'gstin'}
                  label={'GSTIN'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'legalName'}
                  label={'Legal Name'}
                  disabled={false}
                  checked={successParameters.includes('legal_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['legal_name']));
                    setNameError('');
                    setIsDisabled(false);
                    if (name) validateNameInput(name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'GSTIN Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={15}
              value={gstIn}
              onBlur={(value) => {
                getGstIn(value);
              }}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                } else {
                  setIsDisabled(true);
                }
                setIdError('');
                setGstIn(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setGstIn('');
                setIdError('');
                setIsDisabled(true);
              }}
              placeholder={'Ex: 12AAAAA0000A1Z5 '}
              error={idError}
            />
            <Input
              label={'Legal Name'}
              disabled={!isEditable}
              type={'text'}
              value={name}
              onBlur={(value) => {
                if (value) {
                  validateNameInput(value);
                }
              }}
              onChange={(value) => {
                setIsDisabled(false);
                setNameError('');
                setName(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setName('');
                setNameError('');
                setIsDisabled(false);
              }}
              placeholder={''}
              error={nameError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
