import storage from '../api/localstorage';
import AppConstants from './AppConstants';

function getToken() {
  if ((isLocallyServed() || isDevServed()) && process.env.NODE_ENV === 'development') {
    return '4cbe51cf-a294-35a8-b3ae-d3cc89abf29c';
  } else {
    return 'fcbe54cf-a294-45a8-b3ae-d3cc89abf29c';
  }
}

function getDevToken() {
  return '4cbe51cf-a294-35a8-b3ae-d3cc89abf29c';
}

function getRazorpayKey() {
  if (isLocallyServed() || isDevServed() || isAcceptanceServed()) {
    return 'rzp_test_Bp4hoS5qM4akNT';
  } else {
    return 'rzp_live_Y3rihtrDtHkELl';
  }
}

function isLocallyServed() {
  return (
    window.location.hostname.includes('localhost') || window.location.hostname.includes('ngrok')
  );
}

function isDevServed() {
  return window.location.hostname.includes('devdashboard');
}

function isAcceptanceServed() {
  return window.location.hostname.includes('acceptancedashboard');
}

function isLiveMode() {
  return storage.getMode() === AppConstants.LIVE_MODE;
}

function setLiveMode(liveMode) {
  storage.setMode(liveMode ? AppConstants.LIVE_MODE : AppConstants.TEST_MODE);
}

function isLaunchUI() {
  return (
    window.location.pathname.includes('launch-ui') || storage.getMode() === AppConstants.LIVE_MODE
  );
}

function getEnv() {
  if (isLocallyServed()) return 'local';
  if (isAcceptanceServed()) return 'acceptance';
  if (process.env.NODE_ENV === 'development') return 'development';
  return 'prod';
}

const AppHelper = {
  isLocallyServed,
  isDevServed,
  isAcceptanceServed,
  getToken,
  getDevToken,
  getRazorpayKey,
  isLiveMode,
  setLiveMode,
  isLaunchUI,
  getEnv
};

export default AppHelper;
