import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import UploadDocument from '../../../coreComponent/UploadDocument/index';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import moment from 'moment';
import Checkbox from '../../../coreComponent/Checkbox';
import {
  addDocumentOnlyForOCR as addDocumentOnlyForOCRAPI,
  getDigiLockerTokenValidity
} from '../../../api/demoUI';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import storage from '../../../api/localstorage';
import { getDigiLockerUrl, getErrorResponseMessage } from '../../../utils/utilities';

export default function DrivingLicense(props) {
  const [isOCR, setIsOCR] = useState(true);
  const [drivingId, setDrivingId] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [doe, setDoe] = useState('');
  const [idError, setIdError] = useState('');
  const [nameError, setNameError] = useState('');
  const [dobError, setDobError] = useState('');
  const [doeError, setDoeError] = useState('');
  const [document1, setDocument1] = useState('');
  const [document2, setDocument2] = useState('');
  const [docError, setDocError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [docLoading, setDocLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [apiActivityIsVerification, setApiActivityIsVerification] = useState(false);
  const [successParameters, setSuccessParameters] = useState(['id_number']);
  const [requestID, setRequestID] = useState('');
  const [onlyOCRDocument1, setOnlyOCRDocument1] = useState('');
  const [onlyOCRDocument2, setOnlyOCRDocument2] = useState('');
  const [ocrReqData, setOcrReqData] = useState('');
  const [ocrResData, setOcrResData] = useState('');
  const [onlyOcrDocError, setOnlyOcrDocError] = useState('');
  const [onlyOcrErrorMsg, setOnlyOcrErrorMsg] = useState('');
  const [successMsgForOcrOnly, setSuccessMsgFroOcrOnly] = useState('');
  const [isSubmittedForOcrOnly, setIsSubmittedForOcrOnly] = useState(false);
  const [isOnlyOcrDisabled, setOnlyOcrIsDisabled] = useState(true);
  const [onlyOcrErrorCode, setOnlyOcrErrorCode] = useState('');
  const [sourceIsDigiLocker, setSourceIsDigiLocker] = useState(false);
  const [tokenInLocalSt, setTokenInLocalSt] = useState(false);

  useEffect(() => {
    if (props.uiActive === 'DL') {
      loadData();

      if (props.dlOnlyOcrResponse && !isEmpty(props.dlOnlyOcrResponse)) {
        const responseData = props.dlOnlyOcrResponse;
        setOcrResData(JSON.stringify(responseData));
        setIsSubmittedForOcrOnly(true);
      }

      if (props.dlOcrResponse && !isEmpty(props.dlOcrResponse)) {
        let responseData = props.dlOcrResponse;
        setResData(JSON.stringify(responseData));
        setIsEditable(false);
        setRequestID(responseData.configuration.api_config.request_id);
        setIsSubmitted(true);
      }
    }
    return () => {
      storage.setDigiTokenValidation(false);
    };
  }, []);

  useEffect(() => {
    if (tokenInLocalSt && sourceIsDigiLocker && storage.getDigiTokenValidation() === 'true') {
      setIsLoading(true);
      setIsEditable(false);
      if (apiActivityIsVerification && isOCR) {
        addDocument();
      } else if (apiActivityIsVerification && !isOCR) {
        govtVerificationWithoutOCR();
      }
    }
  }, [tokenInLocalSt]);

  useEffect(() => {
    const isTokenUpdatedFn = (e) => {
      const { key, newValue } = e;
      if (key === 'isDigiTokenValid') {
        setTokenInLocalSt(newValue === 'true');
      }
    };
    const setDigiTokenToFalse = () => {
      storage.setDigiTokenValidation(false);
    };

    window.addEventListener('storage', isTokenUpdatedFn);
    window.addEventListener('beforeunload', setDigiTokenToFalse);
    return () => {
      window.removeEventListener('storage', isTokenUpdatedFn);
      window.removeEventListener('beforeunload', isTokenUpdatedFn);
    };
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    if (data && data.documents && data.documents.ind_driving_license) {
      if (data.documents.ind_driving_license.document1 && !isEmpty(props.dlOnlyOcrResponse)) {
        setOnlyOCRDocument1(data.documents.ind_driving_license.document1);
        setRequestHeaders('Content type: application/json');
        setOnlyOcrIsDisabled(false);
        setOcrReqData('no data');
      }
      if (data.documents.ind_driving_license.document2 && !isEmpty(props.dlOnlyOcrResponse)) {
        setOnlyOCRDocument2(data.documents.ind_driving_license.document2);
      }
      if (data.documents.ind_driving_license.document1 && !isEmpty(props.dlOcrResponse)) {
        setDocument1(data.documents.ind_driving_license.document1);
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
      }
      if (data.documents.ind_driving_license.document2 && !isEmpty(props.dlOcrResponse)) {
        setDocument2(data.documents.ind_driving_license.document2);
      }
    }
    setDocLoading(false);
  };

  const getDocumentUrl = (url, index) => {
    setDocError('');
    if (index === 1) {
      apiActivityIsVerification ? setDocument1(url) : setOnlyOCRDocument1(url);
      setIsDisabled(false);
      setOnlyOcrIsDisabled(false);
    } else {
      apiActivityIsVerification ? setDocument2(url) : setOnlyOCRDocument2(url);
    }
    setRequestHeaders('Content type: application/json');
    setReqData('no data');
  };

  /* ---------- For VERIFICATION Activity with input type is OCR ---------*/
  const addDocument = () => {
    let requestBody = {
      docType: 'ind_driving_license',
      personId: props.personId
    };
    if (document1 || document2 || requestID) {
      if (document1 || document2) {
        requestBody['document_front'] = document1;
        requestBody['document_back'] = document2;
        requestBody['success_parameters'] = ['id_number'];
        if (!document1) {
          setIsLoading(false);
          setIsDisabled(true);
          setDocError('Front image is missing');
          return false;
        }
        addDocumentOnlyForOCRAPI(requestBody)
          .then((res) => {
            const responseData = res.data;
            const requestId = responseData.configuration.api_config.request_id;
            if (requestId) {
              requestBody['request_id'] = requestId;
              requestBody['success_parameters'] = successParameters;
              requestBody['sourceIsDigilocker'] = sourceIsDigiLocker;
              setRequestID(requestId);
              govtVerificationWithOCR(requestBody);
            }
          })
          .catch((error) => {
            if (error.status === 402) {
              props.showAlert('insufficient');
            } else {
              props.reloadCredits();
              setDocError(error.api_status_description || 'Something went wrong.');
            }
            setIsLoading(false);
            setIsEditable(true);
            setIsDisabled(true);
          });
      } else {
        requestBody['success_parameters'] = successParameters;
        requestBody['request_id'] = requestID;
        requestBody['sourceIsDigilocker'] = sourceIsDigiLocker;
        govtVerificationWithOCR(requestBody);
      }
    } else {
      setDocError('Either document image or request ID is missing');
      setIsLoading(false);
      setIsDisabled(true);
      setIsEditable(true);
    }
  };

  const getDigiLockerFlow = (requestBody) => {
    getDigiLockerTokenValidity(props.personId)
      .then((res) => {
        if (res.data.isTokenValid) {
          if (isOCR && apiActivityIsVerification) {
            addDocument();
          } else {
            govtVerificationWithoutOCR();
          }
        } else {
          setIsLoading(false);
          setIsEditable(true);
          window.open(getDigiLockerUrl(props.personId), '_blank');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* ----------  For OCR Activity  ---------- */
  const addDocumentOnlyForOCR = () => {
    let requestBody = {
      docType: 'ind_driving_license',
      success_parameters: ['id_number'] /* In ocr only id_number success parameter is req.*/,
      personId: props.personId,
      document_front: onlyOCRDocument1,
      document_back: onlyOCRDocument2
    };
    setOcrReqData(JSON.stringify(requestBody));
    props
      .addDocumentOnlyForOCR('ind_driving_license', requestBody)
      .then((res) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmittedForOcrOnly(true);
        const responseData = res;
        setOcrResData(JSON.stringify(responseData));
        setSuccessMsgFroOcrOnly('Driving License ocr is done successful');
        setOnlyOcrErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
          // setOnlyOcrDocError(error.data.api_status_description || 'Something went wrong.');
        }
        setOcrResData(JSON.stringify(error));
        setIsSubmittedForOcrOnly(true);
        setSuccessMsgFroOcrOnly('');
        setIsLoading(false);
        setOnlyOcrErrorCode(error?.status);
        const errMessage = error?.data?.api_status_message
          ? error?.data?.api_status_message
          : error?.data?.api_status_description;
        setOnlyOcrErrorMsg(errMessage || 'Something went wrong');
        setOnlyOcrIsDisabled(true);
      });
  };

  const getDOB = (date) => {
    if (!date) {
      setDobError('DOB is required');
      setIsDisabled(true);
      return false;
    } else if (!moment(date, 'YYYY-MM-DD').isValid()) {
      setDobError('Invalid date');
      setIsDisabled(true);
      return false;
    }
    if (!date.match('[0-9]{4}-[0-1][0-9]-[0-3][0-9]')) {
      setDobError('Invalid D.O.B');
      setIsDisabled(true);
      return false;
    } else {
      setDobError('');
      return true;
    }
  };

  const getDOE = (date) => {
    if (!moment(date, 'YYYY-MM-DD').isValid()) {
      setDoeError('Invalid date');
      setIsDisabled(true);
      return false;
    }
    if (!date.match('[0-9]{4}-[0-1][0-9]-[0-3][0-9]')) {
      setDoeError('Invalid D.O.E');
      setIsDisabled(true);
      return false;
    } else {
      setDoeError('');
    }
  };

  const getDrivingId = (id) => {
    if (!id) {
      setIdError('Driving ID is Required');
      setIsDisabled(true);
      return false;
    } else if (id.length < 15) {
      setIdError('Driving Id must contains 15 digits');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(id)) {
      setIdError('Special characters not allowed in Driving Id.');
      setIsDisabled(true);
      return false;
    } else if (id.match('\\s')) {
      setIdError('Invalid Driving Id.');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  const govtVerificationWithOCR = (requestBody) => {
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithOCR(requestBody, 'ind_driving_license')
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Driving License Document Check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  /* ---------- For VERIFICATION Activity with input type is MANUAL ---------*/
  const govtVerificationWithoutOCR = () => {
    let requestBody = {
      docType: 'ind_driving_license',
      success_parameters: successParameters,
      personId: props.personId,
      manual_input: {
        id_number: drivingId
      },
      sourceIsDigilocker: sourceIsDigiLocker
    };
    if (dob) {
      requestBody['manual_input']['dob'] = dob;
    }
    if (name) {
      requestBody['manual_input']['name'] = name;
    }
    if (doe) {
      requestBody['manual_input']['doe'] = doe;
    }
    setReqData(JSON.stringify(requestBody));
    props;
    props
      .govtVerificationWithoutOCR('ind_driving_license', requestBody)
      .then((response) => {
        props.reloadCredits();
        props.updateUIPerson(true);
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Driving License Document Check successful.');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('name') && !name) {
      error = true;
      setNameError('Name is Required');
    }
    if (successParameters.includes('dob') && !dob) {
      error = true;
      setDobError('Date of Birth is Required');
    }
    if (successParameters.includes('doe') && !doe) {
      error = true;
      setDoeError('Date of Expiry is Required');
    }
    return error;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!apiActivityIsVerification) {
        setOnlyOcrErrorCode('');
        setOnlyOcrErrorMsg('');
        setIsLoading(true);
        addDocumentOnlyForOCR();
      } else if (isOCR && apiActivityIsVerification) {
        setIsLoading(true);
        setIsEditable(false);
        if (sourceIsDigiLocker) {
          getDigiLockerFlow();
        } else {
          addDocument();
        }
      } else {
        if (
          !isSuccessParameterReqError() &&
          getDrivingId(drivingId) &&
          getDOB(dob) &&
          !nameError &&
          !doeError &&
          !dobError
        ) {
          setIsLoading(true);
          setIsEditable(false);
          setRequestHeaders('Content type: application/json');
          if (sourceIsDigiLocker) {
            getDigiLockerFlow();
          } else {
            govtVerificationWithoutOCR();
          }
        } else {
          setIsDisabled(true);
        }
      }
    }
  };

  const reset = () => {
    if (!apiActivityIsVerification) {
      props.invalidateDLOnlyOcrResponse();
      if (props.identityDocType === 'ind_driving_license') {
        props.invalidateIdentityResponse();
      }
      setOcrReqData('');
      setOcrResData('');
      setOnlyOCRDocument1('');
      setOnlyOCRDocument2('');
      setOnlyOcrDocError('');
      setSuccessMsgFroOcrOnly('');
      setOnlyOcrErrorCode('');
      setOnlyOcrErrorMsg('');
      setIsSubmittedForOcrOnly(false);
      setOnlyOcrIsDisabled(true);
    } else {
      if (isOCR && apiActivityIsVerification) {
        props.invalidateDLOcrResponse();
        if (props.identityDocType === 'ind_driving_license') {
          props.invalidateIdentityResponse();
        }
        setDocument1('');
        setDocument2('');
        setDocError('');
      } else {
        props.invalidateDLResponse();
        setDrivingId('');
        setDob('');
        setDoe('');
        setName('');
        setIdError('');
        setNameError('');
        setDobError('');
        setDoeError('');
      }
      setIsEditable(true);
      setRequestID('');
      setDocLoading(false);
      setIsLoading(false);
      setIsDisabled(true);
      setIsSubmitted(false);
      setRequestHeaders('');
      setReqData('');
      setResData('');
      setSourceIsDigiLocker(false);
      setSuccessMsg('');
      setErrorCode('');
      setErrorMsg('');
    }
    setSuccessParameters(['id_number']);
  };

  const setInputType = (ocr) => {
    setSuccessParameters(['id_number']);
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    if (ocr) {
      setIsOCR(true);
      if (props.dlOcrResponse && !isEmpty(props.dlOcrResponse)) {
        let responseData = props.dlOcrResponse;
        setResData(JSON.stringify(responseData));
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setReqData('');
        setIsEditable(true);
        setIsDisabled(!document1);
        setIsSubmitted(false);
      }
    } else {
      setIsOCR(false);
      if (props.dlResponse && !isEmpty(props.dlResponse)) {
        if (props.dlResponse.output.source) {
          const data = props.dlResponse;
          const id = data.output.source.id_number;
          const d_o_b = data.output.source.dob;
          const d_o_e = data.output.source.date_of_expiry;
          const idName = data.output.source.name;
          let requestData = {
            docType: 'ind_driving_license',
            success_parameters: successParameters,
            personId: props.personId,
            manual_input: {
              id_number: id
            },
            sourceIsDigilocker: sourceIsDigiLocker
          };
          if (d_o_b) {
            requestData['manual_input']['dob'] = d_o_b;
          }
          if (idName) {
            requestData['manual_input']['name'] = idName;
          }
          if (d_o_e) {
            requestData['manual_input']['doe'] = d_o_e;
          }
          const responseData = data;
          setResData(JSON.stringify(responseData));
          setRequestHeaders('Content type: application/json');
          setReqData(JSON.stringify(requestData));
          setDrivingId(id);
          setDob(d_o_b);
          setDoe(d_o_e);
          setName(idName);
        }
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setReqData('');
        setIsEditable(true);
        setIsDisabled(drivingId ? false : true);
        setIsSubmitted(false);
      }
    }
  };

  const setApiActivity = (isVerification) => {
    if (isVerification) {
      setApiActivityIsVerification(true);
    } else {
      setApiActivityIsVerification(false);
    }
  };

  const setVerificationSource = (isDigi) => {
    if (isDigi) {
      setSourceIsDigiLocker(true);
    } else {
      setSourceIsDigiLocker(false);
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Driving License'}</div>
      <div className={'sub-heading'}>
        {
          'Realtime check across multiple public and government databases for verification of user identity against DL details.'
        }
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>

          {/* Api Activity Radio Button */}
          <div className={'radio-ctr'}>
            <div className={'radio-heading'}>{'Choose activity:'}</div>
            <div className={'radio-input'}>
              <input
                className={'input'}
                type={'radio'}
                value={true}
                checked={apiActivityIsVerification}
                onChange={() => setApiActivity(true)}
              />
              <div className={'label'} onClick={() => setApiActivity(true)}>
                {'Verification'}
              </div>
              <input
                className={'input'}
                type={'radio'}
                value={false}
                checked={!apiActivityIsVerification}
                onChange={() => setApiActivity(false)}
              />
              <div className={'label'} onClick={() => setApiActivity(false)}>
                {'OCR'}
              </div>
            </div>
          </div>

          {/* Source Selection Radio Button */}
          {apiActivityIsVerification && (
            <div className={'radio-ctr'}>
              <div className={'radio-heading'}>{'Choose verification source:'}</div>
              <div className={'radio-input'}>
                <input
                  className={'input'}
                  type={'radio'}
                  value={false}
                  checked={!sourceIsDigiLocker}
                  onChange={() => setVerificationSource(false)}
                />
                <div className={'label'} onClick={() => setVerificationSource(false)}>
                  {'Parivahan'}
                </div>
                <input
                  className={'input'}
                  type={'radio'}
                  value={true}
                  checked={sourceIsDigiLocker}
                  onChange={() => setVerificationSource(true)}
                />
                <div className={'label'} onClick={() => setVerificationSource(true)}>
                  {'Digilocker'}
                </div>
              </div>
            </div>
          )}
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'idNumber'}
                  label={'ID Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              {apiActivityIsVerification && (
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'name'}
                    label={'Name'}
                    disabled={false}
                    checked={successParameters.includes('name')}
                    onClick={() => {
                      setSuccessParameters(_.xor(successParameters, ['name']));
                      setNameError('');
                      setIsDisabled(false);
                      if (name) validateNameInput(name);
                    }}
                  />
                </div>
              )}
            </div>
            {apiActivityIsVerification && (
              <div className={'check-input'}>
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'dob'}
                    label={'Date of birth'}
                    disabled={false}
                    checked={successParameters.includes('dob')}
                    onClick={() => {
                      setSuccessParameters(_.xor(successParameters, ['dob']));
                      setDobError('');
                      setIsDisabled(false);
                      if (dob) getDOB(dob);
                    }}
                  />
                </div>
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'doe'}
                    label={'Date of expiry'}
                    disabled={false}
                    checked={successParameters.includes('doe')}
                    onClick={() => {
                      setSuccessParameters(_.xor(successParameters, ['doe']));
                      setDoeError('');
                      setIsDisabled(false);
                      if (doe) getDOE(doe);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {apiActivityIsVerification && (
            <div className={'radio-ctr'}>
              <div className={'radio-heading'}>{'Choose Input type:'}</div>
              <div className={'radio-input'}>
                <input
                  className={'input'}
                  type={'radio'}
                  value={true}
                  checked={isOCR}
                  onChange={() => setInputType(true)}
                />
                <div className={'label'} onClick={() => setInputType(true)}>
                  {'OCR'}
                </div>
                <input
                  className={'input'}
                  type={'radio'}
                  value={false}
                  checked={!isOCR}
                  onChange={() => setInputType(false)}
                />
                <div className={'label'} onClick={() => setInputType(false)}>
                  {'Manual Input'}
                </div>
              </div>
            </div>
          )}
          {/*  If Chosen api activity is OCR */}
          {!apiActivityIsVerification && (
            <div>
              <div className={'image-label upper-label'}>{'ID Image :'}</div>
              {docLoading ? (
                <div className={'upload-double-ctr'}>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <UploadDocument
                    isDouble={true}
                    image1={onlyOCRDocument1}
                    image2={onlyOCRDocument2}
                    isEditable={!isSubmittedForOcrOnly}
                    personId={props.personId}
                    getUrl={(url, index) => getDocumentUrl(url, index)}
                    handleCancel={(index) => {
                      setOnlyOcrDocError('');
                      if (index === 1) {
                        setOnlyOCRDocument1('');
                        setOnlyOcrIsDisabled(true);
                        if (props.identityDocType === 'ind_driving_license') {
                          props.invalidateIdentityResponse();
                        }
                      } else {
                        setOnlyOCRDocument2('');
                      }
                    }}
                  />
                  {onlyOcrDocError ? <div className={'upload-error'}>{onlyOcrDocError}</div> : null}
                </div>
              )}

              <DemoNotes showNotes={'document'} isReports={true} />
            </div>
          )}
          {/* If chosen api activity is Verification */}
          {apiActivityIsVerification &&
            (isOCR ? (
              <div>
                <div className={'image-label upper-label'}>{'ID Image :'}</div>
                {docLoading ? (
                  <div className={'upload-double-ctr'}>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <UploadDocument
                      isDouble={true}
                      image1={document1}
                      image2={document2}
                      isEditable={isEditable}
                      personId={props.personId}
                      getUrl={(url, index) => getDocumentUrl(url, index)}
                      handleCancel={(index) => {
                        setDocError('');
                        if (index === 1) {
                          setDocument1('');
                          requestID ? setIsDisabled(false) : setIsDisabled(true);
                          if (props.identityDocType === 'ind_driving_license') {
                            props.invalidateIdentityResponse();
                          }
                        } else {
                          setDocument2('');
                        }
                      }}
                    />
                    {docError ? <div className={'upload-error'}>{docError}</div> : null}
                  </div>
                )}
                <div className={'or-label'}>{'OR'}</div>
                <div>
                  <Input
                    label={'Request ID'}
                    disabled={isSubmitted ? true : false}
                    type={'text'}
                    maxLength={100}
                    value={requestID ?? ''}
                    onChange={(value) => {
                      document1
                        ? setIsDisabled(false)
                        : value
                        ? setIsDisabled(false)
                        : setIsDisabled(true);
                      setRequestID(value);
                    }}
                    placeholder={'OCR request ID'}
                    id={'req_id'}
                    showClear={isSubmitted ? false : true}
                    onClear={() => {
                      setRequestID('');
                      document1 ? setIsDisabled(false) : setIsDisabled(true);
                    }}
                  />
                </div>
                <DemoNotes showNotes={'document'} isReports={true} />
              </div>
            ) : (
              <div>
                <div className={'input-ctr'}>
                  <Input
                    label={'Driving License ID'}
                    disabled={!isEditable}
                    type={'text'}
                    isRequiredField={true}
                    maxLength={15}
                    value={drivingId}
                    onBlur={(value) => {
                      getDrivingId(value);
                    }}
                    onChange={(value) => {
                      if (value) {
                        setIsDisabled(false);
                      } else {
                        setIsDisabled(true);
                      }
                      setIdError('');
                      setDrivingId(value.toUpperCase());
                    }}
                    showClear={true}
                    onClear={() => {
                      setDrivingId('');
                      setIdError('Driving License Id is required');
                      setIsDisabled(true);
                    }}
                    placeholder={'MH302010xxxxxxx'}
                    error={idError}
                  />
                  <Input
                    label={'Name on Driving License'}
                    disabled={!isEditable}
                    type={'text'}
                    value={name}
                    onBlur={(value) => {
                      if (value) {
                        validateNameInput(value);
                      }
                    }}
                    onChange={(value) => {
                      setNameError('');
                      setIsDisabled(false);
                      setName(value.toUpperCase().trim());
                    }}
                    placeholder={'Name as per Driving License'}
                    showClear={true}
                    onClear={() => {
                      setName('');
                      setNameError('');
                      setIsDisabled(false);
                    }}
                    error={nameError}
                  />
                  <div style={{ display: 'flex' }}>
                    <Input
                      label={'Date of birth'}
                      disabled={!isEditable}
                      type={'text'}
                      maxLength={10}
                      value={dob}
                      isRequiredField={true}
                      onBlur={(value) => {
                        if (value) {
                          getDOB(value);
                        }
                      }}
                      onChange={(value) => {
                        setDobError('');
                        setIsDisabled(false);
                        setDob(value.trim());
                      }}
                      placeholder={'YYYY-MM-DD'}
                      showClear={true}
                      onClear={() => {
                        setDob('');
                        setDobError('');
                        setIsDisabled(false);
                      }}
                      error={dobError}
                    />
                    <div style={{ width: '30px' }}></div>
                    <Input
                      label={'Date of expiry'}
                      disabled={!isEditable}
                      type={'text'}
                      maxLength={10}
                      value={doe}
                      onBlur={(value) => {
                        if (value) {
                          getDOE(value);
                        }
                      }}
                      onChange={(value) => {
                        setDoeError('');
                        setIsDisabled(false);
                        setDoe(value.trim());
                      }}
                      placeholder={'YYYY-MM-DD'}
                      showClear={true}
                      onClear={() => {
                        setDoe('');
                        setDoeError('');
                        setIsDisabled(false);
                      }}
                      error={doeError}
                    />
                  </div>
                </div>
                <DemoNotes showNotes={'detail'} />
              </div>
            ))}
          <div className={'submit-btn'}>
            {apiActivityIsVerification ? (
              isSubmitted ? (
                <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
              ) : (
                <Button
                  text={'Submit'}
                  onClick={handleSubmit}
                  width={'132px'}
                  height={'47px'}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isWhiteBtn={isDisabled || isLoading}
                />
              )
            ) : isSubmittedForOcrOnly ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isOnlyOcrDisabled}
                isLoading={isLoading}
                isWhiteBtn={isOnlyOcrDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={!apiActivityIsVerification ? 'v4/ocr/' : 'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard
              data={apiActivityIsVerification ? reqData : ocrReqData}
              headers={requestHeaders}
            />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={apiActivityIsVerification ? resData : ocrResData}
              successMsg={apiActivityIsVerification ? successMsg : successMsgForOcrOnly}
              errorCode={apiActivityIsVerification ? errorCode : onlyOcrErrorCode}
              errorMsg={apiActivityIsVerification ? errorMsg : onlyOcrErrorMsg}
              reportDoc={'ind_driving_license'}
              showReports={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
