import { connect } from 'react-redux';
import { getAdmins as getAdminsAPI } from '../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    admins: state.admins
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminsAPI: (data) => dispatch(getAdminsAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
