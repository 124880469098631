import React from 'react';

import Image from '../../coreComponent/Image';
import Icon from '../../coreComponent/Icon';
import Header from '../Header';
import './My404Page.scss';

export default function My404Page(props) {
  return (
    <div className={'my-404-ctr'}>
      <Header />
      <div className={'page-ctr'}>
        <Image name={'my404Image.svg'} />
        <div className={'content-ctr'}>
          <div className={'heading'}>{'Sorry!'}</div>
          <div className={'sub-heading'}>
            {'Looks like the page you’re looking for is missing.'}
          </div>
          <div className={'link-ctr'} onClick={() => props.history.push('/dashboard')}>
            <div className={'text'}>{'Take me to the dashboard'}</div>
            <Icon icon={'ArrowRight'} color={'#002C9D'} width={'20'} height={'13'} />
          </div>
        </div>
      </div>
    </div>
  );
}
