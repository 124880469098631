import { connect } from 'react-redux';
import {
  govtVerificationWithoutOCR,
  invalidateRCResponse,
  updateUIPerson
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    rcResponse: AppHelper.isLaunchUI() ? state.rcResponseLaunch : state.rcResponse,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    govtVerificationWithoutOCR: (docType, data) =>
      dispatch(govtVerificationWithoutOCR(docType, data)),
    invalidateRCResponse: () => dispatch(invalidateRCResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
