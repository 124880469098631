import { connect } from 'react-redux';
import { getPeople as getPeopleAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    people: state.people
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPeopleAPI: (data) => dispatch(getPeopleAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
