// Send webhook event Types Digilocker Redirection
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const REDIRECTION_FAILED = 'REDIRECTION_FAILED';
export const USER_CANCELLED = 'USER_CANCELLED';
export const ACCESS_DENIED = 'ACCESS_DENIED';

// Error Messages Digilocker Redirection
export const USER_CANCELLED_MESSAGE =
  'There was an interruption connecting to DigiLocker. Please try again.';
export const ACCESS_DENIED_MESSAGE =
  'Access has been denied. Please try again with the appropriate permissions granted.';
export const COMMON_ERROR_MESSAGE =
  'There was an issue connecting to DigiLocker. Please try again.';

// Login Error Messages
export const LOGIN_ERROR_MESSAGES = {
  INCORRECT_PASSWORD: 'Incorrect Password',
  PASSWORD_NOT_FOUND: 'Password does not exist for this user.',
  EMAIL_PHONE_NOT_FOUND: 'Email id/Phone number is not registered. Please sign up',
  GENERIC_ERR: 'Entered credentials are invalid',
  DEFAULT_ERROR: 'Something went wrong.'
};
