import React, { useState, useEffect, useRef } from 'react';

import Icon from '../../../coreComponent/Icon';
import Image from '../../../coreComponent/Image';
import Tooltip from '../../../coreComponent/Tooltip/index';
import Button from '../../../coreComponent/Button/index';
import { useHistory } from 'react-router-dom';
import './Header.scss';
import LoaderSpinner from 'react-loader-spinner';

export default function Header(props) {
  const history = useHistory();
  const [dropdown, setDropdown] = useState(false);
  const headerNode = useRef();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (headerNode && !headerNode.current.contains(e.target)) {
      setDropdown(false);
    }
  };

  const apiReference = () => {
    window.open('http://docs.springverify.com/', '_blank');
  };

  const profile = () => {
    setDropdown(false);
    props.history.push('/profile');
  };

  const signOut = async () => {
    setLoading(true);
    await props.logout('direct', history);
    setLoading(false);
  };

  return (
    <div className={'dashboard-header-ctr'} data-testid='header'>
      <div>
        <Button text={'API Reference'} onClick={apiReference} width={'150px'} height={'50px'} />
      </div>
      <div className={'info-ctr'}>
        <div className={'credit-ctr'}>
          {(props.credits && props.credits <= 10) || props.credits === 0 ? (
            <div className={'icon-ctr'}>
              <div className={'tooltip-icon'}>
                <Icon icon={'Alert'} color={'#F20000'} width={'16'} height={'16'} />
              </div>
              <Tooltip
                text={'Low credits'}
                left={'-40px'}
                top={'8px'}
                width={'90px'}
                position={'bottom'}
              />
            </div>
          ) : null}
          <div className={'credits'}>
            <b>{props.credits}</b>
            {' credits'}
          </div>
        </div>
        <div className={'user-ctr'}>
          <div className={'username'}>{props.username}</div>
          <div className={'dropdown-icon'} ref={headerNode} onClick={() => setDropdown(!dropdown)}>
            <Image name={'userIcon.svg'} />
            <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
          </div>
          {dropdown && (
            <div className={'dropdown-main-ctr'}>
              <div className={'dropdown-ctr'}>
                <div className={'option'} onClick={profile}>
                  {'Profile'}
                </div>
                <div className={'option'} onClick={signOut}>
                  {'Sign out'}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading && (
        <div className={'overlay-loading'}>
          <LoaderSpinner type='ThreeDots' color='#002c9d' height='100' width='100' />
        </div>
      )}
    </div>
  );
}
Header.defaultProps = {
  credits: '',
  username: ''
};
