import { connect } from 'react-redux';
import { login as loginAPI, setSignupEmail, logout, GoogleOrMsLoginAction } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAPI: (data) => dispatch(loginAPI(data)),
    setSignupEmail: (email) => dispatch(setSignupEmail(email)),
    GoogleOrMsLogin: (path, data) => dispatch(GoogleOrMsLoginAction(path, data)),
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
