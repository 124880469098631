import { connect } from 'react-redux';
import {
  addSelfie,
  govtVerificationWithOCR,
  govtVerificationWithoutOCR,
  invalidateIdentityResponse,
  invalidateDLOcrResponse,
  invalidateDLOnlyOcrResponse,
  invalidateDLResponse,
  updateUIPerson,
  addDocumentOnlyForOCR,
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    dlOcrResponse: AppHelper.isLaunchUI() ? state.dlOcrResponseLaunch : state.dlOcrResponse,
    dlResponse: AppHelper.isLaunchUI() ? state.dlResponseLaunch : state.dlResponse,
    dlOnlyOcrResponse: AppHelper.isLaunchUI() ? state.dlOnlyOcrResponseLaunch : state.dlOnlyOcrResponse,
    identityDocType: AppHelper.isLaunchUI() ? state.identityDocTypeLaunch : state.identityDocType,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSelfie: (data) => dispatch(addSelfie(data)),
    govtVerificationWithOCR: (requestBody,docType) => dispatch(govtVerificationWithOCR(requestBody,docType)),
    govtVerificationWithoutOCR: (docType, data) =>
      dispatch(govtVerificationWithoutOCR(docType, data)),
    invalidateIdentityResponse: () => dispatch(invalidateIdentityResponse()),
    invalidateDLOcrResponse: () => dispatch(invalidateDLOcrResponse()),
    invalidateDLOnlyOcrResponse: () => dispatch(invalidateDLOnlyOcrResponse()),
    invalidateDLResponse: () => dispatch(invalidateDLResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data)),
    addDocumentOnlyForOCR:(docType,data)=>dispatch(addDocumentOnlyForOCR(docType,data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
