import React, { useState, useEffect } from 'react';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import { isEmpty } from 'lodash';

import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import moment from 'moment';
import Checkbox from '../../../coreComponent/Checkbox';
import { getDigiLockerUrl, getErrorResponseMessage } from '../../../utils/utilities';
import { getDigiLockerTokenValidity } from '../../../api/demoUI';
import storage from '../../../api/localstorage';

export default function CovidVaccine(props) {
  const [name, setName] = useState('');
  const [beneficiaryID, setBeneficiaryID] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [responseData, setResponseData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [dateOfFirstDose, setDateOfFirstDose] = useState('');
  const [dateOfSecondDose, setDateOfSecondDose] = useState('');
  const [firstDoseError, setFirstDoseError] = useState('');
  const [secondDoseError, setSecondDoseError] = useState('');
  const [idError, setIdError] = useState('');
  const [nameError, setNameError] = useState('');
  const [tokenInLocalSt, setTokenInLocalSt] = useState(false);

  useEffect(() => {
    if (props.uiActive === 'Covid') {
      if (
        props.uiPerson.documents.ind_covid &&
        props.uiPerson.documents.ind_covid.result.hasOwnProperty('id_number') &&
        !isEmpty(props.covidCheckResponse)
      ) {
        setBeneficiaryID(props.uiPerson.documents.ind_covid.result?.id_number);
        setReqData('');
        setResponseData(JSON.stringify(props.covidCheckResponse));
        setIsEditable(false);
        setIsSubmitted(true);
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setName(props.uiPerson.documents.ind_covid.result?.name);
        setDateOfFirstDose(props.uiPerson.documents.ind_covid.result?.date_of_first_dose);
        setDateOfSecondDose(props.uiPerson.documents.ind_covid.result?.date_of_second_dose);
      }
    }
  }, []);

  useEffect(() => {
    if (tokenInLocalSt && storage.getDigiTokenValidation() === 'true') {
      setIsLoading(true);
      setIsEditable(false);
      covidCheck();
    }
  }, [tokenInLocalSt]);

  useEffect(() => {
    const isTokenUpdatedFn = (e) => {
      const { key, newValue } = e;
      if (key === 'isDigiTokenValid') {
        setTokenInLocalSt(newValue === 'true');
      }
    };

    const setDigiTokenToFalse = () => {
      storage.setDigiTokenValidation(false);
    };

    window.addEventListener('storage', isTokenUpdatedFn);
    window.addEventListener('beforeunload', setDigiTokenToFalse);
    return () => {
      window.removeEventListener('storage', isTokenUpdatedFn);
    };
  }, []);

  const getFirstDoseDate = (date) => {
    if (!moment(date, 'YYYY-MM-DD').isValid()) {
      setFirstDoseError('Invalid Date');
      setIsDisabled(true);
      return false;
    }
    if (!date.match('[0-9]{4}-[0-1][0-9]-[0-3][0-9]')) {
      setFirstDoseError('Invalid Date');
      setIsDisabled(true);
      return false;
    } else {
      setFirstDoseError('');
      return true;
    }
  };

  const validateBeneficiaryId = (id) => {
    if (!id) {
      setIdError('Beneficiary Id is required.');
      setIsDisabled(true);
      return false;
    }
    if (isSpecialCharacter(id)) {
      setIdError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else if (id.length !== 14) {
      setIdError('Beneficiary Id must be of length 14');
      setIsDisabled(true);
      return false;
    } else if (!id.match('[0-9]{14}')) {
      setIdError('Invalid Beneficiary Id');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      return true;
    }
  };

  const getSecondDoseDate = (date) => {
    if (!moment(date, 'YYYY-MM-DD').isValid()) {
      setSecondDoseError('Invalid Date');
      setIsDisabled(true);
      return false;
    }
    if (!date.match('[0-9]{4}-[0-1][0-9]-[0-3][0-9]')) {
      setSecondDoseError('Invalid Date');
      setIsDisabled(true);
      return false;
    } else {
      setSecondDoseError('');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (validateBeneficiaryId(beneficiaryID) && !firstDoseError && !secondDoseError) {
        setIsLoading(true);
        setIsEditable(false);
        getDigiLockerFlow();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const getDigiLockerFlow = () => {
    getDigiLockerTokenValidity(props.personId)
      .then((res) => {
        if (res.data.isTokenValid) {
          covidCheck();
        } else {
          setIsLoading(false);
          setIsEditable(true);
          window.open(getDigiLockerUrl(props.personId), '_blank');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const covidCheck = () => {
    let requestBody = {
      docType: 'ind_covid',
      personId: props.personId,
      success_parameters: ['id_number'],
      sourceIsDigilocker: true,
      manual_input: {
        id_number: beneficiaryID
      }
    };
    if (dateOfFirstDose) {
      requestBody['manual_input']['date_of_first_dose'] = dateOfFirstDose;
    }
    if (dateOfSecondDose) {
      requestBody['manual_input']['date_of_second_dose'] = dateOfSecondDose;
    }
    if (name) {
      requestBody['manual_input']['name'] = name;
    }

    setRequestHeaders('Content type: application/json');
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('ind_covid', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setErrorCode('');
        let responseData = response;
        setResponseData(JSON.stringify(responseData));
        setSuccessMsg('Covid Verification successful.');
        setErrorMsg('');
        setIdError('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const reset = () => {
    props.invalidateCovidResponse();
    setFirstDoseError('');
    setSecondDoseError('');
    setDateOfFirstDose('');
    setDateOfSecondDose('');
    setBeneficiaryID('');
    setName('');
    setNameError('');
    setIdError('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setResponseData('');
    setErrorCode('');
    setErrorMsg('');
    setReqData('');
    setSuccessMsg('');
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Covid Vaccine Certificate'}</div>
      <div className={'sub-heading'}>
        {'Real time extraction of covid vaccine certificate from government database'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          <div className={'radio-ctr'}>
            <div className={'radio-heading'}>{'Verification source:'}</div>
            <div className={'radio-input'}>
              <input
                className={'input'}
                type={'radio'}
                value={true}
                checked={true}
                onChange={() => {}}
              />
              <div className={'label'} onClick={() => {}}>
                {'Digilocker'}
              </div>
            </div>
          </div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'idNumber'}
                  label={'ID Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'Beneficiary ID'}
              isRequiredField={true}
              disabled={!isEditable}
              type={'text'}
              maxLength={14}
              showClear={true}
              value={beneficiaryID}
              onChange={(value) => {
                setIsDisabled(false);
                setBeneficiaryID(value.trim());
                setIdError('');
              }}
              onClear={() => {
                setBeneficiaryID('');
                setIsDisabled(true);
                setIdError('');
              }}
              placeholder={'Beneficiary ID'}
              error={idError}
            />
            <Input
              label={'Name'}
              disabled={!isEditable}
              type={'text'}
              value={name}
              onBlur={(value) => {
                if (value) {
                  validateNameInput(value);
                }
              }}
              onChange={(value) => {
                setIsDisabled(false);
                setNameError('');
                setName(value.toUpperCase().trim());
              }}
              showClear={true}
              onClear={() => {
                setName('');
                setNameError('');
                setIsDisabled(false);
              }}
              placeholder={'Name'}
              error={nameError}
            />
            <div style={{ display: 'flex' }}>
              <Input
                label={'Date of First Dose'}
                disabled={!isEditable}
                type={'text'}
                maxLength={10}
                value={dateOfFirstDose}
                onBlur={(value) => {
                  if (value) {
                    getFirstDoseDate(value);
                  }
                }}
                onChange={(value) => {
                  setFirstDoseError('');
                  setIsDisabled(false);
                  setDateOfFirstDose(value.trim());
                }}
                placeholder={'YYYY-MM-DD'}
                showClear={true}
                onClear={() => {
                  setFirstDoseError('');
                  setIsDisabled(false);
                  setDateOfFirstDose('');
                }}
                error={firstDoseError}
              />
              <div style={{ width: '30px' }}></div>
              <Input
                label={'Date of Second Dose'}
                disabled={!isEditable}
                type={'text'}
                maxLength={10}
                value={dateOfSecondDose}
                onBlur={(value) => {
                  if (value) {
                    getSecondDoseDate(value);
                  }
                }}
                onChange={(value) => {
                  setSecondDoseError('');
                  setIsDisabled(false);
                  setDateOfSecondDose(value.trim());
                }}
                placeholder={'YYYY-MM-DD'}
                showClear={true}
                onClear={() => {
                  setSecondDoseError('');
                  setIsDisabled(false);
                  setDateOfSecondDose('');
                }}
                error={secondDoseError}
              />
            </div>
          </div>
          <DemoNotes showNotes={'detail'} />
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'covidcertificate/'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={responseData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'covid'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
