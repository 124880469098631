import React, { useState } from 'react';

import Image from '../../../coreComponent/Image';
import Icon from '../../../coreComponent/Icon';
import Tooltip from '../../../coreComponent/Tooltip/index';
import Button from '../../../coreComponent/Button/index';
import SwitchButton from '../../../coreComponent/SwitchButton/index';
import AppConstants from '../../../coreComponent/AppConstants';
import storage from '../../../api/localstorage';
import './SideNavbar.scss';

export default function SideNavbar(props) {
  const [accountDropdown, setAccountDropdown] = useState(true);
  const [myTeamDropdown, setMyTeamDropdown] = useState(true);
  const [usageDropdown, setUsageDropdown] = useState(true);
  const [billingDropdown, setBillingDropdown] = useState(true);

  const tryDemoUI = () => {
    storage.setMode('test');
    window.open(AppConstants.baseURL + 'demo-ui', '_blank');
  };

  const launchUI = () => {
    storage.setMode('live');
    window.open(AppConstants.baseURL + 'launch-ui', '_blank');
  };

  const getHelp = () => {
    window.open(
      'https://mail.google.com/mail/?view=cm&fs=1&to=ajith.ravindran@springworks.in',
      '_blank'
    );
  };

  return (
    <div className={'dashboard-side-navbar-ctr'}>
      <div className={'side-header'} data-testid='side-header'>
        <div className={'logo-ctr'}>
          <Image name={'logo.svg'} />
          <div className={'text'}>{'SpringScan'}</div>
        </div>
        <div className={'company-name'}>{props.companyName}</div>
      </div>
      <div className={`${props.toogleDisabled ? 'tooltip-icon' : ''}`}>
        <div className={`toogle-mode-ctr ${props.toogleDisabled ? 'disabled' : ''}`}>
          <SwitchButton
            label={'View Live Mode'}
            checked={props.liveMode}
            onChange={props.toogleLiveMode}
            disabled={props.toogleDisabled}
          />
        </div>
      </div>
      <Tooltip
        text={'You can only use SpringScan in test mode until your account is activated'}
        left={'236px'}
        top={'-80px'}
        width={'300px'}
        position={'right'}
      />
      <div className={'tab-ctr'}>
        <div
          className={`tab ${props.active === 'GettingStarted' ? 'active-tab' : ''}`}
          onClick={() => props.changeActive('GettingStarted')}
        >
          {'Getting Started'}
        </div>
        <div
          data-testid='account-dropdown'
          className={`dropdown-tab ${accountDropdown ? '' : 'dropdown-close'}`}
        >
          <div className={'tab-title'} onClick={() => setAccountDropdown(!accountDropdown)}>
            {accountDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'Account'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'Overview' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Overview')}
          >
            {'Overview'}
          </div>
          <div
            className={`list-tab ${props.active === 'TokenKey' ? 'list-tab-active' : ''} ${
              props.disabled ? 'disabled-tab' : ''
            }`}
            onClick={() => (props.disabled ? null : props.changeActive('TokenKey'))}
          >
            {'Token Key'}
          </div>
          <div
            className={`list-tab ${props.active === 'Settings' ? 'list-tab-active' : ''} ${
              props.disabled ? 'disabled-tab' : ''
            }`}
            onClick={() => (props.disabled ? null : props.changeActive('Settings'))}
          >
            {'Settings'}
          </div>
        </div>
        <div
          data-testid='myTeamDropdown'
          className={`dropdown-tab ${myTeamDropdown ? '' : 'dropdown-close'}`}
        >
          <div className={'tab-title'} onClick={() => setMyTeamDropdown(!myTeamDropdown)}>
            {myTeamDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'My Team'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'Admin' ? 'list-tab-active' : ''} ${
              props.disabled ? 'disabled-tab' : ''
            }`}
            onClick={() => (props.disabled ? null : props.changeActive('Admin'))}
          >
            {'Admin'}
          </div>
        </div>
        <div
          data-testid='usageDropdown'
          className={`dropdown-tab ${usageDropdown ? '' : 'dropdown-close'}`}
        >
          <div className={'tab-title'} onClick={() => setUsageDropdown(!usageDropdown)}>
            {usageDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'Usage'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'Summary' ? 'list-tab-active' : ''} ${
              props.disabled ? 'disabled-tab' : ''
            }`}
            onClick={() => (props.disabled ? null : props.changeActive('Summary'))}
          >
            {'Summary'}
          </div>
          <div
            className={`list-tab ${props.active === 'APILog' ? 'list-tab-active' : ''} ${
              props.disabled ? 'disabled-tab' : ''
            }`}
            onClick={() => (props.disabled ? null : props.changeActive('APILog'))}
          >
            {'API Log'}
          </div>
        </div>
        <div
          data-testid='billingDropdown'
          className={`dropdown-tab ${billingDropdown ? '' : 'dropdown-close'}`}
        >
          <div className={'tab-title'} onClick={() => setBillingDropdown(!billingDropdown)}>
            {billingDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'Billing'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'RechargeAccount' ? 'list-tab-active' : ''} ${
              props.disabled ? 'disabled-tab' : ''
            }`}
            onClick={() => (props.disabled ? null : props.changeActive('RechargeAccount'))}
          >
            {'Recharge Account'}
          </div>
          <div
            className={`list-tab ${props.active === 'CreditUsageReport' ? 'list-tab-active' : ''} ${
              props.disabled ? 'disabled-tab' : ''
            }`}
            onClick={() => (props.disabled ? null : props.changeActive('CreditUsageReport'))}
          >
            {'Credit Usage Report'}
          </div>
        </div>
      </div>
      <div data-testid='side-footer' className={'side-footer'}>
        {props.liveMode ? (
          <div className={`demo-ctr ${props.launchUIDisabled ? 'disabled-demo-ctr' : ''}`}>
            <div className={'content'}>
              {'Run SpringScan APIs in Prod environment through Interface'}
            </div>
            <Button
              text={'Launch UI'}
              onClick={launchUI}
              width={'112px'}
              height={'40px'}
              isUnderline={true}
              isDisabled={props.launchUIDisabled}
            />
          </div>
        ) : (
          <div className={`demo-ctr ${props.demoUIDisabled ? 'disabled-demo-ctr' : ''}`}>
            <div className={'title'}>{'Try out Demo UI'}</div>
            <div className={'sub-title'}>{'Explore SprinScan APIs in test environment'}</div>
            <Button
              text={'Demo UI'}
              onClick={tryDemoUI}
              width={'112px'}
              height={'40px'}
              isUnderline={true}
              isDisabled={props.demoUIDisabled}
            />
          </div>
        )}
        <Image name={'footerLogo.svg'} />
        <div className={'footer-link'} onClick={getHelp}>
          {'Having trouble? Get help'}
        </div>
      </div>
    </div>
  );
}
SideNavbar.defaultProps = {
  active: 'Face',
  changeActive: null,
  companyName: '',
  liveMode: false,
  toogleLiveMode: null,
  disabled: false,
  toogleDisabled: false,
  demoUIDisabled: false,
  launchUIDisabled: false
};
