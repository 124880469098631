import React, { useState, useEffect } from 'react';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import Table from '../../../coreComponent/Table/index';
import DatePicker from '../../../coreComponent/DatePicker/index';
import SearchInput from '../../../coreComponent/SearchInput/index';
import MultiSelect from '../../../coreComponent/MultiSelect/index';
import './APILog.scss';

export default function APILog(props) {
  const [tableRows, setTableRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState(true); // true->asec, false->desc
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });
  const [keyNameFilter, setKeyNameFilter] = useState([]);
  const [endpoint, setEndpoint] = useState('');
  const [keyNameOptions, setKeyNameOptions] = useState([]);
  const [keyCount, setKeyCount] = useState(1);
  const [totalKeys, setTotalKeys] = useState(0);
  const [keysLoading, setKeysLoading] = useState(false);
  useEffect(() => {
    setKeyNameOptions([]);
    setKeyCount(1);
    setTotalKeys(0);
    loadTokenKeys();
    loadData();
  }, [props.liveMode]);

  useEffect(() => {
    if (props.apiLog && !isEmpty(props.apiLog)) {
      let rows = getRowData(props.apiLog.data);
      setTableRows(rows);
      setTotalCount(props.apiLog.total_count);
    }
  }, [props.apiLog]);

  const headCells = [
    {
      id: 'endPoint',
      label: 'API ENDPOINT',
      width: '400px'
    },
    {
      id: 'keyName',
      label: 'KEY NAME',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('keyName');
      }
    },
    {
      id: 'status',
      label: 'STATUS CODE'
    },
    {
      id: 'dateTime',
      label: 'DATE TIME',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('dateTime');
      }
    }
  ];

  const loadTokenKeys = () => {
    setKeysLoading(true);
    let data = {
      page: keyCount,
      range: 20
    };
    setKeyCount(keyCount + 1);
    props.getTokenKeysAPI(data).then((response) => {
      setKeysLoading(false);
      if (response && !isEmpty(response)) {
        let options = [...keyNameOptions];
        forEach(response.data, function (value, key) {
          options.push(value.key_name);
        });
        setKeyNameOptions(options);
        setTotalKeys(response.total_count);
      }
    });
  };

  const loadData = () => {
    setPage(1);
    setRowsPerPage(10);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (endpoint) {
      data.endpoint_filter = endpoint;
    }
    if (keyNameFilter && keyNameFilter.length > 0) {
      data.key_name_filter = keyNameFilter;
    }
    props.getAPILogAPI(data).then(() => {
      setLoading(false);
    });
  };

  //this is for each row the heading creation  with whatever id for headcells
  const createData = (endPoint, keyName, status, dateTime) => {
    return { endPoint, keyName, status, dateTime };
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value, key) {
      let rowData = createData(
        value.endpoint,
        value.key_name,
        value.status,
        moment(value.date_time).utcOffset('+05:30').format('DD-MM-YYYY HH:mm')
      );
      data.push(rowData);
    });
    return data;
  };

  const applySorting = (key) => {
    setLoading(true);
    setPage(1);
    let data = {
      range: rowsPerPage,
      page: 1
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (endpoint) {
      data.endpoint_filter = endpoint;
    }
    if (keyNameFilter && keyNameFilter.length > 0) {
      data.key_name_filter = keyNameFilter;
    }
    switch (key) {
      case 'keyName': {
        setSortBy('key_name');
        setSortOrder(!sortOrder);
        data.sort_by = 'key_name';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'dateTime': {
        setSortBy('createdAt');
        setSortOrder(!sortOrder);
        data.sort_by = 'createdAt';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
    }
    props.getAPILogAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setLoading(true);
    let data = {
      range: rowsPerPage,
      page: newPage
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (endpoint) {
      data.endpoint_filter = endpoint;
    }
    if (keyNameFilter && keyNameFilter.length > 0) {
      data.key_name_filter = keyNameFilter;
    }
    props.getAPILogAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
    setLoading(true);
    let data = {
      range: parseInt(value, 10),
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (endpoint) {
      data.endpoint_filter = endpoint;
    }
    if (keyNameFilter && keyNameFilter.length > 0) {
      data.key_name_filter = keyNameFilter;
    }
    props.getAPILogAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applyDateFilter = (date) => {
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (date && date.start && date.end) {
      setDateFilter(date);
      data.start_date = date.start;
      data.end_date = date.end;
    } else {
      setDateFilter({ start: '', end: '' });
    }
    if (endpoint) {
      data.endpoint_filter = endpoint;
    }
    if (keyNameFilter && keyNameFilter.length > 0) {
      data.key_name_filter = keyNameFilter;
    }
    props.getAPILogAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applySearchFilter = (value) => {
    setEndpoint(value);
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1,
      endpoint_filter: value
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (keyNameFilter && keyNameFilter.length > 0) {
      data.key_name_filter = keyNameFilter;
    }
    props.getAPILogAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applyKeyNameFilter = (value) => {
    setKeyNameFilter(value);
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (value && value.length > 0) {
      data.key_name_filter = value;
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    if (endpoint) {
      data.endpoint_filter = endpoint;
    }
    props.getAPILogAPI(data).then(() => {
      setLoading(false);
    });
  };

  return (
    <div className={'api-log-ctr'}>
      <div className={'search-ctr'}>
        <SearchInput
          value={endpoint}
          handleSearch={(value) => applySearchFilter(value)}
          handleClear={() => applySearchFilter('')}
        />
      </div>
      <div className={'heading-ctr'}>
        <div data-testid='apiLogHeading' className={'heading'}>
          {'API Log'}
        </div>
        <div className={'filter-ctr'}>
          <div className={'select-filter'}>
            <MultiSelect
              placeholder='Filter by Key name'
              value={keyNameFilter}
              options={keyNameOptions}
              onChange={(value) => {
                applyKeyNameFilter(value);
              }}
              loadMore={keyNameOptions.length < totalKeys ? true : false}
              onLoad={loadTokenKeys}
              loading={keysLoading}
            />
          </div>
          <DatePicker
            minDate={new Date(moment().subtract(1, 'month').format('MMMM DD, YYYY HH:mm:ss'))}
            emptyFilter={true}
            startDate={dateFilter.start}
            endDate={dateFilter.end}
            onClear={applyDateFilter}
            onSubmit={applyDateFilter}
          />
        </div>
      </div>
      <Table
        headCells={headCells}
        rowData={tableRows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(pageNo) => handleChangePage(pageNo)}
        handleChangeRowsPerPage={(rows) => handleChangeRowsPerPage(rows)}
        loading={loading}
      />
    </div>
  );
}
