import { connect } from 'react-redux';
import { deleteAdmin as deleteAdminAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAdminAPI: (data) => dispatch(deleteAdminAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
