import React from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../../../../coreComponent/Button/index';
import './DiscardChanges.scss';

export default function DiscardChanges(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={'discard-changes-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='headingDiscardChanges' className={'heading'}>
            {'Discard Unsaved Changes?'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'text'}>{'You have made changes to the notification setting.'}</div>
        <div className={'text'}>{'All changes will be lost.'}</div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={props.onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
          />
        </div>
        <Button
          text={'Discard'}
          onClick={() => {
            props.onSubmit();
            props.onHide();
          }}
          width={'120px'}
          height={'50px'}
        />
      </Modal.Footer>
    </Modal>
  );
}
DiscardChanges.defaultProps = {
  show: false,
  onHide: null,
  onSubmit: null
};
