import { connect } from 'react-redux';
import { kraCheck, invalidateKRAResponse, updateUIPerson } from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    kraResponse: AppHelper.isLaunchUI() ? state.kraResponseLaunch : state.kraResponse,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    kraCheck: (data) => dispatch(kraCheck(data)),
    invalidateKRAResponse: () => dispatch(invalidateKRAResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
