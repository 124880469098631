import React, { useEffect } from 'react';

import Image from '../../coreComponent/Image';
import SideNavbar from '../SideNavbar';
import './VerificationEmailSent.scss';

export default function VerificationEmailSent(props) {
  useEffect(() => {
    window && window.scrollTo(0, 0);
    if (!props.signupEmail) {
      props.history.push('/sign-up');
    }
  }, []);

  const resendEmail = () => {
    let requestBody = {
      email: props.signupEmail
    };
    props.sendVerificationLinkAPI(requestBody).then((response) => {
      props.history.push('/email-sent');
    });
  };

  const signup = () => {
    props.logout();
    props.history.push('/sign-up');
  };

  return (
    <div className={'verification-email-sent-main-ctr'}>
      <SideNavbar />
      <div className={'email-sent-ctr'}>
        <div className={'content-ctr'}>
          <div className={'heading'}>{'Check your email'}</div>
          <div className={'sub-heading'}>
            {"We've sent an email to "}
            <b>{props.signupEmail}</b>
            {' with link to verify yourself and activate your account'}
          </div>
          <div className={'title'}>{'Didn’t get an email'}</div>
          <div className={'sub-heading'}>
            {
              'If you don’t see an email from us within a few minutes, a few things could have happened:'
            }
          </div>
          <div className={'sub-title'}>
            <Image name={'dot.svg'} />
            <div className={'text'}>{'The email is in your spam folder.'}</div>
          </div>
          <div className={'sub-title'}>
            <Image name={'dot.svg'} />
            <div className={'text'}>{'The email address you entered had a mistake or typo.'}</div>
          </div>
          <div className={'sub-title'}>
            <Image name={'dot.svg'} />
            <div className={'text'}>{'You accidentally gave us another email address.'}</div>
          </div>
          <div className={'sub-title'}>
            <Image name={'dot.svg'} />
            <div className={'text'}>{'Click on'}</div>
            <div className={'link'} onClick={resendEmail}>
              {'Resend Email'}
            </div>
            <div className={'text'}>{'if the provided email id is correct'}</div>
          </div>
          <div className={'sub-title'}>
            <Image name={'dot.svg'} />
            <div className={'text'}>{'Something went wrong. Please try to'}</div>
            <div className={'link'} onClick={signup}>
              {'Sign up'}
            </div>
            <div className={'text'}>{'again'}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
