import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../../../../coreComponent/Button/index';
import './DisableKey.scss';

export default function DisableKey(props) {
  const [immediate, setImmediate] = useState(true);

  const disableKey = () => {
    props.onSubmit(immediate);
    onHide();
  };

  const onHide = () => {
    setImmediate(true);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'disable-key-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='disableKeyHeading' className={'heading'}>
            {'Disable Token Key'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'radio-ctr'}>
          <div data-testid='radioHeading' className={'radio-heading'}>
            {'Choose way of deactivation'}
          </div>
          <div data-testid='radioButtons' className={'radio-input'}>
            <input
              className={'input'}
              type={'radio'}
              value={true}
              checked={immediate}
              onChange={() => setImmediate(true)}
            />
            <div data-testid='label1' className={'label'} onClick={() => setImmediate(true)}>
              {'Immediately'}
            </div>
            <input
              className={'input'}
              type={'radio'}
              value={false}
              checked={!immediate}
              onChange={() => setImmediate(false)}
            />
            <div data-testid='label2' className={'label'} onClick={() => setImmediate(false)}>
              {'After 24 hours'}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
          />
        </div>
        <Button text={'Next'} onClick={disableKey} width={'120px'} height={'50px'} />
      </Modal.Footer>
    </Modal>
  );
}
DisableKey.defaultProps = {
  show: false,
  onHide: null,
  onSubmit: null
};
