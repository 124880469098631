import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function UPIID(props) {
  const [name, setName] = useState('');
  const [vpa, setVpa] = useState('');
  const [idError, setIdError] = useState('');
  const [nameError, setNameError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [responseData, setResponseData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['vpa_number']);

  useEffect(() => {
    if (props.uiActive === 'UPI') {
      loadData();
      if (props.upiResponse && !isEmpty(props.upiResponse)) {
        setResponseData(JSON.stringify(props.upiResponse));
        setIsEditable(false);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    let personName = '';
    let upiId = '';
    if (
      data &&
      data.documents &&
      data.documents.upi_details &&
      data.documents.upi_details.result &&
      !isEmpty(props.upiResponse)
    ) {
      personName = data.documents.upi_details.result.name;
      upiId = data.documents.upi_details.result.vpa;
      setName(personName);
      setVpa(upiId);
    }
    if (upiId) {
      const requestData = {
        docType: 'upi_details',
        personId: props.personId,
        manual_input: {
          vpa: upiId,
          name: personName ?? ''
        }
      };
      setIsDisabled(false);
      setRequestHeaders('Query Params');
      setReqData(JSON.stringify(requestData));
    }
  };

  const upiGovCheck = () => {
    const requestBody = {
      docType: 'upi_details',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        vpa_number: vpa
      }
    };

    if (name) {
      requestBody['manual_input']['name'] = name;
    }

    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('upi_details', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(response));
        setSuccessMsg('UPI ID Check successful.');
        setErrorMsg('');
        setNameError('');
        setIdError('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error) || 'Invalid Details');
        setSuccessMsg('');
      });
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('name') && !name) {
      error = true;
      setNameError('Name is Required');
    }
    return error;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!isSuccessParameterReqError() && validateVpa(vpa)) {
        setIsLoading(true);
        setIsEditable(false);
        setRequestHeaders('Query Params');
        upiGovCheck();
      }
    }
  };

  const validateVpaName = (vpaName) => {
    if (isSpecialCharacter(vpaName)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else if (!validateName(vpaName)) {
      setNameError('Name should only alphabets');
      setIsDisabled(true);
      return false;
    } else {
      setNameError('');
      return true;
    }
  };
  const validateVpa = (vpa) => {
    if (!vpa) {
      setIdError('VPA Number is required');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      return true;
    }
  };

  const reset = () => {
    props.invalidateUPIResponse();
    setName('');
    setVpa('');
    setNameError('');
    setIsEditable(true);
    setIsLoading(false);
    setIsDisabled(true);
    setIsSubmitted(false);
    setRequestHeaders('');
    setReqData('');
    setResponseData('');
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    setSuccessParameters(['vpa_number']);
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'UPI'}</div>
      <div className={'sub-heading'}>
        {'Verifies bank account details linked with unique UPI ID from bona fide sources'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'vpa'}
                  label={'VPA Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'name'}
                  label={'Name'}
                  disabled={false}
                  checked={successParameters.includes('name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['name']));
                    setIsDisabled('');
                    setNameError('');
                    if (name) validateVpaName(name);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'VPA'}
              isRequiredField={true}
              disabled={!isEditable}
              type={'text'}
              value={vpa}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIdError('VPA Number is required');
                  setIsDisabled(true);
                }
                setVpa(value);
              }}
              placeholder={'Ex: success@upi'}
              showClear={true}
              onClear={() => {
                setVpa('');
                setIsDisabled(true);
                setIdError('VPA Number is required');
              }}
              error={idError}
            />
            <Input
              label={'Name'}
              disabled={!isEditable}
              type={'text'}
              value={name}
              onBlur={(value) => {
                if (value) {
                  validateVpaName(value);
                }
              }}
              onChange={(value) => {
                setIsDisabled(false);
                setName(value.toUpperCase());
                setNameError('');
              }}
              placeholder={''}
              showClear={true}
              onClear={() => {
                setName('');
                setIsDisabled(false);
                setNameError('');
              }}
              error={nameError}
            />
          </div>
          <DemoNotes showNotes={'upi'} />
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl method={'POST'} url={`v4/dataBaseCheck`} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={responseData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
