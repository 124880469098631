import { connect } from 'react-redux';
import {
  addSelfie,
  govtVerificationWithOCR,
  govtVerificationWithoutOCR,
  invalidateAadhaarResponse,
  invalidateIdentityResponse,
  invalidateAadhaarOcrResponse,
  invalidateAadhaarMaskingResponse,
  invalidateAadhaarOnlyOcrResponse,
  updateUIPerson,
  addDocumentOnlyForOCR,
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    aadhaarOcrResponse: AppHelper.isLaunchUI()
      ? state.aadhaarOcrResponseLaunch
      : state.aadhaarOcrResponse,
    aadhaarOnlyOcrResponse: AppHelper.isLaunchUI()
      ? state.aadhaarOnlyOcrResponseLaunch
      : state.aadhaarOnlyOcrResponse,
    aadhaarResponse: AppHelper.isLaunchUI() ? state.aadhaarResponseLaunch : state.aadhaarResponse,
    identityDocType: AppHelper.isLaunchUI() ? state.identityDocTypeLaunch : state.identityDocType,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSelfie: (data) => dispatch(addSelfie(data)),
    // addDocument: (data) => dispatch(addDocument(data)),
    govtVerificationWithOCR: (requestBody,docType) => dispatch(govtVerificationWithOCR(requestBody,docType)),
    govtVerificationWithoutOCR: (docType, data) =>
      dispatch(govtVerificationWithoutOCR(docType, data)),
    invalidateAadhaarResponse: () => dispatch(invalidateAadhaarResponse()),
    invalidateIdentityResponse: () => dispatch(invalidateIdentityResponse()),
    invalidateAadhaarOcrResponse: () => dispatch(invalidateAadhaarOcrResponse()),
    invalidateAadhaarOnlyOcrResponse: () => dispatch(invalidateAadhaarOnlyOcrResponse()),
    invalidateAadhaarMaskingResponse: () => dispatch(invalidateAadhaarMaskingResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data)),
    addDocumentOnlyForOCR:(docType,data)=>dispatch(addDocumentOnlyForOCR(docType,data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
