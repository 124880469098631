import { connect } from 'react-redux';
import { govtVerificationWithoutOCR, invalidateUPIResponse, updateUIPerson } from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    upiResponse: AppHelper.isLaunchUI() ? state.upiResponseLaunch : state.upiResponse,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    govtVerificationWithoutOCR: (docType,data) => dispatch(govtVerificationWithoutOCR(docType,data)),
    invalidateUPIResponse: () => dispatch(invalidateUPIResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
