import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { isEmpty } from 'lodash';

import storage from '../../api/localstorage';
import AppHelper from '../../coreComponent/AppHelper';
import Icon from '../../coreComponent/Icon';
import SideNavbar from './SideNavbar';
import Header from './Header';
import Profile from './Profile';
import GettingStarted from './GettingStarted/index';
import Overview from './Overview/index';
import TokenKey from './TokenKey/index';
import Settings from './Settings/index';
import DiscardChangesModal from './Settings/DiscardChanges/index';
import Admin from './Admin/index';
import Summary from './Summary/index';
import APILog from './APILog/index';
import RechargeAccount from './RechargeAccount';
import CreditUsageReport from './CreditUsageReport';
import './Dashboard.scss';

export default function Dashboard(props) {
  const [active, setActive] = useState('');
  const [activeCache, setActiveCache] = useState('');
  const [liveMode, setLiveMode] = useState(true);
  const [display, setDisplay] = useState(true);
  const [devDisabled, setDevDisabled] = useState(false);
  const [prodDisabled, setProdDisabled] = useState(false);
  const [demoUIDisabled, setDemoUIDisabled] = useState(false);
  const [launchUIDisabled, setLaunchUIDisabled] = useState(false);
  const [devCredits, setDevCredits] = useState('');
  const [prodCredits, setProdCredits] = useState('');
  const [username, setUsername] = useState('');
  const [discardChanges, setDiscardChanges] = useState(false);
  const [discardChangesModal, setDiscardChangesModal] = useState(false);
  const [keys, setKeys] = useState(false);

  //for session expire within an hour if user is idle
  const handleOnIdle = (event) => {
    if (getRemainingTime() === 0) {
      props.history.push('/');
      props.logout();
    }
  };

  const handleOnActive = (event) => {
    if (getRemainingTime() > 0) {
      reset();
    }
  };

  const handleOnAction = (e) => {
    if (getRemainingTime() > 0) {
      reset();
    }
  };

  const { reset, getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 60, //1 hour
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction
  });

  useEffect(() => {
    window && window.scrollTo(0, 0);
    loadContainer();
  }, [props.location.pathname]);

  useEffect(() => {
    if (AppHelper.isLiveMode()) {
      setLiveMode(true);
    } else {
      setLiveMode(false);
    }
    loadDashboardState();
    props.getUserProfileAPI();
  }, []);

  useEffect(() => {
    if (
      !storage.getDemoToken() ||
      storage.getDemoToken() === 'undefined' ||
      storage.getDemoToken() === 'null' ||
      !storage.getDemoPersonId() ||
      storage.getDemoPersonId() === 'undefined' ||
      storage.getDemoPersonId() === 'null'
    ) {
      setDemoUIDisabled(true);
    } else {
      setDemoUIDisabled(false);
    }
    if (
      !storage.getLaunchToken() ||
      storage.getLaunchToken() === 'undefined' ||
      storage.getLaunchToken() === 'null' ||
      !storage.getLaunchPersonId() ||
      storage.getLaunchPersonId() === 'undefined' ||
      storage.getLaunchPersonId() === 'null'
    ) {
      setLaunchUIDisabled(true);
    } else {
      setLaunchUIDisabled(false);
    }
    if (props.dashboardState && !isEmpty(props.dashboardState)) {
      if (
        props.location.pathname !== '/profile' &&
        props.location.pathname !== '/getting-started' &&
        props.location.pathname !== '/account/overview' &&
        !props.dashboardState.dev_enabled
      ) {
        props.history.push('/dashboard');
      }
      setKeys(
        props.dashboardState.prod_tokens
          ? props.dashboardState.prod_tokens.length !== 0
          : props.dashboardState.dev_tokens && props.dashboardState.dev_tokens.length !== 0
      );
      setDevDisabled(!props.dashboardState.dev_enabled);
      setProdDisabled(
        props.dashboardState.prod_enabled !== null &&
          props.dashboardState.prod_enabled !== undefined
          ? !props.dashboardState.prod_enabled
          : !props.dashboardState.dev_enabled
      );
      setDevCredits(props.dashboardState.dev_credits);
      setProdCredits(
        props.dashboardState.prod_credits
          ? props.dashboardState.prod_credits
          : props.dashboardState.dev_credits
      );
    } else {
      if (
        props.location.pathname !== '/profile' &&
        props.location.pathname !== '/getting-started' &&
        props.location.pathname !== '/account/overview' &&
        !props.loginData.dev_enabled
      ) {
        props.history.push('/dashboard');
      }
      setKeys(
        props.loginData.prod_tokens
          ? props.loginData.prod_tokens.length !== 0
          : props.loginData.dev_tokens && props.loginData.dev_tokens.length !== 0
      );
      setDevDisabled(!props.loginData.dev_enabled);
      setProdDisabled(
        props.loginData.prod_enabled !== null && props.loginData.prod_enabled !== undefined
          ? !props.loginData.prod_enabled
          : !props.loginData.dev_enabled
      );
      setDevCredits(props.loginData.dev_credits);
      setProdCredits(
        props.loginData.prod_credits ? props.loginData.prod_credits : props.loginData.dev_credits
      );
    }
  }, [props.dashboardState]);

  useEffect(() => {
    setUsername(
      props.userProfile
        ? props.userProfile.name
          ? props.userProfile.name.first + ' ' + props.userProfile.name.last
          : ''
        : ''
    );
  }, [props.userProfile]);

  const loadDashboardState = () => {
    props.getDashboardStateAPI();
  };

  const changeLiveMode = () => {
    AppHelper.setLiveMode(!liveMode);
    setLiveMode(!liveMode);
    setDisplay(true);
  };

  const activateAccount = () => {
    props.history.push('/billing/recharge-account');
  };

  const loadContainer = () => {
    let url = props.location.pathname;
    switch (url) {
      case '/profile': {
        setActive('Profile');
        break;
      }
      case '/getting-started': {
        setActive('GettingStarted');
        break;
      }
      case '/account/overview': {
        setActive('Overview');
        break;
      }
      case '/account/token-key': {
        setActive('TokenKey');
        break;
      }
      case '/account/settings': {
        setActive('Settings');
        break;
      }
      case '/my-team/admin': {
        setActive('Admin');
        break;
      }
      case '/usage/summary': {
        setActive('Summary');
        break;
      }
      case '/usage/summary/people-added': {
        setActive('Summary');
        break;
      }
      case '/usage/summary/person': {
        setActive('Summary');
        break;
      }
      case '/usage/api-log': {
        setActive('APILog');
        break;
      }
      case '/billing/recharge-account': {
        setActive('RechargeAccount');
        break;
      }
      case '/billing/credit-usage-report': {
        setActive('CreditUsageReport');
        break;
      }
      default:
        break;
    }
  };

  const changeActive = (activeTab, noDiscard) => {
    setActiveCache(activeTab);
    if (active === 'Settings' && discardChanges) {
      setDiscardChangesModal(true);
    }
    if (!discardChanges || noDiscard) {
      setActive(activeTab);
      switch (activeTab) {
        case 'GettingStarted': {
          loadDashboardState();
          props.history.push('/getting-started');
          break;
        }
        case 'Overview': {
          props.history.push('/account/overview');
          break;
        }
        case 'TokenKey': {
          loadDashboardState();
          props.history.push('/account/token-key');
          break;
        }
        case 'Settings': {
          props.history.push('/account/settings');
          break;
        }
        case 'Admin': {
          props.history.push('/my-team/admin');
          break;
        }
        case 'Summary': {
          props.history.push('/usage/summary');
          break;
        }
        case 'APILog': {
          props.history.push('/usage/api-log');
          break;
        }
        case 'RechargeAccount': {
          props.history.push('/billing/recharge-account');
          break;
        }
        case 'CreditUsageReport': {
          props.history.push('/billing/credit-usage-report');
          break;
        }
        default:
          break;
      }
    }
  };

  const getContainer = () => {
    switch (active) {
      case 'Profile': {
        return <Profile {...props} />;
      }
      case 'GettingStarted': {
        return (
          <GettingStarted
            liveMode={liveMode}
            username={username}
            disabled={devDisabled}
            reloadState={loadDashboardState}
            demoUIDisabled={demoUIDisabled}
            launchUIDisabled={launchUIDisabled}
            {...props}
          />
        );
      }
      case 'Overview': {
        return (
          <Overview
            companyName={props.loginData.company_name}
            companyUrl={props.loginData.website}
            status={prodDisabled}
            {...props}
          />
        );
      }
      case 'TokenKey': {
        return (
          <TokenKey
            liveMode={liveMode}
            demoTokenId={storage.getDemoTokenId()}
            launchTokenId={storage.getLaunchTokenId()}
            {...props}
          />
        );
      }
      case 'Settings': {
        return <Settings discardChanges={(discard) => setDiscardChanges(discard)} {...props} />;
      }
      case 'Admin': {
        return <Admin {...props} />;
      }
      case 'Summary': {
        return <Summary liveMode={liveMode} {...props} />;
      }
      case 'APILog': {
        return <APILog liveMode={liveMode} {...props} />;
      }
      case 'RechargeAccount': {
        return <RechargeAccount liveMode={liveMode} credits={prodCredits} {...props} />;
      }
      case 'CreditUsageReport': {
        return <CreditUsageReport liveMode={liveMode} credits={prodCredits} {...props} />;
      }
      default:
        break;
    }
  };

  return (
    <div className={'company-dashboard-ctr'}>
      <DiscardChangesModal
        show={discardChangesModal}
        onHide={() => setDiscardChangesModal(false)}
        onSubmit={() => {
          setDiscardChanges(false);
          changeActive(activeCache, true);
        }}
      />
      <SideNavbar
        active={active}
        changeActive={(activeTab) => {
          changeActive(activeTab);
        }}
        liveMode={liveMode}
        toogleLiveMode={changeLiveMode}
        companyName={props.loginData.company_name}
        disabled={devDisabled}
        toogleDisabled={prodDisabled}
        demoUIDisabled={demoUIDisabled}
        launchUIDisabled={launchUIDisabled}
        {...props}
      />
      <div className={'dashboard-main-ctr'}>
        <Header credits={liveMode ? prodCredits : devCredits} username={username} {...props} />
        {display ? (
          keys && liveMode ? null : (
            <div className={'notification-ctr'}>
              {liveMode ? (
                <div className={'text'}>
                  {'You are in Live Mode. Generate key to start making API calls.'}
                </div>
              ) : prodDisabled ? (
                <div className={'text'}>
                  {'You are in Test mode, so only test data is shown.'}
                  <div className={'link'} onClick={activateAccount}>
                    {'Activate your account'}
                  </div>
                  {'to start making Prod API calls.'}
                </div>
              ) : (
                <div className={'text'}>
                  {
                    'Your account is activated. Switch to Live mode to make API call in Prod environment.'
                  }
                </div>
              )}
              <div
                className={'close-icon'}
                onClick={() => {
                  setDisplay(false);
                }}
              >
                <Icon icon={'CloseNotification'} color={'#002C9D'} width={'14'} height={'14'} />
              </div>
            </div>
          )
        ) : null}
        {getContainer()}
      </div>
    </div>
  );
}
