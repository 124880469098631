import React, { useState, useEffect } from 'react';

import Icon from '../../../coreComponent/Icon';
import Stats from './Stats/index';
import PeopleAdded from './PeopleAdded/index';
import Person from './Person/index';
import './Summary.scss';

export default function Summary(props) {
  const [displayStats, setDisplayStats] = useState(true);
  const [personId, setPersonId] = useState('');

  useEffect(() => {
    switch (props.location.pathname) {
      case '/usage/summary/person':
        setPersonId(props.location.state.personId);
        setDisplayStats(false);
        break;
      case '/usage/summary/people-added':
        setDisplayStats(false);
        setPersonId('');
        break;
      default:
        setDisplayStats(true);
        setPersonId('');
        break;
    }
  }, [props.location.pathname]);

  const viewAll = () => {
    props.history.push('/usage/summary/people-added');
  };

  const summary = () => {
    props.history.push('/usage/summary');
  };

  return (
    <div className={'summary-ctr'}>
      {personId ? (
        <div>
          <div className={'bredcrumbs-ctr'}>
            <div className={'option'} onClick={summary}>
              {'Summary /'}
            </div>
            <div className={'option'} onClick={viewAll}>
              {'People Added /'}
            </div>
            <div className={'active-option'}>{'Person'}</div>
          </div>
          <Person personId={personId} />
        </div>
      ) : (
        <div>
          {displayStats ? (
            <div>
              <div className={'heading'}>{'Summary'}</div>
              <Stats />
              <div className={'sub-heading-ctr'}>
                <div className={'heading'}>{'Recently Added'}</div>
                <div className={'link'} onClick={viewAll}>
                  <div className={'text'}>{'View All'}</div>
                  <Icon icon={'ArrowRight'} color={'#002C9D'} width={'20'} height={'13'} />
                </div>
              </div>
            </div>
          ) : (
            <div className={'bredcrumbs-ctr'}>
              <div className={'option'} onClick={summary}>
                {'Summary /'}
              </div>
              <div className={'active-option'}>{'People Added'}</div>
            </div>
          )}
          <PeopleAdded displayFilters={!displayStats} {...props} />
        </div>
      )}
    </div>
  );
}
