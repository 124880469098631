import { connect } from 'react-redux';
import {  govtVerificationWithoutOCR,updateUIPerson,invalidateCovidResponse } from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    covidCheckResponse: AppHelper.isLaunchUI()? state.covidCheckResponseLaunch: state.covidCheckResponse,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    govtVerificationWithoutOCR: (docType, data) =>
      dispatch(govtVerificationWithoutOCR(docType, data)),
    invalidateCovidResponse: () => dispatch(invalidateCovidResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);