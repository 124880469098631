import React from 'react';

import './Overview.scss';

export default function Overview(props) {
  return (
    <div className={'overview-ctr'} data-testid='overview'>
      <div className={'heading'}>{'Overview'}</div>
      <div className={'company-details-ctr'}>
        <div className={'data-row'}>
          <div className={'label'}>{'Company Name'}</div>
          <div className={'separator'}>{':'}</div>
          <div>{props.companyName}</div>
        </div>
        <div className={'data-row'}>
          <div className={'label'}>{'Company URL'}</div>
          <div className={'separator'}>{':'}</div>
          <div>{props.companyUrl}</div>
        </div>
        <div className={'data-row'}>
          <div className={'label'}>{'Status'}</div>
          <div className={'separator'}>{':'}</div>
          <div>{props.status ? 'Trial User' : 'Live User'}</div>
        </div>
      </div>
    </div>
  );
}
Overview.defaultProps = {
  companyName: '',
  companyUrl: '',
  status: true
};
