import { connect } from 'react-redux';
import { getCredits, getPerson, updateUIPerson, setUIActive } from '../../actions/demoUI';
import AppHelper from '../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    uiCredits: AppHelper.isLaunchUI() ? state.launchCredits : state.demoCredits,
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    updateUIPersonStatus: AppHelper.isLaunchUI()
      ? state.updateLaunchPerson
      : state.updateDemoPerson,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCredits: () => dispatch(getCredits()),
    getPerson: () => dispatch(getPerson()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data)),
    setUIActive: (data) => dispatch(setUIActive(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
