import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

import './SelectInput.scss';

export default function SelectInput(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <div data-testid='selectInput'>
      <ReactSelect
        value={value}
        className='dropdown-select'
        classNamePrefix='select'
        onChange={(val) => {
          setValue(val);
          props.onSubmit(val);
        }}
        {...props}
      />
    </div>
  );
}

SelectInput.defaultProps = {
  defaultValue: '',
  isDisabled: false,
  isSearchable: false,
  placeholder: 'Select..',
  options: [], //[{value:'',label:''}]
  onSubmit: null
};
