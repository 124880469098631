import React, { useState, useEffect } from 'react';
import BTable from 'react-bootstrap/Table';

import Icon from '../Icon';
import Image from '../Image';
import Shimmer from '../Shimmer';
import SelectInput from '../SelectInput';
import './Table.scss';

export default function Table(props) {
  const [lastPage, setLastPage] = useState(1);
  const [firstIndex, setFirstIndex] = useState(1);
  const [lastIndex, setLastIndex] = useState(10);

  useEffect(() => {
    setLastPage(Math.ceil(props.totalCount / props.rowsPerPage));
    setFirstIndex(props.rowsPerPage * (props.page - 1) + 1);
    setLastIndex(props.rowsPerPage * (props.page - 1) + props.rowsPerPage);
  }, [props.rowData]);

  const getRowsPerPage = (val) => {
    if (val <= 10) {
      return [{ label: val, value: val }];
    } else if (10 < val && val <= 20) {
      return [
        { label: 10, value: 10 },
        { label: val, value: val }
      ];
    } else if (20 < val && val <= 50) {
      return [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: val, value: val }
      ];
    } else if (50 < val && val <= 100) {
      return [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: val, value: val }
      ];
    } else {
      return [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 }
      ];
    }
  };

  return (
    <BTable className={'table-ctr'} data-testid='table'>
      <thead>
        <tr>
          {props.headCells.map((header, index) =>
            header.id === 'id' ? null : (
              <th key={header.id} style={{ textAlign: header.align }}>
                <div className={'header'}>
                  <div className={'text'}>{header.label}</div>
                  {header.sortingIcon ? (
                    <div className={'arrow-icon'} onClick={header.onClick}>
                      <Image name={'upDownArrow.svg'} />
                    </div>
                  ) : null}
                </div>
              </th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {props.loading
          ? Array.apply(null, { length: props.rowsPerPage })
              .map(Number.call, Number)
              .map((data, index) => {
                return (
                  <tr key={index}>
                    {props.headCells.map((cell, i) =>
                      cell.id === 'id' ? null : (
                        <td key={i} style={{ textAlign: cell.align }}>
                          <Shimmer width={cell.width} height={20} />
                        </td>
                      )
                    )}
                  </tr>
                );
              })
          : null}
        {props.rowData.length > 0 && !props.loading
          ? props.rowData.map((row, index) => (
              <tr
                key={index}
                className={props.clickable ? 'clickable-row' : ''}
                onClick={() => {
                  props.clickable && props.onRowClick(row['id']);
                }}
              >
                {props.headCells.map((cell, i) =>
                  cell.id === 'id' ? null : (
                    <td
                      key={i}
                      className={`${cell.onHover ? 'hover-cell' : ''} ${
                        props.clickable && cell.highlight ? 'highlight' : ''
                      }`}
                      style={{ textAlign: cell.align, width: cell.width }}
                    >
                      {row[cell.id]}
                    </td>
                  )
                )}
              </tr>
            ))
          : null}
        {props.rowData.length === 0 && !props.loading ? (
          <tr>
            <td colSpan={props.headCells.length}>
              <div className={'no-data-found-ctr'}>
                <div className={'no-data-icon'}>
                  <Image name={'noData.svg'} />
                </div>
                {props.noDataText}
              </div>
            </td>
          </tr>
        ) : null}
      </tbody>
      {props.rowData && props.rowData.length !== 0 && !props.loading ? (
        <tfoot>
          <tr>
            <td colSpan={props.headCells.length}>
              <div className={'pagination-ctr'}>
                <div className={'dropdown-ctr'}>
                  <div>{'Rows per page:'}</div>
                  <div className={'dropdown'}>
                    <SelectInput
                      defaultValue={
                        getRowsPerPage(props.totalCount).filter(
                          (options) =>
                            options.value ===
                            (props.totalCount < props.rowsPerPage
                              ? props.totalCount
                              : props.rowsPerPage)
                        )[0]
                      }
                      isSearchable={true}
                      options={getRowsPerPage(props.totalCount)}
                      onSubmit={(value) => props.handleChangeRowsPerPage(value.label)}
                    />
                  </div>
                </div>
                <div>{`${firstIndex}-${
                  lastIndex > props.totalCount ? props.totalCount : lastIndex
                } of ${props.totalCount} results`}</div>
                <div className={'arrow-ctr'}>
                  <div
                    className={`left-arrow ${props.page === 1 ? 'disabled-arrow' : ''}`}
                    onClick={() =>
                      props.page === 1 ? null : props.handleChangePage(props.page - 1)
                    }
                  >
                    <Icon
                      icon={'LeftArrow'}
                      color={props.page === 1 ? 'rgba(0, 0, 0, 0.1)' : '#000000'}
                      width={'26'}
                      height={'24'}
                    />
                  </div>
                  <div
                    className={`${props.page >= lastPage ? 'disabled-arrow' : ''}`}
                    onClick={() =>
                      props.page >= lastPage ? null : props.handleChangePage(props.page + 1)
                    }
                  >
                    <Icon
                      icon={'RightArrow'}
                      color={props.page >= lastPage ? 'rgba(0, 0, 0, 0.1)' : '#000000'}
                      width={'26'}
                      height={'24'}
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      ) : null}
    </BTable>
  );
}
Table.defaultProps = {
  noDataText: 'No Data Found',
  headCells: [],
  rowData: [],
  totalCount: 0,
  rowsPerPage: 10,
  page: 1,
  loading: false,
  handleChangePage: null,
  handleChangeRowsPerPage: null,
  clickable: false,
  onRowClick: null
};
