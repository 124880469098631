import React, { useState, useEffect } from 'react';

import Input from '../../coreComponent/Input/index';
import Button from '../../coreComponent/Button/index';
import PasswordValidation from '../../coreComponent/PasswordValidation';
import { validateEmail, validateDomain } from '../../utils/validations';
import SideNavbar from '../SideNavbar';
import './SignUp.scss';

export default function SignUp(props) {
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [validatePassword, setValidatePassword] = useState(false);
  const [isSecure, setIsSecure] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    window && window.scrollTo(0, 0);
    props.logout();
  }, []);

  const verifyEmail = (value) => {
    if (!validateEmail(value)) {
      setError('Please use a valid email id');
    } else if (website && !validateDomain(value, website)) {
      setError('Please use your company mail id');
    } else {
      setError('');
    }
  };

  const validateConfirmPassword = (value, passwordChanged) => {
    if (
      (confirmPassword && passwordChanged && value !== confirmPassword) ||
      (!passwordChanged && value !== password)
    ) {
      setPasswordError('This password does not match. Try again.');
    } else {
      setPasswordError('');
    }
  };

  const signup = () => {
    setSubmitting(true);
    let requestData = {
      email: email,
      website: website,
      password: password
    };
    props
      .signupAPI(requestData)
      .then((response) => {
        setSubmitting(false);
        props.history.push('/verification-email-sent');
      })
      .catch((err) => {
        setSubmitting(false);
        if (err.status === 400 && err.data && err.data.errors && err.data.errors.length > 0) {
          err.data.errors.map((value, index) => {
            if (value.msg === 'User already exist.') {
              setError('Company email already exists. Please try another.');
            } else if (value.msg === 'Company already exist.') {
              setWebsiteError('Company website already exists. Please try another.');
            } else {
              setError('Something went wrong.');
            }
          });
        } else {
          setError('Something went wrong.');
        }
      });
  };

  const login = () => {
    props.history.push('/login');
  };

  const privacyPolicy = () => {
    props.history.push('/privacy-policy');
  };

  const termsAndCondition = () => {
    props.history.push('/terms-and-condition');
  };

  return (
    <div className={'signup-main-ctr'}>
      <SideNavbar />
      <div className={'signup-ctr'}>
        <div className={'signup-form-ctr'}>
          <div className={'heading'}>{'Welcome to SpringScan'}</div>
          <div className={'sub-heading'}>{'Sign up to create your account'}</div>
          <Input
            label={'Company Email*'}
            disabled={submitting}
            type={'text'}
            value={email}
            onBlur={(value) => {
              verifyEmail(value);
            }}
            onChange={(value) => {
              setEmail(value);
              setError('');
            }}
            placeholder={'Enter your work email'}
            error={error}
            errorWithIcon={true}
          />
          <Input
            label={'Company Website*'}
            disabled={submitting}
            type={'text'}
            value={website}
            onBlur={(value) => {
              if (email && !validateDomain(email, value)) {
                setError('Please use your company mail id');
              } else {
                setError('');
              }
            }}
            onChange={(value) => {
              setWebsite(value);
              setWebsiteError('');
            }}
            placeholder={'Enter company website URL'}
            error={websiteError}
            errorWithIcon={true}
          />
          <Input
            label={'Password*'}
            disabled={submitting}
            type={'password'}
            id={'password'}
            value={password}
            onBlur={(value) => {
              setValidatePassword(true);
            }}
            onChange={(value) => {
              setPassword(value);
              validateConfirmPassword(value, true);
            }}
            placeholder={'Enter Password'}
          />
          <PasswordValidation
            value={password}
            invalidate={validatePassword}
            secured={isSecure && confirmPassword && !passwordError}
            isSecure={(secure) => {
              setIsSecure(secure);
            }}
          />
          <Input
            label={'Confirm Password*'}
            disabled={submitting}
            type={'password'}
            id={'cpassword'}
            value={confirmPassword}
            onChange={(value) => {
              setConfirmPassword(value);
              validateConfirmPassword(value);
            }}
            placeholder={'Confirm Password'}
            error={passwordError}
            errorWithIcon={true}
          />
          <div className={'signup-btn'}>
            <Button
              text={'Sign Up'}
              onClick={signup}
              width={'120px'}
              height={'50px'}
              isDisabled={
                !email ||
                !website ||
                !password ||
                !confirmPassword ||
                error ||
                !isSecure ||
                passwordError
              }
              isLoading={submitting}
              isWhiteBtn={
                !email ||
                !website ||
                !password ||
                !confirmPassword ||
                error ||
                !isSecure ||
                passwordError ||
                submitting
              }
            />
          </div>
          <div className={'login-ctr'}>
            <div className={'text'}> {'Already have an account? '}</div>
            <div className={'link'} onClick={login}>
              {'Log in'}
            </div>
          </div>
          <div className={'signup-footer'}>
            <div className={'text'}>
              {
                'By clicking the "Sign Up" button, you are creating a SpringScan account, and you agree to SpringScan\'s'
              }
            </div>
            <div className={'link'} onClick={privacyPolicy}>
              {'Privacy Policy'}
            </div>
            <div className={'text'}>{'and'}</div>
            <div className={'link'} onClick={termsAndCondition}>
              {'Terms and Condition'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
