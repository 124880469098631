import { connect } from 'react-redux';
import {
  sendVerificationLink as sendVerificationLinkAPI,
  logout
} from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {
    signupEmail: state.signupEmail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendVerificationLinkAPI: (data) => dispatch(sendVerificationLinkAPI(data)),
    logout: () => dispatch(logout('direct', null, false, false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
