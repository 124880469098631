import { connect } from 'react-redux';
import { signup as signupAPI, logout } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    signupAPI: (data) => dispatch(signupAPI(data)),
    logout: () => dispatch(logout('direct', null, false, false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
