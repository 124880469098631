import React, { useState, useEffect } from 'react';

import AppHelper from '../../coreComponent/AppHelper';
import Input from '../../coreComponent/Input/index';
import SelectInput from '../../coreComponent/SelectInput';
import Button from '../../coreComponent/Button/index';
import { validateName, validateNumber, validatesPhoneNumber } from '../../utils/validations';
import countryCodes from '../../utils/countryCodes.json';
import SideNavbar from '../SideNavbar';
import './AccountSetup.scss';
import queryString from 'query-string';

export default function AccountSetup(props) {
  const searchUrl = queryString.parse(props.location.search);
  const [token, setToken] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [fnameError, setFnameError] = useState('');
  const [lnameError, setLnameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [cnameError, setCnameError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    window && window.scrollTo(0, 0);
    props.logout();
    setToken(searchUrl?.token);
    setCountryCode({
      label: 'India (+91)',
      value: '+91'
    });
  }, []);

  const setupAccount = () => {
    setSubmitting(true);
    let headers = {
      'Content-Type': 'application/json',
      token: AppHelper.getToken(),
      Authorization: `Bearer ${token}`
    };
    let config = {
      headers: headers
    };
    let requestData = {
      first_name: firstName,
      last_name: lastName,
      phone_number: countryCode.value + mobileNumber,
      company_name: companyName
    };
    props
      .accountSetupAPI(requestData, config)
      .then(() => {
        props.history.push('/dashboard');
      })
      .catch((error) => {
        setSubmitting(false);
        if (
          error.status === 400 &&
          error.data &&
          error.data.errors &&
          error.data.errors.length > 0
        ) {
          error.data.errors.map((value, index) => {
            if (value.msg === 'Phone Number already exist.') {
              setPhoneError('Phone number already exists. Try another.');
            } else if (value.msg === 'Company Name already exist') {
              setCnameError('Company Name already exists. Please try another.');
            } else {
              setCnameError('Something went wrong.');
            }
          });
        } else {
          setCnameError('Something went wrong.');
        }
      });
  };

  return (
    <div className={'account-setup-main-ctr'}>
      <SideNavbar />
      <div className={'account-setup-ctr'}>
        <div className={'account-setup-form-ctr'}>
          <div className={'heading'}>{'Thank you for signing up.'}</div>
          <div className={'heading'}>{'Provide us your contact details to'}</div>
          <div className={'heading'}>{'complete the account setup process'}</div>
          <div className={'form-row'}>
            <Input
              label={'First Name*'}
              disabled={submitting}
              type={'text'}
              value={firstName}
              onChange={(value) => {
                setFirstName(value);
                if (validateName(value)) {
                  setFnameError('');
                } else {
                  setFnameError('Please enter a valid name');
                }
              }}
              placeholder={'Enter First Name'}
              error={fnameError}
              errorWithIcon={true}
            />
            <Input
              label={'Last Name*'}
              disabled={submitting}
              type={'text'}
              value={lastName}
              onChange={(value) => {
                setLastName(value);
                if (validateName(value)) {
                  setLnameError('');
                } else {
                  setLnameError('Please enter a valid name');
                }
              }}
              placeholder={'Enter Last Name'}
              error={lnameError}
              errorWithIcon={true}
            />
          </div>
          <div className={'form-row'}>
            <div className={'select-ctr'}>
              <div className={'label'}>{'Country Code*'}</div>
              <SelectInput
                defaultValue={countryCode}
                isDisabled={submitting}
                isSearchable={true}
                options={countryCodes}
                onSubmit={(value) => {
                  setCountryCode(value);
                  let phoneNumber = value.value + mobileNumber;
                  if (
                    (mobileNumber && phoneNumber && validatesPhoneNumber(phoneNumber)) ||
                    !mobileNumber
                  ) {
                    setPhoneError('');
                  } else {
                    setPhoneError('Please enter a valid mobile number');
                  }
                }}
              />
            </div>
            <Input
              label={'Mobile Number*'}
              disabled={submitting}
              type={'text'}
              value={mobileNumber}
              onBlur={(value) => {
                setMobileNumber(value);
                let phoneNumber = countryCode.value + value;
                if (phoneNumber && validatesPhoneNumber(phoneNumber)) {
                  setPhoneError('');
                } else {
                  setPhoneError('Please enter a valid mobile number');
                }
              }}
              onChange={(value) => {
                setMobileNumber(value);
                if (validateNumber(value)) {
                  setPhoneError('');
                } else {
                  setPhoneError('Please enter a valid mobile number');
                }
              }}
              placeholder={'Enter Mobile Number'}
              error={phoneError}
              errorWithIcon={true}
            />
          </div>
          <Input
            label={'Company  Name*'}
            disabled={submitting}
            type={'text'}
            value={companyName}
            onChange={(value) => {
              setCompanyName(value);
              if (validateName(value)) {
                setCnameError('');
              } else {
                setCnameError('Please enter a valid Company Name');
              }
            }}
            placeholder={'Enter Company Name'}
            error={cnameError}
            errorWithIcon={true}
          />
          <Button
            text={'Submit'}
            onClick={setupAccount}
            width={'120px'}
            height={'50px'}
            isDisabled={
              !firstName ||
              !lastName ||
              !countryCode ||
              !mobileNumber ||
              !companyName ||
              fnameError ||
              lnameError ||
              phoneError ||
              cnameError
            }
            isLoading={submitting}
            isWhiteBtn={
              !firstName ||
              !lastName ||
              !countryCode ||
              !mobileNumber ||
              !companyName ||
              fnameError ||
              lnameError ||
              phoneError ||
              cnameError ||
              submitting
            }
          />
        </div>
      </div>
    </div>
  );
}
