import React, { useState, useEffect } from 'react';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isEmpty } from 'lodash';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function RCCheck(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [rcNumber, setRcNumber] = useState('');
  const [name, setName] = useState('');
  const [chassisNumber, setChassisNumber] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [chassisIdError, setChassisIdError] = useState('');
  const [nameError, setNameError] = useState('');
  const [idError, setIdError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['rc_number']);

  useEffect(() => {
    if (props.uiActive === 'RC') {
      loadData();
      if (props.rcResponse && !isEmpty(props.rcResponse)) {
        setResData(JSON.stringify(props.rcResponse));
        setIsEditable(false);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    let requestBody = {
      doc_type: 'ind_rc',
      personId: props.personId
    };

    if (
      data &&
      data.documents &&
      data.documents.ind_rc &&
      data.documents.ind_rc.result &&
      !isEmpty(props.rcResponse)
    ) {
      if (data.documents.ind_rc.result.rc_number) {
        requestBody.rc_number = data.documents.ind_rc.result.rc_number;
        setRcNumber(data.documents.ind_rc.result.rc_number);
      }
      if (data.documents.ind_rc.result.chassis_number) {
        requestBody.chassis_number = data.documents.ind_rc.result.chassis_number;
        setChassisNumber(data.documents.ind_rc.result.chassis_number);
      }
      if (data.documents.ind_rc.result.name_of_owner) {
        requestBody.name_of_owner = data.documents.ind_rc.result.name_of_owner;
        setName(data.documents.ind_rc.result.name_of_owner);
      }
      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      setReqData(requestBody);
    }
  };

  const reset = () => {
    props.invalidateRCResponse();
    setRcNumber('');
    setName('');
    setChassisNumber('');
    setIdError('');
    setChassisIdError('');
    setNameError('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setSuccessMsg('');
    setResData('');
    setReqData('');
    setErrorCode('');
    setErrorMsg('');
    setSuccessParameters(['rc_number']);
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('owner_name') && !name) {
      error = true;
      setNameError('Owner Name is Required');
    }
    if (successParameters.includes('chassis_number') && !chassisNumber) {
      error = true;
      setChassisIdError('Chassis Number is Required');
    }
    return error;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (
        !isSuccessParameterReqError() &&
        validateRcNo(rcNumber) &&
        !nameError &&
        !chassisIdError
      ) {
        setIsLoading(true);
        setIsEditable(false);
        rcCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const rcCheck = () => {
    let requestBody = {
      docType: 'ind_rc',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        rc_number: rcNumber.trim()
      }
    };
    if (chassisNumber) {
      requestBody['manual_input']['chassis_number'] = chassisNumber;
    }
    if (name) {
      requestBody['manual_input']['owner_name'] = name;
    }
    setReqData(requestBody);
    props
      .govtVerificationWithoutOCR('ind_rc', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('RC check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setSuccessMsg('');
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const validateRcNo = (rcNo) => {
    if (isSpecialCharacter(rcNo)) {
      setIdError('Special characters not allowed in registration number');
      setIsDisabled(true);
      return false;
    } else if (!rcNo.match('[0-9a-zA-Z/]')) {
      setIdError('Invalid registration number');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      setRcNumber(rcNo);
      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      return true;
    }
  };
  const validateChassisNo = (chassisNumber) => {
    if (chassisNumber.length < 17) {
      setChassisIdError('Chassis number must contain 17 characters');
      setIsDisabled(true);
      return false;
    } else if (!chassisNumber.match('[0-9a-zA-Z/]{17}')) {
      setChassisIdError('Invalid chassis number');
      setIsDisabled(true);
      return false;
    } else {
      setChassisIdError('');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Vehicle RC'}</div>
      <div className={'sub-heading'}>
        {'Verify vehicle registration information against government database.'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'rcNumber'}
                  label={'RC Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'ownerName'}
                  label={'Owner Name'}
                  disabled={false}
                  checked={successParameters.includes('owner_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['owner_name']));
                    setIsDisabled(false);
                    setNameError('');
                  }}
                />
              </div>
            </div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'chassisNumber'}
                  label={'Chassis Number'}
                  disabled={false}
                  checked={successParameters.includes('chassis_number')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['chassis_number']));
                    setIsDisabled(false);
                    setChassisIdError('');
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'RC Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              value={rcNumber}
              onBlur={(value) => {
                validateRcNo(value);
              }}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIsDisabled(true);
                  setIdError('RC numbers must contains 10 characters');
                }
                setRcNumber(value.trim().toUpperCase());
              }}
              placeholder={'Ex: KA07XY0070'}
              showClear={true}
              onClear={() => {
                setRcNumber('');
                setIdError('');
                setIsDisabled(true);
              }}
              error={idError}
            />
            <Input
              label={'Owner Name'}
              disabled={!isEditable}
              type={'text'}
              value={name}
              onBlur={(value) => {
                if (value) {
                  validateNameInput(value);
                }
              }}
              onChange={(value) => {
                setName(value.toUpperCase());
                setIsDisabled(false || !rcNumber);
                setNameError('');
              }}
              placeholder={'Owner Name'}
              showClear={true}
              onClear={() => {
                setName('');
                setNameError('');
                setIsDisabled(false) || !rcNumber;
              }}
              error={nameError}
            />
            <Input
              label={'Chassis Number'}
              disabled={!isEditable}
              type={'text'}
              maxLength={17}
              onBlur={(value) => {
                if (value) validateChassisNo(value);
              }}
              value={chassisNumber}
              onChange={(value) => {
                setChassisNumber(value.trim().toUpperCase());
                setIsDisabled(false || !rcNumber);
                setChassisIdError('');
              }}
              placeholder={'Ex: MA7CFBC0BB1234567'}
              showClear={true}
              onClear={() => {
                setChassisNumber('');
                setChassisIdError('');
                setIsDisabled(false || !rcNumber);
              }}
              error={chassisIdError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'ind_rc'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
