import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import Textarea from '../../../coreComponent/Textarea/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isSpecialCharacter, validateEmail, validateName } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function CourtRecordCheck(props) {
  const [name, setName] = useState('');
  const [fathersName, setFathersName] = useState('');
  const [address, setAddress] = useState('');
  const [nameError, setNameError] = useState('');
  const [fathersNameError, setFathersNameError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [responseData, setResponseData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (props.uiActive === 'Court') {
      if (props.courtCheckResponse && !isEmpty(props.courtCheckResponse)) {
        const data = props.courtCheckResponse;
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setResponseData(JSON.stringify(data));
        setIsEditable(false);
        setIsSubmitted(true);
        loadData();
      }
    }
  }, []);

  const loadData = () => {
    setIsLoading(true);
    let data = props.uiPerson;
    let data1 =
      data && data.documents
        ? data.documents.ind_aadhaar
          ? data.documents.ind_aadhaar.result
          : null
        : null;
    let data2 =
      data && data.documents
        ? data.documents.ind_driving_license
          ? data.documents.ind_driving_license.result
          : null
        : null;
    let data3 =
      data && data.documents
        ? data.documents.ind_voter_id
          ? data.documents.ind_voter_id.result
          : null
        : null;
    let data4 =
      data && data.documents
        ? data.documents.ind_pan
          ? data.documents.ind_pan.result
          : null
        : null;
    let personName = '';
    let fName = '';
    let add = '';
    if (data1 && data1.name_on_card && data1.fathers_name && data1.address) {
      personName = data1.name_on_card;
      fName = data1.fathers_name;
      add = data1.address;
    } else if (data2 && data2.name_on_card && data2.fathers_name && data2.address) {
      personName = data2.name_on_card;
      fName = data2.fathers_name;
      add = data2.address;
    } else if (data3 && data3.name_on_card && data3.fathers_name && data3.address) {
      personName = data3.name_on_card;
      fName = data3.fathers_name;
      add = data3.address;
    } else if (data4 && data4.name_on_card && data4.fathers_name && data4.address) {
      personName = data4.name_on_card;
      fName = data4.fathers_name;
      add = data4.address;
    }
    let requestData = {
      personId: props.personId,
      docType: 'criminal_check',
      manual_input: {
        name: personName,
        fatherName: fName,
        address: add
      }
    };
    setName(personName);
    setFathersName(fName);
    setAddress(add);
    setIsDisabled(!(personName && fName && add));
    setRequestHeaders('Content type: application/json');
    setReqData(JSON.stringify(requestData));
    setIsLoading(false);
  };

  const criminalCheck = () => {
    const requestData = {
      docType: 'criminal_check',
      personId: props.personId,
      manual_input: {
        name: name,
        fathers_name: fathersName,
        address: address
      }
    };
    setReqData(JSON.stringify(requestData));
    props
      .govtVerificationWithoutOCR('criminal_check', requestData)
      .then((response) => {
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(response));
        setSuccessMsg('Court Record Check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  const validateFathersName = (fathersNameInput) => {
    if (!fathersNameInput) {
      setFathersNameError('Fathers Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(fathersNameInput)) {
      setFathersNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(fathersNameInput)) {
      setFathersNameError('Fathers name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setFathersNameError('');
    }
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      setIsLoading(true);
      setIsEditable(false);
      setRequestHeaders('Content type: application/json');
      criminalCheck();
    }
  };

  const reset = () => {
    props.invalidateCourtCheckResponse();
    setName('');
    setFathersName('');
    setAddress('');
    setIsEditable(true);
    setIsLoading(false);
    setIsDisabled(true);
    setIsSubmitted(false);
    setRequestHeaders('');
    setReqData('');
    setResponseData('');
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Court Records'}</div>
      <div className={'sub-heading'}>
        {'Fetches court case reports to identify cases against individual'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          <div className={'input-ctr'}>
            <Input
              label={'Name'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              value={name}
              onBlur={(value) => {
                validateNameInput(value);
              }}
              onChange={(value) => {
                setIsDisabled(!value || !fathersName || !address);
                setName(value);
              }}
              placeholder={'Name'}
              showClear={true}
              onClear={() => {
                setName('');
                setIsDisabled(true);
                setNameError('Name is required');
              }}
              error={nameError}
            />
            <Input
              label={'Fathers Name'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              value={fathersName}
              onBlur={(value) => {
                validateFathersName(value);
              }}
              onChange={(value) => {
                setIsDisabled(!value || !name || !address);
                setFathersName(value);
              }}
              placeholder={'Fathers Name'}
              showClear={true}
              onClear={() => {
                setFathersName('');
                setIsDisabled(true);
                setFathersNameError('Fathers Name is required');
              }}
              error={fathersNameError}
            />
            <Textarea
              label={'Address'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              rows='5'
              value={address}
              onChange={(value) => {
                setIsDisabled(!value || !name || !fathersName);
                setAddress(value);
              }}
              placeholder={'Your permanent address'}
            />
          </div>
          <DemoNotes showNotes={'detail'} />
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={responseData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'courtCheck'}
              showReports={responseData && !errorCode}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
