import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../../../../coreComponent/Button/index';
import './DeleteAdmin.scss';

export default function DeleteAdmin(props) {
  const [submitting, setSubmitting] = useState(false);

  const deleteAdmin = () => {
    setSubmitting(true);
    let requestData = {
      admin_id: props.adminDetails.admin_id
    };
    props
      .deleteAdminAPI(requestData)
      .then(() => {
        setSubmitting(false);
        props.onSubmit();
        onHide();
      })
      .catch((error) => {
        setSubmitting(false);
        onHide();
      });
  };

  const onHide = () => {
    setSubmitting(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'delete-admin-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='deleteAdminHeading' className={'heading'}>
            {'Delete Admin'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div data-testid='deleteAdminBody' className={'text'}>
          {`Are you sure you want to delete ${
            props.adminDetails.name
              ? props.adminDetails.name.first + ' ' + props.adminDetails.name.last
              : ''
          }?`}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
            isDisabled={submitting}
          />
        </div>
        <Button
          text={'Confirm'}
          onClick={deleteAdmin}
          width={'120px'}
          height={'50px'}
          isLoading={submitting}
        />
      </Modal.Footer>
    </Modal>
  );
}
DeleteAdmin.defaultProps = {
  show: false,
  onHide: null,
  onSubmit: null,
  adminDetails: null
};
