import React, { useState } from 'react';
import axios from 'axios';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

import { copyToClipboard } from '../../../utils/utilities';
import storage from '../../../api/localstorage';
import AppConstants from '../../../coreComponent/AppConstants';
import AppHelper from '../../../coreComponent/AppHelper';
import Icon from '../../../coreComponent/Icon';
import Image from '../../../coreComponent/Image';
import Tooltip from '../../../coreComponent/Tooltip/index';
import Loader from '../../../coreComponent/Loader/index';
import './DemoCard.scss';

export default function DemoCard(props) {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [isCopied, setIsCopied] = useState(false);
  const [reportDownloading, setReportLoading] = useState(false);

  const downloadReport = (reportDoc) => {
    setReportLoading(true);
    if (reportDoc === 'courtCheck') {
      props
        .downloadCourtCheckReport()
        .then((response) => {
          axios({ url: response.url, method: 'GET', responseType: 'blob' }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${reportDoc}_report.pdf`);
            link.click();
            setReportLoading(false);
          });
        })
        .catch((error) => {
          setReportLoading(false);
          console.log(error);
        });
    } else {
      props
        .downloadReports(reportDoc)
        .then((response) => {
          axios({ url: response.url, method: 'GET', responseType: 'blob' }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${reportDoc}_report.pdf`);
            link.click();
            setReportLoading(false);
          });
        })
        .catch((error) => {
          setReportLoading(false);
          console.log(error);
        });
    }
  };

  const viewReport = (reportDoc) => {
    AppHelper.isLaunchUI()
      ? window.open(
          AppConstants.reportURL + storage.getLaunchPersonId() + '/' + reportDoc,
          '_blank'
        )
      : window.open(
          `https://devreports.springscan.springverify.com/user/report/${storage.getDemoPersonId()}/${reportDoc}`,
          '_blank'
        );
  };

  return (
    <div>
      <div
        className={`card-ctr ${props.successMsg ? 'success' : ''} ${
          props.errorCode || props.errorMsg ? 'failure' : ''
        }`}
      >
        <div
          className={'card-header'}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className={'content-ctr'}>
            <div className={'content'}>{props.title}</div>
            {isOpen && props.headers ? <div className={'sub-content'}>{props.headers}</div> : null}
          </div>
          <div
            className={'arrow-icon'}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Icon
              icon={isOpen ? 'UpArrowDropdown' : 'DownArrowDropdown'}
              color={'#002C9D'}
              width={'12'}
              height={'12'}
            />
          </div>
        </div>
        {isOpen && (
          <div className={'card-body'}>
            {props.data ? (
              <div className={'content-ctr'}>
                {props.data === 'no data' ? null : (
                  <div className={'body-header'}>
                    {props.showReports ? (
                      <div className={'link'} onClick={() => viewReport(props.reportDoc)}>
                        {'View report'}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className={'icons'}>
                      {props.showReports ? (
                        reportDownloading ? (
                          <div className={'loading-ctr'}>
                            <Loader color={'#002C9D'} size={'14px'} />
                          </div>
                        ) : (
                          <div>
                            <div className='tooltip-icon'>
                              <div
                                className={'download-icon'}
                                onClick={() => downloadReport(props.reportDoc)}
                              >
                                <Icon
                                  icon={'Download'}
                                  color={'#002C9D'}
                                  width={'10'}
                                  height={'12'}
                                />
                              </div>
                            </div>
                            <Tooltip
                              text={'Download report'}
                              left={'-32px'}
                              top={'-80px'}
                              width={'80px'}
                            />
                          </div>
                        )
                      ) : null}
                      <div className='tooltip-icon'>
                        <div
                          className={'copy-icon'}
                          onClick={() => {
                            copyToClipboard(JSON.stringify(JSON.parse(props.data), undefined, 4));
                            setIsCopied(true);
                            setTimeout(() => {
                              setIsCopied(false);
                            }, 3000);
                          }}
                        >
                          <Icon icon={'Copy'} color={'#002C9D'} width={'9'} height={'12'} />
                        </div>
                      </div>
                      <Tooltip
                        top={'-50px'}
                        left={isCopied ? '-48px' : '-42px'}
                        width={isCopied ? '60px' : '48px'}
                        text={isCopied ? 'Copied' : 'Copy'}
                      />
                    </div>
                  </div>
                )}
                <div className={'content'}>
                  {props.data === 'no data' ? (
                    'No data to show in body parameter for this request.'
                  ) : (
                    <JSONPretty space={4} data={props.data}></JSONPretty>
                  )}
                </div>
              </div>
            ) : (
              <div className={'placeholder'}>
                <div className={'text'}>
                  {props.title === 'Response'
                    ? 'Once you are done submitting documents or details, JSON API Response will be shown here.'
                    : 'Once you upload documents or details, JSON API Request will be shown here.'}
                </div>
                <Image name={'requestResponsePlaceholder.svg'} />
              </div>
            )}
          </div>
        )}
      </div>
      {props.successMsg ? (
        <div className={'success-ctr'}>
          <div className={'success-icon'}>
            <Icon icon={'CircleTick'} color={'#00CD52'} width={'12'} height={'12'} />
          </div>
          <div className={'message'}>
            {props.successMsg ? props.successMsg : 'Document Check Successful'}
          </div>
        </div>
      ) : null}
      {props.errorCode || props.errorMsg ? (
        <div className={'error-ctr'}>
          <div className={'error-heading'}>
            {props.errorCode ? `Error Code: ${props.errorCode}` : 'Invalid details:'}
          </div>
          <div className={'message'}>
            {props.errorMsg ? props.errorMsg : 'Something went Wrong.'}
          </div>
        </div>
      ) : null}
    </div>
  );
}
DemoCard.defaultProps = {
  isOpen: false,
  data: null, //require data
  title: 'Request', //require title
  headers: '', //require headers in case of Request
  successMsg: '', //require success message in case of Success in Response
  errorCode: '', //require error code in case of Error in Response
  errorMsg: '', //require error message in case of Error in Response
  showReports: false, //true if we need to show View and download reports
  reportDoc: '' //document type of report
};
