import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import Input from '../../../../coreComponent/Input/index';
import Button from '../../../../coreComponent/Button/index';
import './ConfirmPassword.scss';

export default function ConfirmPassword(props) {
  const [tokenId, setTokenId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (props.tokenId) {
      setTokenId(props.tokenId);
    } else if (!props.liveMode) {
      setTokenId(props.testTokenKey ? props.testTokenKey.token_id : '');
    } else {
      setTokenId(props.liveTokenKey ? props.liveTokenKey.token_id : '');
    }
  }, [props.tokenId, props.liveMode, props.testTokenKey, props.liveTokenKey]);

  useEffect(() => {
    if (props.password && props.disableKey && props.errorStatus) {
      setPassword(props.password);
      if (props.errorStatus === 'passwordError') {
        setPasswordError('Incorrect Password. Try again.');
      } else {
        setPasswordError('Something went wrong.');
      }
    }
  }, [props.password, props.errorStatus, props.disableKey]);

  const viewKey = () => {
    setSubmitting(true);
    let requestData = {
      token_id: tokenId,
      password: password
    };
    props
      .viewTokenKeyAPI(requestData)
      .then((response) => {
        setSubmitting(false);
        props.onSubmit(response);
        onHide();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.status === 403) {
          setPasswordError('Incorrect Password. Try again.');
        } else {
          setPasswordError('Something went wrong.');
        }
      });
  };

  const disableKey = () => {
    props.onSubmit(password);
    onHide();
  };

  const onHide = () => {
    setPassword('');
    setPasswordError('');
    setSubmitting(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'confirm-password-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div className={'heading'} data-testid={'modal-title'}>
            {'Confirm password'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          label={'Password*'}
          disabled={submitting}
          type={'password'}
          id={'password'}
          value={password}
          onChange={(value) => {
            setPassword(value);
            setPasswordError('');
          }}
          placeholder={'Enter Password'}
          error={passwordError}
          errorWithIcon={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
            isDisabled={submitting}
          />
        </div>
        <Button
          text={'Confirm'}
          onClick={props.disableKey ? disableKey : viewKey}
          width={'120px'}
          height={'50px'}
          isDisabled={!password || passwordError}
          isLoading={submitting}
        />
      </Modal.Footer>
    </Modal>
  );
}
ConfirmPassword.defaultProps = {
  tokenId: '',
  password: '',
  errorStatus: '',
  disableKey: false,
  show: false,
  onHide: null,
  onSubmit: null,
  liveMode: true
};
