import React from 'react';
import ContentLoader from 'react-content-loader';

export default function Shimmer(props) {
  return (
    <ContentLoader height={props.height} width={props.width}>
      <rect x='0' y='0' width={`${props.width}`} height={`${props.height}`} />
    </ContentLoader>
  );
}

Shimmer.defaultProps = {
  width: 156,
  height: 32
};
