import React, { useState, useEffect } from 'react';

import Image from '../Image';
import './Input.scss';

export default function Input(props) {
  const [value, setValue] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const toogleShowPassword = (id) => {
    let field = document.getElementById(id);
    if (!show) {
      field.type = 'text';
    } else {
      field.type = 'password';
    }
    setShow(!show);
  };

  return (
    <div className={'input-field-ctr'} data-testid='inputField'>
      {props.label && (
        <label className={'input-label'}>
          {props.label}
          {props.isRequiredField && <span className={'asterisk-label'}>{'*'}</span>}
        </label>
      )}
      {props.type === 'percentage' ? (
        <div>
          <input
            className={`input-field ${props.error ? 'error-field' : ''} percentage-input`}
            placeholder={props.placeholder}
            value={value}
            id={props.id}
            type={'number'}
            disabled={props.disabled}
            onBlur={(event) => {
              props.onBlur && props.onBlur(event.target.value);
            }}
            onKeyPress={(event) => {
              props.onKeyPress && props.onKeyPress(event);
            }}
            onChange={(event) => {
              setValue(event.target.value);
              props.onChange(event.target.value);
            }}
            maxLength={props.maxLength}
          />
          <div className={'percentage-icon'}>{'%'}</div>
        </div>
      ) : (
        <input
          className={`input-field ${props.error ? 'error-field' : ''} ${
            props.type === 'password' ? 'password-field' : ''
          }`}
          placeholder={props.placeholder}
          value={value}
          id={props.id}
          type={props.type}
          disabled={props.disabled}
          onBlur={(event) => {
            props.onBlur && props.onBlur(event.target.value);
          }}
          onKeyPress={(event) => {
            props.onKeyPress && props.onKeyPress(event);
          }}
          onChange={(event) => {
            setValue(event.target.value);
            props.onChange(event.target.value);
          }}
          maxLength={props.maxLength}
        />
      )}
      {props.showClear && (
        <div className={'show-icon'}>
          <Image name={'cross.svg'} onClick={() => props.onClear()} />
        </div>
      )}
      {props.type === 'password' && value ? (
        show ? (
          <div className={'show-icon'}>
            <Image name={'hidePassword.svg'} onClick={() => toogleShowPassword(props.id)} />
          </div>
        ) : (
          <div className={'show-icon'}>
            <Image name={'showPassword.svg'} onClick={() => toogleShowPassword(props.id)} />
          </div>
        )
      ) : null}
      {props.error ? (
        <div>
          {props.errorWithIcon ? (
            <div className={'input-error-ctr'}>
              <Image name={'errorIcon.svg'} />
              <div className={'error'}>{props.error}</div>
            </div>
          ) : (
            <div className={'input-error'}>{props.error}</div>
          )}
        </div>
      ) : null}
    </div>
  );
}
Input.defaultProps = {
  disabled: false,
  isRequiredField: false,
  onBlur: null,
  type: 'text',
  value: '',
  onChange: null,
  placeholder: '',
  label: '',
  error: '',
  errorWithIcon: false,
  showClear: false,
  onClear: null,
  onKeyPress: null
};
