import React, { useState, useEffect } from 'react';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import Table from '../../../../coreComponent/Table/index';
import DatePicker from '../../../../coreComponent/DatePicker/index';
import { getTransactionType } from '../../../../utils/utilities';
import './RecentTransactions.scss';

export default function RecentTransactions(props) {
  const [tableRows, setTableRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState(true); // true->asec, false->desc
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });

  useEffect(() => {
    loadData();
  }, [props.liveMode]);

  useEffect(() => {
    if (props.transactions && !isEmpty(props.transactions)) {
      let rows = getRowData(props.transactions.data);
      setTableRows(rows);
      setTotalCount(props.transactions.total_count);
    }
  }, [props.transactions]);

  const headCells = [
    {
      id: 'dateTime',
      label: 'DATE TIME',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('dateTime');
      }
    },
    {
      id: 'docType',
      label: 'DOCUMENT TYPE'
    },
    {
      id: 'credits',
      label: 'CREDITS USED',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('credits');
      }
    },
    {
      id: 'empty',
      label: '',
      width: '300px'
    }
  ];

  const loadData = () => {
    setPage(1);
    setRowsPerPage(10);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    props.getTransactionsAPI(data).then(() => {
      setLoading(false);
    });
  };

  //this is for each row the heading creation  with whatever id for headcells
  const createData = (dateTime, docType, credits, empty) => {
    return { dateTime, docType, credits, empty };
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value, key) {
      let rowData = createData(
        moment(value.createdAt).utcOffset('+05:30').format('DD-MM-YYYY HH:mm'),
        getTransactionType(value.txType),
        getCredits(Math.abs(value.amount)),
        ''
      );
      data.push(rowData);
    });
    return data;
  };

  const getCredits = (credits) => {
    return <div className={'credits'}>{credits}</div>;
  };

  const applySorting = (key) => {
    setLoading(true);
    setPage(1);
    let data = {
      range: rowsPerPage,
      page: 1
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    switch (key) {
      case 'dateTime': {
        setSortBy('createdAt');
        setSortOrder(!sortOrder);
        data.sort_by = 'createdAt';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'credits': {
        setSortBy('amount');
        setSortOrder(!sortOrder);
        data.sort_by = 'amount';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
    }
    props.getTransactionsAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setLoading(true);
    let data = {
      range: rowsPerPage,
      page: newPage
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    props.getTransactionsAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
    setLoading(true);
    let data = {
      range: parseInt(value, 10),
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    props.getTransactionsAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applyDateFilter = (date) => {
    setDateFilter(date);
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (date && date.start && date.end) {
      setDateFilter(date);
      data.start_date = date.start;
      data.end_date = date.end;
    } else {
      setDateFilter({ start: '', end: '' });
    }
    props.getTransactionsAPI(data).then(() => {
      setLoading(false);
    });
  };

  return (
    <div className={'recent-transactions-ctr'} data-testid='recent-transactions'>
      <div className={'heading-ctr'}>
        <div className={'heading'}>{'Recent Transactions'}</div>
        <div>
          <DatePicker
            emptyFilter={true}
            startDate={dateFilter.start}
            endDate={dateFilter.end}
            onClear={applyDateFilter}
            onSubmit={applyDateFilter}
          />
        </div>
      </div>
      <Table
        noDataText={'No Transactions Found'}
        headCells={headCells}
        rowData={tableRows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(pageNo) => handleChangePage(pageNo)}
        handleChangeRowsPerPage={(rows) => handleChangeRowsPerPage(rows)}
        loading={loading}
      />
    </div>
  );
}
