import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducer from '../reducers/springscan-reducer';
import digiFLowReducer from '../reducers/digiLockerFlowReducer';

const persistConfig = {
  key: 'root',
  storage
};


const rootReducer = (state = {}, action) => {
  return {
    ...reducer(state, action),
    digi: digiFLowReducer(state, action)
  };
};

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
