import React from 'react';
import { Modal } from 'react-bootstrap';

import './PersonInfo.scss';

export default function PersonInfo(props) {
  const getDetails = (details) => {
    let detailRows = [];
    for (let detail in details) {
      if (details[detail]) {
        let row = (
          <div key={detail} className={'detail-row'}>
            <div className={'label'}>{detail}</div>
            <div className={'separator'}>{':'}</div>
            <div className={'value'}>{details[detail]}</div>
          </div>
        );
        detailRows.push(row);
      }
    }
    return detailRows;
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={'person-info-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className={'info-ctr'}>
          <div className={'doc-image'}>
            <img data-testid='imageElement' className={'image'} src={props.document.image} />
          </div>
          <div data-testid='docName' className={'doc-name'}>
            {props.document.name}
          </div>
          <div>{props.document.status}</div>
          <div className={'doc-details'}>
            <div data-testid='titleElement' className={'title'}>
              {'PRIMARY DETAILS'}
            </div>
            {getDetails(props.document.details)}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
PersonInfo.defaultProps = {
  document: null,
  show: false,
  onHide: null
};
