import React from 'react';
import Switch from 'react-switch';

import './SwitchButton.scss';

export default function SwitchButton(props) {
  return (
    <label className={`switch-ctr ${props.disabled ? 'disabled' : ''}`}>
      <span>{props.label}</span>
      <Switch
        onChange={props.onChange}
        checked={props.checked}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={11}
        width={30}
        height={15}
        offColor={props.whiteSwitch ? '#b2bae3' : '#999999'}
        onColor={props.whiteSwitch ? '#ffffff' : '#65D782'}
        offHandleColor={'#ffffff'}
        onHandleColor={props.whiteSwitch ? '#002c9d' : '#ffffff'}
        disabled={props.disabled}
      />
    </label>
  );
}

SwitchButton.defaultProps = {
  label: '',
  checked: false,
  onChange: null,
  disabled: false,
  whiteSwitch: true
};
