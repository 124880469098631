import { connect } from 'react-redux';
import { getTokenKeys as getTokenKeysAPI } from '../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    tokenKeys: state.tokenKeys
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTokenKeysAPI: (data) => dispatch(getTokenKeysAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
