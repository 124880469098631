import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-dates/lib/css/_datepicker.css';

import './index.scss';
import * as serviceWorker from './src/serviceWorker';
import store from './src/store';
import Application from './src/component/Application';
import googleEnvironmentConstant from './src/config/google-auth.json';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppHelper from './src/coreComponent/AppHelper';

// Call it once in your app. At the root of your app is the best place
toast.configure({
  autoClose: 8000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER
});

// Getting google client Id
const clientId = AppHelper?.isLocallyServed() ? googleEnvironmentConstant?.local?.clientId : googleEnvironmentConstant?.web?.clientId;

render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={clientId}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <Application />
      </PersistGate>
    </GoogleOAuthProvider>
  </Provider>,

  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
