import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import UploadDocument from '../../../coreComponent/UploadDocument/index';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import Checkbox from '../../../coreComponent/Checkbox';
import { addDocumentOnlyForOCR as addDocumentOnlyForOCRAPI } from '../../../api/demoUI';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function AadhaarCard(props) {
  const [isOCR, setIsOCR] = useState(true);
  const [aadhaarId, setAadhaarId] = useState('');
  const [idError, setIdError] = useState('');
  const [document1, setDocument1] = useState('');
  const [document2, setDocument2] = useState('');
  const [docError, setDocError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [docLoading, setDocLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [onlyOcrErrorCode, setOnlyOcrErrorCode] = useState('');
  const [apiActivityIsVerification, setApiActivityIsVerification] = useState(false);
  const [successParameters, setSuccessParameters] = useState(['id_number']);
  const [requestID, setRequestID] = useState('');
  const [onlyOCRDocument1, setOnlyOCRDocument1] = useState('');
  const [onlyOCRDocument2, setOnlyOCRDocument2] = useState('');
  const [ocrReqData, setOcrReqData] = useState('');
  const [ocrResData, setOcrResData] = useState('');
  const [onlyOcrDocError, setOnlyOcrDocError] = useState('');
  const [onlyOcrErrorMsg, setOnlyOcrErrorMsg] = useState('');
  const [successMsgForOcrOnly, setSuccessMsgFroOcrOnly] = useState('');
  const [isSubmittedForOcrOnly, setIsSubmittedForOcrOnly] = useState(false);
  const [isOnlyOcrDisabled, setOnlyOcrIsDisabled] = useState(true);

  useEffect(() => {
    if (props.uiActive === 'Aadhaar') {
      loadData();
      if (props.aadhaarOnlyOcrResponse && !isEmpty(props.aadhaarOnlyOcrResponse)) {
        const responseData = props.aadhaarOnlyOcrResponse;
        setOcrResData(JSON.stringify(responseData));
        setIsSubmittedForOcrOnly(true);
      }
      if (props.aadhaarOcrResponse && !isEmpty(props.aadhaarOcrResponse)) {
        const responseData = props.aadhaarOcrResponse;
        setRequestID(responseData.configuration.api_config.request_id);
        setResData(JSON.stringify(responseData));
        setIsEditable(false);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    if (data && data.documents && data.documents.ind_aadhaar) {
      if (data.documents.ind_aadhaar.document1 && !isEmpty(props.aadhaarOnlyOcrResponse)) {
        setOnlyOCRDocument1(data.documents.ind_aadhaar.document1);
        setOnlyOcrIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setOcrReqData('no data');
      }
      if (data.documents.ind_aadhaar.document2 && !isEmpty(props.aadhaarOnlyOcrResponse)) {
        setOnlyOCRDocument2(data.documents.ind_aadhaar.document2);
      }
      if (data.documents.ind_aadhaar.document1 && !isEmpty(props.aadhaarOcrResponse)) {
        setDocument1(data.documents.ind_aadhaar.document1);
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
      }
      if (data.documents.ind_aadhaar.document2 && !isEmpty(props.aadhaarOcrResponse)) {
        setDocument2(data.documents.ind_aadhaar.document2);
      }
    }
    setDocLoading(false);
  };

  const getDocumentUrl = (url, index) => {
    setDocError('');
    if (index === 1) {
      apiActivityIsVerification ? setDocument1(url) : setOnlyOCRDocument1(url);
      setOnlyOcrIsDisabled(false);
      setIsDisabled(false);
    } else {
      apiActivityIsVerification ? setDocument2(url) : setOnlyOCRDocument2(url);
    }
    setRequestHeaders('Content type: application/json');
    setReqData('no data');
    setOcrReqData('no data');
  };

  /* ---------- For VERIFICATION Activity with input type is OCR ---------*/
  const addDocument = () => {
    let requestBody = {
      docType: 'ind_aadhaar',
      success_parameters: successParameters,
      personId: props.personId
    };
    /* Prefernece to OCR verification if request_id and Image are present*/
    if (document1 || document2 || requestID) {
      if (document1 || document2) {
        requestBody['document_front'] = document1;
        if (!document1) {
          setIsLoading(false);
          setIsDisabled(true);
          setDocError('Front image is missing');
          return false;
        }
        addDocumentOnlyForOCRAPI(requestBody)
          .then((res) => {
            const responseData = res.data;
            const request_id = responseData.configuration.api_config.request_id;
            if (request_id) {
              requestBody['request_id'] = request_id;
              govtVerificationWithOCR(requestBody);
            }
          })
          .catch((error) => {
            if (error.status === 402) {
              props.showAlert('insufficient');
            } else {
              props.reloadCredits();
              setDocError(error.api_status_description || 'Something went wrong.');
            }
            setIsLoading(false);
            setIsEditable(true);
            setIsDisabled(true);
          });
      } else {
        requestBody['request_id'] = requestID;
        govtVerificationWithOCR(requestBody);
      }
    } else {
      setDocError('Either document image or request ID is missing');
      setIsLoading(false);
      setIsDisabled(true);
      setIsEditable(true);
    }
  };

  /* ----------  For OCR Activity  ---------- */
  const addDocumentOnlyForOCR = () => {
    const requestBody = {
      docType: 'ind_aadhaar',
      success_parameters: successParameters,
      personId: props.personId,
      document_front: onlyOCRDocument1,
      document_back: onlyOCRDocument2
    };
    setOcrReqData(JSON.stringify(requestBody));
    props
      .addDocumentOnlyForOCR('ind_aadhaar', requestBody)
      .then((res) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmittedForOcrOnly(true);
        const responseData = res;
        setOcrResData(JSON.stringify(responseData));
        setSuccessMsgFroOcrOnly('Aadhaar ocr is done successful');
        setOnlyOcrErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
          // setOnlyOcrDocError( error.data.api_status_description || 'Something went wrong.');
        }
        setOcrResData(JSON.stringify(error));
        setIsSubmittedForOcrOnly(true);
        setSuccessMsgFroOcrOnly('');
        setOnlyOcrErrorCode(error?.status);
        setIsLoading(false);
        const errMessage = error?.data?.api_status_message
          ? error?.data?.api_status_message
          : error?.data?.api_status_description;
        setOnlyOcrErrorMsg(errMessage || 'Something went wrong');
        setOnlyOcrIsDisabled(true);
      });
  };

  const getAadhharId = (id) => {
    if (id.length < 12) {
      setIdError('Aadhaar ID must contains 12 digits');
      setIsDisabled(true);
      return false;
    } else if (!id.match('[0-9]{12}')) {
      setIdError('Invalid Aadhaar ID');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      setIsDisabled(false);
      return true;
    }
  };

  const govtVerificationWithOCR = (requestBody) => {
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithOCR(requestBody, 'ind_aadhaar')
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setRequestID(response.configuration.api_config.request_id);
        const responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Aadhaar Document Check successful.');
        setIsEditable(false);
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setSuccessMsg('');
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  /* ---------- For VERIFICATION Activity with input type is MANUAL ---------*/
  const govtVerificationWithoutOCR = () => {
    const requestBody = {
      docType: 'ind_aadhaar',
      success_parameters: successParameters,
      personId: props.personId,
      manual_input: {
        id_number: aadhaarId
      }
    };
    props
      .govtVerificationWithoutOCR('ind_aadhaar', requestBody)
      .then((response) => {
        props.reloadCredits();
        props.updateUIPerson(true);
        setIsLoading(false);
        setIsSubmitted(true);
        const responseData = response;
        setIsEditable(false);
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Aadhaar Document Check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setSuccessMsg('');
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      setIsLoading(true);
      if (!apiActivityIsVerification) {
        setOnlyOcrErrorCode('');
        setOnlyOcrErrorMsg('');
        setIsLoading(true);
        addDocumentOnlyForOCR();
      } else if (isOCR) {
        addDocument();
      } else {
        if (getAadhharId(aadhaarId)) {
          govtVerificationWithoutOCR();
        } else {
          setIsLoading(false);
          setIsDisabled(true);
        }
      }
    }
  };

  const reset = () => {
    if (!apiActivityIsVerification) {
      props.invalidateAadhaarOnlyOcrResponse();
      if (props.identityDocType === 'ind_aadhaar') {
        props.invalidateIdentityResponse();
      }
      setOcrReqData('');
      setOcrResData('');
      setOnlyOCRDocument1('');
      setOnlyOCRDocument2('');
      setOnlyOcrDocError('');
      setSuccessMsgFroOcrOnly('');
      setOnlyOcrErrorCode('');
      setOnlyOcrErrorMsg('');
      setIsSubmittedForOcrOnly(false);
      setOnlyOcrIsDisabled(true);
    } else {
      if (isOCR && apiActivityIsVerification) {
        props.invalidateAadhaarOcrResponse();
        props.invalidateAadhaarMaskingResponse();
        if (props.identityDocType === 'ind_aadhaar') {
          props.invalidateIdentityResponse();
        }
        setDocument1('');
        setDocument2('');
        setDocError('');
      } else {
        props.invalidateAadhaarResponse();
        setAadhaarId('');
        setIdError('');
      }
      setIsEditable(true);
      setDocLoading(false);
      setIsLoading(false);
      setRequestID('');
      setIsDisabled(true);
      setIsSubmitted(false);
      setRequestHeaders('');
      setReqData('');
      setResData('');
      setSuccessMsg('');
      setErrorCode('');
      setErrorMsg('');
    }
  };

  const setInputType = (ocr) => {
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    if (ocr) {
      setIsOCR(true);
      if (props.aadhaarOcrResponse && !isEmpty(props.aadhaarOcrResponse)) {
        const responseData = props.aadhaarOcrResponse;
        setResData(JSON.stringify(responseData));
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setResData('');
        setIsEditable(true);
        setIsDisabled(!document1);
        setIsSubmitted(false);
      }
    } else {
      setIsOCR(false);
      if (props.aadhaarResponse && !isEmpty(props.aadhaarResponse)) {
        const data = props.aadhaarResponse;
        const id = data.output.source.aadhaar_number;
        const requestData = {
          doc_type: 'ind_aadhaar',
          id_number: id
        };
        setResData(JSON.stringify(data));
        setRequestHeaders('Content type: application/json');
        setReqData(JSON.stringify(requestData));
        setAadhaarId(requestData.id_number);
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setReqData('');
        setIsEditable(true);
        setIsDisabled(aadhaarId ? false : true);
        setIsSubmitted(false);
      }
    }
  };

  const setApiActivity = (isVerification) => {
    if (isVerification) {
      setApiActivityIsVerification(true);
    } else {
      setApiActivityIsVerification(false);
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Aadhaar (Basic)'}</div>
      <div className={'sub-heading'}>
        {
          'Realtime check across multiple public and government databases for verification of user identity against Aadhaar details'
        }
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Api Activity Radio Button */}
          <div className={'radio-ctr'}>
            <div className={'radio-heading'}>{'Choose activity:'}</div>
            <div className={'radio-input'}>
              <input
                className={'input'}
                type={'radio'}
                value={true}
                checked={apiActivityIsVerification}
                onChange={() => setApiActivity(true)}
              />
              <div className={'label'} onClick={() => setApiActivity(true)}>
                {'Verification'}
              </div>
              <input
                className={'input'}
                type={'radio'}
                value={false}
                checked={!apiActivityIsVerification}
                onChange={() => setApiActivity(false)}
              />
              <div className={'label'} onClick={() => setApiActivity(false)}>
                {'OCR'}
              </div>
            </div>
          </div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'idNumber'}
                  label='Aadhaar Number'
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
            </div>
          </div>
          {apiActivityIsVerification && (
            <div className={'radio-ctr'}>
              <div className={'radio-heading'}>{'Choose input type:'}</div>
              <div className={'radio-input'}>
                <input
                  className={'input'}
                  type={'radio'}
                  value={true}
                  checked={isOCR}
                  onChange={() => setInputType(true)}
                />
                <div className={'label'} onClick={() => setInputType(true)}>
                  {'OCR'}
                </div>
                <input
                  className={'input'}
                  type={'radio'}
                  value={false}
                  checked={!isOCR}
                  onChange={() => setInputType(false)}
                />
                <div className={'label'} onClick={() => setInputType(false)}>
                  {'Manual'}
                </div>
              </div>
            </div>
          )}
          {/*  If Chosen api activity is OCR */}
          {!apiActivityIsVerification && (
            <div>
              <div className={'image-label upper-label'}>{'ID Image :'}</div>
              {docLoading ? (
                <div className={'upload-double-ctr'}>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <UploadDocument
                    isDouble={true}
                    image1={onlyOCRDocument1}
                    image2={onlyOCRDocument2}
                    isEditable={!isSubmittedForOcrOnly}
                    personId={props.personId}
                    getUrl={(url, index) => getDocumentUrl(url, index)}
                    handleCancel={(index) => {
                      setOnlyOcrDocError('');
                      if (index === 1) {
                        setOnlyOCRDocument1('');
                        setOnlyOcrIsDisabled(true);
                        if (props.identityDocType === 'ind_aadhaar') {
                          props.invalidateIdentityResponse();
                        }
                      } else {
                        setOnlyOCRDocument2('');
                      }
                    }}
                  />
                  {onlyOcrDocError ? <div className={'upload-error'}>{onlyOcrDocError}</div> : null}
                </div>
              )}
              <DemoNotes showNotes={'document'} isReports={true} />
            </div>
          )}
          {/* If chosen api activity is Verification */}
          {apiActivityIsVerification &&
            (isOCR ? (
              <div>
                <div className={'image-label upper-label'}>{'ID Image :'}</div>
                {docLoading ? (
                  <div className={'upload-double-ctr'}>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <UploadDocument
                      isDouble={true}
                      image1={document1}
                      image2={document2}
                      isEditable={isEditable}
                      personId={props.personId}
                      getUrl={(url, index) => getDocumentUrl(url, index)}
                      handleCancel={(index) => {
                        setDocError('');
                        props.invalidateAadhaarMaskingResponse();
                        if (index === 1) {
                          setDocument1('');
                          setIsDisabled(true);
                          if (props.identityDocType === 'ind_aadhaar') {
                            props.invalidateIdentityResponse();
                          }
                        } else {
                          setDocument2('');
                        }
                      }}
                    />
                    {docError ? <div className={'upload-error'}>{docError}</div> : null}
                  </div>
                )}
                <div className={'or-label'}>{'OR'}</div>
                <div>
                  <Input
                    label={'Request ID'}
                    disabled={isSubmitted ? true : false}
                    type={'text'}
                    maxLength={100}
                    value={requestID ?? ''}
                    onChange={(value) => {
                      document1
                        ? setIsDisabled(false)
                        : value
                        ? setIsDisabled(false)
                        : setIsDisabled(true);
                      setRequestID(value);
                    }}
                    placeholder={'OCR request ID'}
                    id={'req_id'}
                    showClear={isSubmitted ? false : true}
                    onClear={() => {
                      setRequestID('');
                      document1 ? setIsDisabled(false) : setIsDisabled(true);
                    }}
                  />
                </div>
                <DemoNotes showNotes={'document'} isReports={true} />
              </div>
            ) : (
              <div>
                <div className={'input-ctr'}>
                  <Input
                    label={'Aadhaar ID'}
                    disabled={!isEditable}
                    type={'text'}
                    isRequiredField={true}
                    maxLength={12}
                    value={aadhaarId}
                    onBlur={(value) => {
                      getAadhharId(value);
                    }}
                    onChange={(value) => {
                      if (value) {
                        setIsDisabled(false);
                        setIdError('');
                      } else {
                        setIsDisabled(true);
                        setIdError('Aadhaar ID must contains 12 digits');
                      }
                      setAadhaarId(value);
                    }}
                    placeholder={'Aadhaar ID'}
                    error={idError}
                    showClear={true}
                    onClear={() => {
                      setAadhaarId('');
                      setIdError('');
                      setIsDisabled(!aadhaarId);
                    }}
                  />
                </div>
                <DemoNotes showNotes={'detail'} />
              </div>
            ))}
          <div className={'submit-btn'}>
            {apiActivityIsVerification ? (
              isSubmitted ? (
                <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
              ) : (
                <Button
                  text={'Submit'}
                  onClick={handleSubmit}
                  width={'132px'}
                  height={'47px'}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isWhiteBtn={isDisabled || isLoading}
                />
              )
            ) : isSubmittedForOcrOnly ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isOnlyOcrDisabled}
                isLoading={isLoading}
                isWhiteBtn={isOnlyOcrDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={!apiActivityIsVerification ? 'v4/ocr/' : 'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard
              data={apiActivityIsVerification ? reqData : ocrReqData}
              headers={requestHeaders}
            />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={apiActivityIsVerification ? resData : ocrResData}
              successMsg={apiActivityIsVerification ? successMsg : successMsgForOcrOnly}
              errorCode={apiActivityIsVerification ? errorCode : onlyOcrErrorCode}
              errorMsg={apiActivityIsVerification ? errorMsg : onlyOcrErrorMsg}
              reportDoc={'ind_aadhaar'}
              // showReports={isOCR && resData && !errorCode }
              showReports={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
