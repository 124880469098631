import React from 'react';

import './CreditsBanner.scss';

export default function CreditsBanner(props) {
  return (
    <div className={'credits-banner-ctr'} data-testid='credits-banner'>
      <div className={'title'}>{'CREDITS AVAILABLE'}</div>
      <div className={'value'}>{props.credits}</div>
    </div>
  );
}
CreditsBanner.defaultProps = {
  credits: ''
};
