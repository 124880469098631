import { connect } from 'react-redux';
import { generateTokenKey as generateTokenKeyAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    generateTokenKeyAPI: (data) => dispatch(generateTokenKeyAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
