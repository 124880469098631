import { connect } from 'react-redux';
import { getInvoiceDetails as getInvoiceDetailsAPI } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoiceDetailsAPI: (data) => dispatch(getInvoiceDetailsAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
