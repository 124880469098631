import { connect } from 'react-redux';
import { getStats as getStatsAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    stats: state.stats
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStatsAPI: () => dispatch(getStatsAPI())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
