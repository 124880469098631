import http from './http';
import storage from './localstorage';
import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';

let baseUrl = AppConstants.developmentAPIURL;

const getConfig = () => {
  let headers = {
    'Content-Type': 'application/json',
    token: AppHelper.getToken(),
    aadhaar_verification_token: storage.getAadhaarToken()
  };
  let config = {
    headers: headers
  };
  return config;
};

const getPersonId = () => {
  let personId = storage.getAadhaarPersonId();
  return personId;
};

export const storePhoneNumber = (data) => {
  return http.post(`${baseUrl}khosla/storePhoneNumber`, data, getConfig());
};

export const cancelProcess = (data) => {
  return http.post(`${baseUrl}khosla/cancelProcess`, data, getConfig());
};

export const khoslaInit = (data) => {
  return http.post(`${baseUrl}khosla/khoslaInit`, data, getConfig());
};

export const khoslaFetch = (data) => {
  return http.post(`${baseUrl}khosla/khoslaFetch`, data, getConfig());
};

export const fetchUser = () => {
  let demoHeaders = {
    'Content-Type': 'application/json',
    token: AppHelper.getToken()
  };
  let demoConfig = {
    headers: demoHeaders
  };
  return http.get(`${baseUrl}user/getPersonPublicApi/${getPersonId()}`, demoConfig);
};
