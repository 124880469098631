import React, { useState, useEffect, useRef } from 'react';

import Icon from '../Icon';
import Checkbox from '../Checkbox/index';
import Loader from '../Loader/index';
import './MultiSelect.scss';

export default function MultiSelect(props) {
  const [value, setValue] = useState([]);
  const [show, setShow] = useState(false);
  const multiSelectNode = useRef();
  useEffect(() => {
    if (props.value && props.value.length > 0) {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    // add when mounted (componentDidMount)
    document.addEventListener('click', handleClick);
    // return function to be called when unmounted (componentWillMount)
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (multiSelectNode && !multiSelectNode.current.contains(e.target)) {
      setShow(false);
    }
  };

  const toogleSelect = (option) => {
    let selectedOptions = value;
    if (value.findIndex((val) => option === val) !== -1) {
      selectedOptions.splice(
        value.findIndex((val) => option === val),
        1
      );
    } else {
      selectedOptions.push(option);
    }
    setValue(selectedOptions);
    setShow(false);
    props.onChange(selectedOptions);
  };

  const clearAll = () => {
    setValue([]);
    setShow(false);
    props.onChange([]);
  };

  return (
    <div className={'multi-select-filter'} ref={multiSelectNode}>
      <div
        className={`multi-select-ctr ${show ? 'filter-open' : ''}`}
        onClick={() => {
          setShow(!show);
        }}
      >
        {value && value.length !== 0 ? (
          <div className={'value'}>
            <div>{value[0]}</div>
            {value.length > 1 ? <div className={'count'}>{`+${value.length - 1}`}</div> : null}
          </div>
        ) : (
          <div className={'placeholder-value'}>{props.placeholder}</div>
        )}
        <Icon icon={'DownArrow'} color={'#333333'} width={'10'} height={'6'} />
      </div>
      {show ? (
        <div className={'multi-select-options-ctr'}>
          {value && value.length > 0 ? (
            <div className={'clear-ctr'} onClick={clearAll}>
              {'Clear All'}
            </div>
          ) : null}
          <div className={'options-ctr'}>
            {props.options.map((option, index) => {
              return (
                <div key={index} data-testid='checkbox' className={'option'}>
                  <Checkbox
                    id={option}
                    label={option}
                    large={true}
                    checked={value.findIndex((val) => option === val) !== -1}
                    onClick={() => {
                      toogleSelect(option);
                    }}
                  />
                </div>
              );
            })}
          </div>
          {props.loadMore ? (
            props.loading ? (
              <div className={'content-loader'}>
                <Loader color={'#002c9d'} size={'12px'} />
                <div className={'text'}>{'Loading...'}</div>
              </div>
            ) : (
              <div className={'load-more-ctr'} onClick={props.onLoad}>
                {'Load More'}
              </div>
            )
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
MultiSelect.defaultProps = {
  placeholder: 'Filter by',
  value: [],
  options: [],
  onChange: null,
  loadMore: false,
  onLoad: null,
  loading: false
};
