import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import UploadDocument from '../../../coreComponent/UploadDocument/index';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import SelectInput from '../../../coreComponent/SelectInput/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function IdentityVerification(props) {
  const [selfieUrl, setSelfieUrl] = useState('');
  const [docUrls, setDocUrls] = useState({
    ind_aadhaar: '',
    ind_pan: '',
    ind_voter_id: '',
    ind_driving_license: '',
    ind_passport: ''
  });
  const [documentUrl, setDocumentUrl] = useState('');
  const [docType, setDocType] = useState('');
  const [docError, setDocError] = useState('');
  const [isDocChanged, setIsDocChanged] = useState(false);
  const [isDocDisabled, setIsDocDisabled] = useState(true);
  const [isEditable, setIsEditable] = useState(true);
  const [docLoading, setDocLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setRequestData] = useState('');
  const [responseData, setResponseData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const docTypeOptions = [
    {
      value: 'ind_aadhaar',
      label: 'Aadhaar Card'
    },
    {
      value: 'ind_pan',
      label: 'Pan Card'
    },
    {
      value: 'ind_voter_id',
      label: 'Voter ID'
    },
    {
      value: 'ind_driving_license',
      label: 'Driving License'
    },
    {
      value: 'ind_passport',
      label: 'Passport'
    }
  ];

  useEffect(() => {
    if (props.uiActive === 'Identity') {
      if (props.identityResponse && !isEmpty(props.identityResponse)) {
        loadData();
        setResponseData(JSON.stringify(props.identityResponse));
        setIsEditable(false);
        setIsDocDisabled(true);
        setIsSubmitted(true);
      }
    }
  }, []);

  useEffect(() => {
    if (docUrls[docType.value]) {
      let requestData = {
        docType: docType.value
      };
      setDocumentUrl(docUrls[docType.value]);
      setRequestHeaders('Content type: application/json');
      setRequestData(JSON.stringify(requestData));
    } else {
      setDocumentUrl('');
      setRequestHeaders('');
      setRequestData('');
    }
  }, [docType]);

  const loadData = () => {
    setDocLoading(true);
    let data = props.uiPerson;
    if (data && data.selfie && data.selfie.url) {
      setSelfieUrl(data.selfie.url);
      setIsDocDisabled(false);
    }
    if (data && data.documents) {
      let documentUrls = {};
      if (data.documents.ind_aadhaar && data.documents.ind_aadhaar.document1) {
        documentUrls['ind_aadhaar'] = data.documents.ind_aadhaar.document1;
      }
      if (data.documents.ind_pan && data.documents.ind_pan.document1) {
        documentUrls['ind_pan'] = data.documents.ind_pan.document1;
      }
      if (data.documents.ind_voter_id && data.documents.ind_voter_id.document1) {
        documentUrls['ind_voter_id'] = data.documents.ind_voter_id.document1;
      }
      if (data.documents.ind_driving_license && data.documents.ind_driving_license.document1) {
        documentUrls['ind_driving_license'] = data.documents.ind_driving_license.document1;
      }
      if (data.documents.ind_passport && data.documents.ind_passport.document1) {
        documentUrls['ind_passport'] = data.documents.ind_passport.document1;
      }
      setDocUrls(documentUrls);
      loadDocType(documentUrls);
    }
    setDocLoading(false);
  };

  const loadDocType = (documentUrls) => {
    if (props.identityDocType) {
      let documentType = docTypeOptions.find((val) => val.value === props.identityDocType);
      setDocType(documentType);
      if (documentType && documentUrls[documentType.value]) {
        let requestData = {
          docType: documentType.value
        };
        setDocumentUrl(documentUrls[documentType.value]);
        setRequestHeaders('Content type: application/json');
        setRequestData(JSON.stringify(requestData));
      }
    }
  };

  const getSelfieUrl = (url) => {
    setSelfieUrl(url);
    addSelfie(url);
  };

  const addSelfie = (url) => {
    setDocLoading(true);
    let requestBody = {
      selfieurl: url
    };

    props
      .addSelfie(requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        setDocLoading(false);
        setSelfieUrl(response.person.selfie.url);
        setIsDocDisabled(false);
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        setDocLoading(false);
      });
  };

  const addDocument = () => {
    let requestData = {
      docType: docType.value,
      personId: props.personId
    };
    let requestBody = {
      docType: docType.value,
      personId: props.personId,
      document_front: documentUrl
    };
    props
      .addDocumentOnlyForOCR(docType.value, requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        compareSelfieAndDocument();
        // setDocumentUrl(response.person[0].documents[docType.value].document1);
        setRequestHeaders('Content type: application/json');
        setRequestData(JSON.stringify(requestData));
        setIsDocChanged(false);
        switch (docType.value) {
          case 'ind_aadhaar': {
            props.invalidateAadhaarOcrResponse();
            break;
          }
          case 'ind_driving_license': {
            props.invalidateDLOcrResponse();
            break;
          }
          case 'ind_voter_id': {
            props.invalidateVoterOcrResponse();
            break;
          }
          case 'ind_pan': {
            props.invalidatePANOcrResponse();
            break;
          }
          case 'ind_passport': {
            props.invalidatePassportResponse();
            break;
          }
          default: {
            break;
          }
        }
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
          setDocError(error.data.api_status_description || 'Something went wrong.');
        }
        setIsLoading(false);
        setIsDocDisabled(false);
        setIsEditable(true);
      });
  };

  const compareSelfieAndDocument = () => {
    let requestBody = {
      personId: props.personId,
      docType: docType.value
    };

    props
      .compareSelfieAndDocument(docType.value, requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(response));
        setSuccessMsg('Face Match successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      setIsLoading(true);
      setIsDocDisabled(true);
      setIsEditable(false);
      if (isDocChanged) {
        addDocument();
      } else {
        compareSelfieAndDocument();
      }
    }
  };

  const reset = () => {
    props.invalidateFaceResponse();
    props.invalidateIdentityDocType();
    props.invalidateIdentityResponse();
    props.invalidateAadhaarOcrResponse();
    props.invalidateDLOcrResponse();
    props.invalidateVoterOcrResponse();
    props.invalidatePANOcrResponse();
    if (docType.value === 'ind_passport') {
      props.invalidatePassportResponse();
    }
    setSelfieUrl('');
    setDocumentUrl('');
    setDocType('');
    setDocError('');
    setIsDocDisabled(true);
    setIsEditable(true);
    setDocLoading(false);
    setIsLoading(false);
    setIsSubmitted(false);
    setRequestHeaders('');
    setRequestData('');
    setResponseData('');
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Face Match'}</div>
      <div className={'sub-heading'}>
        {
          'Compares document against selfie, for a match.  If user document image and selfie matches, generates a high score with a boolean value of true, else false'
        }
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {docLoading ? (
            <div className={'upload-ctr'}>
              <div className={'loader'}>
                <Loader color={'#002C9D'} size={'38px'} />
              </div>
            </div>
          ) : (
            <UploadDocument
              image1={selfieUrl}
              isFace={true}
              isEditable={isEditable}
              personId={props.personId}
              getUrl={(url, index) => getSelfieUrl(url)}
              handleCancel={() => {
                setIsDocDisabled(true);
                setSelfieUrl('');
                props.invalidateFaceResponse();
                props.invalidateIdentityDocType();
                props.invalidateIdentityResponse();
                props.invalidateAadhaarOcrResponse();
                props.invalidateDLOcrResponse();
                props.invalidateVoterOcrResponse();
                props.invalidatePANOcrResponse();
              }}
            />
          )}
          <div className={'select-doctype-ctr'}>
            <div className={'label'}>{'Choose document type to verify Image'}</div>
            <div>
              <SelectInput
                defaultValue={docType}
                isDisabled={isDocDisabled}
                isSearchable={true}
                placeholder={'Choose a document type'}
                options={docTypeOptions}
                onSubmit={(value) => {
                  setDocType(value);
                }}
              />
            </div>
          </div>
          {docType ? (
            <div>
              <UploadDocument
                image1={documentUrl}
                isEditable={isEditable}
                personId={props.personId}
                getUrl={(url, index) => {
                  setDocumentUrl(url);
                  setIsDocChanged(true);
                }}
                handleCancel={() => {
                  setDocumentUrl('');
                  setDocError('');
                }}
              />
              {docError ? <div className={'upload-error'}>{docError}</div> : null}
            </div>
          ) : null}
          <DemoNotes showNotes={'document'} />
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={!selfieUrl || !documentUrl || docError}
                isLoading={isLoading}
                isWhiteBtn={!selfieUrl || !documentUrl || isLoading || docError}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={`v4/faceMatch`} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={responseData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
