const dashboardStatsKeys = {
  PEOPLE_ADDED: 'people_added',
  ID_SCANNED: 'id_scanned',
  GOV_VERIFIED: 'gov_verification',
  FACE_MATCH: 'face_match',
  PENDING: 'pending',
  REJECTED: 'rejected',
  OCR_DONE: 'verified',
  ADMINS: 'admins'
};

const docTypeKeys = {
  AADHAAR_CARD: 'ind_aadhaar',
  DRIVING_LICENSE: 'ind_driving_license',
  VOTER_ID: 'ind_voter_id',
  PAN_CARD: 'ind_pan',
  GST_CERTIFICATE: 'ind_gst_certificate',
  PASSPORT: 'ind_passport',
  VEHICLE_RC: 'ind_rc',
  BANK_ACCOUNT: 'bank_account',
  UPI_ID: 'upi_details'
};

const txTypeKeys = {
  AADHAAR_VERIFICATION: 'ind_aadhaar_verification',
  AADHAAR_OCR: 'ind_aadhaar_ocr',
  DL_VERIFICATION: 'ind_driving_license_verification',
  DL_OCR: 'ind_driving_license_ocr',
  VOTER_VERIFICATION: 'ind_voter_id_verification',
  VOTER_OCR: 'ind_voter_id_ocr',
  PAN_VERIFICATION: 'ind_pan_verification',
  PAN_OCR: 'ind_pan_ocr',
  GST_VERIFICATION: 'ind_gst_certificate_verification',
  GST_OCR: 'ind_gst_certificate_ocr',
  PASSPORT_VERIFICATION: 'ind_passport_verification',
  PASSPORT_OCR: 'ind_passport_ocr',
  RC_VERIFICATION: 'ind_rc_verfication',
  RC_OCR: 'ind_rc_ocr',
  CHEQUE_OCR: 'ind_cheque_ocr',
  BANK_ACCOUNT: 'bank_account',
  UPI_ID: 'upi_details',
  COURT_CHECK: 'CourtCheck',
  AADHAAR_MASK: 'maskAadhaar'
};

const personStatusKeys = {
  SUCCESS: 'completed',
  PENDING: 'in_progress',
  FAILED: 'failed',
  FACE_MATCHED: 'face_match',
  GOVT_VERIFIED: 'gov_verification'
};

const keysMapping = {
  dashboardStatsKeys,
  docTypeKeys,
  txTypeKeys,
  personStatusKeys
};

export default keysMapping;
