import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Button from '../../../coreComponent/Button/index';
import Input from '../../../coreComponent/Input/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function BankAccount(props) {
  const [accountNo, setAccountNo] = useState('');
  const [name, setName] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [accountNoError, setAccountNoError] = useState('');
  const [nameError, setNameError] = useState('');
  const [ifscCodeError, setIfscCodeError] = useState('');
  const [successParameters, setSuccessParameters] = useState(['bank_account_number', 'ifsc_code']);
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, seReqData] = useState('');
  const [responseData, setResponseData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (props.uiActive === 'Bank') {
      loadData();
      if (props.bankResponse && !isEmpty(props.bankResponse)) {
        setResponseData(JSON.stringify(props.bankResponse));
        setIsEditable(false);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    let accNo = '';
    let personName = '';
    let ifsc = '';
    if (
      data &&
      data.documents &&
      data.documents.bank_account &&
      data.documents.bank_account.result &&
      !isEmpty(props.bankResponse)
    ) {
      accNo = data.documents.bank_account.result.bankAccount;
      personName = data.documents.bank_account.result.name;
      ifsc = data.documents.bank_account.result.ifsc;
      setAccountNo(accNo);
      setName(personName);
      setIfscCode(ifsc);
    }
    if (accNo && ifsc) {
      let requestData = {
        docType: 'bank_account',
        personId: props.personId,
        success_parameters: successParameters,
        manual_input: {
          bank_account_number: accNo,
          ifsc_code: ifsc
        }
      };
      if (personName) {
        requestData.name = personName;
      }
      setIsDisabled(false);
      setRequestHeaders('Query Params');
      seReqData(JSON.stringify(requestData));
    }
  };

  const getAccountNo = (accNo) => {
    if (accNo.length < 9) {
      setAccountNoError('Bank Account No must be of length 9-18 digits');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(accNo)) {
      setAccountNoError('Invalid Bank Account Number Format');
      setIsDisabled(true);
      return false;
    } else if (accNo.match('[A-Z]')) {
      setAccountNoError('Invalid Bank Account No');
      setIsDisabled(true);
      return false;
    } else {
      setAccountNoError('');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  const getIfscCode = (ifsc) => {
    if (ifsc.length < 11) {
      setIfscCodeError('IFSC Code must be of length 11');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(ifsc)) {
      setIfscCodeError('Invalid IFSC code format');
      setIsDisabled(true);
      return false;
    } else {
      setIfscCodeError('');
      return true;
    }
  };

  const bankDetailsValidation = (requestBody) => {
    props
      .bankDetailsValidation(requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(response));
        setSuccessMsg('Bank Account Check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResponseData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('name') && !name) {
      error = true;
      setNameError('Name is Required');
    }
    return error;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (
        !isSuccessParameterReqError() &&
        getAccountNo(accountNo) &&
        getIfscCode(ifscCode) &&
        !nameError
      ) {
        let requestBody = {
          docType: 'bank_account',
          personId: props.personId,
          success_parameters: successParameters,
          manual_input: {
            bank_account_number: accountNo,
            ifsc_code: ifscCode
          }
        };
        if (name) {
          requestBody['manual_input']['name'] = name;
        }
        setIsLoading(true);
        setIsEditable(false);
        setRequestHeaders('Query Params');
        seReqData(JSON.stringify(requestBody));
        bankDetailsValidation(requestBody);
      }
    }
  };

  const reset = () => {
    props.invalidateBankResponse();
    setAccountNo('');
    setName('');
    setIfscCode('');
    setAccountNoError('');
    setIfscCodeError('');
    setNameError('');
    setIsEditable(true);
    setIsLoading(false);
    setIsDisabled(true);
    setIsSubmitted(false);
    setRequestHeaders('');
    seReqData('');
    setResponseData('');
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    setSuccessParameters(['bank_account_number', 'ifsc_code']);
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Bank Account'}</div>
      <div className={'sub-heading'}>
        {'Verify bank account details of an individual from bona fide sources'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          <div className={'input-ctr'}>
            {/* Success Parameter Display */}
            <div className={'check-ctr'}>
              <div className={'check-heading'}>{'Success Parameter'}</div>
              <div className={'check-input'}>
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'bankNumber'}
                    label={'Account Number'}
                    disabled={true}
                    disabledWithGreyColor={true}
                    checked={true}
                  />
                </div>
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'ifscCode'}
                    label={'IFSC Code'}
                    disabled={true}
                    disabledWithGreyColor={true}
                    checked={true}
                  />
                </div>
              </div>
              <div className={'check-input'}>
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'name'}
                    label={'Name'}
                    disabled={false}
                    checked={successParameters.includes('name')}
                    onClick={() => {
                      setSuccessParameters(_.xor(successParameters, ['name']));
                      setNameError('');
                      setIsDisabled(false);
                      if (name) validateNameInput(name);
                    }}
                  />
                </div>
              </div>
            </div>
            <Input
              label={'Bank Account Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={18}
              value={accountNo}
              onBlur={(value) => {
                getAccountNo(value);
              }}
              onChange={(value) => {
                setAccountNoError('');
                setIsDisabled(!value || !ifscCode);
                setAccountNo(value.toUpperCase());
              }}
              placeholder={'Ex: 5820807890'}
              showClear={true}
              onClear={() => {
                setAccountNo('');
                setAccountNoError('');
                setIsDisabled(!accountNo || !ifscCode);
              }}
              error={accountNoError}
            />
            <Input
              label={'IFSC Code'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={11}
              value={ifscCode}
              onChange={(value) => {
                setIfscCodeError('');
                setIsDisabled(!value || !accountNo);
                setIfscCode(value.toUpperCase());
              }}
              placeholder={'Ex: XBIN0003333'}
              showClear={true}
              onClear={() => {
                setIfscCode('');
                setIfscCodeError('');
                setIsDisabled(!accountNo || !ifscCode);
              }}
              error={ifscCodeError}
            />
            <Input
              label={'Name'}
              disabled={!isEditable}
              type={'text'}
              value={name}
              onBlur={(value) => {
                if (value) {
                  validateNameInput(value);
                }
              }}
              onChange={(value) => {
                setNameError('');
                setIsDisabled(false);
                setName(value.toUpperCase().trim());
              }}
              placeholder={'Name'}
              showClear={true}
              onClear={() => {
                setName('');
                setNameError('');
                setIsDisabled(false);
              }}
              error={nameError}
            />
          </div>
          <DemoNotes showNotes={'detail'} />
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl method={'GET'} url={`v4/databaseCheck`} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={responseData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
