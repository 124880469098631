import { connect } from 'react-redux';
import { viewTokenKey as viewTokenKeyAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    testTokenKey: state.testTokenKey,
    liveTokenKey: state.liveTokenKey
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    viewTokenKeyAPI: (data) => dispatch(viewTokenKeyAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
