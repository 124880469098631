import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import Shimmer from '../../../../coreComponent/Shimmer';
import Keys from '../../../../utils/keysMapping';
import './Stats.scss';

export default function Stats(props) {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    loadStats();
  }, [props.liveMode]);

  useEffect(() => {
    if (props.stats && !isEmpty(props.stats)) {
      getStats(props.stats);
    }
  }, [props.stats]);

  const loadStats = () => {
    setLoading(true);
    props.getStatsAPI().then(() => {
      setLoading(false);
    });
  };

  const getStats = (data) => {
    let statsArray = [];
    for (let key in data) {
      switch (key) {
        case Keys.dashboardStatsKeys.PEOPLE_ADDED:
          statsArray.push({ title: 'People Added', value: data[key] });
          break;
        case Keys.dashboardStatsKeys.ID_SCANNED:
          statsArray.push({ title: 'ID’s scanned', value: data[key] });
          break;
        case Keys.dashboardStatsKeys.GOV_VERIFIED:
          statsArray.push({ title: 'Gov Verified', value: data[key] });
          break;
        case Keys.dashboardStatsKeys.FACE_MATCH:
          statsArray.push({ title: 'Face Matched', value: data[key] });
          break;
        case Keys.dashboardStatsKeys.PENDING:
          statsArray.push({ title: 'Pending', value: data[key] });
          break;
        case Keys.dashboardStatsKeys.REJECTED:
          statsArray.push({ title: 'Rejected', value: data[key] });
          break;
        case Keys.dashboardStatsKeys.OCR_DONE:
          statsArray.push({ title: 'OCR Done', value: data[key] });
          break;
        case Keys.dashboardStatsKeys.ADMINS:
          statsArray.push({ title: 'Admin', value: data[key] });
          break;
        default:
          break;
      }
    }
    setStats(statsArray);
  };

  return (
    <div className={'stats-ctr'}>
      {loading
        ? Array.apply(null, { length: 8 })
            .map(Number.call, Number)
            .map((data, index) => {
              return (
                <div key={index} className={'stats-item'}>
                  <div className={'stats-value'}>
                    <Shimmer width={126} height={42} />
                  </div>
                  <div className={'stats-title'}>
                    <Shimmer width={126} height={21} />
                  </div>
                </div>
              );
            })
        : stats &&
          stats.length &&
          stats.map((data, index) => {
            return (
              <div key={index} className={'stats-item'}>
                <div className={'stats-value'}>{data.value}</div>
                <div className={'stats-title'}>{data.title}</div>
              </div>
            );
          })}
    </div>
  );
}
