import React from 'react';

import Image from '../Image';
import './Checkbox.scss';

export default function Checkbox(props) {
  return (
    <div className={'checkbox-ctr'} data-testid='checkbox'>
      <input
        type='checkbox'
        className={'checkbox'}
        disabled={props.disabled}
        id={props.id}
        onClick={props.disabled ? null : props.onClick}
      />
      {props.label ? (
        props.large ? (
          <label htmlFor={props.id} className={'checkbox-label-large'}>
            {props.checked ? (
              <Image name={'checkBoxLarge.svg'} />
            ) : (
              <Image name={'unCheckBoxLarge.svg'} />
            )}
            <div className={`text ${props.disabled ? 'disabled' : ''}`}>{props.label}</div>
          </label>
        ) : (
          <label htmlFor={props.id || 'checkbox'} className={'checkbox-label'}>
            {props.checked ?
                ( props.disabledWithGreyColor ?
                 (<Image name={'checkedBoxGrey.svg'} />
                ) : (
                <Image name={'checkedBox.svg'} />
                  )) : (
                  <Image name={'unCheckedBox.svg'} />
            )}
            <div
              className={`text ${props.checked ? 'checked' : ''} ${props.disabled ? 'disabled' : ''} ${props.disabledWithGreyColor ? 'disabled-with-grey': ''}`}
            >
              {props.label}
            </div>
          </label>
        )
      ) : null}
    </div>
  );
}
Checkbox.defaultProps = {
  label: '',
  disabled: false,
  checked: false,
  onClick: null,
  large: false,
  id: 'checkbox',
  disabledWithGreyColor:false
};
