import { connect } from 'react-redux';
import { accountSetup as accountSetupAPI, logout } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountSetupAPI: (data, config) => dispatch(accountSetupAPI(data, config)),
    logout: () => dispatch(logout('direct', null, false, false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
