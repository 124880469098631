import { toast } from 'react-toastify';

import { getErrorMessage } from '../utils/utilities';
import AppHelper from '../coreComponent/AppHelper';

import {
  getCredits as getCreditsAPI,
  getPerson as getPersonAPI,
  uploadOnS3 as uploadOnS3API,
  addSelfie as addSelfieAPI,
  addDocument as addDocumentAPI,
  faceCheckQuality as faceCheckQualityAPI,
  compareSelfieAndDocument as compareSelfieAndDocumentAPI,
  govtVerificationWithOCR as govtVerificationWithOCRAPI,
  govtVerificationWithoutOCR as govtVerificationWithoutOCRAPI,
  bankDetailsValidation as bankDetailsValidationAPI,
  upiIdValidation as upiIdValidationAPI,
  courtCheck as courtCheckAPI,
  aadhaarMasking as aadhaarMaskingAPI,
  kraCheck as kraCheckAPI,
  downloadReports as downloadReportsAPI,
  downloadCourtCheckReport as downloadCourtCheckReportAPI,
  aadhaarXMLSendOTP as aadhaarXMLSendOTPAPI,
  aadhaarXMLVerifyOTP as aadhaarXMLVerifyOTPAPI,
  addDocumentOnlyForOCR as addDocumentOnlyForOCRAPI,
} from '../api/demoUI';

//demo-ui cache variables
export const DEMO_ACTIVE = 'DEMO_ACTIVE';
export const DEMO_CREDITS = 'DEMO_CREDITS';
export const DEMO_PERSON = 'DEMO_PERSON';
export const UPDATE_DEMO_PERSON = 'UPDATE_DEMO_PERSON';

export const INVALIDATE_FACE_RESPONSE = 'INVALIDATE_FACE_RESPONSE';
export const FACE_RESPONSE = 'FACE_RESPONSE';

export const INVALIDATE_IDENTITY_RESPONSE = 'INVALIDATE_IDENTITY_RESPONSE';
export const IDENTITY_RESPONSE = 'IDENTITY_RESPONSE';
export const INVALIDATE_IDENTITY_DOC_TYPE = 'INVALIDATE_IDENTITY_DOC_TYPE';
export const IDENTITY_DOC_TYPE = 'IDENTITY_DOC_TYPE';

export const INVALIDATE_AADHAAR_OCR_RESPONSE = 'INVALIDATE_AADHAAR_OCR_RESPONSE';
export const AADHAAR_OCR_RESPONSE = 'AADHAAR_OCR_RESPONSE';

export const INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE = 'INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE';
export const AADHAAR_ONLY_OCR_RESPONSE = 'AADHAAR_ONLY_OCR_RESPONSE';

export const INVALIDATE_AADHAAR_RESPONSE = 'INVALIDATE_AADHAAR_RESPONSE';
export const AADHAAR_RESPONSE = 'AADHAAR_RESPONSE';

export const INVALIDATE_DL_OCR_RESPONSE = 'INVALIDATE_DL_OCR_RESPONSE';
export const DL_OCR_RESPONSE = 'DL_OCR_RESPONSE';
export const INVALIDATE_DL_ONLY_OCR_RESPONSE = 'INVALIDATE_DL_ONLY_OCR_RESPONSE';
export const DL_ONLY_OCR_RESPONSE = 'DL_ONLY_OCR_RESPONSE';
export const INVALIDATE_DL_RESPONSE = 'INVALIDATE_DL_RESPONSE';
export const DL_RESPONSE = 'DL_RESPONSE';

export const INVALIDATE_VOTER_OCR_RESPONSE = 'INVALIDATE_VOTER_OCR_RESPONSE';
export const VOTER_OCR_RESPONSE = 'VOTER_OCR_RESPONSE';
export const INVALIDATE_VOTER_RESPONSE = 'INVALIDATE_VOTER_RESPONSE';
export const VOTER_RESPONSE = 'VOTER_RESPONSE';
export const INVALIDATE_VOTER_ONLY_OCR_RESPONSE = 'INVALIDATE_VOTER_ONLY_OCR_RESPONSE';
export const VOTER_ONLY_OCR_RESPONSE = 'VOTER_ONLY_OCR_RESPONSE';

export const INVALIDATE_PAN_OCR_RESPONSE = 'INVALIDATE_PAN_OCR_RESPONSE';
export const PAN_OCR_RESPONSE = 'PAN_OCR_RESPONSE';

export const INVALIDATE_PAN_ONLY_OCR_RESPONSE = 'INVALIDATE_PAN_ONLY_OCR_RESPONSE';
export const PAN_ONLY_OCR_RESPONSE = 'PAN_ONLY_OCR_RESPONSE';
export const INVALIDATE_PAN_RESPONSE = 'INVALIDATE_PAN_RESPONSE';
export const PAN_RESPONSE = 'PAN_RESPONSE';

export const INVALIDATE_GST_OCR_RESPONSE = 'INVALIDATE_GST_OCR_RESPONSE';
export const GST_OCR_RESPONSE = 'GST_OCR_RESPONSE';
export const INVALIDATE_GST_RESPONSE = 'INVALIDATE_GST_RESPONSE';
export const GST_RESPONSE = 'GST_RESPONSE';

export const INVALIDATE_PASSPORT_OCR_RESPONSE = 'INVALIDATE_PASSPORT_OCR_RESPONSE';
export const PASSPORT_OCR_RESPONSE = 'PASSPORT_OCR_RESPONSE';
export const INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE = 'INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE';
export const PASSPORT_ONLY_OCR_RESPONSE = 'PASSPORT_ONLY_OCR_RESPONSE';
export const INVALIDATE_PASSPORT_RESPONSE = 'INVALIDATE_PASSPORT_RESPONSE';
export const PASSPORT_RESPONSE = 'PASSPORT_RESPONSE';

export const INVALIDATE_BANK_RESPONSE = 'INVALIDATE_BANK_RESPONSE';
export const BANK_RESPONSE = 'BANK_RESPONSE';

export const INVALIDATE_UPI_RESPONSE = 'INVALIDATE_UPI_RESPONSE';
export const UPI_RESPONSE = 'UPI_RESPONSE';

export const INVALIDATE_COURT_CHECK_RESPONSE = 'INVALIDATE_COURT_CHECK_RESPONSE';
export const COURT_CHECK_RESPONSE = 'COURT_CHECK_RESPONSE';

export const INVALIDATE_COVID_CHECK_RESPONSE = 'INVALIDATE_COVID_CHECK_RESPONSE';
export const COVID_CHECK_RESPONSE = 'COVID_CHECK_RESPONSE';

export const INVALIDATE_AADHAAR_MASKING_RESPONSE = 'INVALIDATE_AADHAAR_MASKING_RESPONSE';
export const AADHAAR_MASKING_RESPONSE = 'AADHAAR_MASKING_RESPONSE';

export const INVALIDATE_COA_RESPONSE = 'INVALIDATE_COA_RESPONSE';
export const COA_RESPONSE = 'COA_RESPONSE';

export const INVALIDATE_CIN_DIN_RESPONSE = 'INVALIDATE_CIN_DIN_RESPONSE';
export const CIN_DIN_RESPONSE = 'CIN_DIN_RESPONSE';

export const INVALIDATE_LIN_RESPONSE = 'INVALIDATE_LIN_RESPONSE';
export const LIN_RESPONSE = 'LIN_RESPONSE';

export const INVALIDATE_RC_RESPONSE = 'INVALIDATE_RC_RESPONSE';
export const RC_RESPONSE = 'RC_RESPONSE';

export const INVALIDATE_KRA_RESPONSE = 'INVALIDATE_KRA_RESPONSE';
export const KRA_RESPONSE = 'KRA_RESPONSE';

export const INVALIDATE_AADHAARXML_RESPONSE = 'INVALIDATE_AADHAARXML_RESPONSE';
export const AADHAARXML_RESPONSE = 'AADHAARXML_RESPONSE';

export const INVALIDATE_UDYOG_RESPONSE = 'INVALIDATE_UDYOG_RESPONSE';
export const UDYOG_RESPONSE = 'UDYOG_RESPONSE';

export const INVALIDATE_UDYAM_RESPONSE = 'INVALIDATE_UDYAM_RESPONSE';
export const UDYAM_RESPONSE = 'UDYAM_RESPONSE';

//launch-ui cache variables
export const LAUNCH_ACTIVE = 'LAUNCH_ACTIVE';
export const LAUNCH_CREDITS = 'LAUNCH_CREDITS';
export const LAUNCH_PERSON = 'LAUNCH_PERSON';
export const UPDATE_LAUNCH_PERSON = 'UPDATE_LAUNCH_PERSON';

export const INVALIDATE_FACE_RESPONSE_LAUNCH = 'INVALIDATE_FACE_RESPONSE_LAUNCH';
export const FACE_RESPONSE_LAUNCH = 'FACE_RESPONSE_LAUNCH';

export const INVALIDATE_IDENTITY_RESPONSE_LAUNCH = 'INVALIDATE_IDENTITY_RESPONSE_LAUNCH';
export const IDENTITY_RESPONSE_LAUNCH = 'IDENTITY_RESPONSE_LAUNCH';
export const INVALIDATE_IDENTITY_DOC_TYPE_LAUNCH = 'INVALIDATE_IDENTITY_DOC_TYPE_LAUNCH';
export const IDENTITY_DOC_TYPE_LAUNCH = 'IDENTITY_DOC_TYPE_LAUNCH';

export const INVALIDATE_AADHAAR_OCR_RESPONSE_LAUNCH = 'INVALIDATE_AADHAAR_OCR_RESPONSE_LAUNCH';
export const AADHAAR_OCR_RESPONSE_LAUNCH = 'AADHAAR_OCR_RESPONSE_LAUNCH';

export const INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE_LAUNCH = 'INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE_LAUNCH';
export const AADHAAR_ONLY_OCR_RESPONSE_LAUNCH = 'AADHAAR_ONLY_OCR_RESPONSE_LAUNCH';

export const INVALIDATE_AADHAAR_RESPONSE_LAUNCH = 'INVALIDATE_AADHAAR_RESPONSE_LAUNCH';
export const AADHAAR_RESPONSE_LAUNCH = 'AADHAAR_RESPONSE_LAUNCH';

export const INVALIDATE_DL_OCR_RESPONSE_LAUNCH = 'INVALIDATE_DL_OCR_RESPONSE_LAUNCH';
export const DL_OCR_RESPONSE_LAUNCH = 'DL_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_DL_ONLY_OCR_RESPONSE_LAUNCH = 'INVALIDATE_DL_ONLY_OCR_RESPONSE_LAUNCH';
export const DL_ONLY_OCR_RESPONSE_LAUNCH = 'DL_ONLY_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_DL_RESPONSE_LAUNCH = 'INVALIDATE_DL_RESPONSE_LAUNCH';
export const DL_RESPONSE_LAUNCH = 'DL_RESPONSE_LAUNCH';

export const INVALIDATE_VOTER_OCR_RESPONSE_LAUNCH = 'INVALIDATE_VOTER_OCR_RESPONSE_LAUNCH';
export const VOTER_OCR_RESPONSE_LAUNCH = 'VOTER_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_VOTER_ONLY_OCR_RESPONSE_LAUNCH = 'INVALIDATE_VOTER_ONLY_OCR_RESPONSE_LAUNCH';
export const VOTER_ONLY_OCR_RESPONSE_LAUNCH = 'VOTER_ONLY_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_VOTER_RESPONSE_LAUNCH = 'INVALIDATE_VOTER_RESPONSE_LAUNCH';
export const VOTER_RESPONSE_LAUNCH = 'VOTER_RESPONSE_LAUNCH';

export const INVALIDATE_PAN_OCR_RESPONSE_LAUNCH = 'INVALIDATE_PAN_OCR_RESPONSE_LAUNCH';
export const PAN_OCR_RESPONSE_LAUNCH = 'PAN_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_PAN_ONLY_OCR_RESPONSE_LAUNCH = 'INVALIDATE_PAN_ONLY_OCR_RESPONSE_LAUNCH';
export const PAN_ONLY_OCR_RESPONSE_LAUNCH = 'PAN_ONLY_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_PAN_RESPONSE_LAUNCH = 'INVALIDATE_PAN_RESPONSE_LAUNCH';
export const PAN_RESPONSE_LAUNCH = 'PAN_RESPONSE_LAUNCH';

export const INVALIDATE_GST_OCR_RESPONSE_LAUNCH = 'INVALIDATE_GST_OCR_RESPONSE_LAUNCH';
export const GST_OCR_RESPONSE_LAUNCH = 'GST_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_GST_RESPONSE_LAUNCH = 'INVALIDATE_GST_RESPONSE_LAUNCH';
export const GST_RESPONSE_LAUNCH = 'GST_RESPONSE_LAUNCH';

export const INVALIDATE_PASSPORT_OCR_RESPONSE_LAUNCH = 'INVALIDATE_PASSPORT_OCR_RESPONSE_LAUNCH';
export const PASSPORT_OCR_RESPONSE_LAUNCH = 'PASSPORT_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE_LAUNCH = 'INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE_LAUNCH';
export const PASSPORT_ONLY_OCR_RESPONSE_LAUNCH = 'PASSPORT_ONLY_OCR_RESPONSE_LAUNCH';
export const INVALIDATE_PASSPORT_RESPONSE_LAUNCH = 'INVALIDATE_PASSPORT_RESPONSE_LAUNCH';
export const PASSPORT_RESPONSE_LAUNCH = 'PASSPORT_RESPONSE_LAUNCH';

export const INVALIDATE_BANK_RESPONSE_LAUNCH = 'INVALIDATE_BANK_RESPONSE_LAUNCH';
export const BANK_RESPONSE_LAUNCH = 'BANK_RESPONSE_LAUNCH';

export const INVALIDATE_UPI_RESPONSE_LAUNCH = 'INVALIDATE_UPI_RESPONSE_LAUNCH';
export const UPI_RESPONSE_LAUNCH = 'UPI_RESPONSE_LAUNCH';

export const INVALIDATE_COURT_CHECK_RESPONSE_LAUNCH = 'INVALIDATE_COURT_CHECK_RESPONSE_LAUNCH';
export const COURT_CHECK_RESPONSE_LAUNCH = 'COURT_CHECK_RESPONSE_LAUNCH';

export const INVALIDATE_COVID_CHECK_RESPONSE_LAUNCH = 'INVALIDATE_COVID_CHECK_RESPONSE_LAUNCH';
export const COVID_CHECK_RESPONSE_LAUNCH = 'COVID_CHECK_RESPONSE_LAUNCH';

export const INVALIDATE_AADHAAR_MASKING_RESPONSE_LAUNCH =
  'INVALIDATE_AADHAAR_MASKING_RESPONSE_LAUNCH';
export const AADHAAR_MASKING_RESPONSE_LAUNCH = 'AADHAAR_MASKING_RESPONSE_LAUNCH';

export const INVALIDATE_COA_RESPONSE_LAUNCH = 'INVALIDATE_COA_RESPONSE_LAUNCH';
export const COA_RESPONSE_LAUNCH = 'COA_RESPONSE_LAUNCH';

export const INVALIDATE_CIN_DIN_RESPONSE_LAUNCH = 'INVALIDATE_CIN_DIN_RESPONSE_LAUNCH';
export const CIN_DIN_RESPONSE_LAUNCH = 'CIN_DIN_RESPONSE_LAUNCH';

export const INVALIDATE_LIN_RESPONSE_LAUNCH = 'INVALIDATE_LIN_RESPONSE_LAUNCH';
export const LIN_RESPONSE_LAUNCH = 'LIN_RESPONSE_LAUNCH';

export const INVALIDATE_RC_RESPONSE_LAUNCH = 'INVALIDATE_RC_RESPONSE_LAUNCH';
export const RC_RESPONSE_LAUNCH = 'RC_RESPONSE_LAUNCH';

export const INVALIDATE_KRA_RESPONSE_LAUNCH = 'INVALIDATE_KRA_RESPONSE_LAUNCH';
export const KRA_RESPONSE_LAUNCH = 'KRA_RESPONSE_LAUNCH';

export const INVALIDATE_AADHAARXML_RESPONSE_LAUNCH = 'INVALIDATE_AADHAARXML_RESPONSE_LAUNCH';
export const AADHAARXML_RESPONSE_LAUNCH = 'AADHAARXML_RESPONSE_LAUNCH';

export const INVALIDATE_UDYOG_RESPONSE_LAUNCH = 'INVALIDATE_UDYOG_RESPONSE_LAUNCH';
export const UDYOG_RESPONSE_LAUNCH = 'UDYOG_RESPONSE_LAUNCH';

export const INVALIDATE_UDYAM_RESPONSE_LAUNCH = 'INVALIDATE_UDYAM_RESPONSE_LAUNCH';
export const UDYAM_RESPONSE_LAUNCH = 'UDYAM_RESPONSE_LAUNCH';

export const setUIActive = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: LAUNCH_ACTIVE,
        data
      }
    : {
        type: DEMO_ACTIVE,
        data
      };
};

export const setUICredits = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: LAUNCH_CREDITS,
        data
      }
    : {
        type: DEMO_CREDITS,
        data
      };
};

export const setUIPerson = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: LAUNCH_PERSON,
        data
      }
    : {
        type: DEMO_PERSON,
        data
      };
};

export const updateUIPerson = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: UPDATE_LAUNCH_PERSON,
        data
      }
    : {
        type: UPDATE_DEMO_PERSON,
        data
      };
};

export const invalidateFaceResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_FACE_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_FACE_RESPONSE
      };
};

export const setFaceResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: FACE_RESPONSE_LAUNCH,
        data
      }
    : {
        type: FACE_RESPONSE,
        data
      };
};

export const invalidateIdentityResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_IDENTITY_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_IDENTITY_RESPONSE
      };
};

export const setIdentityResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: IDENTITY_RESPONSE_LAUNCH,
        data
      }
    : {
        type: IDENTITY_RESPONSE,
        data
      };
};

export const invalidateIdentityDocType = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_IDENTITY_DOC_TYPE_LAUNCH
      }
    : {
        type: INVALIDATE_IDENTITY_DOC_TYPE
      };
};

export const setIdentityDocType = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: IDENTITY_DOC_TYPE_LAUNCH,
        data
      }
    : {
        type: IDENTITY_DOC_TYPE,
        data
      };
};

export const invalidateAadhaarOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_AADHAAR_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_AADHAAR_OCR_RESPONSE
      };
};

export const invalidateAadhaarOnlyOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE
      };
};

export const setAadhaarOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: AADHAAR_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: AADHAAR_OCR_RESPONSE,
        data
      };
};

export const setAadhaarOnlyOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: AADHAAR_ONLY_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: AADHAAR_ONLY_OCR_RESPONSE,
        data
      };
};


export const invalidateAadhaarResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_AADHAAR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_AADHAAR_RESPONSE
      };
};

export const setAadhaarResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: AADHAAR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: AADHAAR_RESPONSE,
        data
      };
};

export const invalidateDLOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_DL_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_DL_OCR_RESPONSE
      };
};

export const invalidateDLOnlyOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_DL_ONLY_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_DL_ONLY_OCR_RESPONSE
      };
};

export const setDLOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: DL_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: DL_OCR_RESPONSE,
        data
      };
};

export const setDLOnlyOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: DL_ONLY_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: DL_ONLY_OCR_RESPONSE,
        data
      };
};

export const invalidateDLResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_DL_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_DL_RESPONSE
      };
};

export const setDLResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: DL_RESPONSE_LAUNCH,
        data
      }
    : {
        type: DL_RESPONSE,
        data
      };
};

export const invalidateVoterOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_VOTER_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_VOTER_OCR_RESPONSE
      };
};

export const setVoterOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: VOTER_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: VOTER_OCR_RESPONSE,
        data
      };
};
export const invalidateVoterOnlyOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_VOTER_ONLY_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_VOTER_ONLY_OCR_RESPONSE
      };
};

export const setVoterOnlyOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: VOTER_ONLY_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: VOTER_ONLY_OCR_RESPONSE,
        data
      };
};

export const invalidateVoterResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_VOTER_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_VOTER_RESPONSE
      };
};

export const setVoterResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: VOTER_RESPONSE_LAUNCH,
        data
      }
    : {
        type: VOTER_RESPONSE,
        data
      };
};

export const invalidatePANOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_PAN_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_PAN_OCR_RESPONSE
      };
};
export const invalidatePANOnlyOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_PAN_ONLY_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_PAN_ONLY_OCR_RESPONSE
      };
};

export const setPANOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: PAN_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: PAN_OCR_RESPONSE,
        data
      };
};
export const setPANOnlyOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: PAN_ONLY_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: PAN_ONLY_OCR_RESPONSE,
        data
      };
};

export const invalidatePANResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_PAN_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_PAN_RESPONSE
      };
};

export const setPANResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: PAN_RESPONSE_LAUNCH,
        data
      }
    : {
        type: PAN_RESPONSE,
        data
      };
};

export const invalidateGSTOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_GST_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_GST_OCR_RESPONSE
      };
};

export const setGSTOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: GST_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: GST_OCR_RESPONSE,
        data
      };
};

export const invalidateGSTResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_GST_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_GST_RESPONSE
      };
};

export const setGSTResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: GST_RESPONSE_LAUNCH,
        data
      }
    : {
        type: GST_RESPONSE,
        data
      };
};

export const invalidatePassportResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_PASSPORT_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_PASSPORT_RESPONSE
      };
};

export const setPassportResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: PASSPORT_RESPONSE_LAUNCH,
        data
      }
    : {
        type: PASSPORT_RESPONSE,
        data
      };
};

export const invalidatePassportOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_PASSPORT_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_PASSPORT_OCR_RESPONSE
      };
};

export const setPassportOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: PASSPORT_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: PASSPORT_OCR_RESPONSE,
        data
      };
};
export const invalidatePassportOnlyOcrResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE
      };
};

export const setPassportOnlyOcrResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: PASSPORT_ONLY_OCR_RESPONSE_LAUNCH,
        data
      }
    : {
        type: PASSPORT_ONLY_OCR_RESPONSE,
        data
      };
};

export const invalidateBankResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_BANK_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_BANK_RESPONSE
      };
};

export const setBankResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: BANK_RESPONSE_LAUNCH,
        data
      }
    : {
        type: BANK_RESPONSE,
        data
      };
};

export const invalidateUPIResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_UPI_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_UPI_RESPONSE
      };
};

export const setUPIResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: UPI_RESPONSE_LAUNCH,
        data
      }
    : {
        type: UPI_RESPONSE,
        data
      };
};

export const invalidateCourtCheckResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_COURT_CHECK_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_COURT_CHECK_RESPONSE
      };
};

export const setCourtCheckResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: COURT_CHECK_RESPONSE_LAUNCH,
        data
      }
    : {
        type: COURT_CHECK_RESPONSE,
        data
      };
};

export const invalidateCovidResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_COVID_CHECK_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_COVID_CHECK_RESPONSE
      };
};

export const setCovidResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: COVID_CHECK_RESPONSE_LAUNCH,
        data
      }
    : {
        type: COVID_CHECK_RESPONSE,
        data
      };
};

export const invalidateAadhaarMaskingResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_AADHAAR_MASKING_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_AADHAAR_MASKING_RESPONSE
      };
};

export const setAadhaarMaskingResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: AADHAAR_MASKING_RESPONSE_LAUNCH,
        data
      }
    : {
        type: AADHAAR_MASKING_RESPONSE,
        data
      };
};

export const invalidateCoaResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_COA_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_COA_RESPONSE
      };
};

export const setCoaResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: COA_RESPONSE_LAUNCH,
        data
      }
    : {
        type: COA_RESPONSE,
        data
      };
};

export const invalidateCINDINResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_CIN_DIN_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_CIN_DIN_RESPONSE
      };
};

export const setCINDINResponse = (data) => {
  return AppHelper.isLaunchUI()
  ? {
    type: CIN_DIN_RESPONSE_LAUNCH,
    data
  }
  : {
    type: CIN_DIN_RESPONSE,
    data
  };
};

export const invalidateLinResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_LIN_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_LIN_RESPONSE
      };
};
export const setLinResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: LIN_RESPONSE_LAUNCH,
        data
      }
    : {
        type: LIN_RESPONSE,
        data
      };
};


export const invalidateUdyogResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_UDYOG_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_UDYOG_RESPONSE
      };
};
export const setUdyogResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: UDYOG_RESPONSE_LAUNCH,
        data
      }
    : {
        type: UDYOG_RESPONSE,
        data
      };
};

export const invalidateUdyamResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_UDYAM_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_UDYAM_RESPONSE
      };
};
export const setUdyamResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: UDYAM_RESPONSE_LAUNCH,
        data
      }
    : {
        type: UDYAM_RESPONSE,
        data
      };
};

export const invalidateKRAResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_KRA_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_KRA_RESPONSE
      };
};

export const setKRAResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: KRA_RESPONSE_LAUNCH,
        data
      }
    : {
        type: KRA_RESPONSE,
        data
      };
};

export const invalidateRCResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_RC_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_RC_RESPONSE
      };
};

export const setRCResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: RC_RESPONSE_LAUNCH,
        data
      }
    : {
        type: RC_RESPONSE,
        data
      };
};

export const invalidateAadhaarXMLResponse = () => {
  return AppHelper.isLaunchUI()
    ? {
        type: INVALIDATE_AADHAARXML_RESPONSE_LAUNCH
      }
    : {
        type: INVALIDATE_AADHAARXML_RESPONSE
      };
};

export const setAadhaarXMLResponse = (data) => {
  return AppHelper.isLaunchUI()
    ? {
        type: AADHAARXML_RESPONSE_LAUNCH,
        data
      }
    : {
        type: AADHAARXML_RESPONSE,
        data
      };
};

export const getCredits = () => (dispatch) => {
  return getCreditsAPI()
    .then((response) => {
      dispatch(setUICredits(response.data && response.data.credits));
      return response.data && response.data.credits;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const getPerson = () => (dispatch) => {
  return getPersonAPI()
    .then((response) => {
      dispatch(setUIPerson(response.data && response.data.person));
      return response.data.person;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const uploadOnS3 = (data) => (dispatch) => {
  return uploadOnS3API(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const addSelfie = (data) => (dispatch) => {
  return addSelfieAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const addDocument = (data) => (dispatch) => {
  return addDocumentAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const faceCheckQuality = (data) => (dispatch) => {
  return faceCheckQualityAPI(data)
    .then((response) => {
      dispatch(setFaceResponse(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const compareSelfieAndDocument = (docType, data) => (dispatch) => {
  return compareSelfieAndDocumentAPI(data)
    .then((response) => {
      dispatch(setIdentityResponse(response.data));
      dispatch(setIdentityDocType(docType));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const govtVerificationWithOCR = (requestBody,docType) => (dispatch) => {
  return govtVerificationWithOCRAPI(requestBody)
    .then((response) => {
      switch (docType) {
        case 'ind_aadhaar':
          dispatch(setAadhaarOcrResponse(response.data));
          break;
        case 'ind_driving_license':
          dispatch(setDLOcrResponse(response.data));
          break;
        case 'ind_voter_id':
          dispatch(setVoterOcrResponse(response.data));
          break;
        case 'ind_pan':
          dispatch(setPANOcrResponse(response.data));
          break;
        case 'ind_gst_certificate':
          dispatch(setGSTOcrResponse(response.data));
          break;
        case 'ind_passport':
          dispatch(setPassportOcrResponse(response.data));
          break;
        default:
          break;
      }
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const govtVerificationWithoutOCR = (docType, data) => (dispatch) => {
  return govtVerificationWithoutOCRAPI(data)
    .then((response) => {
      switch (docType) {
        case 'ind_aadhaar':
          dispatch(setAadhaarResponse(response.data));
          break;
        case 'ind_driving_license':
          dispatch(setDLResponse(response.data));
          break;
        case 'ind_voter_id':
          dispatch(setVoterResponse(response.data));
          break;
        case 'ind_pan':
          dispatch(setPANResponse(response.data));
          break;
        case 'ind_gst_certificate':
          dispatch(setGSTResponse(response.data));
          break;
        case 'ind_coa':
          dispatch(setCoaResponse(response.data));
          break;
        case 'ind_rc':
          dispatch(setRCResponse(response.data));
          break;
        case 'ind_lin':
          dispatch(setLinResponse(response.data));
          break;
        case 'ind_passport':
          dispatch(setPassportResponse(response.data));
          break;
        case 'ind_mca':
          dispatch(setCINDINResponse(response.data));
          break;
        case 'ind_udyog_aadhaar':
          dispatch(setUdyogResponse(response.data));
          break;
        case 'ind_udyam':
          dispatch(setUdyamResponse(response.data));
          break;
        case 'upi_details':
          dispatch(setUPIResponse(response.data));
          break;
        case 'criminal_check':
          dispatch(setCourtCheckResponse(response.data));
          break;
        case 'ind_covid':
          dispatch(setCovidResponse(response.data));
          break;
        default:
          break;
      }
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const bankDetailsValidation = (data) => (dispatch) => {
  return bankDetailsValidationAPI(data)
    .then((response) => {
      dispatch(setBankResponse(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const aadhaarMasking = (data) => (dispatch) => {
  return aadhaarMaskingAPI(data)
    .then((response) => {
      dispatch(setAadhaarMaskingResponse(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const kraCheck = (data) => (dispatch) => {
  return kraCheckAPI(data)
    .then((response) => {
      dispatch(setKRAResponse(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const aadhaarXMLSendOTP = (data, otp) => (dispatch) => {
  return aadhaarXMLSendOTPAPI(data, otp)
    .then((response) => {
      dispatch(setAadhaarXMLResponse(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const aadhaarXMLVerifyOTP = (data) => (dispatch) => {
  return aadhaarXMLVerifyOTPAPI(data)
    .then((response) => {
      dispatch(setAadhaarXMLResponse(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const aadhaarXMLWithDigilocker = (data) => (dispatch) => {
  return govtVerificationWithOCRAPI(data)
    .then((response) => {
      dispatch(setAadhaarXMLResponse(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const downloadReports = (docType) => (dispatch) => {
  return downloadReportsAPI(docType)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const downloadCourtCheckReport = () => (dispatch) => {
  return downloadCourtCheckReportAPI()
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};



export const addDocumentOnlyForOCR = (docType,data) => (dispatch) => {
  return addDocumentOnlyForOCRAPI(data)
    .then((response) => {
      switch (docType) {
        case 'ind_aadhaar':
          dispatch(setAadhaarOnlyOcrResponse(response.data));
          break;
        case 'ind_driving_license':
          dispatch(setDLOnlyOcrResponse(response.data));
          break;
        case 'ind_voter_id':
          dispatch(setVoterOnlyOcrResponse(response.data));
          break;
        case 'ind_pan':
          dispatch(setPANOnlyOcrResponse(response.data));
          break;
        case 'ind_gst_certificate':
          dispatch(setGSTOnlyOcrResponse(response.data));
          break;
        case 'ind_passport':
          dispatch(setPassportOnlyOcrResponse(response.data));
          break;
        default:
          break;
      }
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};
