import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import Image from '../../../../coreComponent/Image';
import Table from '../../../../coreComponent/Table/index';
import DatePicker from '../../../../coreComponent/DatePicker/index';
import './PurchaseHistory.scss';

export default function PurchaseHistory(props) {
  const [tableRows, setTableRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState(true); // true->asec, false->desc
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });

  useEffect(() => {
    loadData();
  }, [props.liveMode]);

  useEffect(() => {
    if (props.purchaseHistory && !isEmpty(props.purchaseHistory)) {
      let rows = getRowData(props.purchaseHistory.data);
      setTableRows(rows);
      setTotalCount(props.purchaseHistory.total_count);
    }
  }, [props.purchaseHistory]);

  const headCells = [
    {
      id: 'dateTime',
      label: 'DATE TIME',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('dateTime');
      }
    },
    {
      id: 'credits',
      label: 'CREDITS PURCHASED',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('credits');
      }
    },
    {
      id: 'price',
      label: 'PRICE',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('price');
      }
    },
    {
      id: 'invoice',
      label: 'INVOICE'
    },
    {
      id: 'download',
      label: '',
      width: '76px',
      onHover: true
    }
  ];

  const loadData = () => {
    setPage(1);
    setRowsPerPage(10);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    props.getPurchaseHistoryAPI(data).then(() => {
      setLoading(false);
    });
  };

  //this is for each row the heading creation  with whatever id for headcells
  const createData = (dateTime, credits, price, invoice, download) => {
    return { dateTime, credits, price, invoice, download };
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value, key) {
      let rowData = createData(
        moment(value.createdAt).utcOffset('+05:30').format('DD-MM-YYYY HH:mm'),
        getCredits(value.credits),
        value.amount / 100,
        value.internal_order_id,
        value.invoice_url ? getDownloadIcon(value.invoice_url, value.internal_order_id) : ''
      );
      data.push(rowData);
    });
    return data;
  };

  const getCredits = (credits) => {
    return <div className={'credits'}>{credits}</div>;
  };

  const getDownloadIcon = (invoiceUrl, orderId) => {
    return (
      <div
        onClick={() => {
          downloadInvoice(invoiceUrl, orderId);
        }}
      >
        <Image name={'downloadIcon.svg'} />
      </div>
    );
  };

  const downloadInvoice = (invoiceUrl, orderId) => {
    axios({ url: invoiceUrl, method: 'GET', responseType: 'blob' }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${orderId}_invoice.pdf`);
      link.click();
    });
  };

  const applySorting = (key) => {
    setLoading(true);
    setPage(1);
    let data = {
      range: rowsPerPage,
      page: 1
    };
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    switch (key) {
      case 'dateTime': {
        setSortBy('createdAt');
        setSortOrder(!sortOrder);
        data.sort_by = 'createdAt';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'credits': {
        setSortBy('credits');
        setSortOrder(!sortOrder);
        data.sort_by = 'credits';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'price': {
        setSortBy('amount');
        setSortOrder(!sortOrder);
        data.sort_by = 'amount';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
    }
    props.getPurchaseHistoryAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setLoading(true);
    let data = {
      range: rowsPerPage,
      page: newPage
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    props.getPurchaseHistoryAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
    setLoading(true);
    let data = {
      range: parseInt(value, 10),
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    if (dateFilter.start && dateFilter.end) {
      data.start_date = dateFilter.start;
      data.end_date = dateFilter.end;
    }
    props.getPurchaseHistoryAPI(data).then(() => {
      setLoading(false);
    });
  };

  const applyDateFilter = (date) => {
    setDateFilter(date);
    setRowsPerPage(10);
    setPage(1);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (date && date.start && date.end) {
      setDateFilter(date);
      data.start_date = date.start;
      data.end_date = date.end;
    } else {
      setDateFilter({ start: '', end: '' });
    }
    props.getPurchaseHistoryAPI(data).then(() => {
      setLoading(false);
    });
  };

  return (
    <div className={'purchase-history-ctr'} data-testid='purchase-history-heading'>
      <div className={'heading-ctr'}>
        <div className={'heading'}>{'Purchase History'}</div>
        <div data-testid='purchaseHistory-datepicker'>
          <DatePicker
            emptyFilter={true}
            startDate={dateFilter.start}
            endDate={dateFilter.end}
            onClear={applyDateFilter}
            onSubmit={applyDateFilter}
          />
        </div>
      </div>
      <Table
        noDataText={'No Purchase History Found'}
        headCells={headCells}
        rowData={tableRows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(pageNo) => handleChangePage(pageNo)}
        handleChangeRowsPerPage={(rows) => handleChangeRowsPerPage(rows)}
        loading={loading}
      />
    </div>
  );
}
