import http from './http';
import storage from './localstorage';
import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';

let baseUrl = AppHelper.isLaunchUI() ? AppConstants.developmentAPIURL : AppConstants.testAPIURL;
const getPersonId = () => {
  if (
    (!AppHelper.isLaunchUI() &&
      (!storage.getDemoPersonId() ||
        storage.getDemoPersonId() === 'undefined' ||
        storage.getDemoPersonId() === 'null')) ||
    (AppHelper.isLaunchUI() &&
      (!storage.getLaunchPersonId() ||
        storage.getLaunchPersonId() === 'undefined' ||
        storage.getLaunchPersonId() === 'null'))
  ) {
    window.open(AppConstants.baseURL, '_self');
  }
  let personId = AppHelper.isLaunchUI() ? storage.getLaunchPersonId() : storage.getDemoPersonId();
  return personId;
};

const getHeaders = () => {
  if (
    (!AppHelper.isLaunchUI() &&
      (!storage.getDemoToken() ||
        storage.getDemoToken() === 'undefined' ||
        storage.getDemoToken() === 'null')) ||
    (AppHelper.isLaunchUI() &&
      (!storage.getLaunchToken() ||
        storage.getLaunchToken() === 'undefined' ||
        storage.getLaunchToken() === 'null'))
  ) {
    window.open(AppConstants.baseURL, '_self');
  }
  let headers = {
    'Content-Type': 'application/json',
    tokenKey: AppHelper.isLaunchUI() ? storage.getLaunchToken() : storage.getDemoToken()
  };
  return headers;
};

const getConfig = () => {
  let config = {
    headers: getHeaders()
  };
  return config;
};

const getAuthConfig = () => {
  if (
    !storage.getDevToken() ||
    storage.getDevToken() === 'undefined' ||
    storage.getDevToken() === 'null' ||
    !storage.getProdToken() ||
    storage.getProdToken() === 'undefined' ||
    storage.getProdToken() === 'null'
  ) {
    window.open(AppConstants.baseURL, '_self');
  }
  let authHeaders = {
    'Content-Type': 'application/json',
    token: AppHelper.isLaunchUI() ? AppHelper.getToken() : AppHelper.getDevToken(),
    Authorization: `Bearer ${
      AppHelper.isLaunchUI() ? storage.getProdToken() : storage.getDevToken()
    }`
  };
  let authConfig = {
    headers: authHeaders
  };
  return authConfig;
};

const noAuthHeaders = {
  'Content-Type': 'application/json',
  token: AppHelper.isLaunchUI() ? AppHelper.getToken() : AppHelper.getDevToken()
};
const noAuthConfig = {
  headers: noAuthHeaders
};

export const getCredits = () => {
  return http.get(`${baseUrl}credits/getCredits`, getAuthConfig());
};

export const getPerson = () => {
  return http.get(`${baseUrl}user/getPersonPublicApi/${getPersonId()}`, noAuthConfig);
};

export const uploadOnS3 = (data) => {
  const config = { ...getConfig() };
  config['headers']['Content-Type'] = 'multipart/form-data';
  return http.post(`${baseUrl}user/person/upload`, data, config);
};

export const addSelfie = (data) => {
  return http.post(`${baseUrl}user/person/${getPersonId()}/selfie`, data, getConfig());
};

export const addDocument = (data) => {
  return http.post(`${baseUrl}user/person/${getPersonId()}/document`, data, getConfig());
};

export const faceCheckQuality = (data) => {
  return http.post(`${baseUrl}v4/checkFaceQuality`, data, getConfig());
};

export const compareSelfieAndDocument = (data) => {
  return http.post(`${baseUrl}v4/faceMatch`, data, getConfig());
};

export const govtVerificationWithOCR = (requestBody) => {
  return http.post(`${baseUrl}v4/databaseCheck`, requestBody, getConfig());
};

export const govtVerificationWithoutOCR = (data) => {
  return http.post(`${baseUrl}v4/databaseCheck`, data, getConfig());
};

export const bankDetailsValidation = (data) => {
  return http.post(`${baseUrl}v4/databaseCheck`, data, getConfig());
};

export const aadhaarMasking = (data) => {
  return http.post(`${baseUrl}v4/databaseCheck`, data, getConfig());
};

export const kraCheck = (data) => {
  return http.post(`${baseUrl}v4/databaseCheck`, data, getConfig());
};

export const aadhaarXMLSendOTP = (data) => {
  return http.post(`${baseUrl}aadhaar/sendOTP/${getPersonId()}`, data, getConfig());
};

export const aadhaarXMLVerifyOTP = (data) => {
  return http.post(`${baseUrl}aadhaar/verifyAadhaar/`, data, getConfig());
};

export const downloadReports = (docType) => {
  return http.get(`${baseUrl}reports/report/${getPersonId()}/${docType}`, noAuthConfig);
};

export const downloadCourtCheckReport = () => {
  return http.get(`${baseUrl}reports/courtCheck/${getPersonId()}`, noAuthConfig);
};

export const addDocumentOnlyForOCR = (data) => {
  return http.post(`${baseUrl}v4/ocr`, data, getConfig());
};

export const getDigiLockerTokenValidity = (personId) => {
  return http.get(`${baseUrl}v4/digilocker/getTokenValidity/${personId}`, getConfig());
};

export const saveDigiLockerToken = (data, token, isLaunchUI) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      tokenKey: token
    }
  };

  let baseURL = isLaunchUI ? AppConstants.developmentAPIURL : AppConstants.testAPIURL;
  return http.post(`${baseURL}v4/digilocker/saveToken`, data, config);
};
