import { connect } from 'react-redux';
import {
  aadhaarXMLSendOTP,
  aadhaarXMLVerifyOTP,
  invalidateAadhaarXMLResponse,
  updateUIPerson,
  aadhaarXMLWithDigilocker
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    aadhaarXMLResponse: AppHelper.isLaunchUI()
      ? state.aadhaarXMLResponseLaunch
      : state.aadhaarXMLResponse,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    aadhaarXMLSendOTP: (data) => dispatch(aadhaarXMLSendOTP(data)),
    aadhaarXMLVerifyOTP: (data) => dispatch(aadhaarXMLVerifyOTP(data)),
    aadhaarXMLWithDigilocker: (data) => dispatch(aadhaarXMLWithDigilocker(data)),
    invalidateAadhaarXMLResponse: () => dispatch(invalidateAadhaarXMLResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
