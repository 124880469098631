import { toast } from 'react-toastify';

import store from '../store';

import { getErrorMessage } from '../utils/utilities';

import storage from '../api/localstorage';
import {
  getDashboardState as getDashboardStateAPI,
  getTokenKeys as getTokenKeysAPI,
  generateTokenKey as generateTokenKeyAPI,
  viewTokenKey as viewTokenKeyAPI,
  disableTokenKey as disableTokenKeyAPI,
  getSettings as getSettingsAPI,
  updateSettings as updateSettingsAPI,
  getUserProfile as getUserProfileAPI,
  changePassword as changePasswordAPI,
  getAdmins as getAdminsAPI,
  addAdmin as addAdminAPI,
  editAdmin as editAdminAPI,
  deleteAdmin as deleteAdminAPI,
  getStats as getStatsAPI,
  getPeople as getPeopleAPI,
  getPerson as getPersonAPI,
  getAPILog as getAPILogAPI,
  getPurchaseHistory as getPurchaseHistoryAPI,
  getTransactions as getTransactionsAPI,
  createOrder as createOrderAPI,
  updateOrder as updateOrderAPI,
  generateInvoice as generateInvoiceAPI
} from '../api/dashboard';

export const DASHBOARD_STATE = 'DASHBOARD_STATE';
export const TEST_TOKEN_KEY = 'TEST_TOKEN_KEY';
export const LIVE_TOKEN_KEY = 'LIVE_TOKEN_KEY';
export const TOKEN_KEYS = 'TOKEN_KEYS';
export const SETTINGS = 'SETTINGS';
export const USER_PROFILE = 'USER_PROFILE';
export const ADMINS = 'ADMINS';
export const STATS = 'STATS';
export const PEOPLE = 'PEOPLE';
export const API_LOG = 'API_LOG';
export const PURCHASE_HISTORY = 'PURCHASE_HISTORY';
export const TRANSACTIONS = 'TRANSACTIONS';

export const setDashbaordState = (data) => {
  return {
    type: DASHBOARD_STATE,
    data
  };
};

export const setTestTokenKey = (data) => {
  return {
    type: TEST_TOKEN_KEY,
    data
  };
};

export const setLiveTokenKey = (data) => {
  return {
    type: LIVE_TOKEN_KEY,
    data
  };
};

export const setTokenKeys = (data) => {
  return {
    type: TOKEN_KEYS,
    data
  };
};

export const setSettings = (data) => {
  return {
    type: SETTINGS,
    data
  };
};

export const setUserProfile = (data) => {
  return {
    type: USER_PROFILE,
    data
  };
};

export const setAdmins = (data) => {
  return {
    type: ADMINS,
    data
  };
};

export const setStats = (data) => {
  return {
    type: STATS,
    data
  };
};

export const setPeople = (data) => {
  return {
    type: PEOPLE,
    data
  };
};

export const setAPILog = (data) => {
  return {
    type: API_LOG,
    data
  };
};

export const setPurchaseHistory = (data) => {
  return {
    type: PURCHASE_HISTORY,
    data
  };
};

export const setTransactions = (data) => {
  return {
    type: TRANSACTIONS,
    data
  };
};

export const getDashboardState = () => (dispatch) => {
  return getDashboardStateAPI()
    .then((response) => {
      storage.setDemoTokenId(response.data.demo_token_id);
      storage.setDemoToken(response.data.demo_token);
      storage.setDemoPersonId(response.data.demo_person_id);
      storage.setLaunchTokenId(
        response.data.launch_token_id
          ? response.data.launch_token_id
          : process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'acceptance'
          ? null
          : response.data.demo_token_id
      );
      storage.setLaunchToken(
        response.data.launch_token
          ? response.data.launch_token
          : process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'acceptance'
          ? null
          : response.data.demo_token
      );
      storage.setLaunchPersonId(
        response.data.launch_person_id
          ? response.data.launch_person_id
          : process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'acceptance'
          ? null
          : response.data.demo_person_id
      );
      dispatch(setDashbaordState(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getTokenKeys = (data) => (dispatch) => {
  return getTokenKeysAPI(data)
    .then((response) => {
      dispatch(setTokenKeys(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const generateTokenKey = (data) => (dispatch) => {
  return generateTokenKeyAPI(data)
    .then((response) => {
      toast.success('Token Key generated successfully.');
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const viewTokenKey = (data) => (dispatch) => {
  return viewTokenKeyAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const disableTokenKey = (data) => (dispatch) => {
  return disableTokenKeyAPI(data)
    .then((response) => {
      toast.success('Token Key disabled successfully.');
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getSettings = () => (dispatch) => {
  return getSettingsAPI()
    .then((response) => {
      dispatch(setSettings(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const updateSettings = (data) => (dispatch) => {
  return updateSettingsAPI(data)
    .then((response) => {
      dispatch(setSettings(data));
      toast.success('Settings updated successfully.');
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getUserProfile = () => (dispatch) => {
  return getUserProfileAPI()
    .then((response) => {
      dispatch(setUserProfile(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const changePassword = (data) => (dispatch) => {
  return changePasswordAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getAdmins = (data) => (dispatch) => {
  return getAdminsAPI(data)
    .then((response) => {
      dispatch(setAdmins(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const addAdmin = (data) => (dispatch) => {
  return addAdminAPI(data)
    .then((response) => {
      toast.success('Admin added successfully.');
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const editAdmin = (data) => (dispatch) => {
  return editAdminAPI(data)
    .then((response) => {
      if (data.admin_id === store.getState().userProfile.uuid) {
        dispatch(getUserProfile());
      }
      toast.success('Admin updated successfully.');
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const deleteAdmin = (data) => (dispatch) => {
  return deleteAdminAPI(data)
    .then((response) => {
      toast.success('Admin deleted successfully.');
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getStats = () => (dispatch) => {
  return getStatsAPI()
    .then((response) => {
      dispatch(setStats(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getPeople = (data) => (dispatch) => {
  return getPeopleAPI(data)
    .then((response) => {
      dispatch(setPeople(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getPerson = (personId) => (dispatch) => {
  return getPersonAPI(personId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getAPILog = (data) => (dispatch) => {
  return getAPILogAPI(data)
    .then((response) => {
      dispatch(setAPILog(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getPurchaseHistory = (data) => (dispatch) => {
  return getPurchaseHistoryAPI(data)
    .then((response) => {
      dispatch(setPurchaseHistory(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const getTransactions = (data) => (dispatch) => {
  return getTransactionsAPI(data)
    .then((response) => {
      dispatch(setTransactions(response.data));
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const createOrder = (data) => (dispatch) => {
  return createOrderAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const updateOrder = (data) => (dispatch) => {
  return updateOrderAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

export const generateInvoice = (data) => (dispatch) => {
  return generateInvoiceAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      throw error.response;
    });
};

const handleError = (err) => {
  const statusCode = err?.response?.status;
  const message = getErrorMessage(err);

  if (statusCode === 401) {
    toast.error(message, { toastId: message.replace(/ /g, '_') });
    return;
  }

  toast.error(message);
};
