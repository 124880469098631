import React, { useState, useEffect } from 'react';

import CreditsBanner from './CreditsBanner/index';
import Packages from './Packages/index';
import PurchaseHistory from './PurchaseHistory/index';
import ReviewOrder from './ReviewOrder/index';
import RechargeSuccessful from './RechargeSuccessful/index';
import './RechargeAccount.scss';

export default function RechargeAccount(props) {
  const [reviewOrder, setReviewOrder] = useState(false);
  const [rechargeSuccessful, setRechargeSuccessful] = useState(false);
  const [rechargeCredits, setRechargeCredits] = useState('');
  const [amount, setAmount] = useState('');
  const [discount, setDiscount] = useState('');
  const [date, setDate] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [invoiceUrl, setInvoiceUrl] = useState('');

  useEffect(() => {
    if (props.location.state) {
      if (props.location.state.show === 'reviewOrder') {
        setRechargeCredits(props.location.state.credits);
        setAmount(props.location.state.amount);
        setDiscount(props.location.state.discount);
        setReviewOrder(true);
      } else if (props.location.state.show === 'rechargeSuccessful') {
        setRechargeCredits(props.location.state.credits);
        setAmount(props.location.state.amount);
        setDate(props.location.state.date);
        setTransactionId(props.location.state.transactionId);
        setInvoiceUrl(props.location.state.invoiceUrl);
        setRechargeSuccessful(true);
      } else {
        setReviewOrder(false);
        setRechargeSuccessful(false);
      }
    }
  }, [props.location.state]);

  const getReviewOrder = (credits, amountRs, discountRs) => {
    props.history.push('/billing/recharge-account', {
      credits: credits,
      amount: amountRs,
      discount: discountRs,
      show: 'reviewOrder'
    });
  };

  const getRechargeSuccessful = (credits, amountRs, rechargeDate, id, url) => {
    props.history.push('/billing/recharge-account', {
      credits: credits,
      amount: amountRs,
      date: rechargeDate,
      transactionId: id,
      invoiceUrl: url,
      show: 'rechargeSuccessful'
    });
  };

  const rechargeAccount = () => {
    props.history.push('/billing/recharge-account', {});
  };

  return (
    <div className={'recharge-account-ctr'}>
      {rechargeSuccessful ? (
        <div>
          <div className={'bredcrumbs-ctr'}>
            <div className={'option'} onClick={rechargeAccount}>
              {'Recharge Account /'}
            </div>
            <div className={'active-option'}>{'Recharge Successful'}</div>
          </div>
          <div className={'heading'}>{'Recharge Account'}</div>
          <RechargeSuccessful
            rechargeCredits={rechargeCredits}
            amount={amount}
            date={date}
            transactionId={transactionId}
            invoiceUrl={invoiceUrl}
            {...props}
          />
        </div>
      ) : reviewOrder ? (
        <div>
          <div className={'bredcrumbs-ctr'}>
            <div className={'option'} onClick={rechargeAccount}>
              {'Recharge Account /'}
            </div>
            <div className={'active-option'}>{'Review Order'}</div>
          </div>
          <div className={'heading'}>{'Recharge Account'}</div>
          <ReviewOrder
            credits={rechargeCredits}
            amount={amount}
            discount={discount}
            onSuccess={getRechargeSuccessful}
          />
        </div>
      ) : (
        <div>
          <div className={'heading'}>{'Recharge Account'}</div>
          <CreditsBanner credits={props.credits} />
          <Packages onClick={getReviewOrder} />
          {props.liveMode ? <PurchaseHistory /> : null}
        </div>
      )}
    </div>
  );
}
RechargeAccount.defaultProps = {
  liveMode: true,
  credits: ''
};
