import { connect } from 'react-redux';
import {
  bankDetailsValidation,
  invalidateBankResponse,
  updateUIPerson
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    bankResponse: AppHelper.isLaunchUI() ? state.bankResponseLaunch : state.bankResponse,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bankDetailsValidation: (data) => dispatch(bankDetailsValidation(data)),
    invalidateBankResponse: () => dispatch(invalidateBankResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
