import { connect } from 'react-redux';
import {
  getSettings as getSettingsAPI,
  updateSettings as updateSettingsAPI
} from '../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    settings: state.settings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsAPI: () => dispatch(getSettingsAPI()),
    updateSettingsAPI: (data) => dispatch(updateSettingsAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
