import { connect } from 'react-redux';
import { editAdmin as editAdminAPI } from '../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editAdminAPI: (data) => dispatch(editAdminAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
