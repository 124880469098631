import React from 'react';

import Image from '../../coreComponent/Image';
import './Header.scss';

export default function Header(props) {
  return (
    <div className={'main-header-ctr'}>
      <div className={'logo-ctr'}>
        <Image name={'logo.svg'} />
        <div className={'text'}>{'SpringScan'}</div>
      </div>
    </div>
  );
}
