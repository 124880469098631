import { connect } from 'react-redux';
import {
  addSelfie,
  govtVerificationWithOCR,
  govtVerificationWithoutOCR,
  invalidateIdentityResponse,
  invalidatePANOcrResponse,
  invalidatePANResponse,
  invalidatePANOnlyOcrResponse,
  updateUIPerson,
  addDocumentOnlyForOCR
} from '../../../actions/demoUI';
import AppHelper from '../../../coreComponent/AppHelper';

const mapStateToProps = (state) => {
  return {
    uiPerson: AppHelper.isLaunchUI() ? state.launchPerson : state.demoPerson,
    panOcrResponse: AppHelper.isLaunchUI() ? state.panOcrResponseLaunch : state.panOcrResponse,
    panOnlyOcrResponse: AppHelper.isLaunchUI() ? state.panOnlyOcrResponseLaunch : state.panOnlyOcrResponse,
    panResponse: AppHelper.isLaunchUI() ? state.panResponseLaunch : state.panResponse,
    identityDocType: AppHelper.isLaunchUI() ? state.identityDocTypeLaunch : state.identityDocType,
    uiActive: AppHelper.isLaunchUI() ? state.launchActive : state.demoActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSelfie: (data) => dispatch(addSelfie(data)),
    govtVerificationWithOCR: (requestBody,docType) => dispatch(govtVerificationWithOCR(requestBody,docType)),
    govtVerificationWithoutOCR: (docType, data) =>
      dispatch(govtVerificationWithoutOCR(docType, data)),
    invalidateIdentityResponse: () => dispatch(invalidateIdentityResponse()),
    invalidatePANOcrResponse: () => dispatch(invalidatePANOcrResponse()),
    invalidatePANOnlyOcrResponse: () => dispatch(invalidatePANOnlyOcrResponse()),
    invalidatePANResponse: () => dispatch(invalidatePANResponse()),
    updateUIPerson: (data) => dispatch(updateUIPerson(data)),
    addDocumentOnlyForOCR:(docType,data)=>dispatch(addDocumentOnlyForOCR(docType,data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
