import React, { useState, useEffect } from 'react';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isEmpty } from 'lodash';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function CIN(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [cinNumber, setCinNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [idError, setIdError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['cin_number']);

  useEffect(() => {
    if (props.uiActive === 'CIN') {
      if (
        props.uiPerson.documents.ind_mca &&
        props.uiPerson.documents.ind_mca.result.hasOwnProperty('cin') &&
        !isEmpty(props.cinDinResponse)
      ) {
        setCinNumber(props.uiPerson.documents.ind_mca.result.cin);
        setReqData(buildRequestBody());
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
      }
      if (
        props.cinDinResponse &&
        !isEmpty(props.cinDinResponse) &&
        props.cinDinResponse.output.source.hasOwnProperty('cin_number')
      ) {
        loadData();
      }
    }
  }, []);

  const loadData = () => {
    setResData(JSON.stringify(props.cinDinResponse));
    setIsEditable(false);
    setIsSubmitted(true);
    setIsDisabled(false);
    setRequestHeaders('Content type: application/json');
    setCinNumber(props.cinDinResponse.output.source.cin_number);
    setReqData(buildRequestBody());
  };

  const buildRequestBody = () => {
    let requestBody = {
      doc_type: 'ind_mca',
      personId: props.personId,
      successParameters: successParameters,
      manual_input: {
        cin_number: cinNumber
      }
    };
    if (companyName) {
      requestBody['manual_input']['company_name'] = companyName;
    }
    return requestBody;
  };

  const reset = () => {
    props.invalidateCINDINResponse();
    setCinNumber('');
    setCompanyName('');
    setIdError('');
    setCompanyNameError('');
    setIsDisabled(true);
    setErrorMsg('');
    setSuccessMsg('');
    setIsSubmitted(false);
    setIsEditable(true);
    setResData('');
    setErrorCode('');
    setReqData('');
    setSuccessParameters(['cin_number']);
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('company_name') && !companyName) {
      error = true;
      setCompanyNameError('Company Name is Required');
    }
    return error ? true : false;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!isSuccessParameterReqError() && validateCINNo(cinNumber) && !companyNameError) {
        setIsLoading(true);
        setIsEditable(false);
        cinCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const cinCheck = () => {
    let requestBody = {
      docType: 'ind_mca',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        cin_number: cinNumber
      }
    };
    if (companyName) {
      requestBody['manual_input']['company_name'] = companyName;
    }
    setRequestHeaders('Content type: application/json');
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('ind_mca', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setErrorCode('');
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('CIN verification successful.');
        setErrorMsg('');
        setIdError('');
        setCompanyNameError('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setSuccessMsg('');
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };
  const validateCINNo = (cinNo) => {
    if (!cinNo) {
      setIdError('CIN Number is Required');
      setIsDisabled(true);
      return false;
    } else if (cinNo.length < 21) {
      setIdError('CIN number must contain 21 characters');
      setIsDisabled(true);
      return false;
    } else if (!cinNo.match('[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}')) {
      setIdError('Invalid CIN number');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      setIsDisabled(false);
      return true;
    }
  };

  const validateCompanyName = (companyName) => {
    if (!companyName) {
      setCompanyNameError('Establishment Name is Required');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(companyName)) {
      setCompanyNameError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else {
      setCompanyNameError('');
      return true;
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'CIN'}</div>
      <div className={'sub-heading'}>
        {'Check Corporate Identification Number under MCA database'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'cinNumber'}
                  label={'CIN Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'companyName'}
                  label={'Company Name'}
                  disabled={false}
                  checked={successParameters.includes('company_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['company_name']));
                    setIsDisabled(false);
                    setCompanyNameError('');
                    if (companyName) validateCompanyName(companyName);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'CIN Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={21}
              value={cinNumber}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIdError('');
                  setIsDisabled(true);
                }
                setCinNumber(value.toUpperCase());
              }}
              placeholder={'Ex: KA34562384270093AG5'}
              error={idError}
              showClear={true}
              onClear={() => {
                setCinNumber('');
                setIdError('');
                setIsDisabled(true);
              }}
            />
            <Input
              label={'Company Name'}
              disabled={!isEditable}
              type={'text'}
              maxLength={100}
              value={companyName}
              onBlur={(value) => {
                if (value) {
                  validateCompanyName(value);
                }
              }}
              onChange={(value) => {
                setIsDisabled(false);
                setCompanyNameError('');
                setCompanyName(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setCompanyName('');
                setCompanyNameError('');
                setIsDisabled(false);
              }}
              placeholder={'Company Name'}
              error={companyNameError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'ind_mca'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
