import React, { useState, useEffect } from 'react';

import storage from '../../api/localstorage';
import AppHelper from '../../coreComponent/AppHelper';
import Icon from '../../coreComponent/Icon';
import Loader from '../../coreComponent/Loader/index';
import Alert from '../../coreComponent/Alert/index';
import SideNavbar from './SideNavbar/index';
import Header from './Header/index';
import SelfieQualityDetection from './SelfieQualityDetection/index';
import IdentityVerification from './IdentityVerification/index';
import AadhaarCard from './AadhaarCard/index';
import DrivingLicense from './DrivingLicense/index';
import VoterID from './VoterID/index';
import PANCard from './PANCard/index';
import GSTCertification from './GSTCertification/index';
import PassportVerification from './PassportVerification/index';
import BankAccount from './BankAccount/index';
import UPIID from './UPIID/index';
import CourtRecordCheck from './CourtRecordCheck/index';
import AadhaarMasking from './AadhaarMasking/index';
import Coa from './CoA/index';
import RCCheck from './RCCheck/index';
import KRACheck from './KRA/index';
import CIN from './CIN/index';
import DIN from './DIN/index';
import LIN from './LIN/index';
import Udyog from './Udyog/index';
import Udyam from './Udyam/index';
import AadhaarXML from './AadhaarXML/index';
import './DemoUI.scss';
import CovidVaccine from './CovidVaccine';

export default function DemoUI(props) {
  const [credits, setCredits] = useState('');
  const [creditAlert, setCreditAlert] = useState('');
  const [active, setActive] = useState('Face');
  const [display, setDisplay] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [launchUI, setLaunchUI] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLaunchUI(AppHelper.isLaunchUI());
  }, []);

  useEffect(() => {
    loadCredits();
    if (!props.uiPerson) {
      setIsLoading(true);
    } else {
      setActive(props.uiActive);
    }
    loadData();
  }, []);

  const getCreditAlert = (msg) => {
    if (msg === 'zero') {
      setCreditAlert(
        `Your credit balance is zero ${
          launchUI
            ? ', please recharge and add credit to your account.'
            : ". Reach out to 'Get help' for credit update."
        }`
      );
    } else {
      setCreditAlert(
        `Your credit balance is insufficient to run this check ${
          launchUI
            ? ', please recharge and add credit to your account.'
            : ". Reach out to 'Get help' for credit update."
        }`
      );
    }
  };

  const loadCredits = () => {
    if (
      (!launchUI &&
        (!storage.getDemoToken() ||
          storage.getDemoToken() === 'undefined' ||
          storage.getDemoToken() === 'null' ||
          !storage.getDemoPersonId() ||
          storage.getDemoPersonId() === 'undefined' ||
          storage.getDemoPersonId() === 'null')) ||
      (launchUI &&
        (!storage.getLaunchToken() ||
          storage.getLaunchToken() === 'undefined' ||
          storage.getLaunchToken() === 'null' ||
          !storage.getLaunchPersonId() ||
          storage.getLaunchPersonId() === 'undefined' ||
          storage.getLaunchPersonId() === 'null'))
    ) {
      props.history.push('/dashboard');
    } else {
      props.getCredits().then((response) => {
        setCredits(response);
        if (response === 0) {
          getCreditAlert('zero');
        }
      });
    }
  };

  const loadData = () => {
    setIsLoading(true);
    if (
      (!launchUI &&
        (!storage.getDemoToken() ||
          storage.getDemoToken() === 'undefined' ||
          storage.getDemoToken() === 'null' ||
          !storage.getDemoPersonId() ||
          storage.getDemoPersonId() === 'undefined' ||
          storage.getDemoPersonId() === 'null')) ||
      (launchUI &&
        (!storage.getLaunchToken() ||
          storage.getLaunchToken() === 'undefined' ||
          storage.getLaunchToken() === 'null' ||
          !storage.getLaunchPersonId() ||
          storage.getLaunchPersonId() === 'undefined' ||
          storage.getLaunchPersonId() === 'null'))
    ) {
      props.history.push('/dashboard');
    } else {
      props
        .getPerson()
        .then((response) => {
          if (response) {
            setIsLoading(false);
            if (!props.uiActive) {
              props.setUIActive('Face');
              setActive('Face');
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const getMainContainer = () => {
    if (
      (!launchUI &&
        (!storage.getDemoToken() ||
          storage.getDemoToken() === 'undefined' ||
          storage.getDemoToken() === 'null' ||
          !storage.getDemoPersonId() ||
          storage.getDemoPersonId() === 'undefined' ||
          storage.getDemoPersonId() === 'null')) ||
      (launchUI &&
        (!storage.getLaunchToken() ||
          storage.getLaunchToken() === 'undefined' ||
          storage.getLaunchToken() === 'null' ||
          !storage.getLaunchPersonId() ||
          storage.getLaunchPersonId() === 'undefined' ||
          storage.getLaunchPersonId() === 'null'))
    ) {
      props.history.push('/dashboard');
    }
    switch (active) {
      case 'Face': {
        return (
          <SelfieQualityDetection
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Identity': {
        return (
          <IdentityVerification
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Aadhaar': {
        return (
          <AadhaarCard
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'DL': {
        return (
          <DrivingLicense
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Voter': {
        return (
          <VoterID
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'PAN': {
        return (
          <PANCard
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'GST': {
        return (
          <GSTCertification
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Passport': {
        return (
          <PassportVerification
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'KRA': {
        return (
          <KRACheck
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Bank': {
        return (
          <BankAccount
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'UPI': {
        return (
          <UPIID
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Court': {
        return (
          <CourtRecordCheck
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Masking': {
        return (
          <AadhaarMasking
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            launchUI={launchUI}
          />
        );
      }
      case 'Coa': {
        return (
          <Coa
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'RC': {
        return (
          <RCCheck
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'CIN': {
        return (
          <CIN
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'LIN': {
        return (
          <LIN
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'DIN': {
        return (
          <DIN
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'AadhaarXML': {
        return (
          <AadhaarXML
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'Udyog': {
        return (
          <Udyog
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'Udyam': {
        return (
          <Udyam
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      case 'Covid': {
        return (
          <CovidVaccine
            reloadCredits={loadCredits}
            credits={credits}
            showAlert={(msg) => getCreditAlert(msg)}
            personId={launchUI ? storage.getLaunchPersonId() : storage.getDemoPersonId()}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={'demo-ui-main-ctr'}>
      <SideNavbar
        active={active}
        changeActive={(activeTab) => {
          if (props.updateUIPersonStatus) {
            loadData();
          }
          setActive(activeTab);
          props.setUIActive(activeTab);
          props.updateUIPerson(false);
        }}
        companyName={props.loginData.company_name}
      />
      <div className={'demo-outer-ctr'}>
        <Header
          credits={credits}
          username={
            props.loginData.user_name &&
            props.loginData.user_name.first + ' ' + props.loginData.user_name.last
          }
          {...props}
        />
        {display ? (
          <div className={'notification-ctr'}>
            <div className={'text'}>
              {'You are in '}
              <b>{launchUI ? 'Live Mode' : 'Test Mode'}</b>
              {launchUI
                ? ', all checks will run in SpringScan Prod environment.'
                : ', So only test data is shown.'}
            </div>
            <div
              className={'close-icon'}
              onClick={() => {
                setDisplay(false);
              }}
            >
              <Icon icon={'CloseNotification'} color={'#002C9D'} width={'14'} height={'14'} />
            </div>
          </div>
        ) : (
          <div className={'notification-hide-ctr'} />
        )}
        {isLoading ? (
          <div className={'demo-loading-ctr'}>
            <Loader size={'120px'} color={'#666666'} width={'0.5rem'} />
          </div>
        ) : (
          getMainContainer()
        )}
        {creditAlert ? (
          <Alert
            message={creditAlert}
            onClose={() => {
              setCreditAlert('');
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
