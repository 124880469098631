import http from './http';
import storage from './localstorage';
import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';

let baseUrl = AppConstants.whatsAppAPIURL;
let khoslaUrl = AppConstants.khoslaAPIURL;
let devUrl = AppConstants.developmentAPIURL;

export const getDetails = () => {
  let headers = {
    'Content-Type': 'application/json',
    Authorization: storage.getWhatsappAadhaarToken()
  };
  let config = {
    headers: headers
  };
  return http.post(`${baseUrl}whatsapp/fetch`, {}, config);
};

export const storePhoneNumber = (data) => {
  let headers = {
    'Content-Type': 'application/json'
  };
  let config = {
    headers: headers
  };
  return http.post(`${baseUrl}whatsapp/saveNumber`, data, config);
};

export const cancelProcess = (data) => {
  let headers = {
    'Content-Type': 'application/json'
  };
  let config = {
    headers: headers
  };
  return http.post(`${baseUrl}whatsapp/cancel`, data, config);
};

export const khoslaInit = (data) => {
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    aadhaar_verification_token: storage.getWhatsappAadhaarToken(),
    token: AppHelper.getToken()
  };
  let config = {
    headers: headers
  };
  return http.post(`${devUrl}khosla/khoslaInit`, data, config);
};

export const khoslaFetch = (data) => {
  return http.post(`${khoslaUrl}_fetch`, data);
};

export const getResult = (data) => {
  let headers = {
    'Content-Type': 'application/json'
  };
  let config = {
    headers: headers
  };
  return http.post(`${baseUrl}whatsapp/updateState`, data, config);
};
