import axios from 'axios';
import { logout } from '../actions/authentication';
import store from '../store';
import { history } from '../component/Application/Application';
const loginEndpoints = ['/v3/auth/login', '/microsoft-login', '/google-login'];

const http = axios.create({
  headers: {
    Accept: 'application/json'
  },
  proxy: {
    port: 4444
  },
  maxContentLength: 1024 * 1024 * 20
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    forceLogout(error);
    return Promise.reject(error);
  }
);

const forceLogout = (err) => {
  const errResponse = err?.response;
  const url = new URL(errResponse?.config?.url);
  const path = url.pathname;

  if (errResponse?.status === 401 && !loginEndpoints.includes(path)) {
    try {
      store.dispatch(logout('direct', history, true));
    } catch (e) {
      console.log('e: ', e);
    }
  }
};

export default http;
