import {
  GET_JWT_AUTH,
  REQ_GOVT_VERIFY,
  REQ_SAVE_TOKEN,
  SAVE_DIGI_ERR,
  SAVE_DIGI_TOKEN,
  SAVE_GOVT_VERIFY,
  SAVE_GOVT_VERIFY_ERR,
  SAVE_JWT_AUTH,
  SAVE_JWT_ERR,
  SAVE_SEND_WEBHOOK_STATE,
  SENDING_WEBHOOK,
  REQ_TOKEN_VALIDITY,
  SAVE_TOKEN_VALIDITY,
  SAVE_TOKEN_VALIDITY_ERR
} from '../actions/digiLockerFlowActions';

export default (state, action) => {
  return {
    digiFlowAuth: saveDigiLockerAuth(state ? state?.digi?.digiFlowAuth : {}, action),
    saveDigiToken: saveDigiLockerToken(state ? state.digi?.saveDigiToken : {}, action),
    govtVerify: verifyGovt(state ? state?.digi?.govtVerify : {}, action),
    sendWebhookState: sendWebhook(state ? state?.digi?.sendWebhookState : {}, action),
    tokenValidity: getTokenValidity(state ? state?.digi?.tokenValidity : {}, action)
  };
};

const saveDigiLockerAuth = (state = {}, action) => {
  switch (action.type) {
    case GET_JWT_AUTH:
      return { loading: true };
    case SAVE_JWT_AUTH:
      return { ...action.data, loading: false };
    case SAVE_JWT_ERR:
      return { ...action.data, error: true, loading: false };
    default:
      return state;
  }
};

const saveDigiLockerToken = (state = {}, action) => {
  switch (action.type) {
    case REQ_SAVE_TOKEN:
      return { loading: true };
    case SAVE_DIGI_TOKEN:
      return { ...action.data, loading: false };
    case SAVE_DIGI_ERR:
      return { ...action.data, error: true, loading: false };
    default:
      return state;
  }
};

const verifyGovt = (state = {}, action) => {
  switch (action.type) {
    case REQ_GOVT_VERIFY:
      return { loading: true };
    case SAVE_GOVT_VERIFY:
      return { ...action.data, loading: false };
    case SAVE_GOVT_VERIFY_ERR:
      return { ...action.data, error: true, loading: false };
    default:
      return state;
  }
};

const sendWebhook = (state = {}, action) => {
  switch (action.type) {
    case SENDING_WEBHOOK:
      return { loading: true };
    case SAVE_SEND_WEBHOOK_STATE:
      return { ...action.data, loading: false };
    default:
      return state;
  }
};

const getTokenValidity = (state = {}, action) => {
  switch (action.type) {
    case REQ_TOKEN_VALIDITY:
      return { tvIsLoading: true };
    case SAVE_TOKEN_VALIDITY:
      return { ...action.data, tvIsLoading: false };
    case SAVE_TOKEN_VALIDITY_ERR:
      return { ...action.data, tvIsLoading: false };
    default:
      return state;
  }
};
