import React from 'react';

import Icon from '../Icon';
import './Alert.scss';

export default function Alert(props) {
  return (
    <div className={'alert-ctr'} data-testid='alert'>
      <div className={'text'}>{props.message}</div>
      <div className={'close-icon'} onClick={props.onClose}>
        <Icon icon={'CloseNotification'} color={'#002C9D'} width={'14'} height={'14'} />
      </div>
    </div>
  );
}

Alert.defaultProps = {
  message: '',
  onClose: null
};
