import React, { useState, useEffect } from 'react';
import { sha256 } from 'js-sha256';

import utils from '../../../utils/index';
import AppConstants from '../../../coreComponent/AppConstants';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import Header from '../../Header';
import queryString from 'query-string';

export default function Result(props) {
  const searchUrl = queryString.parse(window.location.search);
  const [failReason, setFailReason] = useState('');
  const [loading, setLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [clientCode] = useState('SPRI2175');
  const [apiKey] = useState(AppConstants.apiKey);
  const [salt] = useState(AppConstants.salt);
  const [uuid] = useState(searchUrl?.uuid);

  //not required, but still can be used to track khosla response
  // const [status, setStatus] = useState(searchUrl.status);
  // const [requestId, setRequestId] = useState(searchUrl['?requestId']);
  // const [hashFromKhosla, setHashFromKhosla] = useState(searchUrl.hash);
  // const [kycInfo, setKycInfo] = useState(null);

  useEffect(() => {
    if (props.location.state === 'expired') {
      setLoading(false);
      setIsExpired(true);
    } else {
      fetchDetails();
    }
  }, []);

  const fetchDetails = () => {
    let fetchHashDetails = sha256(clientCode + '|' + uuid + '|' + apiKey + '|' + salt);
    let data = {
      client_code: clientCode,
      uuid: uuid,
      api_key: apiKey,
      hash: fetchHashDetails
    };
    props
      .khoslaFetchAPI(data)
      .then((response) => {
        if (response.response_data) {
          let kycInfo = response.response_data.kyc_info;
          kycInfo = utils.decode_base64(kycInfo);
          kycInfo = utils.convertFromBinary(kycInfo);
          let phoneNumber = props.whatsappNewNumber;
          let aadhaarId = props.whatsappAadhaarDetails.aadhaarId;
          let aadhaarFail =
            kycInfo.document_id.substring(
              kycInfo.document_id.length - 4,
              kycInfo.document_id.length
            ) !== aadhaarId.substring(aadhaarId.length - 4, aadhaarId.length);
          let success = !aadhaarFail ? kycInfo.mobile === phoneNumber : false;
          callSuccessApi(kycInfo, success, aadhaarFail ? 'aadhaarFail' : 'phoneFail');
        } else {
          callSuccessApi(null, false, 'fail');
        }
      })
      .catch(() => {
        callSuccessApi(null, false, 'fail');
      });
  };

  const callSuccessApi = (kycInfo, success, fail) => {
    let successMessage = `Congratulations! Your Aadhaar verification process is completed successfully on the number *${props.whatsappNewNumber}*.`;
    let failureMessage =
      "Your Aadhaar Verification is unsuccessful as you've *NOT Completed* the process successfully. Please send *SpringScan* again to start the verification process.";
    let aadhaarFailureMessage =
      'Your Aadhaar Verification is unsuccessful due to *Aadhaar ID Mismatch*. Please send *SpringScan* again to start the verification process.';
    let phoneFailureMessage =
      'Your Aadhaar Verification is unsuccessful as you have *NOT* entered the *Registered Phone Number* with your Aadhaar. Please send *SpringScan* again to start the verification process.';
    let requestBody = {
      message: success
        ? successMessage
        : fail === 'aadhaarFail'
        ? aadhaarFailureMessage
        : fail === 'phoneFail'
        ? phoneFailureMessage
        : failureMessage,
      xml: kycInfo,
      phoneNumber: props.whatsappAadhaarDetails.phoneNumber
    };

    props
      .getResultAPI(requestBody)
      .then(() => {
        setLoading(false);
        setIsSuccess(success);
        setFailReason(fail);
      })
      .catch(() => {
        setLoading(false);
        setIsSuccess(false);
        setFailReason('fail');
      });
  };

  const handleSubmit = () => {
    window.open(AppConstants.whatsAppNumberAPI, '_self');
  };

  return (
    <div className={'whatsapp-aadhaar-verification-ctr'}>
      <Header />
      <div className={'main-ctr'}>
        <div className={'heading'}>{'SpringScan Verification Portal'}</div>
        {loading ? (
          <div className={'loader'}>
            <Loader size={'60px'} color={'#999999'} width={'0.4rem'} />
          </div>
        ) : (
          <div>
            <div className={isSuccess ? 'successMessage' : 'failureMessage'}>
              {isExpired ? 'Page Expired !!' : isSuccess ? 'Congratulations!!' : 'Failure!!'}
            </div>
            <div className={'sub-heading'}>
              {isExpired
                ? "This Page has been expired. Please click on 'Go to SpringScan WhatsApp' to reinitiate the process."
                : isSuccess
                ? "You have successfully completed the verification process. A PDF report will be sent to your SpringScan WhatsApp shortly. Please click on 'Go to SpringScan WhatsApp' to return back."
                : failReason === 'aadhaarFail'
                ? "Your Aadhaar Verification is unsuccessful due to 'Aadhaar ID Mismatch'. Please click on 'Go to SpringScan WhatsApp' to reinitiate the process."
                : failReason === 'phoneFail'
                ? "Your Aadhaar Verification is unsuccessful as you have not entered the 'Registered Phone Number' with your Aadhaar. Please click on 'Go to SpringScan WhatsApp' to reinitiate the process."
                : "Your Aadhaar Verification is unsuccessful as you've 'NOT Completed' the process successfully. Please click on 'Go to SpringScan WhatsApp' to reinitiate the process."}
            </div>
            <div className={'large-button-ctr'}>
              <Button text={'Go to SpringScan WhatsApp'} onClick={handleSubmit} height={'50px'} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
