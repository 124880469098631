import { connect } from 'react-redux';
import { changePassword as changePasswordAPI } from '../../../../actions/dashboard';
import { logout } from '../../../../actions/authentication';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePasswordAPI: (data) => dispatch(changePasswordAPI(data)),
    logout: (type, history) => dispatch(logout(type, history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
