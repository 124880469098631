import React, { useState, useEffect } from 'react';

import storage from '../../api/localstorage';
import AppConstants from '../../coreComponent/AppConstants';
import Loader from '../../coreComponent/Loader/index';
import Input from '../../coreComponent/Input/index';
import Button from '../../coreComponent/Button/index';
import Header from '../Header';
import './AadhaarVerification.scss';
import queryString from 'query-string';

export default function AadhaarVerification(props) {
  const [loading, setLoading] = useState(true);
  const [proceedLoading, setProceedLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    getDetails();
  }, []);

  const getDetails = () => {
    const searchUrl = queryString.parse(window.location.search);
    if (searchUrl) {
      storage.setAadhaarToken(searchUrl?.token);
      storage.setAadhaarPersonId(searchUrl?.personId);
      setPhoneNumber(searchUrl?.phoneNumber);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleProceed = () => {
    if (phoneNumber.length !== 10 || !phoneNumber.match('^[6-9][0-9]*')) {
      setPhoneError('Please enter a valid Phone Number');
    } else {
      setPhoneError('');
      setProceedLoading(true);
      storePhoneNumber();
    }
  };

  const storePhoneNumber = () => {
    let requestBody = {
      personId: storage.getAadhaarPersonId(),
      newPhoneNumber: phoneNumber
    };

    props
      .storePhoneNumberAPI(requestBody)
      .then(() => {
        callKhoslaInitAPI();
      })
      .catch((error) => {
        if (error && error.status === 404) {
          props.history.push('/aadhaar-verification/redirection', 'missing');
        } else {
          props.history.push('/aadhaar-verification/redirection', 'expired');
        }
      });
  };

  const callKhoslaInitAPI = () => {
    let requestBody = {
      personId: storage.getAadhaarPersonId(),
      redirect_url: AppConstants.developmentURL + 'aadhaar-verification/redirection',
      mobile: phoneNumber
    };

    props
      .khoslaInitAPI(requestBody)
      .then((response) => {
        window.open(response.url, '_self');
      })
      .catch((error) => {
        if (error && error.status === 404) {
          props.history.push('/aadhaar-verification/redirection', 'missing');
        } else {
          props.history.push('/aadhaar-verification/redirection', 'expired');
        }
      });
  };

  const handleCancel = () => {
    setCancelLoading(true);
    let requestBody = {
      personId: storage.getAadhaarPersonId()
    };

    props
      .cancelProcessAPI(requestBody)
      .then((response) => {
        window.open(response.url, '_self');
      })
      .catch((error) => {
        if (error && error.status === 404) {
          this.props.history.push('/aadhaar-verification/redirection', 'missing');
        } else {
          this.props.history.push('/aadhaar-verification/redirection', 'expired');
        }
      });
  };

  return (
    <div className={'aadhaar-verification-ctr'}>
      <Header />
      <div className={'main-ctr'}>
        <div className={'heading'}>{'SpringScan Verification Portal'}</div>
        {loading ? (
          <div className={'loader'}>
            <Loader size={'60px'} color={'#999999'} width={'0.4rem'} />
          </div>
        ) : (
          <div>
            <div className={'sub-heading'}>
              {
                "Welcome to SpringScan Aadhaar Verification Portal. Please click 'Proceed' to initiate the verification process."
              }
            </div>
            <Input
              label={'Phone Number'}
              type={'text'}
              maxLength={10}
              value={phoneNumber}
              onChange={(value) => {
                setPhoneError('');
                setPhoneNumber(value);
              }}
              placeholder={'Phone Number'}
              error={phoneError}
            />
            <div className={'button-ctr'}>
              <div className={'button'}>
                <Button
                  text={'Proceed'}
                  onClick={handleProceed}
                  height={'50px'}
                  isDisabled={cancelLoading || proceedLoading}
                  isLoading={proceedLoading}
                />
              </div>
              <div className={'button'}>
                <Button
                  text={'Cancel'}
                  onClick={handleCancel}
                  height={'50px'}
                  isDisabled={cancelLoading || proceedLoading}
                  isLoading={cancelLoading}
                  isWhiteBtn={true}
                />
              </div>
            </div>
            <div className={'sub-title'}>
              {
                '* The Phone Number you are providing must be linked with the Aadhaar Card which you are verifying.'
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
