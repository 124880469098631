import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validateEmail = (email) => {
  if (email.match('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)+$')) {
    return true;
  }
  return false;
};

export const validateName = (name) => {
  if (name.match(/^[^-.\s][a-zA-Z_.\s-]+$/)) {
    return true;
  }
  return false;
};

export const validateNumber = (number) => {
  if (number.match(/^[0-9]*$/)) {
    return true;
  }
  return false;
};

export const isSpecialCharacter = (value) => {
  if (value.match('.*[`~!@#$%^&*()-+={}|:;"\'<>,.?].*')) {
    return true;
  }
  return false;
};

export const emailDomainExtractor = (email) => {
  if (email) return email.split('@')[1].split('.')[0].toLowerCase();
  return '';
};

export const websiteDomainExtractor = (website) => {
  if (website)
    return website
      .replace('http://', '')
      .replace('https://', '')
      .replace('www.', '')
      .split(/[/?#@.]/)[0]
      .toLowerCase();
  return '';
};

export const validateDomain = (email, website) => {
  if (email && website) {
    return emailDomainExtractor(email) === websiteDomainExtractor(website);
  }
  return false;
};

/**
 * Validates phone number
 * case 1: if input given contains intl number(eg:+15417543010),checks for validity
 * case 2: if local string(eg:9892782982) is given parses the number with default code(i.e india) and checks validity
 * @param   {string} phoneNumber
 * @return  {boolean}
 */
export const validatesPhoneNumber = (phoneNumber) => {
  var numberObj = null;
  /** checks for valid regex. */
  if (!/^(\+)?[1-9][0-9]*$/i.test(phoneNumber)) {
    return false;
  }
  if (phoneNumber.length > 10) {
    //when number contains countrycode but not E164 number format
    if (!phoneNumber.startsWith('+')) {
      phoneNumber = `+${phoneNumber}`;
    }
    numberObj = parsePhoneNumberFromString(phoneNumber);
  } else {
    //adding 'IN' as code if country code is not present
    numberObj = parsePhoneNumberFromString(phoneNumber, 'IN');
  }
  if (numberObj === undefined || (numberObj && !numberObj.isValid())) {
    return false;
  }
  return true;
};

/**
 * Returns a number obj
 * case 1: if input given contains intl number(eg:+15417543010),checks for validity
 * case 2: if local string(eg:9892782982) is given parses the number with default code(i.e india) and checks validity
 * @param   {string} phoneNumber
 * @return  {object} intlphoneNumber
 */
export const getIntlPhoneNumber = (phoneNumber) => {
  var intlphoneNumber = null;
  if (phoneNumber.length > 10) {
    //when number contains countrycode but not E164 number format
    if (!phoneNumber.startsWith('+')) {
      phoneNumber = `+${phoneNumber}`;
    }
    intlphoneNumber = parsePhoneNumberFromString(phoneNumber);
  } else {
    //adding 'IN' as code if country code is not present
    intlphoneNumber = parsePhoneNumberFromString(phoneNumber, 'IN');
  }
  return intlphoneNumber;
};
