import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import Icon from '../Icon';
import './SearchInput.scss';

export default function SearchInput(props) {
  const [value, setValue] = useState('');
  const [active, setActive] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <div className={`search-input-ctr ${active || value ? 'search-input-active-ctr' : ''}`}>
      <Icon icon={'Search'} color={'#666666'} width={'24'} height={'24'} />
      <Form.Control
        className={'input'}
        placeholder={props.placeholder}
        value={value}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            props.handleSearch(value);
          }
        }}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
      {value && (
        <div
          data-testid='clear'
          className={'clear-btn'}
          onClick={() => {
            setValue('');
            props.handleClear();
          }}
        >
          {'Clear'}
        </div>
      )}
    </div>
  );
}
SearchInput.defaultProps = {
  handleSearch: null,
  handleClear: null,
  placeholder: 'Search..',
  value: ''
};
