import React, { useState, useEffect } from 'react';

import Icon from '../../coreComponent/Icon';
import Input from '../../coreComponent/Input/index';
import Button from '../../coreComponent/Button/index';
import PasswordValidation from '../../coreComponent/PasswordValidation';
import SideNavbar from '../SideNavbar';
import './SetPassword.scss';
import queryString from 'query-string';

export default function SetPassword(props) {
  const searchUrl = queryString.parse(props.location.search);
  const [title, setTitle] = useState('Set');
  const [uuid, setUuid] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [isSecure, setIsSecure] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    window && window.scrollTo(0, 0);
    props.logout();
    setUuid(searchUrl?.uuid);
    if (props.location.pathname.match('reset-password')) {
      setTitle('Reset');
    } else {
      setTitle('Set');
    }
  }, []);

  const validateConfirmPassword = (value, passwordChanged) => {
    if (
      (confirmPassword && passwordChanged && value !== confirmPassword) ||
      (!passwordChanged && value !== password)
    ) {
      setPasswordError('This password does not match. Try again.');
    } else {
      setPasswordError('');
    }
  };

  const savePassword = () => {
    setSubmitting(true);
    let requestData = {
      uuid: uuid,
      password: password
    };
    props
      .setPasswordAPI(requestData)
      .then((response) => {
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const login = () => {
    props.history.push('/login');
  };

  return (
    <div className={'set-password-main-ctr'}>
      <SideNavbar />
      <div className={'set-password-ctr'}>
        <div className={'set-password-form-ctr'}>
          {submitted ? (
            <div>
              <div className={'success-icon-ctr'}>
                <Icon icon={'Success'} color={'#65D782'} width={'40'} height={'42'} />
              </div>
              <div className={'heading'}>{'Success!'}</div>
              <div className={'sub-heading'}>{'Your password has been updated successfully'}</div>
              <div className={'save-btn'}>
                <Button text={'Return to Login'} onClick={login} width={'160px'} height={'50px'} />
              </div>
            </div>
          ) : (
            <div>
              <div className={'heading'}>
                {title}
                {' Password'}
              </div>
              <Input
                label={'New Password*'}
                disabled={submitting}
                type={'password'}
                id={'password'}
                value={password}
                onBlur={(value) => {
                  setValidatePassword(true);
                }}
                onChange={(value) => {
                  setPassword(value);
                  validateConfirmPassword(value, true);
                }}
                placeholder={'Enter New Password'}
              />
              <PasswordValidation
                value={password}
                invalidate={validatePassword}
                secured={isSecure && confirmPassword && !passwordError}
                isSecure={(secure) => {
                  setIsSecure(secure);
                }}
              />
              <Input
                label={'Confirm Password*'}
                disabled={submitting}
                type={'password'}
                id={'cpassword'}
                value={confirmPassword}
                onChange={(value) => {
                  setConfirmPassword(value);
                  validateConfirmPassword(value);
                }}
                placeholder={'Confirm Password'}
                error={passwordError}
                errorWithIcon={true}
              />
              <div className={'save-btn'}>
                <Button
                  text={'Save'}
                  onClick={savePassword}
                  width={'120px'}
                  height={'50px'}
                  isDisabled={!password || !confirmPassword || !isSecure || passwordError}
                  isLoading={submitting}
                  isWhiteBtn={
                    !password || !confirmPassword || !isSecure || passwordError || submitting
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
