import React, { useState, useEffect } from 'react';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isEmpty } from 'lodash';
import Checkbox from '../../../coreComponent/Checkbox';
import { isSpecialCharacter } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function DIN(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [dinNumber, setDinNumber] = useState('');
  const [directorName, setDirectorName] = useState('');
  const [directorNameError, setDirectorNameError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [idError, setIdError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['din_number']);

  useEffect(() => {
    if (props.uiActive === 'DIN') {
      if (
        props.uiPerson.documents.ind_mca &&
        props.uiPerson.documents.ind_mca.result.hasOwnProperty('din') &&
        !isEmpty(props.cinDinResponse)
      ) {
        setDinNumber(props.uiPerson.documents.ind_mca.result.din);
        setIsDisabled(false);
        setReqData(buildRequestBody());
        setRequestHeaders('Content type: application/json');
      }
      if (
        props.cinDinResponse &&
        !isEmpty(props.cinDinResponse) &&
        props.cinDinResponse.output.source.hasOwnProperty('din_number')
      ) {
        loadData();
      }
    }
  }, []);

  const loadData = () => {
    setResData(JSON.stringify(props.cinDinResponse));
    setIsEditable(false);
    setIsSubmitted(true);
    setIsDisabled(false);
    setRequestHeaders('Content type: application/json');
    setDinNumber(props.cinDinResponse.output.source.din_number);
    setReqData(buildRequestBody());
  };

  const buildRequestBody = () => {
    let requestBody = {
      doc_type: 'ind_mca',
      personId: props.personId,
      successParameters: successParameters,
      manual_input: {
        din_number: dinNumber
      }
    };
    if (directorName) {
      requestBody['manual_input']['director_name'] = directorName;
    }
    return requestBody;
  };

  const reset = () => {
    props.invalidateCINDINResponse();
    setDinNumber('');
    setIdError('');
    setDirectorName('');
    setDirectorNameError('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setResData('');
    setErrorCode('');
    setErrorMsg('');
    setReqData('');
    setSuccessMsg('');
    setSuccessParameters(['din_number']);
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('director_name') && !directorName) {
      error = true;
      setDirectorNameError('Director Name is Required');
    }
    return error ? true : false;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!isSuccessParameterReqError() && validateDINNo(dinNumber) && !directorNameError) {
        setIsLoading(true);
        setIsEditable(false);
        dinCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const dinCheck = () => {
    let requestBody = {
      docType: 'ind_mca',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        din_number: dinNumber
      }
    };
    if (directorName) {
      requestBody['manual_input']['director_name'] = directorName;
    }
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('ind_mca', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setErrorCode('');
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('DIN check successful.');
        setErrorMsg('');
        setErrorCode('');
        setDirectorNameError('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const validateDirectorName = (directorName) => {
    if (!directorName) {
      setDirectorNameError('Director Name is Required');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(directorName)) {
      setDirectorNameError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else {
      setDirectorNameError('');
      return true;
    }
  };
  const validateDINNo = (dinNo) => {
    if (!dinNo) {
      setIdError('DIN Number is Required');
      setIsDisabled(true);
      return false;
    } else if (dinNo.length < 8) {
      setIdError('DIN number must contain 8 characters');
      setIsDisabled(true);
      return false;
    } else if (!dinNo.match('[0-9]{8}')) {
      setIdError('Invalid DIN number');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      setDinNumber(dinNo);
      setIsDisabled(false);
      return true;
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'DIN'}</div>
      <div className={'sub-heading'}>
        {'Check Director Identification Number under MCA database'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'dinNumber'}
                  label={'DIN Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'directorName'}
                  label={'Director Name'}
                  disabled={false}
                  checked={successParameters.includes('director_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['director_name']));
                    setIsDisabled(false);
                    setDirectorNameError('');
                    if (directorName) validateDirectorName(directorName);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'DIN Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={8}
              value={dinNumber}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIdError('');
                  setIsDisabled(true);
                }
                setDinNumber(value.toUpperCase());
              }}
              placeholder={'Ex: 34904573'}
              error={idError}
              showClear={true}
              onClear={() => {
                setDinNumber('');
                setIdError('');
                setIsDisabled(true);
              }}
            />
            <Input
              label={'Director Name'}
              disabled={!isEditable}
              type={'text'}
              maxLength={100}
              value={directorName}
              onBlur={(value) => {
                if (value) {
                  validateDirectorName(value);
                }
              }}
              onChange={(value) => {
                setIsDisabled(false);
                setDirectorNameError('');
                setDirectorName(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setDirectorName('');
                setDirectorNameError('');
                setIsDisabled(false);
              }}
              placeholder={'Director Name'}
              error={directorNameError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>

          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'ind_mca'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
