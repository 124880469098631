import React, { useState, useEffect } from 'react';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import { isEmpty } from 'lodash';
import { isSpecialCharacter } from '../../../utils/validations';
import Checkbox from '../../../coreComponent/Checkbox';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function LIN(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [linNumber, setLinNumber] = useState('');
  const [establishmentName, setEstablishmentName] = useState('');
  const [stateName, setStateName] = useState('');
  const [stateNameError, setStateNameError] = useState('');
  const [establishmentNameError, setEstablishmentNameError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [idError, setIdError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successParameters, setSuccessParameters] = useState(['lin_number']);

  useEffect(() => {
    if (props.uiActive === 'LIN') {
      if (
        props.uiPerson.documents.ind_lin &&
        props.uiPerson.documents.ind_lin.result.hasOwnProperty('lin_number') &&
        !isEmpty(props.linResponse)
      ) {
        setLinNumber(props.uiPerson.documents.ind_lin.result.lin_number);
        setEstablishmentName(props.uiPerson.documents.ind_lin.result.establishment_name);
        setStateName(props.uiPerson.documents.ind_lin.result.state);
        setReqData(buildRequestBody());
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
      }
      if (
        props.linResponse &&
        !isEmpty(props.linResponse) &&
        props.linResponse.output.source.hasOwnProperty('lin_number')
      ) {
        loadData();
      }
    }
  }, []);

  const loadData = () => {
    setResData(JSON.stringify(props.linResponse));
    setIsEditable(false);
    setIsSubmitted(true);
    setIsDisabled(false);
    setRequestHeaders('Content type: application/json');
    setLinNumber(props.linResponse.output.source.lin_number);
    setEstablishmentName(props.linResponse.output.source.establishment_name);
    setReqData(buildRequestBody());
  };

  const buildRequestBody = () => {
    let requestBody = {
      doc_type: 'ind_lin',
      personId: props.personId,
      successParameters: successParameters,
      manual_input: {
        lin_number: linNumber,
        state: stateName
      }
    };
    if (establishmentName) {
      requestBody['manual_input']['establishment_name'] = establishmentName;
    }
    return requestBody;
  };

  const reset = () => {
    props.invalidateLinResponse();
    setLinNumber('');
    setEstablishmentName('');
    setIdError('');
    setEstablishmentNameError('');
    setStateNameError('');
    setStateName('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setResData('');
    setErrorCode('');
    setErrorMsg('');
    setReqData('');
    setSuccessMsg('');
    setSuccessParameters(['lin_number']);
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (
        validateEstablishmentName(establishmentName) &&
        validateLINNo(linNumber) &&
        validateStateName(stateName)
      ) {
        setIsLoading(true);
        setIsEditable(false);
        linCheck();
      } else {
        setIsDisabled(true);
      }
    }
  };

  const linCheck = () => {
    let requestBody = {
      docType: 'ind_lin',
      personId: props.personId,
      success_parameters: successParameters,
      manual_input: {
        lin_number: linNumber,
        establishment_name: establishmentName,
        state: stateName
      }
    };
    setRequestHeaders('Content type: application/json');
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithoutOCR('ind_lin', requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setErrorCode('');
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('LIN verification successful.');
        setErrorMsg('');
        setIdError('');
        setEstablishmentNameError('');
      })
      .catch((error) => {
        if (error?.status == 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setSuccessMsg('');
        setErrorCode(error?.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  const validateEstablishmentName = (establishmentName) => {
    if (!establishmentName) {
      setEstablishmentNameError('Establishment Name is Required');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(establishmentName)) {
      setEstablishmentNameError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else if (!establishmentName.match('[A-Z]')) {
      setEstablishmentNameError('Enter valid establishment name and should only letters');
      setIsDisabled(true);
      return false;
    } else {
      setEstablishmentNameError('');
      return true;
    }
  };
  const validateStateName = (stateName) => {
    if (!stateName) {
      setStateNameError('State Name is Required');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(stateName)) {
      setStateNameError('Special characters not allowed.');
      setIsDisabled(true);
      return false;
    } else if (!stateName.match('[A-Z]')) {
      setStateNameError('Enter valid state name and it should only letters');
      setIsDisabled(true);
      return false;
    } else {
      setStateNameError('');
      return true;
    }
  };

  const validateLINNo = (linNumber) => {
    if (!linNumber) {
      setIdError('LIN Number is Required');
      setIsDisabled(true);
      return false;
    } else if (linNumber.length < 11) {
      setIdError('LIN number must contain 11 characters');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(linNumber)) {
      setIdError('Special characters not allowed');
      setIsDisabled(true);
      return false;
    } else if (!linNumber.match('[0-9]{4}-[0-9]{4}-[0-9]{1}')) {
      setIdError('Invalid LIN number');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      return true;
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'LIN'}</div>
      <div className={'sub-heading'}>{'Check Labour Identification Number under MCA database'}</div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'linNumber'}
                  label={'LIN Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'establishmentName'}
                  label={'Establishment Name'}
                  disabled={false}
                  checked={successParameters.includes('establishment_name')}
                  onClick={() => {
                    setSuccessParameters(_.xor(successParameters, ['establishment_name']));
                  }}
                />
              </div>
            </div>
          </div>
          <div className={'input-ctr'}>
            <Input
              label={'LIN Number'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={21}
              value={linNumber}
              onChange={(value) => {
                if (value) {
                  setIsDisabled(false);
                  setIdError('');
                } else {
                  setIdError('LIN Number is required');
                  setIsDisabled(true);
                }
                setLinNumber(value.toUpperCase());
              }}
              placeholder={'Ex: 1-2238-9428-1'}
              error={idError}
              showClear={true}
              onClear={() => {
                setLinNumber('');
                setIdError('');
                setIsDisabled(true);
              }}
            />
            <Input
              label={'Establishment Name'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={100}
              value={establishmentName}
              onChange={(value) => {
                if (!value) {
                  setIsDisabled(true);
                  setEstablishmentNameError('Establishment Field is required');
                } else {
                  setIsDisabled(false);
                  setEstablishmentNameError('');
                }
                setEstablishmentName(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setEstablishmentName('');
                setEstablishmentNameError('');
                setIsDisabled(false);
              }}
              placeholder={''}
              error={establishmentNameError}
            />
            <Input
              label={'State'}
              disabled={!isEditable}
              type={'text'}
              isRequiredField={true}
              maxLength={100}
              value={stateName}
              onChange={(value) => {
                if (!value) {
                  setIsDisabled(true);
                  setStateNameError('State Field is required');
                } else {
                  setIsDisabled(false);
                  setStateNameError('');
                }
                setStateName(value.toUpperCase());
              }}
              showClear={true}
              onClear={() => {
                setStateName('');
                setStateNameError('');
                setIsDisabled(false);
              }}
              placeholder={''}
              error={stateNameError}
            />
            <DemoNotes showNotes={'detail'} />
          </div>
          <div className={'submit-btn'}>
            {isSubmitted ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isWhiteBtn={isDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={'ind_lin'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
