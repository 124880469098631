import { connect } from 'react-redux';
import { khoslaFetch as khoslaFetchAPI } from '../../../actions/aadhaarVerification';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    khoslaFetchAPI: (data) => dispatch(khoslaFetchAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
