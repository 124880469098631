import { toast } from 'react-toastify';

import { getErrorMessage } from '../utils/utilities';

import {
  storePhoneNumber as storePhoneNumberAPI,
  cancelProcess as cancelProcessAPI,
  khoslaInit as khoslaInitAPI,
  khoslaFetch as khoslaFetchAPI,
  fetchUser as fetchUserAPI
} from '../api/aadhaarVerification';

export const storePhoneNumber = (data) => (dispatch) => {
  return storePhoneNumberAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const cancelProcess = (data) => (dispatch) => {
  return cancelProcessAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const khoslaInit = (data) => (dispatch) => {
  return khoslaInitAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const khoslaFetch = (data) => (dispatch) => {
  return khoslaFetchAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const fetchUser = () => (dispatch) => {
  return fetchUserAPI()
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};
