import React, { useState, useEffect } from 'react';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';

import Image from '../../../coreComponent/Image';
import Tooltip from '../../../coreComponent/Tooltip/index';
import Table from '../../../coreComponent/Table/index';
import Button from '../../../coreComponent/Button/index';
import GenerateKeyModal from './GenerateKey/index';
import ConfirmPasswordModal from './ConfirmPassword/index';
import ViewKeyModal from './ViewKey/index';
import DisableKeyModal from './DisableKey/index';
import DisableTokenKeyModal from './DisableTokenKey/index';
import './TokenKey.scss';

export default function TokenKey(props) {
  const [tableRows, setTableRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState(true); // true->asec, false->desc
  const [tokenId, setTokenId] = useState('');
  const [tokenKey, setTokenKey] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [immediate, setImmediate] = useState(true);
  const [disable, setDisable] = useState(false);
  const [generateKeyModal, setGenerateKeyModal] = useState(false);
  const [confirmPasswordModal, setConfirmPasswordModal] = useState(false);
  const [viewKeyModal, setViewKeyModal] = useState(false);
  const [disableKeyModal, setDisableKeyModal] = useState(false);
  const [disableTokenKeyModal, setDisableTokenKeyModal] = useState(false);

  useEffect(() => {
    loadData();
  }, [props.liveMode]);

  useEffect(() => {
    if (props.tokenKeys && !isEmpty(props.tokenKeys)) {
      let rows = getRowData(props.tokenKeys.data);
      setTableRows(rows);
      setTotalCount(props.tokenKeys.total_count);
    }
  }, [props.tokenKeys]);

  const headCells = [
    {
      id: 'keyName',
      label: 'KEY NAME',
      sortingIcon: totalCount === 0 ? false : true,
      width: '150px',
      onClick: () => {
        applySorting('keyName');
      }
    },
    {
      id: 'createdAt',
      label: 'CREATED AT',
      width: '160px',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('createdAt');
      }
    },
    {
      id: 'expiredAt',
      label: 'EXPIRED AT',
      width: '160px',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('expiredAt');
      }
    },
    {
      id: 'status',
      label: 'STATUS',
      width: '150px',
      sortingIcon: totalCount === 0 ? false : true,
      onClick: () => {
        applySorting('status');
      }
    },
    {
      id: 'viewKey',
      label: 'TOKEN KEY',
      width: '75px'
    },
    {
      id: 'keyDisable',
      label: '',
      width: '76px',
      onHover: true
    }
  ];

  const loadData = () => {
    setPage(1);
    setRowsPerPage(10);
    setLoading(true);
    let data = {
      range: 10,
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    props.getTokenKeysAPI(data).then(() => {
      setLoading(false);
    });
  };

  //this is for each row the heading creation  with whatever id for headcells
  const createData = (keyName, createdAt, expiredAt, status, viewKey, keyDisable) => {
    return { keyName, createdAt, expiredAt, status, viewKey, keyDisable };
  };

  const getRowData = (values) => {
    let data = [];
    forEach(values, function (value, key) {
      let rowData = createData(
        value.key_name,
        moment(value.created_at).utcOffset('+05:30').format('DD-MM-YYYY HH:mm'),
        value.expired_at && value.status !== 'expiring_soon'
          ? moment(value.expired_at).utcOffset('+05:30').format('DD-MM-YYYY HH:mm')
          : '',
        value.status === 'expired'
          ? 'Expired'
          : value.status === 'expiring_soon'
          ? getExpiringSoonStatus(value.disabled_at)
          : 'Active',
        getTokenKey(value.token_id),
        value.status === 'active' &&
          value.token_id !== props.demoTokenId &&
          value.token_id !== props.launchTokenId
          ? getDisableIcon(value.token_id)
          : ''
      );
      data.push(rowData);
    });
    return data;
  };

  const getExpiringSoonStatus = (expiringTime) => {
    return (
      <div>
        <div className='tooltip-icon'>
          <div className={'status'}>{'Expiring Soon'}</div>
        </div>
        <Tooltip
          top={'-58px'}
          left={'-94px'}
          width={'262px'}
          text={`This key will expire on ${moment(expiringTime)
            .utcOffset('+05:30')
            .add(1, 'days')
            .format('DD-MM-YYYY HH:mm')}`}
        />
      </div>
    );
  };

  const getTokenKey = (id) => {
    return (
      <div
        data-testid='viewKey'
        className={'view-link'}
        onClick={() => {
          setTokenId(id);
          setDisable(false);
          setConfirmPasswordModal(true);
        }}
      >
        {'View'}
      </div>
    );
  };

  const getDisableIcon = (id) => {
    return (
      <div
        onClick={() => {
          setTokenId(id);
          setDisableKeyModal(true);
        }}
      >
        <Image name={'disableIcon.svg'} />
      </div>
    );
  };

  const applySorting = (key) => {
    setLoading(true);
    setPage(1);
    let data = {
      range: rowsPerPage,
      page: 1
    };
    switch (key) {
      case 'keyName': {
        setSortBy('name');
        setSortOrder(!sortOrder);
        data.sort_by = 'name';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'createdAt': {
        setSortBy('createdAt');
        setSortOrder(!sortOrder);
        data.sort_by = 'createdAt';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'expiredAt': {
        setSortBy('expiredAt');
        setSortOrder(!sortOrder);
        data.sort_by = 'expiredAt';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
      case 'status': {
        setSortBy('status');
        setSortOrder(!sortOrder);
        data.sort_by = 'status';
        data.order_by = sortOrder ? 'asec' : 'desc';
        break;
      }
    }
    props.getTokenKeysAPI(data).then(() => {
      setLoading(false);
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setLoading(true);
    let data = {
      range: rowsPerPage,
      page: newPage
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    props.getTokenKeysAPI(data).then(() => {
      setLoading(false);
    });
  };
  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(1);
    setLoading(true);
    let data = {
      range: parseInt(value, 10),
      page: 1
    };
    if (sortBy) {
      data.sort_by = sortBy;
      data.order_by = !sortOrder ? 'asec' : 'desc';
    }
    props.getTokenKeysAPI(data).then(() => {
      setLoading(false);
    });
  };

  const confirmPassword = (data) => {
    if (disable) {
      setPassword(data);
      setDisableTokenKeyModal(true);
    } else {
      setTokenKey(data.token);
      setViewKeyModal(true);
    }
  };

  const disableKey = (isImmediate) => {
    setImmediate(isImmediate);
    setDisable(true);
    setPassword('');
    setError('');
    setConfirmPasswordModal(true);
  };

  const disableTokenKey = (status) => {
    if (status === 'success') {
      setPassword('');
      setError('');
      loadData();
    } else {
      setError(status);
      setConfirmPasswordModal(true);
    }
  };

  return (
    <div className={'token-key-ctr'}>
      <GenerateKeyModal
        show={generateKeyModal}
        onHide={() => setGenerateKeyModal(false)}
        onSubmit={loadData}
      />
      <ConfirmPasswordModal
        tokenId={tokenId}
        password={password}
        errorStatus={error}
        disableKey={disable}
        show={confirmPasswordModal}
        onHide={() => setConfirmPasswordModal(false)}
        onSubmit={(data) => confirmPassword(data)}
      />
      <ViewKeyModal tokenKey={tokenKey} show={viewKeyModal} onHide={() => setViewKeyModal(false)} />
      <DisableKeyModal
        show={disableKeyModal}
        onHide={() => setDisableKeyModal(false)}
        onSubmit={(isImmediate) => disableKey(isImmediate)}
      />
      <DisableTokenKeyModal
        tokenId={tokenId}
        immediate={immediate}
        password={password}
        show={disableTokenKeyModal}
        onHide={() => setDisableTokenKeyModal(false)}
        onSubmit={(status) => disableTokenKey(status)}
      />
      <div className={'heading-ctr'}>
        <div className={'heading'}>{'Token Key'}</div>
        <div>
          <Button
            text={'Generate New Key'}
            onClick={() => {
              setGenerateKeyModal(true);
            }}
            width={'200px'}
            height={'50px'}
          />
        </div>
      </div>
      <Table
        headCells={headCells}
        rowData={tableRows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={(pageNo) => handleChangePage(pageNo)}
        handleChangeRowsPerPage={(rows) => handleChangeRowsPerPage(rows)}
        loading={loading}
      />
    </div>
  );
}
