import { toast } from 'react-toastify';

import { getErrorMessage } from '../utils/utilities';

import {
  getDetails as getDetailsAPI,
  storePhoneNumber as storePhoneNumberAPI,
  cancelProcess as cancelProcessAPI,
  khoslaInit as khoslaInitAPI,
  khoslaFetch as khoslaFetchAPI,
  getResult as getResultAPI
} from '../api/whatsappAadhaarVerification';

export const WHATSAPP_AADHAAR_DETAILS = 'WHATSAPP_AADHAAR_DETAILS';
export const WHATSAPP_NEW_NUMBER = 'WHATSAPP_NEW_NUMBER';

export const setWhatsappAadhaarDetails = (data) => {
  return {
    type: WHATSAPP_AADHAAR_DETAILS,
    data
  };
};

export const setWhatsappNewNumber = (data) => {
  return {
    type: WHATSAPP_NEW_NUMBER,
    data
  };
};

export const getDetails = () => (dispatch) => {
  return getDetailsAPI()
    .then((response) => {
      dispatch(setWhatsappAadhaarDetails(response.data));
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const storePhoneNumber = (data) => (dispatch) => {
  return storePhoneNumberAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const cancelProcess = (data) => (dispatch) => {
  return cancelProcessAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const khoslaInit = (data) => (dispatch) => {
  return khoslaInitAPI(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const khoslaFetch = (data) => (dispatch) => {
  return khoslaFetchAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};

export const getResult = (data) => (dispatch) => {
  return getResultAPI(data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(getErrorMessage(error));
      throw error.response;
    });
};
