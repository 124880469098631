import React from 'react';

import Image from '../../coreComponent/Image';
import './SideNavbar.scss';

export default function SideNavbar(props) {
  const getHelp = () => {
    window.open(
      'https://mail.google.com/mail/?view=cm&fs=1&to=ajith.ravindran@springworks.in',
      '_blank'
    );
  };

  return (
    <div className={'side-navbar-ctr'}>
      <div className={'header'}>
        <div className={'logo-ctr'}>
          <Image name={'logo.svg'} />
          <div className={'text'}>{'SpringScan'}</div>
        </div>
      </div>
      <div className={'footer'}>
        <Image name={'footerLogo.svg'} />
        <div className={'footer-link'} onClick={getHelp}>
          {'Having trouble? Get help'}
        </div>
      </div>
    </div>
  );
}
