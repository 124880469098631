import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Image from '../../coreComponent/Image';
import './RechargeInvoice.scss';

export default function RechargeInvoice(props) {
  const [credits, setCredits] = useState('');
  const [accountName, setAccountName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState(moment().format('DD MMMM YYYY'));
  const [transactionId, setTransactionId] = useState('');
  const [mode, setMode] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    window && window.scrollTo(0, 0);
    if (props && props.match && props.match.params && props.match.params.orderId) {
      let orderId = props.match.params.orderId;
      getDetails(orderId);
    } else {
      props.history.push('/dashboard');
    }
  }, []);

  const getDetails = (orderId) => {
    let data = {
      internalOrderId: orderId
    };
    props
      .getInvoiceDetailsAPI(data)
      .then((response) => {
        setCredits(response.credits);
        setAccountName(response.account_name);
        setEmail(response.email_id);
        setDate(moment(response.date).format('DD MMMM YYYY'));
        setTransactionId(response.order_id);
        setMode(response.transaction_mode.toUpperCase());
        setAmount(response.amount / 100);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={'invoice-container'} data-testid='invoice-container'>
      <Image name={'springscanLogo.svg'} />
      <div className={'heading-container'}>
        <div className={'heading'}>{'Recharge Details'}</div>
        <div className={'sub-heading'}>{`${credits} Credits`}</div>
      </div>
      <div className={'details-container'}>
        <div className={'row-container'}>
          <div className={'col-container'}>
            <div className={'details-field'}>{'Account Name'}</div>
            <div className={'details-field-value'}>{accountName}</div>
          </div>
          <div className={'col-container'}>
            <div className={'details-field'}>{'Email ID'}</div>
            <div className={'details-field-value'}>{email}</div>
          </div>
        </div>
        <div className={'row-container'}>
          <div className={'col-container'}>
            <div className={'details-field'}>{'Date'}</div>
            <div className={'details-field-value'}>{date}</div>
          </div>
          <div className={'col-container'}>
            <div className={'details-field'}>{'Transaction ID:'}</div>
            <div className={'details-field-value'}>{transactionId}</div>
          </div>
        </div>
        <div className={'row-container'}>
          <div className={'col-container'}>
            <div className={'details-field'}>{'Credits purchased'}</div>
            <div className={'details-field-value'}>{`${credits} credits`}</div>
          </div>
          <div className={'col-container'}>
            <div className={'details-field'}>{'Transaction Mode'}</div>
            <div className={'details-field-value'}>{mode}</div>
          </div>
        </div>
      </div>
      <div className={'amount-container'}>
        <div className={'details-field'}>{'Amount paid'}</div>
        <div className={'amount'}>{`Rs ${amount}`}</div>
      </div>
      {/* Need to update the copy of info text from Product */}
      {/* <div className={'info-container'}>
        <Image className={'invoice-info-icon'} name={'infoIcon.svg'} />
        <div className={'info-content'}>
          <Image className={'list-icon'} name={'listIcon.svg'} />
          {
            'Reprehenderit commodo excepteur sint id aliquip consectetur excepteur excepteur eiusmod do labore dolor mollit ut. Nostrud nostrud in dolor id elit. Esse reprehenderit pariatur reprehenderit id enminim tempor est. Ut veniam occaecat cillum est occaecat qui.'
          }
        </div>
        <div className={'info-content'}>
          <Image className={'list-icon'} name={'listIcon.svg'} />
          {'Terms and conditions for SpringScan invoice can go here'}
        </div>
        <div className={'info-content'}>
          <Image className={'list-icon'} name={'listIcon.svg'} />
          {'Terms and conditions for SpringScan invoice can go here'}
        </div>
      </div> */}
    </div>
  );
}
