import { connect } from 'react-redux';
import {
  createOrder as createOrderAPI,
  updateOrder as updateOrderAPI,
  generateInvoice as generateInvoiceAPI,
  getDashboardState as getDashboardStateAPI
} from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    userProfile: state.userProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createOrderAPI: (data) => dispatch(createOrderAPI(data)),
    updateOrderAPI: (data) => dispatch(updateOrderAPI(data)),
    generateInvoiceAPI: (data) => dispatch(generateInvoiceAPI(data)),
    getDashboardStateAPI: () => dispatch(getDashboardStateAPI())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
