import React, { useState, useEffect } from 'react';

import './Textarea.scss';
import Image from '../Image';

export default function Textarea(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div className={'textarea-ctr'}>
      {props.label && <label className={'textarea-label'}>{props.label}
        {props.isRequiredField && <span  className={'asterisk-label'}>{'*'}</span>}
      </label>}
      <textarea
        className={'textarea'}
        disabled={props.disabled}
        onBlur={(event) => props.onBlur && props.onBlur(event.target.value)}
        type={props.type}
        rows={props.rows}
        value={value}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        placeholder={props.placeholder}
      />
        {props.showClear &&( <div className={'show-icon'}>
        <Image name={'cross.svg'} onClick={() => props.onClear()} />
      </div> )}
    </div>
  );
}
Textarea.defaultProps = {
  label: '',
  disabled: false,
  isRequiredField:false,
  onBlur: null,
  type: 'text',
  rows: '5',
  value: '',
  onChange: null,
  placeholder: '',
  showClear:false,
  onClear:null
};
