import { connect } from 'react-redux';
import {
  getTokenKeys as getTokenKeysAPI,
  getAPILog as getAPILogAPI
} from '../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    apiLog: state.apiLog
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTokenKeysAPI: (data) => dispatch(getTokenKeysAPI(data)),
    getAPILogAPI: (data) => dispatch(getAPILogAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
