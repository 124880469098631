import http from './http';
import storage from './localstorage';
import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';

let liveUrl = AppConstants.developmentAPIURL;

const getBaseUrl = () => {
  let baseUrl = AppHelper.isLiveMode() ? AppConstants.developmentAPIURL : AppConstants.testAPIURL;
  return baseUrl;
};

const getHeaders = () => {
  if (
    !storage.getDevToken() ||
    storage.getDevToken() === 'undefined' ||
    storage.getDevToken() === 'null' ||
    !storage.getProdToken() ||
    storage.getProdToken() === 'undefined' ||
    storage.getProdToken() === 'null'
  ) {
    window.open(AppConstants.baseURL, '_self');
  }
  let headers = {
    'Content-Type': 'application/json',
    token: AppHelper.isLiveMode() ? AppHelper.getToken() : AppHelper.getDevToken(),
    Authorization: `Bearer ${
      AppHelper.isLiveMode() ? storage.getProdToken() : storage.getDevToken()
    }`
  };
  return headers;
};

const getLiveHeaders = () => {
  if (
    !storage.getProdToken() ||
    storage.getProdToken() === 'undefined' ||
    storage.getProdToken() === 'null'
  ) {
    window.open(AppConstants.baseURL, '_self');
  }
  let liveHeaders = {
    'Content-Type': 'application/json',
    token: AppHelper.getToken(),
    Authorization: `Bearer ${storage.getProdToken()}`
  };
  return liveHeaders;
};

const getConfig = () => {
  let config = {
    headers: getHeaders()
  };
  return config;
};

const getLiveConfig = () => {
  let liveConfig = {
    headers: getLiveHeaders()
  };
  return liveConfig;
};

export const getDashboardState = () => {
  return http.get(`${liveUrl}v3/dashboard/dashboardState`, getLiveConfig());
};

export const getTokenKeys = (data) => {
  let headersData = {
    headers: getHeaders(),
    params: data
  };
  return http.get(`${getBaseUrl()}v3/account/tokenKeys`, headersData);
};

export const generateTokenKey = (data) => {
  return http.post(`${getBaseUrl()}v3/account/tokenKey`, data, getConfig());
};

export const viewTokenKey = (data) => {
  return http.post(`${getBaseUrl()}v3/account/viewTokenKey`, data, getConfig());
};

export const disableTokenKey = (data) => {
  return http.put(`${getBaseUrl()}v3/account/disableTokenKey`, data, getConfig());
};

export const getSettings = () => {
  return http.get(`${liveUrl}v3/settings/notificationSettings`, getLiveConfig());
};

export const updateSettings = (data) => {
  return http.put(`${liveUrl}v3/settings/notificationSettings`, data, getLiveConfig());
};

export const getUserProfile = () => {
  return http.get(`${liveUrl}v3/profile/userDetails`, getLiveConfig());
};

export const changePassword = (data) => {
  return http.put(`${liveUrl}v3/profile/changePassword`, data, getLiveConfig());
};

export const getAdmins = (data) => {
  let headersData = {
    headers: getLiveHeaders(),
    params: data
  };
  return http.get(`${liveUrl}v3/admin/getAdmins`, headersData);
};

export const addAdmin = (data) => {
  return http.post(`${liveUrl}v3/admin/addAdmin`, data, getLiveConfig());
};

export const editAdmin = (data) => {
  return http.put(`${liveUrl}v3/admin/updateAdmin`, data, getLiveConfig());
};

export const deleteAdmin = (data) => {
  let headersData = {
    headers: getLiveHeaders(),
    params: data
  };
  return http.delete(`${liveUrl}v3/admin/deleteAdmin`, headersData);
};

export const getStats = () => {
  return http.get(`${getBaseUrl()}dashboard/company/stats`, getConfig());
};

export const getPeople = (data) => {
  let headersData = {
    headers: getHeaders(),
    params: data
  };
  return http.get(`${getBaseUrl()}dashboard/company/getPeople`, headersData);
};

export const getPerson = (personId) => {
  return http.get(`${getBaseUrl()}user/person/${personId}`, getConfig());
};

export const getAPILog = (data) => {
  let headersData = {
    headers: getHeaders(),
    params: data
  };
  return http.get(`${getBaseUrl()}v3/apiLog`, headersData);
};

export const getPurchaseHistory = (data) => {
  let headersData = {
    headers: getLiveHeaders(),
    params: data
  };
  return http.get(`${liveUrl}transactions/purchaseHistory`, headersData);
};

export const getTransactions = (data) => {
  let headersData = {
    headers: getHeaders(),
    params: data
  };
  return http.get(`${getBaseUrl()}transactions/getCompanyTransactions`, headersData);
};

export const createOrder = (data) => {
  return http.post(`${liveUrl}credits/createOrder`, data, getLiveConfig());
};

export const updateOrder = (data) => {
  return http.post(`${liveUrl}credits/updateOrder`, data, getLiveConfig());
};

export const generateInvoice = (data) => {
  return http.post(`${liveUrl}credits/generateInvoice`, data, getLiveConfig());
};
