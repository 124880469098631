import { connect } from 'react-redux';
import {
  khoslaFetch as khoslaFetchAPI,
  getResult as getResultAPI
} from '../../../actions/whatsappAadhaarVerification';

const mapStateToProps = (state) => {
  return {
    whatsappAadhaarDetails: state.whatsappAadhaarDetails,
    whatsappNewNumber: state.whatsappNewNumber
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    khoslaFetchAPI: (data) => dispatch(khoslaFetchAPI(data)),
    getResultAPI: (data) => dispatch(getResultAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
