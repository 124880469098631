import { connect } from 'react-redux';
import {
  getJwtAuth,
  getTokenValidity,
  govtVerification,
  sendWebhook
} from '../../actions/digiLockerFlowActions';

const mapStateToProps = (state) => {
  return {
    digiFlowAuth: state?.digi?.digiFlowAuth,
    tokenValidity: state?.digi?.tokenValidity
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (id, token, isDirectFlow) => dispatch(getJwtAuth(id, token, isDirectFlow)),
    getTokenValidity: (personId, token, isDirectFlow) =>
      dispatch(getTokenValidity(personId, token, isDirectFlow)),
    verifyGovtToken: (body, token, isDirectFlow) =>
      dispatch(govtVerification(body, token, isDirectFlow))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
