import { connect } from 'react-redux';
import {
  getDashboardState as getDashboardStateAPI,
  getUserProfile as getUserProfileAPI
} from '../../actions/dashboard';
import { logout } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    dashboardState: state.dashboardState,
    userProfile: state.userProfile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardStateAPI: () => dispatch(getDashboardStateAPI()),
    getUserProfileAPI: () => dispatch(getUserProfileAPI()),
    logout: (type, history) => dispatch(logout(type, history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
