import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Loader(props) {
  const customStyle = {
    width: props.size,
    height: props.size,
    color: props.color,
    borderWidth: props.width
  };
  return (
    <div data-testid='loader'>
      <Spinner animation={props.animation} style={customStyle} />
    </div>
  );
}

Loader.defaultProps = {
  animation: 'border',
  color: '#999999',
  size: '20px',
  width: '0.15rem'
};
