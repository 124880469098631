import React, { useState, useEffect } from 'react';
import { validateName, isSpecialCharacter } from '../../../utils/validations';
import Input from '../../../coreComponent/Input/index';
import Button from '../../../coreComponent/Button/index';
import Textarea from '../../../coreComponent/Textarea/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import moment from 'moment';
import { isEmpty } from 'lodash';
import storage from '../../../api/localstorage';
import { getErrorResponseMessage } from '../../../utils/utilities';
import { USER_CANCELLED_MESSAGE } from '../../../utils/constants';

export default function AadhaarXML(props) {
  const [isEditable, setIsEditable] = useState(true);
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [aadhaarError, setAadhaarError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [requestHeaders, setRequestHeaders] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [dobError, setDobError] = useState('');
  const [gender, setGender] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [reportId, setReportId] = useState('');
  const [requestId, setRequestId] = useState('');
  const [otp, setOTP] = useState('');
  const [isOTPSubmitted, setIsOTPSubmitted] = useState(false);
  const [sourceIsDigiLocker, setSourceIsDigiLocker] = useState(true);
  const [tokenInLocalSt, setTokenInLocalSt] = useState('');
  const [verifyWithReqId, setVerifyWithReqId] = useState(false);
  const [requestIdValue, setRequestIdValue] = useState('');

  useEffect(() => {
    if (props.uiActive === 'AadhaarXML') {
      if (!isEmpty(props.aadhaarXMLResponse) && props.aadhaarXMLResponse) {
        setResData(JSON.stringify(props.aadhaarXMLResponse));
        setIsEditable(false);
        setIsSubmitted(true);
      }

      loadData();
    }
    const requestId = storage.getDigiReqId();
    setRequestIdValue(requestId || '');
  }, []);

  useEffect(() => {
    if (tokenInLocalSt && sourceIsDigiLocker && storage.getDigiTokenValidation() === 'true') {
      if (sourceIsDigiLocker) {
        setIsLoading(true);
        setIsEditable(false);
        aadhaarXMLWithDigilocker(null, true);
        storage.removeDigiTokenValidation();
      }
    }
  }, [tokenInLocalSt]);

  useEffect(() => {
    const isTokenUpdatedFn = (e) => {
      const { key, newValue } = e;
      if (key === 'isDigiTokenValid') {
        setTokenInLocalSt(newValue === 'true' ? Date.now() : '');
      }
    };
    const setDigiTokenToFalse = () => {
      storage.setDigiTokenValidation(false);
    };
    window.addEventListener('storage', isTokenUpdatedFn);
    window.addEventListener('beforeunload', setDigiTokenToFalse);
    return () => {
      window.removeEventListener('storage', isTokenUpdatedFn);
    };
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    let requestBody = {};
    if (
      data &&
      data.documents &&
      data.documents.ind_aadhaar &&
      data.documents.ind_aadhaar.result &&
      data.documents.ind_aadhaar.result.id_number &&
      !isEmpty(props.aadhaarXMLResponse)
    ) {
      requestBody.id_number = data.documents.ind_aadhaar.result.id_number;
      requestBody.phone_number = data.documents.ind_aadhaar.result.phone
        ? data.documents.ind_aadhaar.result.phone
        : '';

      setIsDisabled(false);
      setRequestHeaders('Content type: application/json');
      setAadhaarNumber(requestBody.id_number);
      setPhoneNumber(requestBody.phone_number);
      setDob(data.documents.ind_aadhaar?.results?.dob);
      setDob(data.documents.ind_aadhaar?.results?.name);
      setReqData(requestBody);
    }
  };

  const reset = () => {
    if (sourceIsDigiLocker && verifyWithReqId && requestIdValue) {
      setRequestIdValue('');
    }
    props.invalidateAadhaarXMLResponse();
    setAadhaarNumber('');
    setAadhaarError('');
    setPhoneNumber('');
    setName('');
    setDob('');
    setGender('');
    setAddress('');
    setDobError('');
    setNameError('');
    setPhoneError('');
    setReportId('');
    setOTP('');
    setIsDisabled(true);
    setIsSubmitted(false);
    setIsEditable(true);
    setSuccessMsg('');
    setIsLoading(false);
    setReqData('');
    setResData('');
    setErrorCode('');
    setErrorMsg('');
    setIsOTPSubmitted(false);
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
      return;
    }
    if (sourceIsDigiLocker && verifyWithReqId && requestIdValue) {
      aadhaarXMLWithDigilocker(requestIdValue);
      setIsLoading(true);
      setIsEditable(false);
      return;
    }

    if (sourceIsDigiLocker) {
      if (
        validateAadhaarNo(aadhaarNumber) &&
        !phoneError &&
        !dobError &&
        !aadhaarError &&
        !nameError
      ) {
        setIsLoading(true);
        setIsEditable(false);
        aadhaarXMLWithDigilocker();
      }
    } else {
      if (
        validateAadhaarNo(aadhaarNumber) &&
        validatePhoneNo(phoneNumber) &&
        !phoneError &&
        !dobError &&
        !aadhaarError &&
        !nameError
      ) {
        setIsLoading(true);
        setIsEditable(false);
        aadhaarSendOtp();
      }
    }
  };

  const aadhaarXMLWithDigilocker = (requestId = null, stopRedirect = false) => {
    const requestBody = {
      docType: 'ind_aadhaar',
      success_parameters: ['id_number'],
      personId: props.personId,
      isDashboardFlow: true,
      sourceIsDigilocker: sourceIsDigiLocker
    };

    if (requestId) {
      requestBody['request_id'] = requestId;
    } else {
      requestBody['manual_input'] = {
        id_number: aadhaarNumber,
        phone_number: phoneNumber,
        name: name,
        dob: dob,
        address: address
      };
    }
    setReqData(JSON.stringify(requestBody));
    props
      .aadhaarXMLWithDigilocker(requestBody)
      .then((response) => {
        storage.removeDigiTokenValidation();
        if (response?.requestId) {
          storage.setDigiReqId(response.requestId);
          setRequestIdValue(response?.requestId || '');
        }
        if (response?.redirection_url && stopRedirect) {
          setErrorMsg(USER_CANCELLED_MESSAGE);
          setIsLoading(false);
          setIsSubmitted(true);
          return;
        }
        if (response?.redirection_url) {
          window.open(response?.redirection_url, '_blank');
          setIsLoading(false);
          setIsSubmitted(true);
          return;
        }
        props.reloadCredits();
        props.updateUIPerson(true);
        setIsLoading(false);
        setIsSubmitted(true);
        const responseData = response;
        setIsEditable(false);
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Aadhaar (XML) check successful.');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setSuccessMsg('');
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  const aadhaarSendOtp = () => {
    let requestBody = {
      aadhaar_number: aadhaarNumber,
      phone_number: phoneNumber,
      name: name,
      dob: dob,
      address: address,
      gender: gender
    };
    setReqData(JSON.stringify(requestBody));

    props
      .aadhaarXMLSendOTP(requestBody)
      .then((response) => {
        props.reloadCredits();
        setIsLoading(false);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setReportId(responseData.data.reportId);
        setRequestId(responseData.request_id);
        setSuccessMsg('OTP sent');
        setErrorMsg('');
        setErrorCode('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsEditable(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
      });
  };

  const validateAadhaarNo = (id) => {
    if (id.length !== 12) {
      setAadhaarError('Aadhaar ID must contains 12 digits');
      setIsDisabled(true);
      return false;
    } else if (!id.match('[0-9]{12}')) {
      setAadhaarError('Invalid Aadhaar ID');
      setIsDisabled(true);
      return false;
    } else {
      setAadhaarError('');
      setIsDisabled(false);
      return true;
    }
  };

  const validateDob = (date) => {
    if (!moment(date, 'YYYY-MM-DD').isValid()) {
      setDobError('Invalid date');
      setIsDisabled(true);
      return false;
    }
    if (!date.match('[0-9]{4}-[0-1][0-9]-[0-3][0-9]')) {
      setDobError('Invalid Date or Format');
      setIsDisabled(true);
      return false;
    } else {
      setDobError('');
      setDob(date);
      setIsDisabled(false);
      return true;
    }
  };

  const validatePhoneNo = (phone) => {
    if (phone.length !== 10) {
      setPhoneError('Phone Number must be of length 10');
      setIsDisabled(true);
      return false;
    } else if (!phone.match('[6-9][0-9]{9}')) {
      setPhoneError('Invalid Phone Number');
      setIsDisabled(true);
      return false;
    } else {
      setPhoneError('');
      setIsDisabled(false);
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
      return false;
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
    }
  };

  const handleVerifyOTP = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      setIsLoading(true);
      setIsEditable(false);
      aadhaarVerifyOtp();
    }
  };

  const aadhaarVerifyOtp = () => {
    let requestBody = {
      otp: otp,
      reportId: reportId,
      personId: props.personId,
      request_id: requestId,
      success_parameters: ['id_number']
    };
    setIsOTPSubmitted(true);
    setSuccessMsg('');
    setReqData(requestBody);
    props
      .aadhaarXMLVerifyOTP(requestBody)
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        if (responseData.information) {
          let information = JSON.parse(responseData.information);
          responseData.information = information;
        }
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Aadhaar (XML) check successful');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsEditable(true);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const setVerificationSource = (isDigi) => {
    if (isDigi) {
      setSourceIsDigiLocker(true);
    } else {
      setSourceIsDigiLocker(false);
    }
  };

  const handleFlowChange = (value) => {
    setVerificationSource(value);
    if (!value) setVerifyWithReqId(false);
    storage.removeDigiTokenValidation();
  };

  const handleManualFLowChange = (value) => {
    setVerifyWithReqId(value);
    storage.removeDigiTokenValidation();
    if (value && requestIdValue) {
      setIsDisabled(false);
    }
  };

  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Aadhaar (XML)'}</div>
      <div className={'sub-heading'}>
        {'Get detailed information of Aadhaar number with request to the user for OTP'}
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          <div className={'radio-ctr'}>
            <div className={'radio-heading'}>{'Choose verification source:'}</div>
            <div className={'radio-input'}>
              {/* <input
                className={'input'}
                type={'radio'}
                value={false}
                checked={!sourceIsDigiLocker}
                onChange={() => handleFlowChange(false)}
              />
              <div className={'label'} onClick={() => handleFlowChange(false)}>
                {'UIDAI'}
              </div> */}
              <input
                className={'input'}
                type={'radio'}
                value={true}
                checked={sourceIsDigiLocker}
                onChange={() => handleFlowChange(true)}
              />
              <div className={'label'} onClick={() => handleFlowChange(true)}>
                {'Digilocker'}
              </div>
            </div>
          </div>

          {sourceIsDigiLocker && (
            <div className={'radio-ctr'}>
              <div className={'radio-input'}>
                <input
                  className={'input'}
                  type={'radio'}
                  value={false}
                  checked={!verifyWithReqId}
                  onChange={() => handleManualFLowChange(false)}
                />
                <div className={'label'} onClick={() => handleManualFLowChange(false)}>
                  {'Manual'}
                </div>
                <input
                  className={'input'}
                  type={'radio'}
                  value={true}
                  checked={verifyWithReqId}
                  onChange={() => handleManualFLowChange(true)}
                />
                <div className={'label'} onClick={() => handleManualFLowChange(true)}>
                  {'Request ID'}
                </div>
              </div>
            </div>
          )}

          <div className={'input-ctr'}>
            {!verifyWithReqId && (
              <>
                <Input
                  label={'Aadhaar Number'}
                  disabled={!isEditable || reportId}
                  type={'text'}
                  isRequiredField={true}
                  maxLength={12}
                  value={aadhaarNumber}
                  onBlur={(value) => {
                    validateAadhaarNo(value);
                  }}
                  onChange={(value) => {
                    setIsDisabled(false);
                    setAadhaarError('');
                    setAadhaarNumber(value.toUpperCase());
                  }}
                  showClear={true}
                  onClear={() => {
                    setAadhaarNumber('');
                    setIsDisabled(!aadhaarNumber);
                    setAadhaarError('');
                  }}
                  placeholder={'Aadhaar Number'}
                  error={aadhaarError}
                />
                <Input
                  label={'Phone Number'}
                  disabled={!isEditable || reportId}
                  type={'text'}
                  isRequiredField={sourceIsDigiLocker ? false : true}
                  maxLength={10}
                  value={phoneNumber}
                  onBlur={(value) => {
                    if (value) {
                      validatePhoneNo(value);
                    }
                  }}
                  onChange={(value) => {
                    setPhoneError('');
                    setIsDisabled(false);
                    setPhoneNumber(value);
                  }}
                  showClear={true}
                  onClear={() => {
                    setPhoneNumber('');
                    setIsDisabled(false);
                    setPhoneError('');
                  }}
                  placeholder={'Phone Number'}
                  error={phoneError}
                />
                <Input
                  label={'Name'}
                  disabled={!isEditable || reportId}
                  type={'text'}
                  value={name}
                  onBlur={(value) => {
                    if (value) {
                      validateNameInput(value);
                    }
                  }}
                  onChange={(value) => {
                    setName(value.toUpperCase());
                    setIsDisabled(false);
                    setNameError('');
                  }}
                  showClear={true}
                  onClear={() => {
                    setName('');
                    setIsDisabled(false);
                    setNameError('');
                  }}
                  placeholder={'Name'}
                  error={nameError}
                />
                <Input
                  label={'D.O.B'}
                  disabled={!isEditable || reportId}
                  type={'text'}
                  maxLength={10}
                  value={dob}
                  onBlur={(value) => {
                    if (value) {
                      validateDob(value);
                    }
                  }}
                  onChange={(value) => {
                    if (value.length < 10 || !validateDob(value)) {
                      setIsDisabled(true);
                    } else {
                      setDobError('');
                      setIsDisabled(false);
                    }
                    setDob(value);
                  }}
                  showClear={true}
                  onClear={() => {
                    setDob('');
                    setIsDisabled(false);
                    setDobError('');
                  }}
                  placeholder={'YYYY-MM-DD'}
                  error={dobError}
                />
                <Textarea
                  label={'Address'}
                  disabled={!isEditable || reportId}
                  type={'text'}
                  rows='5'
                  value={address}
                  onChange={(value) => {
                    setAddress(value);
                  }}
                  showClear={true}
                  onClear={() => {
                    setAddress('');
                    setIsDisabled(false);
                  }}
                  placeholder={'Address'}
                />
              </>
            )}
            {verifyWithReqId && (
              <div>
                <Input
                  label={'Request ID'}
                  disabled={!isEditable || reportId}
                  isRequiredField={true}
                  type={'text'}
                  maxLength={100}
                  value={requestIdValue}
                  onChange={(value) => {
                    if (value) setIsDisabled(false);
                    setRequestIdValue(value);
                  }}
                  placeholder={'OCR request ID'}
                  showClear={!isEditable ? false : true}
                  onClear={() => {
                    setIsDisabled(true);
                    setRequestIdValue('');
                  }}
                />
              </div>
            )}

            {reportId && !isSubmitted && !sourceIsDigiLocker && (
              <React.Fragment>
                <Input
                  label={'Report ID*'}
                  type={'text'}
                  value={reportId}
                  placeholder={'Report ID'}
                  disabled={isOTPSubmitted}
                />
                <Input
                  label={'OTP*'}
                  type={'text'}
                  value={otp}
                  onChange={(value) => {
                    if (value.length == 0) {
                      setIsDisabled(true);
                    }
                    setOTP(value.trim());
                  }}
                  placeholder={'OTP'}
                  disabled={isOTPSubmitted}
                />
              </React.Fragment>
            )}
            <DemoNotes showNotes={'detail'} />
          </div>

          {!sourceIsDigiLocker && (
            <div className={'submit-btn'} style={{ display: 'flex', margin: 'auto' }}>
              {reportId || isSubmitted ? (
                <React.Fragment>
                  <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
                  {!isSubmitted && (
                    <Button
                      text={'Submit'}
                      onClick={handleVerifyOTP}
                      width={'132px'}
                      height={'47px'}
                      isDisabled={!otp}
                      isLoading={isLoading}
                      isWhiteBtn={isDisabled || isLoading}
                    />
                  )}
                </React.Fragment>
              ) : (
                <Button
                  text={'Send OTP'}
                  onClick={handleSubmit}
                  width={'132px'}
                  height={'47px'}
                  isDisabled={isDisabled || !aadhaarNumber.trim() || !phoneNumber.trim()}
                  isLoading={isLoading}
                  isWhiteBtn={isDisabled || isLoading}
                />
              )}
            </div>
          )}
          {sourceIsDigiLocker && (
            <div className={'submit-btn'}>
              {isSubmitted ? (
                <>
                  <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
                  {verifyWithReqId && (
                    <Button
                      text={'Submit'}
                      onClick={handleSubmit}
                      width={'132px'}
                      height={'47px'}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      isWhiteBtn={isDisabled || isLoading}
                    />
                  )}
                </>
              ) : (
                <Button
                  text={'Submit'}
                  onClick={handleSubmit}
                  width={'132px'}
                  height={'47px'}
                  className={'ml-2'}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isWhiteBtn={isDisabled || isLoading}
                />
              )}
            </div>
          )}
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={`aadhaar/sendOTP/${props.personId}`} />
          <div className={'request-response-ctr'}>
            <DemoCard data={reqData} headers={requestHeaders} />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={resData}
              successMsg={successMsg}
              errorCode={errorCode}
              errorMsg={errorMsg}
              reportDoc={''}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
