import React, { useState, useEffect } from 'react';
import Image from '../../coreComponent/Image';
import Header from '../Header';
import './ClientRedirectionPage.scss';
import ThreeDots from '../../coreComponent/ThreeDotLoader/ThreeDotLoader';
import { getDigiLockerUrlForClientRedirection, getValidJWTToken } from '../../utils/utilities';
import jwt_decode from 'jwt-decode';
import { Modal } from 'react-bootstrap';

export default function ClientRedirectionPage(props) {
  // URL Query Params
  const id = new URLSearchParams(document.location.search).get('id') || null;
  const state = new URLSearchParams(document.location.search).get('state');
  const directFlow =
    JSON.parse(new URLSearchParams(document.location.search).get('direct_flow')) || false;

  const {
    authenticate,
    digiFlowAuth = {},
    getTokenValidity,
    tokenValidity = {},
    verifyGovtToken
  } = props;
  const {
    payload,
    personId,
    tokenKey,
    requestId,
    error = false,
    errorMsg,
    loading = true
  } = digiFlowAuth;

  const { tvIsLoading = true } = tokenValidity;
  const [showCloseWindow, setShowCloseWindow] = useState(false);
  const [dbCheckIsLoading, setDbCheckIsLoading] = useState(true);
  const [isDbCheckSuccess, setDbCheckSuccess] = useState(false);

  useEffect(() => {
    if (!id || !state) {
      props.history.push('/dashboard');
      return;
    }
    const popup = window.open('', '_blank', 'height=1,width=1');
    setTimeout(() => {
      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        setShowPopupInfoModal(true);
      } else {
        popup.close();
        getAuthentication();
      }
    }, 200);

    const getAuthentication = async () => {
      try {
        await authenticate(id, state, directFlow);
        setShowCloseWindow(true);
      } catch (e) {
        setShowCloseWindow(true);
      }
    };
  }, []);

  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    const validateExistingToken = async () => {
      try {
        const { isTokenValid } = await getTokenValidity(personId, tokenKey, directFlow);
        if (isTokenValid) {
          verifyExistingGovtToken();
        } else {
          setDbCheckIsLoading(false);
          redirectToDigiLocker();
        }
      } catch (e) {
        console.error('validateExistingToken err: ', e);
      }
    };
    if (directFlow && personId && tokenKey) {
      validateExistingToken();
    } else {
      redirectToDigiLocker();
    }
  }, [tokenKey]);

  const redirectToDigiLocker = () => {
    if (personId && tokenKey && payload) {
      const decodeToken = jwt_decode(getValidJWTToken(payload));
      getDigiLockerUrlForClientRedirection(personId, tokenKey, decodeToken, requestId, directFlow);
      if (directFlow) handleTimer();
    }
  };

  const verifyExistingGovtToken = async () => {
    const decodeToken = jwt_decode(getValidJWTToken(payload));
    const requestBody = {
      personId: personId,
      docType: decodeToken?.docType,
      success_parameters: decodeToken?.success_parameters,
      sourceIsDigilocker: true,
      request_id: requestId
    };
    await verifyGovtToken(requestBody, tokenKey, directFlow);
    setDbCheckSuccess(true);
    setDbCheckIsLoading(false);
  };

  const handleTimer = () => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 1) {
          clearInterval(intervalId);
          window.close();
          return prevTime;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const [showPopupInfoModal, setShowPopupInfoModal] = useState(false);

  return (
    <div className={'my-digilocker-redirect'}>
      <Modal
        show={showPopupInfoModal}
        onHide={() => {
          setShowPopupInfoModal(false);
        }}
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Body className='PSModalBody'>
          <div className='text-center mb-4'>
            <h6 className='mb-4 mt-4'>
              Sorry, but it seems that your browser is currently blocking popup settings. To
              continue, <strong>kindly enable popup settings and refresh the page.</strong>
            </h6>
            <Image name='popup-settings.png' className={'w-100'} />
            <div className='w-100 text-right mt-4'>
              Popup settings enabled?{' '}
              <button
                className='reloadPageBtn'
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload page
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Header />
      {!loading && !tvIsLoading && !dbCheckIsLoading ? (
        <div className={'page-ctr'}>
          {error ? <Image name={'failureResponse.svg'} /> : <Image name={'successResponse.svg'} />}
          <div className={'content-ctr'}>
            <div className={'heading'}>{'Thank you for your patience.'}</div>
            <div className={'sub-heading'}>
              {error ? (
                <div>
                  {(errorMsg && errorMsg.toUpperCase()) || 'Something went wrong please try again!'}
                </div>
              ) : (
                <>
                  {!directFlow && <div>You can now close this tab.</div>}
                  {directFlow && showCloseWindow && (
                    <p>
                      {isDbCheckSuccess ? (
                        <>Verification Successful.</>
                      ) : (
                        <>
                          We are going to redirect to digiLocker.
                          <small className='mt-4 d-block'>
                            This page will automatically close in {timeLeft} seconds.
                          </small>
                        </>
                      )}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={'page-ctr'}>
          <ThreeDots />
        </div>
      )}
    </div>
  );
}
