import { LOGIN_DATA, SIGNUP_EMAIL, LOGOUT } from '../actions/authentication';

import {
  DASHBOARD_STATE,
  TEST_TOKEN_KEY,
  LIVE_TOKEN_KEY,
  TOKEN_KEYS,
  SETTINGS,
  USER_PROFILE,
  ADMINS,
  STATS,
  PEOPLE,
  API_LOG,
  PURCHASE_HISTORY,
  TRANSACTIONS
} from '../actions/dashboard';

import {
  DEMO_ACTIVE,
  DEMO_CREDITS,
  DEMO_PERSON,
  UPDATE_DEMO_PERSON,
  INVALIDATE_FACE_RESPONSE,
  FACE_RESPONSE,
  INVALIDATE_IDENTITY_RESPONSE,
  IDENTITY_RESPONSE,
  INVALIDATE_IDENTITY_DOC_TYPE,
  IDENTITY_DOC_TYPE,
  INVALIDATE_AADHAAR_OCR_RESPONSE,
  AADHAAR_OCR_RESPONSE,
  INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE,
  AADHAAR_ONLY_OCR_RESPONSE,
  INVALIDATE_AADHAAR_RESPONSE,
  AADHAAR_RESPONSE,
  INVALIDATE_DL_OCR_RESPONSE,
  DL_OCR_RESPONSE,
  INVALIDATE_DL_ONLY_OCR_RESPONSE,
  DL_ONLY_OCR_RESPONSE,
  INVALIDATE_DL_RESPONSE,
  DL_RESPONSE,
  INVALIDATE_VOTER_OCR_RESPONSE,
  VOTER_OCR_RESPONSE,
  INVALIDATE_VOTER_ONLY_OCR_RESPONSE,
  VOTER_ONLY_OCR_RESPONSE,
  INVALIDATE_VOTER_RESPONSE,
  VOTER_RESPONSE,
  INVALIDATE_PAN_OCR_RESPONSE,
  PAN_OCR_RESPONSE,
  INVALIDATE_PAN_ONLY_OCR_RESPONSE,
  PAN_ONLY_OCR_RESPONSE,
  INVALIDATE_PAN_RESPONSE,
  PAN_RESPONSE,
  INVALIDATE_GST_OCR_RESPONSE,
  GST_OCR_RESPONSE,
  INVALIDATE_GST_RESPONSE,
  GST_RESPONSE,
  INVALIDATE_PASSPORT_OCR_RESPONSE,
  PASSPORT_OCR_RESPONSE,
  INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE,
  PASSPORT_ONLY_OCR_RESPONSE,
  INVALIDATE_PASSPORT_RESPONSE,
  PASSPORT_RESPONSE,
  INVALIDATE_BANK_RESPONSE,
  BANK_RESPONSE,
  INVALIDATE_UPI_RESPONSE,
  UPI_RESPONSE,
  INVALIDATE_COURT_CHECK_RESPONSE,
  COURT_CHECK_RESPONSE,
  INVALIDATE_COVID_CHECK_RESPONSE,
  COVID_CHECK_RESPONSE,
  INVALIDATE_AADHAAR_MASKING_RESPONSE,
  AADHAAR_MASKING_RESPONSE,
  INVALIDATE_COA_RESPONSE,
  COA_RESPONSE,
  INVALIDATE_CIN_DIN_RESPONSE,
  CIN_DIN_RESPONSE,
  INVALIDATE_LIN_RESPONSE,
  LIN_RESPONSE,
  INVALIDATE_RC_RESPONSE,
  RC_RESPONSE,
  INVALIDATE_KRA_RESPONSE,
  KRA_RESPONSE,
  INVALIDATE_UDYOG_RESPONSE,
  UDYOG_RESPONSE,
  INVALIDATE_UDYAM_RESPONSE,
  UDYAM_RESPONSE,
  INVALIDATE_AADHAARXML_RESPONSE,
  AADHAARXML_RESPONSE,
  LAUNCH_ACTIVE,
  LAUNCH_CREDITS,
  LAUNCH_PERSON,
  UPDATE_LAUNCH_PERSON,
  INVALIDATE_FACE_RESPONSE_LAUNCH,
  FACE_RESPONSE_LAUNCH,
  INVALIDATE_IDENTITY_RESPONSE_LAUNCH,
  IDENTITY_RESPONSE_LAUNCH,
  INVALIDATE_IDENTITY_DOC_TYPE_LAUNCH,
  IDENTITY_DOC_TYPE_LAUNCH,
  INVALIDATE_AADHAAR_OCR_RESPONSE_LAUNCH,
  AADHAAR_OCR_RESPONSE_LAUNCH,
  INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE_LAUNCH,
  AADHAAR_ONLY_OCR_RESPONSE_LAUNCH,
  INVALIDATE_AADHAAR_RESPONSE_LAUNCH,
  AADHAAR_RESPONSE_LAUNCH,
  INVALIDATE_DL_OCR_RESPONSE_LAUNCH,
  DL_OCR_RESPONSE_LAUNCH,
  INVALIDATE_DL_ONLY_OCR_RESPONSE_LAUNCH,
  DL_ONLY_OCR_RESPONSE_LAUNCH,
  INVALIDATE_DL_RESPONSE_LAUNCH,
  DL_RESPONSE_LAUNCH,
  INVALIDATE_VOTER_OCR_RESPONSE_LAUNCH,
  VOTER_OCR_RESPONSE_LAUNCH,
  INVALIDATE_VOTER_ONLY_OCR_RESPONSE_LAUNCH,
  VOTER_ONLY_OCR_RESPONSE_LAUNCH,
  INVALIDATE_VOTER_RESPONSE_LAUNCH,
  VOTER_RESPONSE_LAUNCH,
  INVALIDATE_PAN_OCR_RESPONSE_LAUNCH,
  PAN_OCR_RESPONSE_LAUNCH,
  INVALIDATE_PAN_ONLY_OCR_RESPONSE_LAUNCH,
  PAN_ONLY_OCR_RESPONSE_LAUNCH,
  INVALIDATE_PAN_RESPONSE_LAUNCH,
  PAN_RESPONSE_LAUNCH,
  INVALIDATE_GST_OCR_RESPONSE_LAUNCH,
  GST_OCR_RESPONSE_LAUNCH,
  INVALIDATE_GST_RESPONSE_LAUNCH,
  GST_RESPONSE_LAUNCH,
  INVALIDATE_PASSPORT_OCR_RESPONSE_LAUNCH,
  PASSPORT_OCR_RESPONSE_LAUNCH,
  INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE_LAUNCH,
  PASSPORT_ONLY_OCR_RESPONSE_LAUNCH,
  INVALIDATE_PASSPORT_RESPONSE_LAUNCH,
  PASSPORT_RESPONSE_LAUNCH,
  INVALIDATE_BANK_RESPONSE_LAUNCH,
  BANK_RESPONSE_LAUNCH,
  INVALIDATE_UPI_RESPONSE_LAUNCH,
  UPI_RESPONSE_LAUNCH,
  INVALIDATE_COURT_CHECK_RESPONSE_LAUNCH,
  COURT_CHECK_RESPONSE_LAUNCH,
  INVALIDATE_COVID_CHECK_RESPONSE_LAUNCH,
  COVID_CHECK_RESPONSE_LAUNCH,
  INVALIDATE_AADHAAR_MASKING_RESPONSE_LAUNCH,
  AADHAAR_MASKING_RESPONSE_LAUNCH,
  INVALIDATE_COA_RESPONSE_LAUNCH,
  COA_RESPONSE_LAUNCH,
  INVALIDATE_CIN_DIN_RESPONSE_LAUNCH,
  CIN_DIN_RESPONSE_LAUNCH,
  INVALIDATE_LIN_RESPONSE_LAUNCH,
  LIN_RESPONSE_LAUNCH,
  INVALIDATE_RC_RESPONSE_LAUNCH,
  RC_RESPONSE_LAUNCH,
  INVALIDATE_KRA_RESPONSE_LAUNCH,
  KRA_RESPONSE_LAUNCH,
  INVALIDATE_UDYOG_RESPONSE_LAUNCH,
  UDYOG_RESPONSE_LAUNCH,
  INVALIDATE_UDYAM_RESPONSE_LAUNCH,
  UDYAM_RESPONSE_LAUNCH,
  INVALIDATE_AADHAARXML_RESPONSE_LAUNCH,
  AADHAARXML_RESPONSE_LAUNCH
} from '../actions/demoUI';

import {
  WHATSAPP_AADHAAR_DETAILS,
  WHATSAPP_NEW_NUMBER
} from '../actions/whatsappAadhaarVerification';

export default (state, action) => {
  if (action.type === LOGOUT) {
    state = {};
  }
  return {
    loginData: loginData(state ? state.loginData : {}, action),
    signupEmail: signupEmail(state ? state.signupEmail : '', action),

    dashboardState: dashboardState(state ? state.dashboardState : {}, action),
    testTokenKey: testTokenKey(state ? state.testTokenKey : {}, action),
    liveTokenKey: liveTokenKey(state ? state.liveTokenKey : {}, action),
    tokenKeys: tokenKeys(state ? state.tokenKeys : {}, action),
    settings: settings(state ? state.settings : {}, action),
    userProfile: userProfile(state ? state.userProfile : {}, action),
    admins: admins(state ? state.admins : {}, action),
    stats: stats(state ? state.stats : {}, action),
    people: people(state ? state.people : {}, action),
    apiLog: apiLog(state ? state.apiLog : {}, action),
    purchaseHistory: purchaseHistory(state ? state.purchaseHistory : {}, action),
    transactions: transactions(state ? state.transactions : {}, action),

    demoActive: demoActive(state ? state.demoActive : 'Face', action),
    demoCredits: demoCredits(state ? state.demoCredits : '', action),
    demoPerson: demoPerson(state ? state.demoPerson : {}, action),
    updateDemoPerson: updateDemoPerson(state ? state.updateDemoPerson : false, action),
    faceResponse: faceResponse(state ? state.faceResponse : {}, action),
    identityResponse: identityResponse(state ? state.identityResponse : {}, action),
    identityDocType: identityDocType(state ? state.identityDocType : '', action),
    aadhaarOcrResponse: aadhaarOcrResponse(state ? state.aadhaarOcrResponse : {}, action),
    aadhaarOnlyOcrResponse: aadhaarOnlyOcrResponse(state ? state.aadhaarOnlyOcrResponse : {}, action),
    aadhaarResponse: aadhaarResponse(state ? state.aadhaarResponse : {}, action),
    dlOcrResponse: dlOcrResponse(state ? state.dlOcrResponse : {}, action),
    dlOnlyOcrResponse: dlOnlyOcrResponse(state ? state.dlOnlyOcrResponse : {}, action),
    dlResponse: dlResponse(state ? state.dlResponse : {}, action),
    voterOcrResponse: voterOcrResponse(state ? state.voterOcrResponse : {}, action),
    voterOnlyOcrResponse: voterOnlyOcrResponse(state ? state.voterOnlyOcrResponse : {}, action),
    voterResponse: voterResponse(state ? state.voterResponse : {}, action),
    panOcrResponse: panOcrResponse(state ? state.panOcrResponse : {}, action),
    panOnlyOcrResponse: panOnlyOcrResponse(state ? state.panOnlyOcrResponse : {}, action),
    panResponse: panResponse(state ? state.panResponse : {}, action),
    gstOcrResponse: gstOcrResponse(state ? state.gstOcrResponse : {}, action),
    gstResponse: gstResponse(state ? state.gstResponse : {}, action),
    passportOcrResponse: passportOcrResponse(state ? state.passportOcrResponse : {}, action),
    passportOnlyOcrResponse: passportOnlyOcrResponse(state ? state.passportOnlyOcrResponse : {}, action),
    passportResponse: passportResponse(state ? state.passportResponse : {}, action),
    bankResponse: bankResponse(state ? state.bankResponse : {}, action),
    upiResponse: upiResponse(state ? state.upiResponse : {}, action),
    courtCheckResponse: courtCheckResponse(state ? state.courtCheckResponse : {}, action),
    covidCheckResponse: covidCheckResponse(state ? state.covidCheckResponse : {}, action),
    aadhaarMaskingResponse: aadhaarMaskingResponse(
      state ? state.aadhaarMaskingResponse : {},
      action
    ),
    coaResponse: coaResponse(state ? state.coaResponse : {}, action),
    cinDinResponse: cinDinResponse(state ? state.cinDinResponse : {}, action),
    linResponse: linResponse(state ? state.linResponse : {}, action),
    udyogResponse: udyogResponse(state ? state.udyogResponse : {}, action),
    udyamResponse: udyamResponse(state ? state.udyamResponse : {}, action),
    rcResponse: rcResponse(state ? state.rcResponse : {}, action),
    kraResponse: kraResponse(state ? state.kraResponse : {}, action),
    aadhaarXMLResponse: aadhaarXMLResponse(state ? state.aadhaarXMLResponse : {}, action),
    launchActive: launchActive(state ? state.launchActive : 'Face', action),
    launchCredits: launchCredits(state ? state.launchCredits : '', action),
    launchPerson: launchPerson(state ? state.launchPerson : {}, action),
    updateLaunchPerson: updateLaunchPerson(state ? state.updateLaunchPerson : false, action),
    faceResponseLaunch: faceResponseLaunch(state ? state.faceResponseLaunch : {}, action),
    identityResponseLaunch: identityResponseLaunch(
      state ? state.identityResponseLaunch : {},
      action
    ),
    identityDocTypeLaunch: identityDocTypeLaunch(state ? state.identityDocTypeLaunch : '', action),
    aadhaarOcrResponseLaunch: aadhaarOcrResponseLaunch(
      state ? state.aadhaarOcrResponseLaunch : {},
      action
    ),
    aadhaarOnlyOcrResponseLaunch: aadhaarOnlyOcrResponseLaunch(
      state ? state.aadhaarOnlyOcrResponseLaunch : {},
      action
    ),
    aadhaarResponseLaunch: aadhaarResponseLaunch(state ? state.aadhaarResponseLaunch : {}, action),
    dlOcrResponseLaunch: dlOcrResponseLaunch(state ? state.dlOcrResponseLaunch : {}, action),
    dlOnlyOcrResponseLaunch: dlOnlyOcrResponseLaunch(state ? state.dlOnlyOcrResponseLaunch : {}, action),
    dlResponseLaunch: dlResponseLaunch(state ? state.dlResponseLaunch : {}, action),
    voterOcrResponseLaunch: voterOcrResponseLaunch(
      state ? state.voterOcrResponseLaunch : {},
      action
    ),
    voterOnlyOcrResponseLaunch: voterOnlyOcrResponseLaunch(
      state ? state.voterOnlyOcrResponseLaunch : {},
      action
    ),
    voterResponseLaunch: voterResponseLaunch(state ? state.voterResponseLaunch : {}, action),
    panOcrResponseLaunch: panOcrResponseLaunch(state ? state.panOcrResponseLaunch : {}, action),
    panOnlyOcrResponseLaunch: panOnlyOcrResponseLaunch(state ? state.panOnlyOcrResponseLaunch : {}, action),
    panResponseLaunch: panResponseLaunch(state ? state.panResponseLaunch : {}, action),
    gstOcrResponseLaunch: gstOcrResponseLaunch(state ? state.gstOcrResponseLaunch : {}, action),
    gstResponseLaunch: gstResponseLaunch(state ? state.gstResponseLaunch : {}, action),
    passportOcrResponseLaunch: passportOcrResponseLaunch(
      state ? state.passportOcrResponseLaunch : {},
      action
    ),
    passportOnlyOcrResponseLaunch: passportOnlyOcrResponseLaunch(
      state ? state.passportOnlyOcrResponseLaunch : {},
      action
    ),
    passportResponseLaunch: passportResponseLaunch(
      state ? state.passportResponseLaunch : {},
      action
    ),
    bankResponseLaunch: bankResponseLaunch(state ? state.bankResponseLaunch : {}, action),
    upiResponseLaunch: upiResponseLaunch(state ? state.upiResponseLaunch : {}, action),
    courtCheckResponseLaunch: courtCheckResponseLaunch(
      state ? state.courtCheckResponseLaunch : {},
      action
    ),
    covidCheckResponseLaunch: covidCheckResponseLaunch(
      state ? state.covidCheckResponseLaunch : {},
      action
    ),
    aadhaarMaskingResponseLaunch: aadhaarMaskingResponseLaunch(
      state ? state.aadhaarMaskingResponseLaunch : {},
      action
    ),
    coaResponseLaunch: coaResponseLaunch(state ? state.coaResponseLaunch : {}, action),
    cinDinResponseLaunch: cinDinResponseLaunch(state ? state.cinDinResponseLaunch : {}, action),
    linResponseLaunch: linResponseLaunch(state ? state.linResponseLaunch : {}, action),
    udyogResponseLaunch: udyogResponseLaunch(state ? state.udyogResponseLaunch : {}, action),
    udyamResponseLaunch: udyamResponseLaunch(state ? state.udyamResponseLaunch : {}, action),
    rcResponseLaunch: rcResponseLaunch(state ? state.rcResponseLaunch : {}, action),
    kraResponseLaunch: kraResponseLaunch(state ? state.kraResponseLaunch : {}, action),
    aadhaarXMLResponseLaunch: aadhaarXMLResponseLaunch(
      state ? state.aadhaarXMLResponseLaunch : {},
      action
    ),
    whatsappAadhaarDetails: whatsappAadhaarDetails(
      state ? state.whatsappAadhaarDetails : {},
      action
    ),
    whatsappNewNumber: whatsappNewNumber(state ? state.whatsappNewNumber : '', action)
  };
};

// authentication action
const loginData = (state, action) => {
  if (action.type === LOGIN_DATA) {
    return action.data;
  } else {
    return state;
  }
};
const signupEmail = (state, action) => {
  if (action.type === SIGNUP_EMAIL) {
    return action.data;
  } else {
    return state;
  }
};

// dashboard action
const dashboardState = (state, action) => {
  if (action.type === DASHBOARD_STATE) {
    return action.data;
  } else {
    return state;
  }
};
const testTokenKey = (state, action) => {
  if (action.type === TEST_TOKEN_KEY) {
    return action.data;
  } else {
    return state;
  }
};
const liveTokenKey = (state, action) => {
  if (action.type === LIVE_TOKEN_KEY) {
    return action.data;
  } else {
    return state;
  }
};
const tokenKeys = (state, action) => {
  if (action.type === TOKEN_KEYS) {
    return action.data;
  } else {
    return state;
  }
};
const settings = (state, action) => {
  if (action.type === SETTINGS) {
    return action.data;
  } else {
    return state;
  }
};
const userProfile = (state, action) => {
  if (action.type === USER_PROFILE) {
    return action.data;
  } else {
    return state;
  }
};
const admins = (state, action) => {
  if (action.type === ADMINS) {
    return action.data;
  } else {
    return state;
  }
};
const stats = (state, action) => {
  if (action.type === STATS) {
    return action.data;
  } else {
    return state;
  }
};
const people = (state, action) => {
  if (action.type === PEOPLE) {
    return action.data;
  } else {
    return state;
  }
};
const apiLog = (state, action) => {
  if (action.type === API_LOG) {
    return action.data;
  } else {
    return state;
  }
};
const purchaseHistory = (state, action) => {
  if (action.type === PURCHASE_HISTORY) {
    return action.data;
  } else {
    return state;
  }
};
const transactions = (state, action) => {
  if (action.type === TRANSACTIONS) {
    return action.data;
  } else {
    return state;
  }
};

// demo-ui action
const demoActive = (state, action) => {
  if (action.type === DEMO_ACTIVE) {
    return action.data;
  } else {
    return state;
  }
};
const demoCredits = (state, action) => {
  if (action.type === DEMO_CREDITS) {
    return action.data;
  } else {
    return state;
  }
};
const demoPerson = (state, action) => {
  if (action.type === DEMO_PERSON) {
    return action.data;
  } else {
    return state;
  }
};
const updateDemoPerson = (state, action) => {
  if (action.type === UPDATE_DEMO_PERSON) {
    return action.data;
  } else {
    return state;
  }
};
const faceResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_FACE_RESPONSE:
      return {};
    case FACE_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const identityResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_IDENTITY_RESPONSE:
      return {};
    case IDENTITY_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const identityDocType = (state, action) => {
  switch (action.type) {
    case INVALIDATE_IDENTITY_DOC_TYPE:
      return '';
    case IDENTITY_DOC_TYPE:
      return action.data;
    default:
      return state;
  }
};
const aadhaarOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_OCR_RESPONSE:
      return {};
    case AADHAAR_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const aadhaarOnlyOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE:
      return {};
    case AADHAAR_ONLY_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const aadhaarResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_RESPONSE:
      return {};
    case AADHAAR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const dlOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_DL_OCR_RESPONSE:
      return {};
    case DL_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const dlResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_DL_RESPONSE:
      return {};
    case DL_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const voterOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_VOTER_OCR_RESPONSE:
      return {};
    case VOTER_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const voterOnlyOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_VOTER_ONLY_OCR_RESPONSE:
      return {};
    case VOTER_ONLY_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const voterResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_VOTER_RESPONSE:
      return {};
    case VOTER_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const panOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAN_OCR_RESPONSE:
      return {};
    case PAN_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const panOnlyOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAN_ONLY_OCR_RESPONSE:
      return {};
    case PAN_ONLY_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const panResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAN_RESPONSE:
      return {};
    case PAN_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const gstOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_GST_OCR_RESPONSE:
      return {};
    case GST_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const gstResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_GST_RESPONSE:
      return {};
    case GST_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const passportResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PASSPORT_RESPONSE:
      return {};
    case PASSPORT_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const passportOnlyOcrResponse = (state, action) => {
  switch (action.type) {
    case PASSPORT_ONLY_OCR_RESPONSE:
      return {};
    case PASSPORT_ONLY_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const passportOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PASSPORT_OCR_RESPONSE:
      return {};
    case PASSPORT_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const bankResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BANK_RESPONSE:
      return {};
    case BANK_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const upiResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_UPI_RESPONSE:
      return {};
    case UPI_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const courtCheckResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_COURT_CHECK_RESPONSE:
      return {};
    case COURT_CHECK_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const covidCheckResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_COVID_CHECK_RESPONSE:
      return {};
    case COVID_CHECK_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const aadhaarMaskingResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_MASKING_RESPONSE:
      return {};
    case AADHAAR_MASKING_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const coaResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_COA_RESPONSE:
      return {};
    case COA_RESPONSE:
      return action.data;
    default:
      return state;
  }
};

const cinDinResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_CIN_DIN_RESPONSE:
      return {};
    case CIN_DIN_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const linResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_LIN_RESPONSE:
      return {};
    case LIN_RESPONSE:
      return action.data;
    default:
      return state;
  }
};

const udyogResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_UDYOG_RESPONSE:
      return {};
    case UDYOG_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const udyamResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_UDYAM_RESPONSE:
      return {};
    case UDYAM_RESPONSE:
      return action.data;
    default:
      return state;
  }
};

const rcResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_RC_RESPONSE:
      return {};
    case RC_RESPONSE:
      return action.data;
    default:
      return state;
  }
};

const kraResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_KRA_RESPONSE:
      return {};
    case KRA_RESPONSE:
      return action.data;
    default:
      return state;
  }
};

const aadhaarXMLResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAARXML_RESPONSE:
      return {};
    case AADHAARXML_RESPONSE:
      return action.data;
    default:
      return state;
  }
};

// launch-ui action
const launchActive = (state, action) => {
  if (action.type === LAUNCH_ACTIVE) {
    return action.data;
  } else {
    return state;
  }
};
const launchCredits = (state, action) => {
  if (action.type === LAUNCH_CREDITS) {
    return action.data;
  } else {
    return state;
  }
};
const launchPerson = (state, action) => {
  if (action.type === LAUNCH_PERSON) {
    return action.data;
  } else {
    return state;
  }
};
const updateLaunchPerson = (state, action) => {
  if (action.type === UPDATE_LAUNCH_PERSON) {
    return action.data;
  } else {
    return state;
  }
};
const faceResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_FACE_RESPONSE_LAUNCH:
      return {};
    case FACE_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const identityResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_IDENTITY_RESPONSE_LAUNCH:
      return {};
    case IDENTITY_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const identityDocTypeLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_IDENTITY_DOC_TYPE_LAUNCH:
      return '';
    case IDENTITY_DOC_TYPE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const aadhaarOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_OCR_RESPONSE_LAUNCH:
      return {};
    case AADHAAR_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const aadhaarOnlyOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_ONLY_OCR_RESPONSE_LAUNCH:
      return {};
    case AADHAAR_ONLY_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const aadhaarResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_RESPONSE_LAUNCH:
      return {};
    case AADHAAR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const dlOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_DL_OCR_RESPONSE_LAUNCH:
      return {};
    case DL_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const dlOnlyOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_DL_ONLY_OCR_RESPONSE_LAUNCH:
      return {};
    case DL_ONLY_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const dlOnlyOcrResponse = (state, action) => {
  switch (action.type) {
    case INVALIDATE_DL_ONLY_OCR_RESPONSE:
      return {};
    case DL_ONLY_OCR_RESPONSE:
      return action.data;
    default:
      return state;
  }
};
const dlResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_DL_RESPONSE_LAUNCH:
      return {};
    case DL_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const voterOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_VOTER_OCR_RESPONSE_LAUNCH:
      return {};
    case VOTER_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const voterOnlyOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_VOTER_ONLY_OCR_RESPONSE_LAUNCH:
      return {};
    case VOTER_ONLY_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const voterResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_VOTER_RESPONSE_LAUNCH:
      return {};
    case VOTER_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const panOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAN_OCR_RESPONSE_LAUNCH:
      return {};
    case PAN_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const panOnlyOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAN_ONLY_OCR_RESPONSE_LAUNCH:
      return {};
    case PAN_ONLY_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const panResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PAN_RESPONSE_LAUNCH:
      return {};
    case PAN_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const gstOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_GST_OCR_RESPONSE_LAUNCH:
      return {};
    case GST_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const gstResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_GST_RESPONSE_LAUNCH:
      return {};
    case GST_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const passportResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PASSPORT_RESPONSE_LAUNCH:
      return {};
    case PASSPORT_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const passportOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PASSPORT_OCR_RESPONSE_LAUNCH:
      return {};
    case PASSPORT_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const passportOnlyOcrResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_PASSPORT_ONLY_OCR_RESPONSE_LAUNCH:
      return {};
    case PASSPORT_ONLY_OCR_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const bankResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_BANK_RESPONSE_LAUNCH:
      return {};
    case BANK_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const upiResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_UPI_RESPONSE_LAUNCH:
      return {};
    case UPI_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const courtCheckResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_COURT_CHECK_RESPONSE_LAUNCH:
      return {};
    case COURT_CHECK_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const covidCheckResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_COVID_CHECK_RESPONSE_LAUNCH:
      return {};
    case COVID_CHECK_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const aadhaarMaskingResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAAR_MASKING_RESPONSE_LAUNCH:
      return {};
    case AADHAAR_MASKING_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

const coaResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_COA_RESPONSE_LAUNCH:
      return {};
    case COA_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

const cinDinResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_CIN_DIN_RESPONSE_LAUNCH:
      return {};
    case CIN_DIN_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

const linResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_LIN_RESPONSE_LAUNCH:
      return {};
    case LIN_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

const udyogResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_UDYOG_RESPONSE_LAUNCH:
      return {};
    case UDYOG_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};
const udyamResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_UDYAM_RESPONSE_LAUNCH:
      return {};
    case UDYAM_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

const rcResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_RC_RESPONSE_LAUNCH:
      return {};
    case RC_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

const kraResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_KRA_RESPONSE_LAUNCH:
      return {};
    case KRA_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

const aadhaarXMLResponseLaunch = (state, action) => {
  switch (action.type) {
    case INVALIDATE_AADHAARXML_RESPONSE_LAUNCH:
      return {};
    case AADHAARXML_RESPONSE_LAUNCH:
      return action.data;
    default:
      return state;
  }
};

// whatsapp Aadhaar Verification action
const whatsappAadhaarDetails = (state, action) => {
  if (action.type === WHATSAPP_AADHAAR_DETAILS) {
    return action.data;
  } else {
    return state;
  }
};
const whatsappNewNumber = (state, action) => {
  if (action.type === WHATSAPP_NEW_NUMBER) {
    return action.data;
  } else {
    return state;
  }
};
