import { connect } from 'react-redux';
import { logout } from '../../actions/authentication';

const mapStateToProps = (state) => {
  return {
    signupEmail: state.signupEmail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout('direct', null, false, false))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
