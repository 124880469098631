import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import storage from '../../api/localstorage';

export default ({ component: Component, redirect = '/', ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        storage.getDevToken() && storage.getProdToken() ? (
          rest.render ? (
            rest.render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: redirect, state: { from: props.location } }} />
        )
      }
    />
  );
};
