import React, { useState } from 'react';

import Image from '../../../coreComponent/Image';
import Icon from '../../../coreComponent/Icon';
import './SideNavbar.scss';

export default function SideNavbar(props) {
  const [idDropdown, setIdDropdown] = useState(true);
  const [bankingDropdown, setBankingDropdown] = useState(true);
  const [companyDropdown, setCompanyDropdown] = useState(true);
  const [otherDropdown, setOtherDropdown] = useState(true);
  const getHelp = () => {
    window.open(
      'https://mail.google.com/mail/?view=cm&fs=1&to=ajith.ravindran@springworks.in',
      '_blank'
    );
  };

  return (
    <div className={'demo-side-navbar-ctr'} data-testid='sidenavbar'>
      <div className={'side-header'}>
        <div className={'logo-ctr'}>
          <Image name={'logo.svg'} />
          <div className={'text'}>{'SpringScan'}</div>
        </div>
        <div className={'company-name'}>{props.companyName}</div>
      </div>
      <div className={'tab-ctr'}>
        <div
          className={`tab ${props.active === 'Face' ? 'active-tab' : ''}`}
          onClick={() => props.changeActive('Face')}
        >
          {'Selfie Quality Detection'}
        </div>
        <div
          className={`tab ${props.active === 'Identity' ? 'active-tab' : ''}`}
          onClick={() => props.changeActive('Identity')}
        >
          {'Face Match'}
        </div>
        <div className={`dropdown-tab ${idDropdown ? '' : 'dropdown-close'}`}>
          <div className={'tab-title'} onClick={() => setIdDropdown(!idDropdown)}>
            {idDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'ID Verification'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'PAN' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('PAN')}
          >
            {'PAN'}
          </div>
          <div
            className={`list-tab ${props.active === 'KRA' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('KRA')}
            data-testid='KRA'
          >
            {'PAN KRA'}
          </div>
          <div
            className={`list-tab ${props.active === 'DL' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('DL')}
          >
            {'Driving License'}
          </div>
          <div
            className={`list-tab ${props.active === 'Voter' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Voter')}
          >
            {'Voter ID'}
          </div>
          {/* <div
            className={`list-tab ${props.active === 'Aadhaar' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Aadhaar')}
          >
            {'Aadhaar (Basic)'}
          </div> */}
          <div
            className={`list-tab ${props.active === 'AadhaarXML' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('AadhaarXML')}
          >
            {'Aadhaar (XML)'}
          </div>
          <div
            className={`list-tab ${props.active === 'Passport' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Passport')}
          >
            {'Passport (IND)'}
          </div>
        </div>
        <div className={`dropdown-tab ${bankingDropdown ? '' : 'dropdown-close'}`}>
          <div className={'tab-title'} onClick={() => setBankingDropdown(!bankingDropdown)}>
            {bankingDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'Banking Verification'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'Bank' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Bank')}
          >
            {'Bank Account'}
          </div>
          <div
            className={`list-tab ${props.active === 'UPI' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('UPI')}
          >
            {'UPI'}
          </div>
        </div>
        <div className={`dropdown-tab ${companyDropdown ? '' : 'dropdown-close'}`}>
          <div className={'tab-title'} onClick={() => setCompanyDropdown(!companyDropdown)}>
            {companyDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'Company Verification'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'CIN' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('CIN')}
          >
            {'CIN'}
          </div>
          <div
            className={`list-tab ${props.active === 'LIN' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('LIN')}
          >
            {'LIN'}
          </div>
          <div
            className={`list-tab ${props.active === 'DIN' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('DIN')}
          >
            {'DIN'}
          </div>
          <div
            className={`list-tab ${props.active === 'GST' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('GST')}
          >
            {'GST'}
          </div>
        </div>
        <div className={`dropdown-tab ${otherDropdown ? '' : 'dropdown-close'}`}>
          <div className={'tab-title'} onClick={() => setOtherDropdown(!otherDropdown)}>
            {otherDropdown ? (
              <Icon icon={'UpArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            ) : (
              <Icon icon={'RightArrowDropdown'} color={'#FFFFFF'} width={'12'} height={'12'} />
            )}
            <div className={'text'}>{'Other Verification'}</div>
          </div>
          <div
            className={`list-tab ${props.active === 'RC' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('RC')}
          >
            {'Vehicle RC'}
          </div>
          <div
            className={`list-tab ${props.active === 'Coa' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Coa')}
          >
            {'CoA'}
          </div>
          <div
            className={`list-tab ${props.active === 'Court' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Court')}
          >
            {'Court Records'}
          </div>
          <div
            className={`list-tab ${props.active === 'Udyog' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Udyog')}
          >
            {'Udyog'}
          </div>
          <div
            className={`list-tab ${props.active === 'Udyam' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Udyam')}
          >
            {'Udyam'}
          </div>
          <div
            className={`list-tab ${props.active === 'Covid' ? 'list-tab-active' : ''}`}
            onClick={() => props.changeActive('Covid')}
          >
            {'Covid Vaccine'}
          </div>
        </div>
        <div
          className={`tab ${props.active === 'Masking' ? 'active-tab' : ''}`}
          onClick={() => props.changeActive('Masking')}
        >
          {'Aadhaar Masking'}
        </div>
      </div>
      <div className={'side-footer'}>
        <Image name={'footerLogo.svg'} />
        <div className={'footer-link'} onClick={getHelp}>
          {'Having trouble? Get help'}
        </div>
      </div>
    </div>
  );
}
SideNavbar.defaultProps = {
  active: 'Face',
  changeActive: null,
  companyName: ''
};
