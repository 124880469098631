import { connect } from 'react-redux';
import { downloadReports, downloadCourtCheckReport } from '../../../actions/demoUI';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadReports: (docType) => dispatch(downloadReports(docType)),
    downloadCourtCheckReport: () => dispatch(downloadCourtCheckReport())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
