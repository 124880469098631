import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import Input from '../../../../coreComponent/Input/index';
import Button from '../../../../coreComponent/Button/index';
import SelectInput from '../../../../coreComponent/SelectInput';
import countryCodes from '../../../../utils/countryCodes.json';
import {
  validateName,
  validateEmail,
  validateNumber,
  validatesPhoneNumber
} from '../../../../utils/validations';
import './AddAdmin.scss';

export default function AddAdmin(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    setCountryCode({
      label: 'India (+91)',
      value: '+91'
    });
  }, []);

  const addAdmin = () => {
    setSubmitting(true);
    let requestData = {
      name: name,
      email_id: email,
      phone_number: countryCode.value + mobileNumber
    };
    props
      .addAdminAPI(requestData)
      .then(() => {
        setSubmitting(false);
        props.onSubmit();
        onHide();
      })
      .catch((error) => {
        setSubmitting(false);
        if (
          error.status === 400 &&
          error.data &&
          error.data.errors &&
          error.data.errors.length > 0
        ) {
          error.data.errors.map((value, index) => {
            if (value.msg === 'Phone Number Already exist') {
              setPhoneError('Phone number already exists. Try another.');
            } else if (value.msg === 'Email Already Exist') {
              setEmailError('Email already exists. Try another.');
            } else {
              setEmailError('Something went wrong.');
            }
          });
        } else {
          setEmailError('Something went wrong.');
        }
      });
  };

  const onHide = () => {
    setName('');
    setEmail('');
    setCountryCode({
      label: 'India (+91)',
      value: '+91'
    });
    setMobileNumber('');
    setNameError('');
    setEmailError('');
    setPhoneError('');
    setSubmitting(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'add-admin-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='addAdmin' className={'heading'}>
            {'Add Admin'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          label={'Name*'}
          disabled={submitting}
          type={'text'}
          value={name}
          onChange={(value) => {
            setName(value);
            if (validateName(value)) {
              setNameError('');
            } else {
              setNameError('Please enter a valid name');
            }
          }}
          placeholder={'Enter Admin Name'}
          error={nameError}
          errorWithIcon={true}
        />
        <Input
          label={'Email ID*'}
          disabled={submitting}
          type={'text'}
          value={email}
          onBlur={(value) => {
            if (!validateEmail(value)) {
              setEmailError('Please use a valid email id');
            } else {
              setEmailError('');
            }
          }}
          onChange={(value) => {
            setEmail(value);
            setEmailError('');
          }}
          placeholder={'Enter Admin Email ID'}
          error={emailError}
          errorWithIcon={true}
        />
        <div className={'form-row'}>
          <div className={'select-ctr'}>
            <div data-testid='label' className={'label'}>
              {'Country Code*'}
            </div>
            <SelectInput
              defaultValue={countryCode}
              isDisabled={submitting}
              isSearchable={true}
              options={countryCodes}
              onSubmit={(value) => {
                setCountryCode(value);
                let phoneNumber = value.value + mobileNumber;
                if (
                  (mobileNumber && phoneNumber && validatesPhoneNumber(phoneNumber)) ||
                  !mobileNumber
                ) {
                  setPhoneError('');
                } else {
                  setPhoneError('Please enter a valid mobile number');
                }
              }}
            />
          </div>
          <Input
            label={'Mobile*'}
            disabled={submitting}
            type={'text'}
            value={mobileNumber}
            onBlur={(value) => {
              setMobileNumber(value);
              let phoneNumber = countryCode.value + value;
              if (phoneNumber && validatesPhoneNumber(phoneNumber)) {
                setPhoneError('');
              } else {
                setPhoneError('Please enter a valid mobile number');
              }
            }}
            onChange={(value) => {
              setMobileNumber(value);
              if (validateNumber(value)) {
                setPhoneError('');
              } else {
                setPhoneError('Please enter a valid mobile number');
              }
            }}
            placeholder={'Enter Admin Mobile Number'}
            error={phoneError}
            errorWithIcon={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
            isDisabled={submitting}
          />
        </div>
        <Button
          text={'Send Invite'}
          onClick={addAdmin}
          width={'120px'}
          height={'50px'}
          isDisabled={
            !name ||
            !email ||
            !countryCode ||
            !mobileNumber ||
            nameError ||
            emailError ||
            phoneError
          }
          isLoading={submitting}
        />
      </Modal.Footer>
    </Modal>
  );
}
AddAdmin.defaultProps = {
  show: false,
  onHide: null,
  onSubmit: null
};
