import { connect } from 'react-redux';
import { fetchUser as fetchUserAPI } from '../../../actions/aadhaarVerification';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAPI: () => dispatch(fetchUserAPI())
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
