import React, { useState, useEffect } from 'react';

import Image from '../Image';
import './PasswordValidation.scss';

export default function PasswordValidation(props) {
  const [minCheck, setMinCheck] = useState('');
  const [maxCheck, setMaxCheck] = useState('');
  const [upperCheck, setUpperCheck] = useState('');
  const [specialCheck, setSpecialCheck] = useState('');
  const [numberCheck, setNumberCheck] = useState('');
  useEffect(() => {
    if (props.invalidate) {
      invalidatePassword(props.value);
    } else {
      validatePassword(props.value);
    }
  }, [props.value, props.invalidate]);

  const validatePassword = (password) => {
    let flag = false;
    if (password.length >= 8) {
      setMinCheck('success');
    } else {
      flag = true;
      setMinCheck('');
    }
    if (password.length >= 8 && password.length <= 12) {
      setMaxCheck('success');
    } else {
      flag = true;
      setMaxCheck('');
    }
    if (password.match('.*[A-Z].*')) {
      setUpperCheck('success');
    } else {
      flag = true;
      setUpperCheck('');
    }
    if (password.match('.*[~!@#$%^&*+=].*?')) {
      setSpecialCheck('success');
    } else {
      flag = true;
      setSpecialCheck('');
    }
    if (password.match('.*[0-9].*')) {
      setNumberCheck('success');
    } else {
      flag = true;
      setNumberCheck('');
    }
    if (flag) {
      props.isSecure(false);
    } else {
      props.isSecure(true);
    }
  };

  const invalidatePassword = (password) => {
    let flag = false;
    if (password.length >= 8) {
      setMinCheck('success');
    } else {
      flag = true;
      setMinCheck('fail');
    }
    if (password.length >= 8 && password.length <= 12) {
      setMaxCheck('success');
    } else {
      flag = true;
      setMaxCheck('fail');
    }
    if (password.match('.*[A-Z].*')) {
      setUpperCheck('success');
    } else {
      flag = true;
      setUpperCheck('fail');
    }
    if (password.match('.*[!@#$%^&*+=_.,?].*')) {
      setSpecialCheck('success');
    } else {
      flag = true;
      setSpecialCheck('fail');
    }
    if (password.match('.*[0-9].*')) {
      setNumberCheck('success');
    } else {
      flag = true;
      setNumberCheck('fail');
    }
    if (flag) {
      props.isSecure(false);
    } else {
      props.isSecure(true);
    }
  };

  return (
    <div>
      {props.secured ? (
        <div className={'password-secure-ctr'}>
          <Image name={'securePassword.svg'} />
          <div data-testid='securePasswordText' className={'text'}>
            {'Your password is secure'}
          </div>
        </div>
      ) : (
        <div className={'password-validation-ctr'}>
          <div
            data-testid='minCharText'
            className={`validation ${minCheck === 'success' ? 'success-validation' : ''} ${
              minCheck === 'fail' ? 'fail-validation' : ''
            }`}
          >
            {'8 Characters Minimum'}
          </div>
          <div
            data-testid='maxCharText'
            className={`validation ${maxCheck === 'success' ? 'success-validation' : ''} ${
              maxCheck === 'fail' ? 'fail-validation' : ''
            }`}
          >
            {'12 Characters Maximum'}
          </div>
          <div
            data-testid='uppercaseCharText'
            className={`validation ${upperCheck === 'success' ? 'success-validation' : ''} ${
              upperCheck === 'fail' ? 'fail-validation' : ''
            }`}
          >
            {'One Uppercase Character'}
          </div>
          <div
            data-testid='specialCharText'
            className={`validation ${specialCheck === 'success' ? 'success-validation' : ''} ${
              specialCheck === 'fail' ? 'fail-validation' : ''
            }`}
          >
            <br />
            {'One Special Character'}
          </div>
          <div
            data-testid='numberText'
            className={`validation ${numberCheck === 'success' ? 'success-validation' : ''} ${
              numberCheck === 'fail' ? 'fail-validation' : ''
            }`}
          >
            {'One Number'}
          </div>
        </div>
      )}
    </div>
  );
}
PasswordValidation.defaultProps = {
  value: '',
  invalidate: false,
  isSecure: null,
  secured: false
};
