import { connect } from 'react-redux';
import { getTransactions as getTransactionsAPI } from '../../../../actions/dashboard';

const mapStateToProps = (state) => {
  return {
    transactions: state.transactions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionsAPI: (data) => dispatch(getTransactionsAPI(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
