import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Input from '../../../../coreComponent/Input/index';
import Button from '../../../../coreComponent/Button/index';
import PasswordValidation from '../../../../coreComponent/PasswordValidation';
import { useHistory } from 'react-router-dom';
import './ChangePassword.scss';

export default function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [validatePassword, setValidatePassword] = useState(false);
  const [isSecure, setIsSecure] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newPasswordErr, setNewPasswordErr] = useState('');
  const history = useHistory();

  const validateConfirmPassword = (value, passwordChanged) => {
    if (
      (confirmPassword && passwordChanged && value !== confirmPassword) ||
      (!passwordChanged && value !== password)
    ) {
      setPasswordError('This password does not match. Try again.');
    } else {
      setPasswordError('');
    }
  };

  const changePassword = () => {
    setSubmitting(true);
    let requestData = {
      old_password: oldPassword,
      new_password: password
    };
    props
      .changePasswordAPI(requestData)
      .then(() => {
        props.logout('change password', history);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.status === 403) {
          const errMsg = error?.data?.message || 'Something went wrong.';
          if (errMsg === 'Incorrect password.') {
            setOldPasswordError(errMsg);
            return;
          }
          setNewPasswordErr(errMsg);
        } else {
          setOldPasswordError('Something went wrong.');
        }
      });
  };

  const onHide = () => {
    setOldPassword('');
    setPassword('');
    setConfirmPassword('');
    setOldPasswordError('');
    setPasswordError('');
    setValidatePassword(false);
    setIsSecure(false);
    setSubmitting(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'change-password-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='changePasswordHeading' className={'heading'}>
            {'Change Password'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          label={'Old  Password*'}
          disabled={submitting}
          type={'password'}
          id={'opassword'}
          value={oldPassword}
          onChange={(value) => {
            setOldPassword(value);
            setOldPasswordError('');
          }}
          placeholder={'Enter Old Password'}
          error={oldPasswordError}
          errorWithIcon={true}
        />
        <Input
          label={'New  Password*'}
          disabled={submitting}
          type={'password'}
          id={'password'}
          value={password}
          onBlur={(value) => {
            setValidatePassword(true);
          }}
          onChange={(value) => {
            setPassword(value);
            setNewPasswordErr('');
            validateConfirmPassword(value, true);
          }}
          placeholder={'Enter New Password'}
          error={newPasswordErr}
          errorWithIcon={true}
        />
        <PasswordValidation
          value={password}
          invalidate={validatePassword}
          secured={isSecure && confirmPassword && !passwordError}
          isSecure={(secure) => {
            setIsSecure(secure);
          }}
        />
        <Input
          label={'Confirm Password*'}
          disabled={submitting}
          type={'password'}
          id={'cpassword'}
          value={confirmPassword}
          onChange={(value) => {
            setConfirmPassword(value);
            validateConfirmPassword(value);
          }}
          placeholder={'Confirm Password'}
          error={passwordError}
          errorWithIcon={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
            isDisabled={submitting}
          />
        </div>
        <Button
          text={'Save'}
          onClick={changePassword}
          width={'120px'}
          height={'50px'}
          isDisabled={
            !oldPassword ||
            !password ||
            !confirmPassword ||
            !isSecure ||
            oldPasswordError ||
            passwordError
          }
          isLoading={submitting}
        />
      </Modal.Footer>
    </Modal>
  );
}
ChangePassword.defaultProps = {
  show: false,
  onHide: null
};
