import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import Input from '../../../../coreComponent/Input/index';
import Button from '../../../../coreComponent/Button/index';
import SelectInput from '../../../../coreComponent/SelectInput';
import countryCodes from '../../../../utils/countryCodes.json';
import {
  validateName,
  validateNumber,
  validatesPhoneNumber,
  getIntlPhoneNumber
} from '../../../../utils/validations';
import './EditAdmin.scss';

export default function EditAdmin(props) {
  const [name, setName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setName(
      props.adminDetails.name
        ? props.adminDetails.name.first + ' ' + props.adminDetails.name.last
        : ''
    );
    let phoneNumber =
      '+' + props.adminDetails.phone_number
        ? getIntlPhoneNumber('+' + props.adminDetails.phone_number)
        : '';
    setCountryCode({
      label: phoneNumber ? `${phoneNumber.country} (+${phoneNumber.countryCallingCode})` : '',
      value: phoneNumber ? `+${phoneNumber.countryCallingCode}` : ''
    });
    setMobileNumber(phoneNumber ? phoneNumber.nationalNumber : '');
  }, [props.adminDetails]);

  const editAdmin = () => {
    setSubmitting(true);
    let requestData = {
      admin_id: props.adminDetails.admin_id,
      name: name,
      phone_number: countryCode.value + mobileNumber
    };
    props
      .editAdminAPI(requestData)
      .then(() => {
        setSubmitting(false);
        props.onSubmit();
        onHide();
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.status === 400) {
          setPhoneError('Phone number already exists. Try another.');
        }
      });
  };

  const onHide = () => {
    setName('');
    setCountryCode('');
    setMobileNumber('');
    setNameError('');
    setPhoneError('');
    setSubmitting(false);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={onHide}
      backdrop={'static'}
      dialogClassName={'edit-admin-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div data-testid='editHeading' className={'heading'}>
            {'Edit Details'}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          label={'Name*'}
          disabled={submitting}
          type={'text'}
          value={name}
          onChange={(value) => {
            setName(value);
            if (validateName(value)) {
              setNameError('');
            } else {
              setNameError('Please enter a valid name');
            }
          }}
          placeholder={'Enter Admin Name'}
          error={nameError}
          errorWithIcon={true}
        />
        <div className={'form-row'}>
          <div className={'select-ctr'}>
            <div data-testid='label' className={'label'}>
              {'Country Code*'}
            </div>
            <SelectInput
              defaultValue={countryCode}
              isDisabled={submitting}
              isSearchable={true}
              options={countryCodes}
              onSubmit={(value) => {
                setCountryCode(value);
                let phoneNumber = value.value + mobileNumber;
                if (
                  (mobileNumber && phoneNumber && validatesPhoneNumber(phoneNumber)) ||
                  !mobileNumber
                ) {
                  setPhoneError('');
                } else {
                  setPhoneError('Please enter a valid mobile number');
                }
              }}
            />
          </div>
          <Input
            label={'Mobile*'}
            disabled={submitting}
            type={'text'}
            value={mobileNumber}
            onBlur={(value) => {
              setMobileNumber(value);
              let phoneNumber = countryCode.value + value;
              if (phoneNumber && validatesPhoneNumber(phoneNumber)) {
                setPhoneError('');
              } else {
                setPhoneError('Please enter a valid mobile number');
              }
            }}
            onChange={(value) => {
              setMobileNumber(value);
              if (validateNumber(value)) {
                setPhoneError('');
              } else {
                setPhoneError('Please enter a valid mobile number');
              }
            }}
            placeholder={'Enter Admin Mobile Number'}
            error={phoneError}
            errorWithIcon={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
            isDisabled={submitting}
          />
        </div>
        <Button
          text={'Save'}
          onClick={editAdmin}
          width={'120px'}
          height={'50px'}
          isDisabled={!name || !countryCode || !mobileNumber || nameError || phoneError}
          isLoading={submitting}
        />
      </Modal.Footer>
    </Modal>
  );
}
EditAdmin.defaultProps = {
  show: false,
  onHide: null,
  onSubmit: null,
  adminDetails: null
};
