import { connect } from 'react-redux';
import { uploadOnS3 } from '../../actions/demoUI';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadOnS3: (data) => dispatch(uploadOnS3(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
