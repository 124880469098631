import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import UploadDocument from '../../../coreComponent/UploadDocument/index';
import Loader from '../../../coreComponent/Loader/index';
import Button from '../../../coreComponent/Button/index';
import DemoNotes from '../DemoNotes/index';
import DemoUrl from '../DemoUrl/index';
import DemoCard from '../DemoCard/index';
import Input from '../../../coreComponent/Input/index';
import moment from 'moment';
import Checkbox from '../../../coreComponent/Checkbox';
import { addDocumentOnlyForOCR as addDocumentOnlyForOCRAPI } from '../../../api/demoUI';
import { isSpecialCharacter, validateName } from '../../../utils/validations';
import { getErrorResponseMessage } from '../../../utils/utilities';

export default function PassportVerification(props) {
  const [document1, setDocument1] = useState('');
  const [document2, setDocument2] = useState('');
  const [docError, setDocError] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [docLoading, setDocLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestHeaders, setRequestHeaders] = useState('');
  const [reqData, setReqData] = useState('');
  const [resData, setResData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isOCR, setIsOCR] = useState(true);
  const [idNumber, setIdNumber] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [passportFileNumber, setPassportFileNumber] = useState('');
  const [dobError, setDobError] = useState('');
  const [passportFileError, setPassportFileError] = useState('');
  const [idError, setIdError] = useState('');
  const [nameError, setNameError] = useState('');
  const [apiActivityIsVerification, setApiActivityIsVerification] = useState(false);
  const [successParameters, setSuccessParameters] = useState([
    'id_number',
    'passport_file_number',
    'dob'
  ]);
  const [requestID, setRequestID] = useState('');
  const [onlyOCRDocument1, setOnlyOCRDocument1] = useState('');
  const [onlyOCRDocument2, setOnlyOCRDocument2] = useState('');
  const [ocrReqData, setOcrReqData] = useState('');
  const [ocrResData, setOcrResData] = useState('');
  const [onlyOcrDocError, setOnlyOcrDocError] = useState('');
  const [onlyOcrErrorMsg, setOnlyOcrErrorMsg] = useState('');
  const [successMsgForOcrOnly, setSuccessMsgFroOcrOnly] = useState('');
  const [isSubmittedForOcrOnly, setIsSubmittedForOcrOnly] = useState(false);
  const [isOnlyOcrDisabled, setOnlyOcrIsDisabled] = useState(true);
  const [onlyOcrErrorCode, setOnlyOcrErrorCode] = useState('');
  useEffect(() => {
    if (props.uiActive === 'Passport') {
      loadData();
      if (props.passportOnlyOcrResponse && !isEmpty(props.passportOnlyOcrResponse)) {
        const responseData = props.passportOnlyOcrResponse;
        setOcrResData(JSON.stringify(responseData));
        setIsSubmittedForOcrOnly(true);
      }
      if (props.passportOcrResponse && !isEmpty(props.passportOcrResponse)) {
        const responseData = props.passportOcrResponse;
        setResData(JSON.stringify(responseData));
        setIsEditable(false);
        setRequestID(responseData.configuration.api_config.request_id);
        setIsSubmitted(true);
      }
    }
  }, []);

  const loadData = () => {
    let data = props.uiPerson;
    if (data && data.documents && data.documents.ind_passport) {
      if (data.documents.ind_passport.document1 && !isEmpty(props.passportOnlyOcrResponse)) {
        setOnlyOCRDocument1(data.documents.ind_passport.document1);
        setOnlyOcrIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setOcrReqData('no data');
      }
      if (data.documents.ind_passport.document2 && !isEmpty(props.passportOnlyOcrResponse)) {
        setOnlyOCRDocument2(data.documents.ind_passport.document2);
      }
      if (data.documents.ind_passport.document1 && !isEmpty(props.passportOcrResponse)) {
        setDocument1(data.documents.ind_passport.document1);
        setIsDisabled(false);
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
      }
      if (data.documents.ind_passport.document2 && !isEmpty(props.passportOcrResponse)) {
        setDocument2(data.documents.ind_passport.document2);
      }
    }
    setDocLoading(false);
  };

  const getDocumentUrl = (url, index) => {
    setDocError('');
    if (index === 1) {
      apiActivityIsVerification ? setDocument1(url) : setOnlyOCRDocument1(url);
      setIsDisabled(false);
      setOnlyOcrIsDisabled(false);
    } else {
      apiActivityIsVerification ? setDocument2(url) : setOnlyOCRDocument2(url);
    }
    setRequestHeaders('Content type: application/json');
    setReqData('no data');
  };

  const addDocument = () => {
    let requestBody = {
      docType: 'ind_passport',
      personId: props.personId
    };
    if (document1 || document2 || requestID) {
      if (document1 || document2) {
        requestBody['document_front'] = document1;
        requestBody['document_back'] = document2;
        requestBody['success_parameters'] = ['id_number', 'passport_file_number', 'dob'];
        if (!document1) {
          setIsLoading(false);
          setIsDisabled(true);
          setDocError('Front image is missing');
          return false;
        }
        addDocumentOnlyForOCRAPI(requestBody)
          .then((res) => {
            const responseData = res.data;
            const requestId = responseData.configuration.api_config.request_id;
            if (requestId) {
              requestBody['request_id'] = requestId;
              requestBody['success_parameters'] = successParameters;
              setRequestID(requestId);
              govtVerificationWithOCR(requestBody);
            }
          })
          .catch((error) => {
            if (error.status === 402) {
              props.showAlert('insufficient');
            } else {
              props.reloadCredits();
              setDocError(error.api_status_description || 'Something went wrong.');
            }
            setIsLoading(false);
            setIsEditable(true);
            setErrorMsg(error.api_status_description);
            setIsDisabled(true);
          });
      } else {
        requestBody['success_parameters'] = successParameters;
        requestBody['request_id'] = requestID;
        govtVerificationWithOCR(requestBody);
      }
    } else {
      setDocError('Either document image or request ID is missing');
      setIsLoading(false);
      setIsDisabled(true);
      setIsEditable(true);
    }
  };

  const addDocumentOnlyForOCR = () => {
    let requestBody = {
      docType: 'ind_passport',
      success_parameters: [
        'id_number',
        'passport_file_number',
        'dob'
      ] /* In ocr only id_number success parameter is req.*/,
      personId: props.personId,
      document_front: onlyOCRDocument1,
      document_back: onlyOCRDocument2
    };

    setOcrReqData(JSON.stringify(requestBody));

    props
      .addDocumentOnlyForOCR('ind_passport', requestBody)
      .then((res) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmittedForOcrOnly(true);
        const responseData = res;
        setOcrResData(JSON.stringify(responseData));
        setSuccessMsgFroOcrOnly('Passport OCR is done successful');
        setOnlyOcrErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
          // setOnlyOcrDocError(error.data.api_status_description || 'Something went wrong.');
        }
        setOcrResData(JSON.stringify(error));
        setIsSubmittedForOcrOnly(true);
        setSuccessMsgFroOcrOnly('');
        setIsLoading(false);
        setOnlyOcrErrorCode(error.status);
        const errMessage = error?.data?.api_status_message
          ? error?.data?.api_status_message
          : error?.data?.api_status_description;
        setOnlyOcrErrorMsg(errMessage || 'Something went wrong');
        setOnlyOcrIsDisabled(true);
      });
  };

  const govtVerificationWithOCR = (requestBody) => {
    setReqData(JSON.stringify(requestBody));
    props
      .govtVerificationWithOCR(requestBody, 'ind_passport')
      .then((response) => {
        props.updateUIPerson(true);
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(response));
        setErrorCode('');
        setErrorMsg('');
        setSuccessMsg('Passport Document Check successful.');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        }
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };

  const validateDOB = (date) => {
    if (!date) {
      setDobError('Date is required');
      setIsDisabled(true);
      return false;
    } else if (!moment(date, 'YYYY-MM-DD').isValid()) {
      setDobError('Invalid date');
      setIsDisabled(true);
      return false;
    } else if (!date.match('[0-9]{4}-[0-1][0-9]-[0-3][0-9]')) {
      setDobError('Invalid D.O.B');
      setIsDisabled(true);
      return false;
    } else {
      setDobError('');
      return true;
    }
  };

  const validatePassportNumber = (id) => {
    if (!id) {
      setIdError('Passport Number is required');
      setIsDisabled(true);
      return false;
    }
    if (!id.match('[A-Z]{1}[0-9]{7}')) {
      setIdError('Invalid Passport Number');
      setIsDisabled(true);
      return false;
    } else {
      setIdError('');
      return true;
    }
  };

  const validatePassportFileNumber = (id) => {
    if (!id) {
      setPassportFileError('Passport File Number is required');
      setIsDisabled(true);
      return false;
    }
    if (!id.match('[A-Z]{2}[0-9]{13}')) {
      setPassportFileError('Invalid Passport File Number');
      setIsDisabled(true);
      return false;
    } else {
      setPassportFileError('');
      return true;
    }
  };

  const validateNameInput = (nameInput) => {
    if (!nameInput) {
      setNameError('Name is Required');
      setIsDisabled(true);
    } else if (isSpecialCharacter(nameInput)) {
      setNameError('Special characters not allowed.');
      setIsDisabled(true);
    } else if (!validateName(nameInput)) {
      setNameError('Name should contains only alphabets');
      setIsDisabled(true);
    } else {
      setNameError('');
      return true;
    }
  };

  const isSuccessParameterReqError = () => {
    let error = false;
    if (successParameters.includes('name') && !name) {
      error = true;
      setNameError('Name is required');
    }
    return error;
  };

  const handleSubmit = () => {
    if (props.credits === 0) {
      props.showAlert('zero');
    } else {
      if (!apiActivityIsVerification) {
        setOnlyOcrErrorCode('');
        setOnlyOcrErrorMsg('');
        setIsLoading(true);
        addDocumentOnlyForOCR();
      } else if (isOCR && apiActivityIsVerification) {
        setIsLoading(true);
        setIsEditable(false);
        addDocument();
      } else {
        if (
          !isSuccessParameterReqError() &&
          validatePassportFileNumber(passportFileNumber) &&
          validatePassportNumber(idNumber) &&
          validateDOB(dob) &&
          !nameError
        ) {
          let requestData = {
            doc_type: 'ind_passport',
            id_number: idNumber,
            passport_file_no: passportFileNumber,
            date_of_birth: dob,
            name_on_card: name
          };
          setIsEditable(false);
          setIsLoading(true);
          setRequestHeaders('Content type: application/json');
          setReqData(JSON.stringify(requestData));
          govtVerificationWithoutOCR();
        }
      }
    }
  };
  const govtVerificationWithoutOCR = () => {
    let requestBody = {
      docType: 'ind_passport',
      success_parameters: successParameters,
      personId: props.personId,
      manual_input: {
        id_number: idNumber,
        passport_file_number: passportFileNumber,
        dob: dob
      }
    };
    if (name) {
      requestBody['manual_input']['name'] = name;
    }
    props
      .govtVerificationWithoutOCR('ind_passport', requestBody)
      .then((response) => {
        props.reloadCredits();
        setIsLoading(false);
        setIsSubmitted(true);
        let responseData = response;
        setResData(JSON.stringify(responseData));
        setSuccessMsg('Passport check successful.');
        setErrorMsg('');
        setIsEditable(false);
        setErrorCode('');
        setErrorMsg('');
      })
      .catch((error) => {
        if (error.status === 402) {
          props.showAlert('insufficient');
        } else {
          props.reloadCredits();
        }
        setIsLoading(false);
        setIsSubmitted(true);
        setResData(JSON.stringify(error));
        setErrorCode(error.status);
        setErrorMsg(getErrorResponseMessage(error));
        setSuccessMsg('');
      });
  };
  const reset = () => {
    if (!apiActivityIsVerification) {
      props.invalidatePassportOnlyOcrResponse();
      if (props.identityDocType === 'ind_voter_id') {
        props.invalidateIdentityResponse();
      }
      setOcrReqData('');
      setOcrResData('');
      setOnlyOCRDocument1('');
      setOnlyOCRDocument2('');
      setOnlyOcrDocError('');
      setSuccessMsgFroOcrOnly('');
      setOnlyOcrErrorCode('');
      setOnlyOcrErrorMsg('');
      setIsSubmittedForOcrOnly(false);
      setOnlyOcrIsDisabled(true);
    } else {
      if (isOCR && apiActivityIsVerification) {
        props.invalidatePassportOcrResponse();
        if (props.identityDocType === 'ind_voter_id') {
          props.invalidateIdentityResponse();
        }
        setDocument1('');
        setDocument2('');
        setRequestID('');
        setDocError('');
      } else {
        props.invalidatePassportResponse();
        setIdNumber('');
        setDob('');
        setPassportFileNumber('');
        setName('');
        setNameError('');
        setIdNumber('');
        setPassportFileError('');
        setDobError('');
      }
      setIsEditable(true);
      setDocLoading(false);
      setIsLoading(false);
      setIsDisabled(true);
      setIsSubmitted(false);
      setRequestHeaders('');
      setReqData('');
      setResData('');
      setSuccessMsg('');
      setErrorCode('');
      setErrorMsg('');
    }
    setSuccessParameters(['id_number', 'passport_file_number', 'dob']);
  };

  const setInputType = (ocr) => {
    setSuccessParameters(['id_number', 'passport_file_number', 'dob']);
    setSuccessMsg('');
    setErrorCode('');
    setErrorMsg('');
    if (ocr) {
      setIsOCR(true);
      if (props.passportOcrResponse && !isEmpty(props.passportOcrResponse)) {
        let responseData = props.passportOcrResponse;
        setResData(JSON.stringify(responseData));
        setRequestHeaders('Content type: application/json');
        setReqData('no data');
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setResData('');
        setIsEditable(true);
        setIsDisabled(!document1);
        setIsSubmitted(false);
      }
    } else {
      setIsOCR(false);
      if (props.passportResponse && !isEmpty(props.passportResponse)) {
        const data = props.passportResponse;
        const id = data.output.source.id_number;
        const idName = data.output.source.name;
        const d_o_b = data.output.source.dob;
        const file_number = data.output.source.passport_file_number;
        let requestData = {
          doc_type: 'ind_passport',
          id_number: id,
          name_on_card: idName,
          date_of_birth: d_o_b,
          passport_file_no: file_number
        };
        setIdNumber(id);
        setName(idName);
        setDob(d_o_b);
        setPassportFileNumber(file_number);
        setResData(JSON.stringify(data));
        setRequestHeaders('Content type: application/json');
        setReqData(JSON.stringify(requestData));
        setIsEditable(false);
        setIsSubmitted(true);
      } else {
        setResData('');
        setRequestHeaders('');
        setReqData('');
        setIsEditable(true);
        setIsDisabled(idNumber ? false : true);
        setIsSubmitted(false);
      }
    }
  };

  const setApiActivity = (isVerification) => {
    if (isVerification) {
      setApiActivityIsVerification(true);
    } else {
      setApiActivityIsVerification(false);
    }
  };
  return (
    <div className={'demo-main-ctr'}>
      <div className={'heading'}>{'Passport (IND)'}</div>
      <div className={'sub-heading'}>
        {
          'Real time check of MRZ code to establish verification of user identity against Passport details'
        }
      </div>
      <div className={'details-ctr'}>
        <div className={'input-details-ctr'}>
          <div className={'title'}>{'Input details'}</div>
          {/* Api Activity Radio Button */}
          <div className={'radio-ctr'}>
            <div className={'radio-heading'}>{'Choose activity:'}</div>
            <div className={'radio-input'}>
              <input
                className={'input'}
                type={'radio'}
                value={true}
                checked={apiActivityIsVerification}
                onChange={() => setApiActivity(true)}
              />
              <div className={'label'} onClick={() => setApiActivity(true)}>
                {'Verification'}
              </div>
              <input
                className={'input'}
                type={'radio'}
                value={false}
                checked={!apiActivityIsVerification}
                onChange={() => setApiActivity(false)}
              />
              <div className={'label'} onClick={() => setApiActivity(false)}>
                {'OCR'}
              </div>
            </div>
          </div>
          {/* Success Parameter Display */}
          <div className={'check-ctr'}>
            <div className={'check-heading'}>{'Success Parameter'}</div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'fileNumber'}
                  label={'File Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'idNumber'}
                  label={'ID Number'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
            </div>
            <div className={'check-input'}>
              <div className={'checkbox-container'}>
                <Checkbox
                  id={'dob'}
                  label={'Date of Birth'}
                  disabled={true}
                  disabledWithGreyColor={true}
                  checked={true}
                />
              </div>
              {apiActivityIsVerification && (
                <div className={'checkbox-container'}>
                  <Checkbox
                    id={'name'}
                    label={'Name'}
                    disabled={false}
                    checked={successParameters.includes('name')}
                    onClick={() => {
                      setSuccessParameters(_.xor(successParameters, ['name']));
                      setNameError('');
                      setIsDisabled(false);
                      if (name) validateNameInput(name);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {apiActivityIsVerification && (
            <div className={'radio-ctr'}>
              <div className={'radio-heading'}>{'Choose verification type:'}</div>
              <div className={'radio-input'}>
                <input
                  className={'input'}
                  type={'radio'}
                  value={true}
                  checked={isOCR}
                  onChange={() => setInputType(true)}
                />
                <div className={'label'} onClick={() => setInputType(true)}>
                  {'OCR'}
                </div>
                <input
                  className={'input'}
                  type={'radio'}
                  value={false}
                  checked={!isOCR}
                  onChange={() => setInputType(false)}
                />
                <div className={'label'} onClick={() => setInputType(false)}>
                  {'Manual Input'}
                </div>
              </div>
            </div>
          )}
          {/*  If Chosen api activity is OCR */}
          {!apiActivityIsVerification && (
            <div>
              <div className={'image-label upper-label'}>{'ID Image :'}</div>
              {docLoading ? (
                <div className={'upload-double-ctr'}>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                  <div className={'upload-small-ctr'}>
                    <div className={'loader'}>
                      <Loader color={'#002C9D'} size={'24px'} />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <UploadDocument
                    isDouble={true}
                    image1={onlyOCRDocument1}
                    image2={onlyOCRDocument2}
                    isEditable={!isSubmittedForOcrOnly}
                    personId={props.personId}
                    getUrl={(url, index) => getDocumentUrl(url, index)}
                    handleCancel={(index) => {
                      setOnlyOcrDocError('');
                      if (index === 1) {
                        setOnlyOCRDocument1('');
                        setOnlyOcrIsDisabled(true);
                        if (props.identityDocType === 'ind_passport') {
                          props.invalidateIdentityResponse();
                        }
                      } else {
                        setOnlyOCRDocument2('');
                      }
                    }}
                  />
                  {onlyOcrDocError ? <div className={'upload-error'}>{onlyOcrDocError}</div> : null}
                </div>
              )}

              <DemoNotes showNotes={'document'} isReports={true} />
            </div>
          )}
          {apiActivityIsVerification &&
            (isOCR ? (
              <div>
                <div className={'image-label upper-label'}>{'ID Image :'}</div>
                {docLoading ? (
                  <div className={'upload-double-ctr'}>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                    <div className={'upload-small-ctr'}>
                      <div className={'loader'}>
                        <Loader color={'#002C9D'} size={'24px'} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <UploadDocument
                      isDouble={true}
                      image1={document1}
                      image2={document2}
                      isEditable={isEditable}
                      personId={props.personId}
                      getUrl={(url, index) => getDocumentUrl(url, index)}
                      handleCancel={(index) => {
                        setDocError('');
                        if (index === 1) {
                          setDocument1('');
                          setIsDisabled(true);
                          if (props.identityDocType === 'ind_passport') {
                            props.invalidateIdentityResponse();
                          }
                        } else {
                          setDocument2('');
                        }
                      }}
                    />
                    {docError ? <div className={'upload-error'}>{docError}</div> : null}
                  </div>
                )}
                <div className={'or-label'}>{'OR'}</div>
                <div>
                  <Input
                    label={'Request ID'}
                    disabled={isSubmitted ? true : false}
                    type={'text'}
                    maxLength={100}
                    value={requestID ? requestID : ''}
                    onChange={(value) => {
                      document1
                        ? setIsDisabled(false)
                        : value
                        ? setIsDisabled(false)
                        : setIsDisabled(true);
                      setRequestID(value);
                    }}
                    placeholder={'OCR request ID'}
                    id={'req_id'}
                    showClear={isSubmitted ? false : true}
                    onClear={() => {
                      setRequestID('');
                      document1 ? setIsDisabled(false) : setIsDisabled(true);
                    }}
                  />
                </div>
                <DemoNotes showNotes={'document'} isReports={true} />
              </div>
            ) : (
              <div className='input-ctr'>
                <Input
                  label={'File Number'}
                  disabled={!isEditable}
                  type={'text'}
                  isRequiredField={true}
                  maxLength={15}
                  value={passportFileNumber}
                  onBlur={(value) => {
                    if (value) validatePassportFileNumber(value);
                  }}
                  onChange={(value) => {
                    setPassportFileError('');
                    setIsDisabled(!value);
                    setPassportFileNumber(value.trim().toUpperCase());
                  }}
                  placeholder={'Ex: 5378 44563897 2303'}
                  error={passportFileError}
                  showClear={true}
                  onClear={() => {
                    setPassportFileError('');
                    setPassportFileNumber('');
                    setIsDisabled(true);
                  }}
                />
                <Input
                  label={'ID Number'}
                  disabled={!isEditable}
                  type={'text'}
                  isRequiredField={true}
                  maxLength={8}
                  value={idNumber}
                  onBlur={(value) => {
                    if (value) validatePassportNumber(value);
                  }}
                  onChange={(value) => {
                    setIdError('');
                    setIdNumber(value.trim().toUpperCase());
                    setIsDisabled(!value);
                  }}
                  placeholder={'Ex: H456 7893'}
                  error={idError}
                  showClear={true}
                  onClear={() => {
                    setIdError('');
                    setIdNumber('');
                    setIsDisabled(true);
                  }}
                />
                <Input
                  label={'Date of Birth'}
                  disabled={!isEditable}
                  type={'text'}
                  isRequiredField={true}
                  maxLength={10}
                  value={dob}
                  onBlur={(value) => {
                    if (value) validateDOB(value);
                  }}
                  onChange={(value) => {
                    setDobError('');
                    setIsDisabled(!value);
                    setDob(value.trim());
                  }}
                  placeholder={'YYYY-MM-DD'}
                  error={dobError}
                  showClear={true}
                  onClear={() => {
                    setDobError('');
                    setDob('');
                    setIsDisabled(true);
                  }}
                />
                {apiActivityIsVerification && (
                  <Input
                    label={'Name'}
                    disabled={!isEditable}
                    type={'text'}
                    value={name}
                    onBlur={(value) => {
                      if (value) {
                        validateNameInput(value);
                      }
                    }}
                    onChange={(value) => {
                      setNameError('');
                      setName(value.trim().toUpperCase());
                      setIsDisabled(false);
                    }}
                    error={nameError}
                    placeholder={'Name'}
                    showClear={true}
                    onClear={() => {
                      setName('');
                      setNameError('');
                      setIsDisabled(false);
                    }}
                  />
                )}
              </div>
            ))}
          <div className={'submit-btn'}>
            {apiActivityIsVerification ? (
              isSubmitted ? (
                <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
              ) : (
                <Button
                  text={'Submit'}
                  onClick={handleSubmit}
                  width={'132px'}
                  height={'47px'}
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  isWhiteBtn={isDisabled || isLoading}
                />
              )
            ) : isSubmittedForOcrOnly ? (
              <Button text={'Reset'} onClick={reset} width={'132px'} height={'47px'} />
            ) : (
              <Button
                text={'Submit'}
                onClick={handleSubmit}
                width={'132px'}
                height={'47px'}
                isDisabled={isOnlyOcrDisabled}
                isLoading={isLoading}
                isWhiteBtn={isOnlyOcrDisabled || isLoading}
              />
            )}
          </div>
        </div>
        <div className={'result-ctr'}>
          <div className={'title'}>{'Result'}</div>
          <DemoUrl url={!apiActivityIsVerification ? 'v4/ocr/' : 'v4/databaseCheck'} />
          <div className={'request-response-ctr'}>
            <DemoCard
              data={apiActivityIsVerification ? reqData : ocrReqData}
              headers={requestHeaders}
            />
            <DemoCard
              isOpen={true}
              title={'Response'}
              data={apiActivityIsVerification ? resData : ocrResData}
              successMsg={apiActivityIsVerification ? successMsg : successMsgForOcrOnly}
              errorCode={apiActivityIsVerification ? errorCode : onlyOcrErrorCode}
              errorMsg={apiActivityIsVerification ? errorMsg : onlyOcrErrorMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
