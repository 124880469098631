import React from 'react';
import { Modal } from 'react-bootstrap';

import Button from '../../../../coreComponent/Button/index';
import './DiscardSettings.scss';

export default function DiscardSettings(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName={'discard-settings-ctr'}
      backdropClassName={'modal-background'}
    >
      <Modal.Header>
        <Modal.Title>
          <div className={'heading'}>{'Disable reminder for credit usage alert?'}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'text'}>
          {
            'Your setting to get reminder mail or sms for credit usage will be turned off for this account.'
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'cancel-btn'}>
          <Button
            text={'Cancel'}
            onClick={props.onHide}
            width={'120px'}
            height={'50px'}
            isWhiteBtn={true}
          />
        </div>
        <Button
          text={'Discard'}
          onClick={() => {
            props.onSubmit();
            props.onHide();
          }}
          width={'120px'}
          height={'50px'}
        />
      </Modal.Footer>
    </Modal>
  );
}
DiscardSettings.defaultProps = {
  show: false,
  onHide: null,
  onSubmit: null
};
