import http from './http';
import AppConstants from '../coreComponent/AppConstants';
import AppHelper from '../coreComponent/AppHelper';

let baseUrl = AppConstants.developmentAPIURL;
let headers = {
  'Content-Type': 'application/json',
  token: AppHelper.getToken()
};
let config = {
  headers: headers
};

export const login = (data) => {
  return http.post(`${baseUrl}v3/auth/login`, data, config);
};

export const logout = (baseUri, jwtToken, token) => {
  const logoutHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${jwtToken}`,
    token: token
  };

  const customConfig = {
    headers: logoutHeaders
  };
  return http.post(`${baseUri}v3/auth/logout`, {}, customConfig);
};

// Google or Ms login API
export const GoogleOrMsLogin = (path, data) => {
  return http.post(`${baseUrl}v3/auth/${path}`, data, config);
};

export const signup = (data) => {
  return http.post(`${baseUrl}v3/signup/createCompany`, data, config);
};

export const sendVerificationLink = (data) => {
  return http.post(`${baseUrl}v3/signup/sendVerificationLink`, data, config);
};

export const accountSetup = (data, customConfig) => {
  return http.post(`${baseUrl}v3/signup/setupAccount`, data, customConfig);
};

export const forgotPassword = (data) => {
  return http.post(`${baseUrl}v3/auth/sendResetPasswordLink`, data, config);
};

export const setPassword = (data) => {
  return http.post(`${baseUrl}v3/auth/setPassword`, data, config);
};

export const getInvoiceDetails = (data) => {
  let headersData = {
    headers: headers,
    params: data
  };
  return http.get(`${baseUrl}credits/getInvoiceDetails`, headersData);
};
